import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { EXAM_MODULE_URLS } from "modules/exam-module/constants/exam-modules-urls";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";

function ListModulesStudent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [completedApplicationData, setCompletedApplicationData] = useState([]);
  const userData = sessionstorageService.getUserData("userData");
  const studentId = userData?.admissionManagement?.personalData?.studentId;

  useEffect(() => {
    if (studentId) {
      axiosService
        .get(`${EXAM_MODULE_URLS.GET_STUDENT_TABLE}?studentId=${studentId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data?.data;
            setCompletedApplicationData(responseData);
          }
        });
    }
  }, [location.pathname, studentId]);

  const onRowClicked = (event) => {
    navigate("/academics-management/my-module-view", {
      state: {
        data: {
          Data: event.data?.module?.module,
        },
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Module Code",
      field: "module.module.moduleCode",
    },
    {
      headerName: "Module Name",
      field: "module.module.moduleName",
      cellStyle: { textDecoration: "underline" },
    },
    {
      headerName: "Total Hours",
      field: "module.module.totalHours",
    },
    {
      headerName: "Beta Credits",
      field: "module.module.betaCredits",
    },
    {
      headerName: "Level",
      field: "module.module.level",
    },
    {
      headerName: "Status",
      field: "module.module.status",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">My Modules </span>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={completedApplicationData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ListModulesStudent;
