import { AppConfig } from "env.development";

export const CLASS_URLS = {
  GET_ALL_PROGRAMS: `${AppConfig.api_url}program-fee/v1/all`,
  GET_CAMPUS: `${AppConfig.api_url}program-fee/v1/campus/`,
  GET_PROGRAM_AND_SEMESTER_ID: `${AppConfig.api_url}admission/v1/program-fee`,
  GET_SEMESTER: `${AppConfig.api_url}program/v1/semester/all/`,
  GET_PROGRAM_MASTER_ID: `${AppConfig.api_url}program/v1/semester`,
  GET_RESOURCE: `${AppConfig.api_url}resource/v1/campus/all/`,
  GET_TIME: `${AppConfig.api_url}schedule/v1/drop-down`,
  POST_URL: `${AppConfig.api_url}schedule/v1`,
  GET_ALL: `${AppConfig.api_url}schedule/v1/all`,
};
