import React from "react";
import "./AddExtraCurricular.css";
import { Field, Formik, Form, ErrorMessage } from "formik";
import Modal from "react-modal";
import * as Yup from "yup";
import vector_image from "assets/images/StudentPortal/Add-can.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { STUDENT_PORTAL_EXTRACURRICULAR_URL } from "modules/student-portal/constants/student-portal-urls";
import { STUDENT_PORTAL_EXTRACURRICULAR_CONSTANTS } from "modules/student-portal/constants/extra-eurricular-constants";

function AddExtraCurricular({ open, onClose }) {
  const [participationService] = useState("");

  const initialValues = {
    activityType: "",
    position: "",
    gradeLevels: "",
    participationTime: "",
    totalHoursSpent: "",
  };

  const validationSchema = Yup.object().shape({
    activityType: Yup.string().required("Activity type is required"),
    position: Yup.string().required("Position is required"),
    gradeLevels: Yup.string().required("Grade levels is required"),
    participationTime: Yup.string().required("Participation time is required"),
    totalHoursSpent: Yup.string().required("Total hours spent is required"),
  });

  const onSubmit = async (values) => {
    try {
      const requestData = {
        activityType: values.activityType,
        position: values.position,
        gradeLevels: values.gradeLevels,
        participationTime: values.participationTime,
        totalHoursSpent: values.totalHoursSpent,
      };

      const response = await axiosService.post(
        `${STUDENT_PORTAL_EXTRACURRICULAR_URL.POST_EXTRACURRICULAR_URL}`,
        requestData
      );

      if (response.status === 200) {
        toast.success(STUDENT_PORTAL_EXTRACURRICULAR_CONSTANTS.SUCCESS);
        onClose();
      } else {
        toast.error(`Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      toast.error("An error occurred while creating the task");
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      contentLabel="Example Modal"
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="modal-Con justify-content-lg-end">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#333333" }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={participationService || initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, handleChange, handleBlur }) => (
              <Form>
                <div className="asps-main-container p-0">
                  <div className="asps-modal-top">
                    <span className="asps-extracurricular-modal-title">
                      Add an activity
                    </span>
                    <div>
                      <img
                        src={vector_image}
                        className="asps-close-icon"
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </div>
                  </div>
                  <div className="asps-participation-service">
                    <label className="asps-participation-activity-title">
                      Activity Type *
                    </label>
                    <Field
                      className="asps-ecc-activity-field w-100"
                      name="activityType"
                      placeholder="Enter type"
                    />
                    <ErrorMessage
                      name="activityType"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-service">
                    <label>Position / Leadership *</label>
                    <Field
                      className="asps-ecc-position-field w-100"
                      name="position"
                      placeholder="Enter position"
                    />
                    <ErrorMessage
                      name="position"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-service">
                    <label>Grade Level *</label>
                    <Field
                      className="asps-ecc-grade-field w-100"
                      name="gradeLevels"
                      placeholder="Enter grade"
                    />
                    <ErrorMessage
                      name="gradeLevels"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-service">
                    <label>Time of participation *</label>
                    <Field
                      className="asps-ecc-time-field w-100"
                      name="participationTime"
                      placeholder="Enter time"
                    />
                    <ErrorMessage
                      name="participationTime"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-service">
                    <label>Total hours spent *</label>
                    <Field
                      className="asps-ecc-hour-field w-100"
                      name="totalHoursSpent"
                      placeholder="Enter hour"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/, "");
                        handleChange("totalHoursSpent")(numericValue);
                      }}
                      onBlur={handleBlur("totalHoursSpent")}
                      value={values.totalHoursSpent}
                    />
                    <ErrorMessage
                      name="totalHoursSpent"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="d-flex gap-3 asps-all-buttons">
                    <button
                      className="communication-management-cancel-button"
                      type="button"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="communication-management-submit-button"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddExtraCurricular;
