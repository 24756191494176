import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import "./NotificationList.css";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import { CIRCULAR_URLS } from "../circular-notice/constants/circular-urls";
import NotificationModal from "./NotificationModal";
import { useLocation } from "react-router-dom";

function NotificationList() {
  const [notificationData, setNotificationData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(false);

  const location = useLocation();
  useEffect(() => {
    axiosService
      .get(`${CIRCULAR_URLS.GET_NOTIFICATION_BY_USER_ID}${userData?.userId}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data?.data;
          console.log(response, "response");
          setNotificationData(responseData);
        }
      });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    setData(event.data);
    setModalOpen(true);
  };
  const userData = sessionService.getUserData("userData");

  const [columnDefs] = useState([
    {
      headerName: "Student Id",
      field: "studentId",
      width: 250,
    },
    {
      headerName: "Notification",
      field: "notification",
      cellRenderer: function (params) {
        const value = params.value;
        return <p className="notification-list-notification-text">{value}</p>;
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header align-items-center flex-wrap flex-row gap-2">
        <span className="lead-table-title-text">All Notifications </span>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={notificationData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>

      <NotificationModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        data={data}
      />
    </div>
  );
}

export default NotificationList;
