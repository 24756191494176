import React from 'react'

const EditIcon2 = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0013 1.39068C14.0915 1.48117 14.1421 1.60373 14.1421 1.7315C14.1421 1.85927 14.0915 1.98183 14.0013 2.07232L12.9942 3.08029L11.0633 1.14931L12.0703 0.141341C12.1608 0.0508404 12.2836 0 12.4116 0C12.5396 0 12.6623 0.0508404 12.7529 0.141341L14.0013 1.38972V1.39068ZM12.3116 3.76192L10.3807 1.83094L3.8028 8.40978C3.74966 8.4629 3.70966 8.5277 3.68598 8.59901L2.90876 10.9297C2.89467 10.9722 2.89266 11.0178 2.90298 11.0613C2.9133 11.1049 2.93553 11.1447 2.96718 11.1764C2.99884 11.208 3.03867 11.2303 3.08223 11.2406C3.12579 11.2509 3.17137 11.2489 3.21386 11.2348L5.54454 10.4576C5.61576 10.4342 5.68055 10.3945 5.73378 10.3417L12.3116 3.76192Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5452C0 12.9293 0.152581 13.2976 0.424177 13.5692C0.695773 13.8408 1.06414 13.9934 1.44823 13.9934H12.0686C12.4527 13.9934 12.8211 13.8408 13.0927 13.5692C13.3642 13.2976 13.5168 12.9293 13.5168 12.5452V6.75223C13.5168 6.6242 13.466 6.50141 13.3754 6.41088C13.2849 6.32035 13.1621 6.26949 13.0341 6.26949C12.9061 6.26949 12.7833 6.32035 12.6927 6.41088C12.6022 6.50141 12.5513 6.6242 12.5513 6.75223V12.5452C12.5513 12.6732 12.5005 12.796 12.41 12.8865C12.3194 12.977 12.1966 13.0279 12.0686 13.0279H1.44823C1.3202 13.0279 1.19741 12.977 1.10688 12.8865C1.01635 12.796 0.965488 12.6732 0.965488 12.5452V1.92479C0.965488 1.79676 1.01635 1.67397 1.10688 1.58344C1.19741 1.49291 1.3202 1.44205 1.44823 1.44205H7.7239C7.85193 1.44205 7.97472 1.39119 8.06525 1.30066C8.15579 1.21013 8.20665 1.08734 8.20665 0.959306C8.20665 0.831275 8.15579 0.708487 8.06525 0.617955C7.97472 0.527423 7.85193 0.476563 7.7239 0.476562H1.44823C1.06414 0.476563 0.695773 0.629144 0.424177 0.90074C0.152581 1.17234 0 1.5407 0 1.92479V12.5452Z" fill="white" />
        </svg>
    )
}

export default EditIcon2
