import React from "react";

function Section() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.849084C0 1.01004 0.0602039 1.16441 0.167368 1.27822C0.274531 1.39204 0.419876 1.45598 0.571429 1.45598H15.4286C15.5801 1.45598 15.7255 1.39204 15.8326 1.27822C15.9398 1.16441 16 1.01004 16 0.849084C16 0.688125 15.9398 0.533759 15.8326 0.419943C15.7255 0.306128 15.5801 0.242188 15.4286 0.242188H0.571429C0.419876 0.242188 0.274531 0.306128 0.167368 0.419943C0.0602039 0.533759 0 0.688125 0 0.849084ZM0 16.6284C0 16.7894 0.0602039 16.9437 0.167368 17.0575C0.274531 17.1714 0.419876 17.2353 0.571429 17.2353H15.4286C15.5801 17.2353 15.7255 17.1714 15.8326 17.0575C15.9398 16.9437 16 16.7894 16 16.6284C16 16.4674 15.9398 16.3131 15.8326 16.1993C15.7255 16.0854 15.5801 16.0215 15.4286 16.0215H0.571429C0.419876 16.0215 0.274531 16.0854 0.167368 16.1993C0.0602039 16.3131 0 16.4674 0 16.6284Z"
        fill="#f7931d"
      />
      <path
        d="M1.14453 7.52629C1.14453 7.20438 1.26494 6.89564 1.47927 6.66801C1.69359 6.44038 1.98428 6.3125 2.28739 6.3125H13.716C14.0191 6.3125 14.3098 6.44038 14.5241 6.66801C14.7384 6.89564 14.8588 7.20438 14.8588 7.52629V9.95388C14.8588 10.2758 14.7384 10.5845 14.5241 10.8122C14.3098 11.0398 14.0191 11.1677 13.716 11.1677H2.28739C1.98428 11.1677 1.69359 11.0398 1.47927 10.8122C1.26494 10.5845 1.14453 10.2758 1.14453 9.95388V7.52629Z"
        fill="#f7931d"
      />
    </svg>
  );
}

export default Section;
