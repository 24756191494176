import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateMailingAddress.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";

function CreateMailingAddress() {
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [refresh, setRefresh] = useState(false);
  const [admissionData, setAdmissionData] = useState(null);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);

  const initialValues = {
    street: admissionData?.mailingAddress?.street || "",
    streetNo: admissionData?.mailingAddress?.streetNo || "",
    city: admissionData?.mailingAddress?.city || "",
    postalCode: admissionData?.mailingAddress?.postalCode || "",
    province: admissionData?.mailingAddress?.province || "",
    unit: admissionData?.mailingAddress?.unit || "",
    country: admissionData?.mailingAddress?.country || "",
    mobile1: admissionData?.mailingAddress?.mobile1 || "",
    mobile2: admissionData?.mailingAddress?.mobile2 || "",
  };

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.mailingAddress.isApproved);
        });
    }
  }, [location.pathname, refresh]);

  const navigateToPersonalData = () => {
    navigation("/admission-management/create-personal-data", {
      state: { data: data },
    });
  };
  const navigateToGuardian = () => {
    navigation("/admission-management/create-guardian-details", {
      state: { data: data },
    });
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = async (values) => {
    navigation("/admission-management/create-guardian-details", {
      state: { data: data },
    });
  };
  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.mailingAddress;
    requestData.isApproved = true;
    requestData.isRejected = false;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_MAILING}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Mailing Address Approved Successfully");
        navigateToGuardian();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.mailingAddress;
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_MAILING}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Mailing Address Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  const dateString = new Date(admissionData?.mailingAddress?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {() => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails[0].applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Mailing Address</div>

                <div className="">
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Street *</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="street"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">No</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="streetNo"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">City *</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="city"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Postal Code *</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="postalCode"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Province *</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="province"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Apt/Unit</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="unit"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Country *</label>

                      <Field
                        disabled={true}
                        name="country"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Mobile 1 *</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="mobile1"
                      />
                    </div>

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Mobile 2</label>
                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="mobile2"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    className="create-admission-previous-button"
                    onClick={navigateToPersonalData}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="create-admission-save-button"
                    onClick={navigateToGuardian}
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.mailingAddress.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.mailingAddress.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.mailingAddress?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateMailingAddress;
