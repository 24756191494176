import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import sessionService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { TIMETABLE_URLS } from "modules/timetable-management/constants/timetable-constants-url";
import { useLocation } from "react-router-dom";
import MeetingLinkPopup from "../meeting-link-popup/MeetingLinkPopup";

const StudentCalendar = () => {
  const location = useLocation();
  const userData = sessionService.getUserData("userData");

  const studentId = userData?.admissionManagement?.personalData?.studentId;

  const [calendarData, setCalendarData] = useState([]);
  const [MeetingLinkPopUp, setMeetingLinkToPopUp] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [program, setProgram] = useState("");
  useEffect(() => {
    if (studentId) {
      axiosService
        .get(`${TIMETABLE_URLS.GET_STUDENT_CALENDAR_BY_USERID}/${studentId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response?.data?.data;
            setCalendarData(responseData);
          }
        });
    }
  }, [studentId, location.pathname]);
  const convertTo24HourFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === "AM" && hours === 12) {
      hours = 0;
    } else if (period === "PM" && hours !== 12) {
      hours += 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const transformedEventsData = calendarData
    ? calendarData.map((event) => {
        const startTimeEvent = convertTo24HourFormat(event.startTime);
        const endTimeEvent = convertTo24HourFormat(event.endTime);

        const startTime = new Date(`${event.startDate}T${startTimeEvent}`);
        const endTime = new Date(`${event.endDate}T${endTimeEvent}`);
        const program = event.module.programFeeMaster.program;

        return {
          title: event?.module?.module?.moduleName,
          start: startTime,
          end: endTime,
          stime: event.startTime,
          etime: event.endTime,
          description: event?.description,
          // url: "https://www.366icons.com/media/01/profile-avatar-account-icon-16699.png",
          extendedProps: {
            program: program,
          },
        };
      })
    : [];

  return (
    <div className="view-leads-table-container">
      <div className="student-ps-top sticky-header">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">My Program Schedule </span>
        </div>
      </div>
      <div>
        {/* <div className="schedules-top-div">
          <div>
            <div className="schedules-top-div-text">Faculty Assigned</div>
            <div className="schedules-top-div-bottomtext">Facultyname Here</div>
          </div>
          <div>
            <div className="schedules-top-div-text">Campus Assigned</div>
            <div className="schedules-top-div-bottomtext">Campus Name here</div>
          </div>
          <div>
            <div className="schedules-top-div-text">Mode of Delivery</div>
            <div className="schedules-top-div-bottomtext">Online</div>
          </div>
          <div>
            <div className="schedules-top-div-text">Eve of Delivery</div>
            <div className="schedules-top-div-bottomtext">Online</div>
          </div>
        </div> */}
        <div className="Schedules-fullcalendar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            themeSystem="standard"
            headerToolbar={{
              left: "prev,today,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek",
            }}
            dayMinWidth="10px"
            dragScroll="true"
            views={{
              dayGridMonth: {
                titleFormat: { month: "long" },
                showNonCurrentDates: false,
                eventDisplay: "block",
              },
              timeGrid: {
                titleFormat: { day: "2-digit", month: "long", year: "numeric" },
                columnHeaderFormat: {
                  day: "numeric",
                  month: "numeric",
                  omitCommas: true,
                },
                dayHeaderFormat: {
                  day: "numeric",
                  month: "short",
                  weekday: "long",
                  omitCommas: true,
                },
                slotDuration: "00:30:00",
                slotLabelInterval: "00:30:00",
                slotLabelFormat: { hour: "2-digit", minute: "2-digit" },
              },
            }}
            events={transformedEventsData}
            eventContent={function (arg) {
              let para = document.createElement("p");
              let italicEl = document.createElement("i");
              let spanning = document.createElement("div");
              // let profileimage = document.createElement("img");
              // profileimage.src = arg.event.url;
              let startTime = new Date(arg.event.start).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
              let endTime = new Date(arg.event.end).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });

              spanning.innerHTML = `${startTime} - ${endTime}`;
              para.innerHTML = arg.event.title;

              if (arg.event.extendedProps.isUrgent) {
                italicEl.innerHTML = "urgent event";
              } else {
                italicEl.innerHTML = "normal event";
              }

              let arrayOfDomNodes = [para, spanning];
              return { domNodes: arrayOfDomNodes };
            }}
            eventBorderColor="#F69F3A"
            eventBackgroundColor="#473929"
            eventClick={function (info) {
              info.jsEvent.preventDefault();
              setMeetingLinkToPopUp(true);
              setMeetingLink(info.event);
              setProgram(info.event.extendedProps.program);
              info.el.style.borderColor = "red";
            }}
            weekends={false}
            selectable="true"
          />
        </div>
      </div>
      {MeetingLinkPopUp && (
        <MeetingLinkPopup
          open={MeetingLinkPopUp}
          onClose={() => {
            setMeetingLinkToPopUp(false);
          }}
          meetingLink={meetingLink}
          program={program}
          setMeetingLink={setMeetingLink}
        />
      )}
    </div>
  );
};

export default StudentCalendar;
