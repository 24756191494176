import React, { useEffect, useRef, useState } from "react";
import "./QueryFeedbackChat.css";
import sessionstorageService from "services/sessionstorage.service";
import QueryChatList from "./chat-list/QueryChatList";
import { ImAttachment } from "react-icons/im";
import { IoMdSend } from "react-icons/io";
import NewConversationPopup from "./new-conversation/NewConversationPopup";
import { QUERY_FEEDBACK_URLS } from "../constants/query-feedback-urls";
import { axiosService } from "services/axios.service";
import { RiCloseCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { NEW_CONVERSATION_CONSTANTS } from "../constants/query-feedback-constants";
import useravatar from "assets/images/user-avatar.svg";
import { FaFile } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

function QueryFeedbackChat() {
  const [chatId, setChatId] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [sendMessageText, setSendMessageText] = useState(null);
  const [closedUpdateRefresh, setClosedUpdateRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [attachment, setAttachment] = useState(null);
  const [attachmentFileName, setAttachmentFileName] = useState(null);
  const [viewQuery, setViewQuery] = useState("open");

  const userData = sessionstorageService.getUserData("userData");

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const [openConversation, setOpenConversation] = useState(false);
  const scrollContainerRef = useRef(null);
  const messageContainerRef = useRef(null);

  const scrollBottom = () => {
    const container = scrollContainerRef.current;
    const messager = scrollContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight + messager.scrollHeight;
    }
  };

  const fetchData = async () => {
    const response = await axiosService.get(
      `${QUERY_FEEDBACK_URLS.GET_CHAT_BY_CHAT_ID}${chatId}`
    );
    const data = response?.data?.data;
    setChatData(response?.data?.data);
    if (data?.receiver?.userId !== userData?.userId) {
      setReceiver(data?.receiver);
    } else {
      setReceiver(data?.sender);
    }
    scrollBottom();
  };
  useEffect(() => {
    if (chatData) {
      scrollBottom();
    }
  }, [chatData]);

  useEffect(() => {
    calculateTextareaHeight();
  }, [sendMessageText]);

  useEffect(() => {
    setAttachment(null);
    setAttachmentFileName(null);
    setSendMessageText("");
    const fetchDataAndUpdate = async () => {
      if (chatId) {
        const response = await axiosService.getByTime(
          `${QUERY_FEEDBACK_URLS.GET_CHAT_BY_CHAT_ID}${chatId}`
        );
        const data = response?.data?.data;
        if (
          chatData === null ||
          chatData?.conversation?.length != data?.conversation?.length
        ) {
          setChatData(data);
          scrollBottom();
          if (data?.receiver?.userId !== userData?.userId) {
            setReceiver(data?.receiver);
          } else {
            setReceiver(data?.sender);
          }
        }
      }
    };
    fetchDataAndUpdate();
    const intervalId = setInterval(fetchDataAndUpdate, 1000);
    return () => clearInterval(intervalId);
  }, [chatId, userData?.userId, chatData]);

  const SendMessage = async () => {
    if (sendMessageText !== "") {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const conversation = {
        senderId: userData?.userId,
        senderName:
          userData?.firstName +
          " " +
          (userData?.lastName ? userData?.lastName : ""),
        message: sendMessageText,
        date: formattedDate,
      };
      let sendData = {
        chatId: chatId,
        chatConversation: conversation,
      };
      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.SEND_MESSAGE}`,
        sendData
      );
      if (response.status === 200) {
        setSendMessageText("");
        fetchData();
      }
    } else if (attachment) {
      setLoading(true);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const conversation = {
        senderId: userData?.userId,
        senderName:
          userData?.firstName +
          " " +
          (userData?.lastName ? userData?.lastName : ""),
        message: `<a target="_blank" rel="noreferrer" href=${attachment}> ${attachmentFileName}</a>`,
        date: formattedDate,
      };
      let sendData = {
        chatId: chatId,
        chatConversation: conversation,
      };

      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.SEND_MESSAGE}`,
        sendData
      );
      if (response.status === 200) {
        setSendMessageText("");
        setAttachment(null);
        setAttachmentFileName(null);
        fetchData();
        setLoading(false);
      } else {
        toast.error(response.error.message);
        setLoading(false);
      }
    }
  };
  function formatDateString(inputDateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDateString).toLocaleDateString(
      "en-US",
      options
    );

    return formattedDate;
  }
  const closeChat = async () => {
    if (chatId) {
      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.CLOSE_CHAT}${chatId}`
      );
      if (response.status === 200) {
        setClosedUpdateRefresh(!closedUpdateRefresh);
        toast.success(NEW_CONVERSATION_CONSTANTS.CLOSED_SUCCESS);
      } else {
        toast.error(response.data.message);
      }
    }
  };
  const attachFile = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setSendMessageText("");
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file?.size > maxSizeInBytes) {
      toast.error(NEW_CONVERSATION_CONSTANTS.MAX_FILE_SIZE);
      setLoading(false);
    } else {
      try {
        const response = await axiosService.postFile(
          `${QUERY_FEEDBACK_URLS.ATTACHMENT_FILE_UPLOAD}`,
          file,
          true
        );
        if (response.status === 200) {
          setAttachmentFileName(file?.name);
          const data = response.data.data;
          setAttachment(data);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message.split(":")[1]);
        setLoading(false);
      }
    }
  };
  const handleDrop = async (event) => {
    setIsDraggingOver(false);
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setLoading(true);
    setSendMessageText("");

    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      toast.error(NEW_CONVERSATION_CONSTANTS.MAX_FILE_SIZE);
      setLoading(false);
    } else {
      try {
        const response = await axiosService.postFile(
          `${QUERY_FEEDBACK_URLS.ATTACHMENT_FILE_UPLOAD}`,
          file,
          true
        );
        if (response.status === 200) {
          const data = response.data.data;
          setAttachment(data);
          setLoading(false);
          setAttachmentFileName(file?.name);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message.split(":")[1]);
        setLoading(false);
      }
    }
  };

  const dateObject = new Date(chatData?.updatedOn);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dateObject?.toLocaleString("en-US", options);

  const calculateTextareaHeight = () => {
    const element = document.getElementById("query-chatlist-message-input");
    if (element) {
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;
    }
  };
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  return (
    <div className="communication-management-main-container pb-4">
      <div className="communication-management-head-container sticky-header-communication flex-row gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">
            Query/Feedback
          </span>
          <button
            className={`d-none d-md-block ${
              viewQuery === "open"
                ? " view-closed-query-button"
                : "view-opened-query-button"
            }`}
            onClick={() => {
              if (viewQuery === "open") {
                setViewQuery("closed");
              } else {
                setViewQuery("open");
              }
            }}
          >
            {viewQuery === "open"
              ? "View Closed Queries"
              : "View Active Queries"}
          </button>
        </div>
        <div className="d-none d-md-flex align-items-center gap-3">
          {hasPermission("QueriesFeedbackChat: create") && (
            <button
              className="communication-save-head-button"
              onClick={() => setOpenConversation(true)}
            >
              New Conversation
            </button>
          )}
          {hasPermission("QueriesFeedbackChat: delete") && chatData?.isOpen && (
            <button
              className="mark-as-closed-button"
              onClick={() => closeChat()}
            >
              Mark as closed
            </button>
          )}
        </div>
        <Dropdown className="d-flex d-md-none">
          <Dropdown.Toggle
            className="lead-table-head-button"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0 ">
            <Dropdown.Item
              onClick={() => {
                if (viewQuery === "open") {
                  setViewQuery("closed");
                } else {
                  setViewQuery("open");
                }
              }}
            >
              {viewQuery === "open"
                ? "View Closed Queries"
                : "View Active Queries"}
            </Dropdown.Item>
            {hasPermission("QueriesFeedbackChat: create") && (
              <Dropdown.Item onClick={() => setOpenConversation(true)}>
                New Conversation
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="communication-management-content-container row g-0">
        <div className="query-feedback-left-container col-12 col-md-4 col-lg-3 custom-scrollbar">
          <QueryChatList
            setChatId={setChatId}
            refresh={openConversation}
            chatId={chatId}
            viewQuery={viewQuery}
            closedUpdateRefresh={closedUpdateRefresh}
          />
        </div>
        <div
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDraggingOver(true);
          }}
          onDragLeave={() => {
            setIsDraggingOver(false);
          }}
          ref={scrollContainerRef}
          className="d-none d-md-block query-feedback-right-container custom-scrollbar p-0 ps-2 col-md-8 col-lg-9 position-relative"
        >
          {isDraggingOver ? (
            <div className="drop-here-container">
              <FaFile /> Drop an file here
            </div>
          ) : chatId ? (
            <>
              <div className="profile-container-query-chat-screen  sticky-header-communication">
                <img
                  alt="img"
                  src={receiver?.profileImage || useravatar}
                  className="profile-image-query-chat-screen"
                />

                <div className="d-flex flex-column justify-content-center">
                  <span className="profile-name-text-query-chat-screen">
                    {receiver?.firstName} {receiver?.lastName}
                  </span>
                  <span className="profile-student-id-text-query-chat-screen">
                    {receiver?.studentId ? "@" + receiver?.studentId : ""}
                  </span>
                </div>
              </div>
              <div className="query-chat-container mb-6">
                {chatData?.conversation?.map((message) => (
                  <div
                    className={`${
                      message.senderId === userData?.userId
                        ? "send-message-text-container"
                        : "receive-message-text-container"
                    }`}
                  >
                    <div
                      ref={messageContainerRef}
                      className="send-receive-message-text-inner-container"
                    >
                      {message.message
                        ?.trim()
                        ?.startsWith(
                          '<a target="_blank" rel="noreferrer" href='
                        ) ? (
                        <span className="send-receive-message-text">
                          Uploaded File :{"  "}
                          <ImAttachment color="#F69F3A" />
                          <span
                            className="attachment-color-query"
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </span>
                      ) : (
                        <span className="send-receive-message-text">
                          {message.message}
                        </span>
                      )}
                      <span className="send-receive-message-text-date">
                        by{" "}
                        <span className="send-receive-message-text-date-underline">
                          {message?.senderName}
                        </span>{" "}
                        on {formatDateString(message?.date)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              {chatData?.isOpen ? (
                <div className="query-chatlist-message-input-container gap-3 py-2">
                  <textarea
                    id="query-chatlist-message-input"
                    className="query-chatlist-message-input custom-scrollbar"
                    placeholder={`${
                      attachmentFileName ? "" : "Type a message"
                    }`}
                    disabled={attachmentFileName}
                    value={sendMessageText}
                    onChange={(event) => {
                      setSendMessageText(event.target.value);
                    }}
                  />
                  {attachmentFileName && (
                    <span className="attachement-text-container">
                      <span className="close-attachment-icon">
                        <RiCloseCircleFill
                          onClick={() => {
                            setAttachment(null);
                            setAttachmentFileName(null);
                          }}
                        />
                      </span>
                      <a
                        href={attachment}
                        target="_blank"
                        rel="noreferrer"
                        className="attachement-text"
                      >
                        {attachmentFileName}
                      </a>
                    </span>
                  )}
                  <label
                    htmlFor="fileInput"
                    className="query-chatlist-message-attach-input-icon"
                  >
                    <ImAttachment color="#F69F3A" />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={attachFile}
                  />

                  <button
                    className="chat-message-input-send-button background-primary"
                    onClick={() => SendMessage()}
                    disabled={loading || (!sendMessageText && !attachment)}
                  >
                    {loading ? (
                      <div
                        class="lds-ring"
                        style={{ width: "10px", height: "10px" }}
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <IoMdSend color="#F7931D" size={"25px"} />
                    )}
                  </button>
                </div>
              ) : (
                <div className="query-chatlist-message-input-container gap-3 py-2">
                  <span className="feedback-closed-on-text">
                    Conversation closed on {formattedDate}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className="no-chat-text"> No chat to display</div>
          )}
        </div>
      </div>

      {openConversation && (
        <NewConversationPopup
          open={openConversation}
          onClose={() => setOpenConversation(false)}
        />
      )}
    </div>
  );
}

export default QueryFeedbackChat;
