import React, { useEffect, useRef } from "react";
import "./PaymentReceipt.css";
import beta_header from "assets/images/payment-receipt/beta-header.jpg";
import beta_footer from "assets/images/payment-receipt/beta-footer.jpg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { axiosService } from "services/axios.service";
import { AUTH_URLS } from "core/auth/constants/auth-urls";

function PaymentReceipt({ resultData, methodClose, method }) {
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    let hours = date.getHours();
    const amPm = hours < 12 ? "AM" : "PM";
    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${
      (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
    } ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  const contentRef = useRef(null);
  const downloadPDF = async () => {
    const input = contentRef.current;
    html2canvas(input, {
      useCORS: true,
      allowTaint: true,
      scale: 2,
      logging: true,
      dpi: 192,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.8);
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );

      const pdfBase64 = pdf.output("datauristring");

      // Convert base64 to Blob
      const byteCharacters = atob(pdfBase64.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a File object from Blob
      const pdfFile = new File(
        [blob],
        `${resultData?.trnOrderNumber}_payment_receipt.pdf`,
        { type: "application/pdf" }
      );
      if (method === "mail") {
        await axiosService.postFile(
          `${AUTH_URLS?.MAIL_RECEIPT}?trnOrderNumber=${resultData?.trnOrderNumber}`,
          pdfFile
        );
      } else if (method === "save") {
        pdf.save(`${resultData?.trnOrderNumber}_payment_receipt.pdf`);
      }
      methodClose();
    });
  };

  const paymentMethod = (mode) => {
    if (mode === "CC") {
      return "Credit Card";
    } else {
      return mode;
    }
  };

  useEffect(() => {
    if (resultData) {
      downloadPDF();
    }
  }, [resultData]);
  return (
    <div className="payment-receipt-container" ref={contentRef}>
      <img src={beta_header} className="payment-receipt-header" />

      <div className="payment-receipt-content">
        <h4 className="text-center">Payment Receipt</h4>
        <table style={{ width: "fit-content", marginTop: "30px" }}>
          <tr>
            <th className="payment-receipt-text-label">Date :</th>
            <td className="payment-receipt-text-value">
              {formatTimestamp(resultData?.updatedOn)}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Transaction Id :</th>
            <td className="payment-receipt-text-value">
              {resultData?.transactionId}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Order Id :</th>
            <td className="payment-receipt-text-value">
              {resultData?.trnOrderNumber}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">FullName :</th>
            <td className="payment-receipt-text-value">
              {resultData?.fullName}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Email Address :</th>
            <td className="payment-receipt-text-value">{resultData?.email}</td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Date of Birth :</th>
            <td className="payment-receipt-text-value">
              {resultData?.dateOfBirth}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Mobile number :</th>
            <td className="payment-receipt-text-value">
              {resultData?.mobileNumber}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">
              Country of Citizenship :
            </th>
            <td className="payment-receipt-text-value">
              {resultData?.country}
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Pay For :</th>
            <td className="payment-receipt-text-value"> {resultData?.pay}</td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label ">Payee Information :</th>
            <td className="payment-receipt-text-value text-capitalize">
              {resultData?.payeeInfo} Card
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Amount :</th>
            <td className="payment-receipt-text-value">
              {resultData?.cadAmount} CAD $
            </td>
          </tr>
          <tr>
            <th className="payment-receipt-text-label">Payment Mode :</th>
            <td className="payment-receipt-text-value">
              {paymentMethod(resultData?.paymentMethod)}
            </td>
          </tr>
        </table>
      </div>
      <img src={beta_footer} className="payment-receipt-footer" />
    </div>
  );
}

export default PaymentReceipt;
