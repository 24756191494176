import { AppConfig } from "env.development";

export const STUDENT_PORTAL_URL = {
  ALL_ADMISSION: `${AppConfig.api_url}beta/v1/faculty/all`,
  GET_ADMISSION_BY_ID: `${AppConfig.api_url}beta/v1/`,
  GET_MODULES_BY_PROGRAM_ID: `${AppConfig.api_url}program/v1/get-module/`,
  GET_PROFILE_DATA_BY_ID: `${AppConfig.api_url}dashboard/v1/student-view/`,
  UPDATE_STUDENT_PROFILE: `${AppConfig.api_url}dashboard/v1/view-profile/`,
  GET_STUDENT_MODULE_MARKS: `${AppConfig.api_url}manage-marks/v2`,
};

export const STUDENT_PORTAL_EXTRACURRICULAR_URL = {
  POST_EXTRACURRICULAR_URL: `${AppConfig.api_url}extra-curricular/v1`,
  GET_EXTRACURRICULAR_URL: `${AppConfig.api_url}extra-curricular/v1/all`,
  GET_EXTRACURRICULAR_BY_STUDENT_ID: `${AppConfig.api_url}extra-curricular/v1/activities/`,
};
export const STUDENT_PORTAL_SERVICE_URL = {
  POST_SERVICE_URL: `${AppConfig.api_url}student-portal/services/v1`,
  GET_SERVICE_URL: `${AppConfig.api_url}student-portal/services/v1/all`,
};
export const STUDENT_PORTAL_PARTICIPATION_URL = {
  POST_PARTICIPATION_URL: `${AppConfig.api_url}participation/v1`,
  GET_PARTICIPATION_URL: `${AppConfig.api_url}participation/v1/all`,
  GET_PARTICIPATION_BY_STUDENT_ID: `${AppConfig.api_url}participation/v1/activities/`,
};
export const STUDENT_PORTAL_DOCUMENT_FACULTY_URL = {
  GET_ALL_DOCUMENTS_FACULTY_URL: `${AppConfig.api_url}student-portal/documents/v1/all/count`,
  GET_INDIVIDUAL_DOCUMENT_URL: `${AppConfig.api_url}student-portal/documents/v1/`,
  UPDATE_DOCUMENT_BY_FACULTY: `${AppConfig.api_url}student-portal/documents/v1/faculty`,
};
export const STUDENT_PORTAL_FEEDBACK_URL = {
  GET_ALL_Modules_BY_ID: `${AppConfig.api_url}feed-back/v1/get-module/`,
  GET_ALL_FEEDBACK_MASTER: `${AppConfig.api_url}feed-back/v1`,
  SAVED_STUDENT_FEEDBACK: `${AppConfig.api_url}feed-back/v1`,

  GET_ALL_Modules: `${AppConfig.api_url}`,
  GET_ALL_STUDENT_FEEDBACK_MODULES: `${AppConfig.api_url}feed-back/v1/`,

  // GET_INDIVIDUAL_DOCUMENT_URL: `${AppConfig.api_url}student-portal/documents/v1/`,
  // UPDATE_DOCUMENT_BY_FACULTY: `${AppConfig.api_url}student-portal/documents/v1/faculty`,
};
