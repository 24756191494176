import { RxTriangleDown } from "react-icons/rx";
import "./AddModule.css";
import { Field, FieldArray, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect, useState } from "react";
import EditIcon from "assets/image-components/EditIcon";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import EditorModal from "../add-program/EditorModal";
import ModuleOutcomeModal from "./ModuleOutcomeModal";
import TeachingActivityModal from "./TeachingActivityModal";
import DeleteIcon from "assets/image-components/DeleteIcon";
import { IoIosArrowBack } from "react-icons/io";
import { BsFillPlusSquareFill, BsXSquareFill } from "react-icons/bs";
import Dropzone from "react-dropzone";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";

function AddModule() {
  const navigate = useNavigate();
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teachingModalOpen, setTeachingModalOpen] = useState(false);
  const [moduleOverview, setModuleOverview] = useState("");
  const [indicativeContent, setIndicativeContent] = useState("");
  const [currentLabel, setCurrentLabel] = useState("");
  const [initialEditorContent, setInitialEditorContent] = useState("");
  const [editedRow, setEditedRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const location = useLocation();

  const Data = location.state;
  const moduleId = Data?.moduleId;

  const [moduleData, setModuleData] = useState("");

  useEffect(() => {
    if (moduleId) {
      axiosService
        .get(`${PROGRAM_URLS.GET_MODULE_BY_MODULE_ID}/${moduleId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setModuleData(responseData);
            setBreakdownLearnings(responseData?.breakdownLearnings || []);
            setOutcomeData(responseData?.moduleOutcomes || []);
            setModuleOverview(responseData?.moduleOverview || "");
            setIndicativeContent(responseData?.indicativeContent || "");
          }
        });
    }
  }, [location.pathname, moduleId]);

  const openEditorModal = (label, initialContent) => {
    setInitialEditorContent(initialContent);
    setIsEditorModalOpen(true);
    setCurrentLabel(label);
  };

  const handleEditorSave = (label, plainTextContent) => {
    if (label === "Module Descriptor") {
      setModuleOverview(plainTextContent);
    } else if (label === "Indicative Content") {
      setIndicativeContent(plainTextContent);
    } else {
    }
  };

  const closeEditorModal = () => {
    setIsEditorModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTeachingModalOpen(false);
  };
  const [outcomeData, setOutcomeData] = useState([]);
  const [breakdownLearnings, setBreakdownLearnings] = useState([]);
  const handleOutcomeSubmit = (outcome) => {
    if (editedRow !== null) {
      const updatedOutcomeData = [...outcomeData];
      updatedOutcomeData[editedRow] = outcome;
      setOutcomeData(updatedOutcomeData);
      setEditedRow(null);
    } else {
      setOutcomeData([...outcomeData, outcome]);
    }
  };

  const handleEditRow = (index) => {
    setEditedRow(index);
    setIsModalOpen(true);
  };

  const handleDeleteRow = (index) => {
    const updatedOutcomeData = [...outcomeData];
    updatedOutcomeData.splice(index, 1);
    setOutcomeData(updatedOutcomeData);
  };

  const handleTeachingSubmit = (breakdown) => {
    if (editRow !== null) {
      const updatedBreakdownLearningsData = [...breakdownLearnings];
      updatedBreakdownLearningsData[editRow] = breakdown;
      setBreakdownLearnings(updatedBreakdownLearningsData);
      setEditRow(null);
    } else {
      setBreakdownLearnings([...breakdownLearnings, breakdown]);
    }
  };

  const handleEditRowTeach = (index) => {
    setEditRow(index);
    setTeachingModalOpen(true);
  };

  const handleDeleteRowTeach = (index) => {
    const updatedBreakdownLearningsData = [...breakdownLearnings];
    updatedBreakdownLearningsData.splice(index, 1);
    setBreakdownLearnings(updatedBreakdownLearningsData);
  };

  let initialValues = {
    moduleName: moduleData?.moduleName || "",
    moduleCode: moduleData?.moduleCode || "",
    totalHours: moduleData?.totalHours || "",
    betaCredits: moduleData?.betaCredits || "",
    level: moduleData?.level || "",
    status: moduleData?.status || "",
    facultyAssigned: moduleData?.facultyAssigned || null,
    moduleOverview: moduleData?.moduleOverview || "",
    indicativeContent: moduleData?.indicativeContent || "",
    courseMaterials: moduleData.courseMaterials || [
      { fileName: null, fileUrl: null },
    ],
  };

  const Level = [
    { value: "LOW", label: "LOW" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "HIGH", label: "HIGH" },
  ];
  const Status = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ];
  const [facultyAssignedOptions, setFacultyAssignedOptions] = useState([]);

  useEffect(() => {
    axiosService.get(`${ROLE_URLS.GET_FACULTY_URL}`).then((response) => {
      const user = response?.data?.data;

      const facultyAssignedOptions = user?.map((user) => ({
        value: user.firstName,
        label: user.firstName,
        facultyAssignedObject: { userId: user?.userId },
      }));
      if (facultyAssignedOptions) {
        setFacultyAssignedOptions(facultyAssignedOptions);
      }
    });
  }, []);

  const isSaveButtonDisabled = (values) => {
    return (
      values.moduleName === "" ||
      values.moduleCode === "" ||
      values.totalHours === "" ||
      values.betaCredits === "" ||
      values.level === "" ||
      values.status === "" ||
      values.facultyAssigned === ""
    );
  };

  const onSubmit = async (formValues) => {
    const requestData = {
      moduleName: formValues.moduleName,
      moduleCode: formValues.moduleCode,
      totalHours: formValues.totalHours,
      betaCredits: formValues.betaCredits,
      level: formValues.level,
      status: formValues.status,
      facultyAssigned: formValues.facultyAssigned,
      moduleOverview: moduleOverview,
      indicativeContent: indicativeContent,
      moduleOutcomes: outcomeData,
      breakdownLearnings: breakdownLearnings,
      programFees: [],
      courseMaterials: formValues.courseMaterials,
    };
    try {
      if (moduleData) {
        requestData.moduleId = moduleData.moduleId;
        const response = await axiosService.put(
          `${PROGRAM_URLS.UPDATE_MODULES_ACADEMICS}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Updated successfully");
          navigate("/academics-management/all-modules");
        } else {
          toast.error("update failed");
        }
      } else {
        const response = await axiosService.post(
          `${PROGRAM_URLS.ADD_MODULES_ACADEMICS}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Saved successfully");
          navigate("/academics-management/all-modules");
        } else {
          toast.error("failed");
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleFileDrop = async (
    index,
    acceptedFiles,
    values,
    setFieldValue
  ) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      toast.error("No files dropped or selected");
      return;
    }
    const maxFileSize = 50 * 1024 * 1024;
    try {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        if (file.name.length > 150) {
          toast.error("File name is too long");
          return;
        }

        if (file.size > maxFileSize) {
          toast.error("File size exceeds the limit of 50MB.");
          return;
        }

        formData.append("files", file);
      });

      const response = await axiosService.postFile3(
        `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL1}`,
        formData
      );

      if (response.status === 200) {
        const responseData = response?.data;
        const updatedMaterials = [...values.courseMaterials];
        responseData.forEach((fileData, idx) => {
          updatedMaterials[index + idx] = {
            fileName: fileData.fileName,
            fileUrl: fileData.fileUrl,
          };
        });
        setFieldValue("courseMaterials", updatedMaterials);
        toast.success("Files uploaded successfully!");
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <div className="academics-main-container pb-4" style={{ color: "#ffffff" }}>
      <div className="student-portal-header-container sticky-header flex-sm-row gap-2">
        <h6 className="lead-table-title-text">
          <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
            <IoIosArrowBack size={20} />
          </span>
          Add a Module
        </h6>
      </div>

      <div className="mt-2">
        <h6 style={{ color: "#F69F3A", fontSize: "18px", fontWeight: 400 }}>
          Module Details
        </h6>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, resetForm, setFieldValue }) => (
            <Form>
              <div className="user-main-container p-0">
                <div className="">
                  <div className="row g-0 mt-2">
                    <div className="col-12 p-0 pe-md-2 col-md-8 col-lg-6 d-flex flex-column">
                      <label className="lead-first-name">Module Name *</label>
                      <Field
                        className=" lead-input col-8 col-md-10"
                        placeholder="Module Full Name"
                        name="moduleName"
                      />
                    </div>
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-middle-name">Module Code *</label>
                      <Field
                        className=" lead-input col-8 col-md-10"
                        placeholder="Module short code"
                        name="moduleCode"
                      />
                    </div>
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-last-name">Total Hours *</label>
                      <Field
                        className=" lead-input col-8 col-md-10"
                        placeholder="Example"
                        name="totalHours"
                      />
                    </div>
                  </div>
                  <div className="row g-0 mt-1">
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-address-line-1">
                        Beta Credits *
                      </label>
                      <Field
                        className=" lead-input col-8 col-md-10"
                        placeholder="numbers only"
                        name="betaCredits"
                      />
                    </div>
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-source-name">Level *</label>

                      <Field
                        className=" lead-select-input"
                        name="level"
                        render={({ field, form }) => (
                          <Select
                            id="level"
                            value={
                              field.value
                                ? Level.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={Level}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-source-name">Status *</label>

                      <Field
                        className=" lead-select-input"
                        name="status"
                        render={({ field, form }) => (
                          <Select
                            id="status"
                            value={
                              field.value
                                ? Status.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={Status}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 p-0 pe-md-2 col-md-4 col-lg-3 d-flex flex-column">
                      <label className="lead-source-name">
                        Faculty Assigned *
                      </label>
                      <Field
                        className="lead-select-input"
                        name="facultyAssigned"
                        render={({ field, form }) => (
                          <Select
                            value={
                              field.value && field.value.userId
                                ? facultyAssignedOptions.find(
                                    (option) =>
                                      option.facultyAssignedObject?.userId ===
                                      field.value.userId
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(
                                field.name,
                                option ? option.facultyAssignedObject : null
                              );
                            }}
                            options={facultyAssignedOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-12 p-0 pe-md-2 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Module Descriptor
                      </label>
                      {moduleOverview && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal("Module Descriptor", moduleOverview)
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          moduleOverview
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {moduleOverview ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: moduleOverview,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => openEditorModal("Module Descriptor")}
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-12 p-0 pe-md-2 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Indicative Content
                      </label>
                      {indicativeContent && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal(
                              "Indicative Content",
                              indicativeContent
                            )
                          }
                        >
                          {" "}
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          indicativeContent
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {indicativeContent ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: indicativeContent,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() =>
                              openEditorModal("Indicative Content")
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-12 p-0 pe-md-2 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Module Outcome & Assessment
                      </label>

                      <div style={{ overflowX: "auto", width: "100%" }}>
                        <table style={{ minWidth: "100%" }}>
                          <thead className="view-module-table-head">
                            <tr>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "50%" }}
                              >
                                Outcome
                              </th>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "25%" }}
                              >
                                Coursework
                              </th>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "5%" }}
                              >
                                Exam
                              </th>

                              <th
                                className="table-data-view-profile"
                                style={{ width: "10%" }}
                              >
                                In-Person
                              </th>
                              {outcomeData.length > 0 && (
                                <th
                                  className="table-data-view-profile"
                                  style={{ width: "10%" }}
                                >
                                  Edit/Delete
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {outcomeData.map((data, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor: "#333333",
                                  marginTop: "5px",
                                }}
                              >
                                <td className="table-data-view-profile">
                                  {data.outcome}
                                </td>
                                <td className="table-data-view-profile">
                                  {data.coursework === true ? "YES" : "NO"}
                                </td>
                                <td className="table-data-view-profile">
                                  {data.exam === true ? "YES" : "NO"}
                                </td>
                                <td className="table-data-view-profile">
                                  {data.inPerson === true ? "YES" : "NO"}
                                </td>
                                <td className="table-data-view-profile">
                                  <span
                                    style={{ paddingRight: "10px" }}
                                    onClick={() => {
                                      handleEditRow(index);
                                    }}
                                  >
                                    <EditIcon />
                                  </span>
                                  <span
                                    style={{ paddingRight: "10px" }}
                                    onClick={() => {
                                      handleDeleteRow(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Field
                        placeholder="Click to add a row"
                        className="add-module-modal mt-2"
                        name="moduleOutCome"
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-12 p-0 pe-md-2 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Breakdown Learning and Teaching Activities
                      </label>
                      <div style={{ overflowX: "auto", width: "100%" }}>
                        <table style={{ minWidth: "100%" }}>
                          <thead className="view-module-table-head">
                            <tr>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "18%" }}
                              >
                                Learning Activities
                              </th>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "7%" }}
                              >
                                Hours
                              </th>
                              <th
                                className="table-data-view-profile"
                                style={{ width: "65%" }}
                              >
                                Description
                              </th>
                              {breakdownLearnings.length > 0 && (
                                <th
                                  className="table-data-view-profile"
                                  style={{ width: "10%" }}
                                >
                                  Edit/Delete
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {breakdownLearnings.map((data, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor: "#333333",
                                  marginTop: "5px",
                                }}
                              >
                                <td className="table-data-view-profile">
                                  {data.learningActivities}
                                </td>
                                <td className="table-data-view-profile">
                                  {data.hours}
                                </td>
                                <td className="table-data-view-profile">
                                  {data.description}
                                </td>

                                <td className="table-data-view-profile">
                                  <span
                                    style={{ paddingRight: "10px" }}
                                    onClick={() => {
                                      handleEditRowTeach(index);
                                    }}
                                  >
                                    <EditIcon />
                                  </span>
                                  <span
                                    style={{ paddingRight: "10px" }}
                                    onClick={() => {
                                      handleDeleteRowTeach(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <Field
                        placeholder="Click to add a row"
                        className="add-module-modal mt-2"
                        name="teachingActivities"
                        onClick={() => {
                          setTeachingModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <FieldArray name="courseMaterials">
                      {({ remove, push }) => (
                        <div>
                          {values.courseMaterials.length > 0 &&
                            values.courseMaterials.map((material, index) => (
                              <div
                                key={index}
                                className="d-flex gap-2 docs-upload-outer-container"
                              >
                                <div className="upload-files-inner-input-container">
                                  <div
                                    className="col d-flex"
                                    style={{ flexDirection: "column" }}
                                  >
                                    {index <= 0 && (
                                      <label
                                        className="label-text-active label-width-upload-files"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Upload Study Materials*
                                      </label>
                                    )}
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        handleFileDrop(
                                          index,
                                          acceptedFiles,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      accept="image/*, application/pdf"
                                      multiple={true}
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <div
                                          {...getRootProps()}
                                          className={`create-list-upload-input-big ${
                                            isDragActive ? "drag-active" : ""
                                          }`}
                                        >
                                          <input
                                            {...getInputProps()}
                                            className="d-none"
                                            type="file"
                                            id={`upload${index}`}
                                            multiple
                                          />
                                          {material.fileName ? (
                                            <span className="text-white">
                                              {material.fileName}
                                            </span>
                                          ) : (
                                            "No file chosen"
                                          )}
                                          <div className="create-list-upload-input-choose">
                                            Choose File
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>

                                <div className="col">
                                  {index <= 0 && (
                                    <label
                                      className="label-text-active"
                                      style={{
                                        position: "relative",
                                        top: "-10px",
                                      }}
                                    >
                                      Action
                                    </label>
                                  )}
                                  <div className="action-button-upload">
                                    {values.courseMaterials.length >= 30 ? (
                                      <BsFillPlusSquareFill
                                        className="create-campaign-add-button-icon"
                                        disabled
                                      />
                                    ) : (
                                      <BsFillPlusSquareFill
                                        className="create-campaign-add-button-icon"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          push({
                                            fileName: null,
                                            fileUrl: null,
                                          })
                                        }
                                      />
                                    )}
                                    {values.courseMaterials.length === 1 ? (
                                      <BsXSquareFill
                                        className="create-campaign-add-button-icon"
                                        disabled
                                      />
                                    ) : (
                                      <BsXSquareFill
                                        style={{ cursor: "pointer" }}
                                        className="create-campaign-add-button-icon"
                                        onClick={() => remove(index)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center gap-3 py-3">
                <button
                  type="button"
                  className="user-head-button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className={`user-save-head-button ${
                    isSaveButtonDisabled(values)
                      ? "user-save-head-button-disabled"
                      : ""
                  }`}
                  disabled={isSaveButtonDisabled(values)}
                >
                  {moduleData ? "Update" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <EditorModal
        isOpen={isEditorModalOpen}
        onClose={closeEditorModal}
        onSave={handleEditorSave}
        label={currentLabel}
        initialHtmlContent={initialEditorContent}
      />
      <ModuleOutcomeModal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleOutcomeSubmit}
        onDeleteRow={handleDeleteRow}
        onEditRow={handleEditRow}
        outcomeData={outcomeData}
        editValues={editedRow !== null ? outcomeData[editedRow] : null}
      />

      <TeachingActivityModal
        isOpen={teachingModalOpen}
        onClose={closeModal}
        onSubmit={handleTeachingSubmit}
        onDeleteRow={handleDeleteRowTeach}
        onEditRow={handleEditRowTeach}
        teachingActivityData={breakdownLearnings}
        editValues={editRow !== null ? breakdownLearnings[editRow] : null}
      />
    </div>
  );
}

export default AddModule;
