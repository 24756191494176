import { Field, Form, Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./CreateStudentProfile.css";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import Dropzone from "react-dropzone";
import { BiCheck } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import sessionService from "services/sessionstorage.service";
import betalogo from "assets/images/icons/beta.svg";
import React, { useEffect, useState } from "react";
import ConsentFileDocx from "../../consent-file-doc/ConsentFileDocx";

function CreateStudentProfile() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const admissionId = sessionService.getAdmissionId("admissionId");
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentProfileData, setStudentProfileData] = useState(null);
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;

  const studentProfileFromSession = sessionService.getStudentProfile();

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);

          setStudentName(data?.personalData?.firstName);
          setStudentEmail(data?.personalData?.email);
          setApproved(data?.profile?.isApproved);
          setRejected(data?.profile?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);

          if (!studentProfileFromSession) {
            setIsChecked(data?.profile?.isAgree);
          }
        });
    }
  }, []);
  const [formData, setFormData] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  const attachFile = async (values) => {
    const docFile = new File([pdfBlob], "document.docx", {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    if (docFile) {
      const response = await axiosService.postFile2(
        `${ADMISSION_URL_FOR_STUDENT.SEND_DOC_SIGN_DATA}`,
        studentName,
        studentEmail,
        docFile
      );

      if (response.status === 200) {
        await sessionService.setStudentProfile(values);
        window.open(response.data, "_self");
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const envelopeId = searchParams.get("envelopeId");

  const [consentFile, setConsentFile] = useState(null);
  const [consentFileName, setConsentFileName] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEnvelopeIdFromRouter = async () => {
    if (envelopeId && studentEmail) {
      setStudentProfileData(studentProfileFromSession);
      setIsChecked(studentProfileFromSession?.isAgree);
      setLoading(true);
      try {
        const response = await axiosService.get(
          `${ADMISSION_URL_FOR_STUDENT.DOWNLOAD_DOC_SIGN_DATA}?envelopeId=${envelopeId}&recipientEmail=${studentEmail}&facultyEmail=faculty@gmail.com`
        );
        if (response.status === 200) {
          const fileData = response.data;
          setConsentFile(fileData?.url);
          setConsentFileName(fileData?.filename);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching file:", error);
      }
      setLoading(false);
    }
  };
  const [handlePdf, setHandlePdf] = useState(false);

  useEffect(() => {
    handleEnvelopeIdFromRouter();
  }, [envelopeId, studentEmail]);

  useEffect(() => {
    const handleAttachFile = async () => {
      await attachFile(formData);
    };

    if (pdfBlob && formData) {
      handleAttachFile();
      setLoading(false);
    }
  }, [pdfBlob, formData]);

  const handleCheckboxChange = async (event, values) => {
    const { checked } = event.target;
    setIsChecked(checked);

    if (checked) {
      try {
        setLoading(true);
        setHandlePdf(true);
        const updatedValues = {
          ...values,
          isAgree: true,
        };
        setFormData(updatedValues);
      } catch (error) {
        console.error("Error generating PDF:", error);
        setLoading(false);
      }
    }
  };

  const initialValues = {
    academicLevel: "",
    favoriteSubjects: "",
    practicalExperience: "",
    preferredHobbies: "",
    academicAchievements: "",
    totalYears: "",
    reasonLeaving: "",
    courseExempted: "",
    instituteName: "",
    studentId: admissionData?.profile?.studentId,
    consentFile: consentFile,
    consentFileName: consentFileName,
    isAgree: isChecked,
  };

  const handleClear = async (values) => {
    values.resetForm();
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (values) => {
    if (!isChecked) {
      toast.error("Please Accept the Conditions");
      return;
    }
    const dataType = "studentProfile";
    values.isRejected = false;
    const data = {
      ...values,
      consentFile: consentFile,
      consentFileName: consentFileName,
    };
    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employment: admissionData?.employments,
        other: admissionData?.otherInformation,
        upload: admissionData?.upload,
        student: data,
      },
    };
    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        await sessionService.setStudentProfile(null);
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.STUDENT_PROFILE_SUCCESS);
        navigation("/pre-onboarding/view-application-student");
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.STUDENT_PROFILE_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.STUDENT_PROFILE_ERROR);
      }
    }
  };

  const dateString = new Date(admissionData?.profile?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  const [upload1, setUpload1] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileDrop = async (fieldName, acceptedFiles, setFieldValue) => {
    const file = acceptedFiles[0];
    if (acceptedFiles[0]?.name?.length > 150) {
      toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.IMAGE_LENGTH);
      return;
    }
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    const maxFileSize = 5 * 1024 * 1024;

    if (
      file &&
      allowedFileTypes.includes(file.type) &&
      file.size <= maxFileSize
    ) {
      try {
        if (fieldName === "consentFile") {
          setIsUploading(true);
        }

        const formData = new FormData();
        formData.append("file", acceptedFiles);
        const response = await axiosService.postFile(
          `${ADMISSION_URL_FOR_STUDENT.FILE_UPLOAD_URL}`,
          file,
          acceptedFiles
        );
        if (response.status === 200) {
          toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_SUCCESS);
          const data = response.data.data;
          if (fieldName === "consentFile") {
            setIsUploading(false);
            setUpload1(data);
            setConsentFile(data);
            setConsentFileName(file.name);
          }
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_FAILURE);
        }
      } catch (error) {
        if (fieldName === "consentFile") {
          setIsUploading(false);
        }

        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_ERROR);
        }
      }
    } else {
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload only .png, .jpeg, .jpg, or .pdf files."
        );
      } else if (file.size > maxFileSize) {
        toast.error("File size exceeds the limit of 2MB.");
      }
    }
  };

  return (
    <Formik
      initialValues={
        studentProfileData || admissionData?.profile || initialValues
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>
              <div className="create-student-profile-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Student Profile</div>
                <div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Highest academic level(e.g Completed High school,Completed
                      Bacheors e.t.c.)
                    </label>
                    <Field
                      type="text"
                      name="academicLevel"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Favorite Subjects/Fields
                    </label>
                    <Field
                      type="text"
                      name="favoriteSubjects"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Practical experience (Internship/part-time experience)
                    </label>
                    <Field
                      type="text"
                      name="practicalExperience"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Preferred hobbies/extra-curricular activities
                    </label>
                    <Field
                      type="text"
                      name="preferredHobbies"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Additional academic achievements
                    </label>
                    <Field
                      as="textarea"
                      name="academicAchievements"
                      className="create-admission-input-student-text-area"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      How many years have you studied in total
                    </label>
                    <Field
                      type="text"
                      name="totalYears"
                      className="Pre-onboard-create-admission-input-big-STotal"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Reason for leaving
                    </label>
                    <Field
                      as="textarea"
                      name="reasonLeaving"
                      className="create-admission-input-student-text-area"
                    />
                  </div>
                </div>

                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Course has been exempted
                    </label>
                    <Field
                      type="text"
                      name="courseExempted"
                      className="create-admission-input-exemption"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Name of the Institute for exemption
                    </label>
                    <Field
                      type="text"
                      name="instituteName"
                      className="create-admission-input-exemption"
                    />
                  </div>
                </div>
                <div className="signup-checkbox-container ">
                  <input
                    onChange={(event) => handleCheckboxChange(event, values)}
                    checked={isChecked}
                    className="auth-checkbox"
                    type="checkbox"
                  />
                  <span className="auth-label-text">
                    I agree to the Terms & Conditions
                  </span>
                </div>
                {(envelopeId || admissionData?.profile?.consentFile) && (
                  <div className="create-list-upload-inner-input-container  mt-4">
                    <div className="column d-flex pe-1 gap-2">
                      <label className="label-text-active" htmlFor="upload1">
                        Consent File *
                      </label>
                      <Dropzone
                        onDrop={async (acceptedFiles) => {
                          setUpload1(null);
                          handleFileDrop(
                            acceptedFiles,
                            setConsentFile(acceptedFiles)
                          );
                        }}
                        accept="image/*, application/pdf"
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            {...getRootProps()}
                            className={`create-list-upload-input-big-contest  ${
                              isDragActive ? "drag-active" : ""
                            }`}
                          >
                            <input
                              {...getInputProps()}
                              className="d-none"
                              type="file"
                              id="upload1"
                              name="consentFile"
                            />

                            {consentFileName ||
                            admissionData?.profile?.consentFileName ? (
                              <span className="text-white">
                                {consentFileName ||
                                  admissionData?.profile?.consentFileName}
                              </span>
                            ) : (
                              "No file chosen"
                            )}
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    {(admissionData?.profile?.consentFile ||
                      upload1 ||
                      consentFile) &&
                    !isUploading ? (
                      <BiCheck color="green" size={25} />
                    ) : null}

                    {isUploading && (
                      <div className="lds-ring lds-ring-uploadpage">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                    {(upload1 ||
                      admissionData?.profile?.consentFile ||
                      consentFile) && (
                      <a
                        href={
                          upload1 ||
                          admissionData?.profile?.consentFile ||
                          consentFile
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="view-eye-icon"
                      >
                        <FaEye />
                      </a>
                    )}
                    {touched &&
                      touched.consentFile &&
                      errors &&
                      errors.consentFile && (
                        <div className="error-message error highqualification">
                          {errors.consentFile}
                        </div>
                      )}
                  </div>
                )}
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    className="create-admission-previous-button"
                    onClick={() =>
                      navigation("/pre-onboarding/create-list-upload-files", {
                        state: {
                          data: programId,
                        },
                      })
                    }
                  >
                    Previous
                  </button>
                  {!admissionData?.profile?.studentId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      disabled={values.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        disabled={values.isSubmitting}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {!admissionData?.profile?.studentId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(values)}
                    >
                      Clear
                    </button>
                  )}

                  {(admissionData?.profile?.studentId ||
                    admissionData?.profile?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation("/pre-onboarding/view-application-student", {
                          state: {
                            data: programId,
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}
                </div>
                {admissionData?.profile?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.profile?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.profile?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="application-loader">
                <img alt="img" className="blink" src={betalogo} />
              </div>
            )}{" "}
            <div style={{ position: "absolute", left: -9999 }}>
              {handlePdf && (
                <ConsentFileDocx
                  setPdfBlob={setPdfBlob}
                  studentName={studentName}
                  setHandlePdf={setHandlePdf}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateStudentProfile;
