import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./ListProgram.css";

function ListProgram() {
  const location = useLocation();
  const navigate = useNavigate();
  const [completedApplicationData, setCompletedApplicationData] = useState([]);
  const [totalModulesCount, setTotalModulesCount] = useState("");
  const [modulesCreditCount, setModulesCreditCount] = useState("");

  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_ALL_PROGRAMS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data?.programModel;
        const moduleCount = response.data.data.totalModules;
        const creditCount = response.data.data.totalPrograms;
        setCompletedApplicationData(responseData);
        setTotalModulesCount(moduleCount);
        setModulesCreditCount(creditCount);
      }
    });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/academics-management/view-program", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };

  function formatProgramType(programType) {
    switch (programType) {
      case "NONVOCATIONAL":
        return "Non-Vocational";
      case "VOCATIONALANDACADEMIC":
        return "Vocational, Academic";

      default:
        return programType;
    }
  }
  const [columnDefs] = useState([
    {
      headerName: "Program Code",
      field: "programCode",
    },
    {
      headerName: "Program Name",
      field: "program",
      width: 320,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
        textDecoration: "underline",
      },
    },
    {
      headerName: "Semester",
      field: "noOfSemesters",
    },
    {
      headerName: "Campus",
      field: "campus",
    },
    {
      headerName: "Duration",
      field: "programDuration",
    },
    {
      headerName: "No. of modules",
      field: "semester",
    },

    {
      headerName: "Program Type",
      field: "programType",
      valueFormatter: function (params) {
        return formatProgramType(params.value);
      },
      width: 250,
    },
    {
      headerName: "Study Level",
      field: "studyLevel",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const getRowHeight = (params) => {
    if (params.data?.program?.length > 50) {
      return 80;
    } else if (params.data?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };

  return (
    <div className="view-leads-table-container">
      <div className="sticky-header lead-table-head-container align-items-center justify-content-sm-start flex-row flex-wrap gap-2">
        <h6 className="lead-table-title-text">All Programs </h6>

        <div className="d-flex ms-sm-auto align-items-center gap-3">
          <div className="academic-all-program-module-count">
            Total Module<span>{totalModulesCount}</span>
          </div>
          <div className="academic-all-program-module-count border-0">
            *Total Module Credits<span>{modulesCreditCount}</span>
          </div>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <div className="d-flex align-items-center gap-3">
            <button
              className="communication-save-head-button"
              onClick={() => {
                navigate("/academics-management/add-program");
              }}
            >
              Add Program
            </button>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={completedApplicationData.map((item) => item.master)}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
      </div>
    </div>
  );
}

export default ListProgram;
