import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { TIMETABLE_URLS } from "modules/timetable-management/constants/timetable-constants-url";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";

function ListResources() {
  const location = useLocation();
  const navigate = useNavigate();
  const [resourceData, setResourceData] = useState("");

  useEffect(() => {
    axiosService.get(`${TIMETABLE_URLS.GET_ALL_RESOURCES}`).then((response) => {
      if (response.status === 200) {
        const responseData = response?.data?.data;
        setResourceData(responseData);
      }
    });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/timetable-management/add-resource", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Resource ID",
      field: "resourceId",
    },
    {
      headerName: "Resource Name",
      field: "resourceName",
    },
    {
      headerName: "Resource Type",
      field: "resourceType",
    },
    {
      headerName: "Campus",
      field: "campus",
    },
    {
      headerName: "Resource Admin",
      field: "resourceAdmin.firstName",
    },
    {
      headerName: "No. of Seats",
      field: "seatCapacity",
    },
    {
      headerName: "Building/Block Name",
      field: "blockName",
    },
    {
      headerName: "Floor Number",
      field: "floor",
    },
    {
      headerName: "Online ",
      field: "onlineFacility",
    },
    {
      headerName: "Availability",
      field: "availability",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Resources </span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="sps-fv-top-count sps-fv-hi">
            <button
              className="user-head-button"
              onClick={() => {
                navigate("/timetable-management/add-resource");
              }}
            >
              Add New Resource
            </button>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={resourceData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ListResources;
