import { AppConfig } from "env.development";

export const HR_MODULE_URLS = {
    GET_ALL_EMPLOYEES: `${AppConfig.api_url}employee/v1/all`,
    SAVE_EMPLOYEE: `${AppConfig.api_url}employee/v1`,
    GET_EMPLOYEE_BY_ID: `${AppConfig.api_url}employee/v1`,
    UPDATE_EMPLOYEE: `${AppConfig.api_url}employee/v1`,
    OFFER_LETTER: `${AppConfig.api_url}employee/upload`,
    GET_EMPLOYEE_BY_USERID: `${AppConfig.api_url}employee/v1/user/`
};
