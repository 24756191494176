import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import "./ReportingToPopup.css";
import { Typeahead } from "react-bootstrap-typeahead";
import warningicon from "../../../../assets/images/Vectorwarning.png";
import { axiosService } from "services/axios.service";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import { toast } from "react-toastify";
import useravatar from "assets/images/user-avatar.svg";
import { USER_URL } from "modules/user-management/constants/user-urls";

const ReportingToPopup = ({
  open,
  empId,
  onClose,
  employeeData,
  setCycle,
  cycle,
}) => {
  const [currentReportsName, setCurrentReportsName] = useState(null);
  const [currentReportsCode, setCurrentReportsCode] = useState(
    employeeData?.reportsTo?.employeeId
  );
  const [currentReportsEmpId, setCurrentReportsEmpId] = useState(null);

  useEffect(() => {
    let Name = `${
      employeeData?.reportsTo?.gender === "MALE"
        ? "Mr. "
        : employeeData?.reportsTo?.gender === "FEMALE"
        ? "Mrs. "
        : ""
    }${employeeData?.reportsTo?.employeeFirstName} ${
      employeeData?.reportsTo?.employeeLastName
    }`;
    setCurrentReportsName(Name);
  }, []);

  const [singleSelections, setSingleSelections] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axiosService
      .get(`${USER_URL.GET_ALL_USERS_EXCEPT_STUDENTS_AND_PRESTUDENTS}`)
      .then((response) => {
        const reports = response.data?.data;

        let filteredResults = reports?.filter(
          (a) => a.userId !== employeeData?.employee?.user?.userId
        );

        let options = filteredResults.map((a) => {
          return {
            name: a.firstName + " " + (a?.lastName ? a?.lastName : ""),
            userId: a.userId,
          };
        });
        setOptions(options);
      });
  }, []);

  const handleCurrentReporteeChange = (a) => {
    if (a.length > 0) {
      setCurrentReportsName(a[0]?.name);
      setCurrentReportsCode(a[0].userId);
      setCurrentReportsEmpId(a[0].userId);
    } else {
      setCurrentReportsCode(null);
    }
  };

  const handleSubmit = () => {
    let reportsTo = {
      userId: currentReportsEmpId,
    };

    let user = {
      userId: employeeData?.employee?.user?.userId,
    };

    let Data = employeeData?.employee;
    Data.reportsTo = reportsTo;
    Data.user = user;

    try {
      axiosService
        .put(`${HR_MODULE_URLS.UPDATE_EMPLOYEE}`, Data)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Employee Updated successfully!");
            onClose();
            setCycle(!cycle);
          }
        });
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-sm-8 col-md-6 col-lg-5 p-3"
          style={{
            height: "auto",
            maxHeight: "90vh",
            backgroundColor: "#212226",
          }}
        >
          <div className="new-conversation-popup-header sticky-header top-0">
            <span className="new-conversation-popup-title-text">
              Reporting To
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose color="#ffffff" size={"22px"} />
            </span>
          </div>
          <div className="d-flex flex-column mt-2">
            {currentReportsName && currentReportsCode && (
              <>
                {" "}
                <div className="reporting-popup"> Currently reports to</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    margin: "20px 0px",
                  }}
                >
                  <span>
                    {/* <div style={{ width: '50px', height: '50px', borderRadius: '50%' }}> */}
                    <img
                      src={useravatar}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    {/* </div> */}
                  </span>
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#F69F3A",
                      }}
                    >
                      {currentReportsName}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFFFFF",
                      }}
                    >
                      Code: #{currentReportsCode}
                    </span>
                  </span>
                </div>
              </>
            )}

            <div className=" m-2 d-flex flex-column">
              <div className="d-flex mb-2  flex-wrap">
                <label className="lead-middle-name">
                  Change reporting manager
                </label>
              </div>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                style={{
                  height: "30px",
                  width: "100%",
                  backgroundColor: "#333333 !important",
                }}
                onChange={(a) => {
                  setSingleSelections(a);
                  handleCurrentReporteeChange(a);
                }}
                options={options}
                placeholder="Type to search"
                selected={singleSelections}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 10px 0px",
              }}
            >
              <span style={{ margin: "0px 10px" }}>
                <img
                  src={warningicon}
                  alt="logo"
                  className="error-icon"
                  style={{ height: "18px", width: "18px" }}
                />
              </span>
              <span
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "400",
                  opacity: "0.5",
                }}
              >
                Submitting this form will send automated emails to the reporting
                person and the faculty.
              </span>
            </div>
          </div>

          <div
            className="d-flex gap-3 pt-4"
            style={{ borderTop: "1px solid #7A7A7A" }}
          >
            <button
              className="communication-management-cancel-button"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>

            <button
              // disabled={!employeeId || !employeeType}
              className={`communication-management-submit-button ${
                !currentReportsEmpId && "opacity-50"
              }`}
              disabled={!currentReportsEmpId}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportingToPopup;
