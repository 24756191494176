import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddProgram from "../add-program/AddProgram";
import ListModules from "../list-modules/ListModules";
import ListProgram from "../list-program/ListProgram";
import AcademicsHome from "../academics-home/AcademicsHome";
import ViewProgram from "../view-program/ViewProgram";
import ViewModules from "../view-modules/ViewModules";
import AddModule from "../add-module/AddModule";
import ListModulesStudent from "../student-view-modules/ListModulesStudent";
import ViewModulesStudent from "../student-view-modules/ViewModulesStudent";
import sessionstorageService from "services/sessionstorage.service";
import LookUpNavbar from "../lookup/lookup-navbar/LookUpNavbar";
import AddStartDateProgram from "../lookup/start-date/add-start-date/AddStartDateProgram";
import ViewIndividualDate from "../lookup/start-date/add-start-date/ViewIndividualDate";
import ListEnrolledStaff from "../lookup/enrolled-staff/ListEnrolledStaff";

function AcademicsManagementRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionstorageService.getUserData("userData");
  const isStudent = userData?.role?.name === "student";

  useEffect(() => {
    if (location.pathname === "/academics-management") {
      if (isStudent) {
        navigate("/academics-management/view-program");
      } else {
        navigate("/academics-management/academics-home");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="academics-home" element={<AcademicsHome />} />
      <Route path="add-program" element={<AddProgram />} />
      <Route path="add-module" element={<AddModule />} />
      <Route path="all-program" element={<ListProgram />} />
      <Route path="view-program" element={<ViewProgram />} />
      <Route path="view-modules" element={<ViewModules />} />
      <Route path="all-modules" element={<ListModules />} />

      <Route path="my-modules" element={<ListModulesStudent />} />
      <Route path="my-module-view" element={<ViewModulesStudent />} />
      <Route path="lookup" element={<LookUpNavbar />} />
      <Route path="lookup-add" element={<AddStartDateProgram />} />
      <Route path="lookup-view" element={<ViewIndividualDate />} />
      <Route path="lookup-view" element={<ListEnrolledStaff />} />
    </Routes>
  );
}

export default AcademicsManagementRoutes;
