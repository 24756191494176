import { Field, Formik, Form, ErrorMessage } from "formik";
import React from "react";
import { axiosService } from "services/axios.service";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { USER_URL } from "modules/user-management/constants/user-urls";

function AddEnrolledStaff({ open, onClose, staffEdit }) {
  const validationSchema = Yup.object().shape({
    enrolledStaffName: Yup.string().required("StaffName is required"),
  });

  const initialValues = {
    enrolledStaffName: staffEdit ? staffEdit?.enrolledStaffName : "",
  };

  const onSubmit = async (values) => {
    const requestData = {
      enrolledStaffName: values.enrolledStaffName,
    };

    try {
      if (staffEdit) {
        requestData.staffId = staffEdit?.staffId;
        const response = await axiosService.put(
          `${USER_URL.POST_STAFF_NAME}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Staff Name updated successfully");
          onClose();
        }
      } else {
        const response = await axiosService.post(
          `${USER_URL.POST_STAFF_NAME}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Staff Name Saved Successfully");
          onClose();
        }
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="flex-sm-row justify-content-between">
                  <div className="new-conversation-popup-header">
                    <span className="add-news-current-affair-title-text">
                      Add Enrolled Staff Name
                    </span>

                    <span className="d-flex justify-content-center align-items-center">
                      <IoClose
                        color="#ffffff"
                        size={"23px"}
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #7a7a7a",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="create-financial-main-input-container">
                    <div className="create-admission-inner-first-input-container w-100">
                      <div className="label-text-active">Enrolled Staff *</div>
                      <Field
                        name="enrolledStaffName"
                        className="create-financial-input-big w-100"
                      />
                      <ErrorMessage
                        name="enrolledStaffName"
                        component="div"
                        className="add-staff-error"
                      />
                    </div>
                  </div>
                </div>
                <div className=" d-flex align-items-center gap-3 mt-3">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Close
                  </button>

                  <button
                    className="communication-management-submit-button"
                    type="submit"
                  >
                    {staffEdit ? "Update" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddEnrolledStaff;
