import React from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";

function NotificationModal({ open, onClose, data }) {
  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-6 p-3 custom-scrollbar"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <div className="flex-sm-row justify-content-between">
            <div className="new-conversation-popup-header">
              <span className="add-news-current-affair-title-text">
                Full Notification
              </span>

              <span
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IoClose
                  color="#ffffff"
                  size={"23px"}
                  onClick={() => {
                    onClose();
                  }}
                />
              </span>
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #7a7a7a",
              fontSize: "14px",
              color: "#ffffff",
            }}
          >
            <div className="create-admission-inner-first-input-container">
              <div
                className="publish-exam-check"
                dangerouslySetInnerHTML={{
                  __html: data?.fullNotification,
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 mt-3">
            <button
              className="communication-management-cancel-button"
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NotificationModal;
