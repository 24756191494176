export const LEAD_CONSTANTS = {
  SUCCESS: "Lead Created successfully",
  FAILED: "Lead Failed to Create",
  ERROR_OCCUR:
    "An unexpected error occurred during lead creating. Please contact admin/support",
};
export const LEAD_DELETE_CONSTANTS = {
  SUCCESS: "Lead Deleted successfully",
  FAILED: "Lead Failed to Delete",
};
export const LEAD_CONVERT_CONSTANTS = {
  SUCCESS: "Status Converted successfully",
  FAILED: "Status Failed to Convert",
};
export const LEAD_IMPORT_CONSTANTS = {
  SUCCESS: "Status Imported successfully",
  FAILED: "Status Failed to Import",
};
export const LEAD_UPDATE_CONSTANTS = {
  SUCCESS: "Lead Updated successfully",
  FAILED: "Lead Failed to Update",
  ERROR_OCCUR:
    "An unexpected error occurred during lead updating. Please contact admin/support",
};
export const SEND_EMAIL_CONSTANTS = {
  SUCCESS: "Email sent successfully",
  FAILED: "Failed to Send Email",
  ERROR_OCCUR:
    "An unexpected error occurred during sending Email. Please contact admin/support",
};
