import { AppConfig } from "env.development";

export const DOCUMENTS_URL_FOR_STUDENT = {
  GET_DOCUMENTS_URL: `${AppConfig.api_url}student-portal/documents/v1/documents-master/all`,
  GET_DOCUMENTS_URL_FOR_STUDENTID: `${AppConfig.api_url}student-portal/documents/v1/doc-master/`,
  GET_DOCUMENTS_BY_ID: `${AppConfig.api_url}student-portal/documents/v1/`,
  REQUEST_VERIFICATION_URL: `${AppConfig.api_url}student-portal/documents/v1`,
  ADD_REMINDER: `${AppConfig.api_url}student-portal/documents/v1/reminder`,
  GET_USER_DATA_BY_USER_ID: `${AppConfig.api_url}user/v1/`,
  UPLOAD_ENROLLMENT_FILES: `${AppConfig.api_url}student-portal/documents/v2/upload-stud`,
  UPLOAD_PROVISIONAL_FILES: `${AppConfig.api_url}student-portal/documents/v5/upload-stud`,
  UPLOAD_ACTIVE_FILES: `${AppConfig.api_url}student-portal/documents/v3/upload-stud`,

  UPLOAD_CLOA_FLOA_FILES: `${AppConfig.api_url}student-portal/documents/v1/upload-stud`,
};
