import { AppConfig } from "env.development";

export const FEE_URLS = {
  ADD_FEE_PLANS: `${AppConfig.api_url}fee-master/v1`,
  GET_ALL_FEE_PLANS: `${AppConfig.api_url}fee-master/v1/all`,
  GET_FEE_BY_FEEMASTER_ID: `${AppConfig.api_url}fee-master/v1/`,
  GET_ALL_STUDENTS: `${AppConfig.api_url}admission/v1/student-master/all`,
  GET_STUDENT_DETAILS_BY_STUDENT_ID: `${AppConfig.api_url}dashboard/v1/student-view/`,
  ADD_INVOICE_FEE: `${AppConfig.api_url}fee-invoice/v1`,
  GET_ALL_INVOICE_FEE: `${AppConfig.api_url}fee-invoice/v1/all/status`,
  GET_STUDENT_MASTER_ID: `${AppConfig.api_url}admission/v1/get-student/all/`,
  GET_STUDENT_INVOICE_BY_ID: `${AppConfig.api_url}fee-invoice/v1/status/`,
  ADD_SPONSOR_INVOICE_FEE: `${AppConfig.api_url}sponsor-invoice/v1`,
  GET_ALL_SPONSOR_INVOICE_FEE: `${AppConfig.api_url}sponsor-invoice/v1/all/status`,
  GET_STUDENT_SPONSOR_INVOICE_BY_ID: `${AppConfig.api_url}sponsor-invoice/v1/`,
  GET_ALL_CAMPUS_BY_PROGRAM_NAME: `${AppConfig.api_url}program-fee/v1/campus-all/`,
  GET_STUDENT_RECEIPTS_BY_ID: `${AppConfig.api_url}fee-invoice/v1/get-all-receipt/`,
  GET_STUDENT_UPDATED_MARKS: `${AppConfig.api_url}manage-marks/v1/update-marks`,
};
