import React, { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { toast } from "react-toastify";
import { FORUM_DISCUSSION_CONSTANTS } from "../../constants/forum-discussion-constants";
import { FORUM_DISCUSSION_URLS } from "../../constants/forum-discussion-urls";

function CreateDiscussion({ open, onClose, forumId }) {
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const userData = sessionstorageService.getUserData("userData");

  const onSubmit = async () => {
    try {
      let sendData = {
        title: title,
        description: description,
        forum: {
          forumId: forumId,
        },
        createdUser: {
          userId: userData.userId,
        },
      };
      const response = await axiosService.post(
        `${FORUM_DISCUSSION_URLS.CREATE_NEW_DISCUSSION}`,
        sendData
      );
      if (response.status === 200) {
        toast.success(FORUM_DISCUSSION_CONSTANTS.CREATE_DISCUSSION_SUCCESS);
        onClose();
      } else {
      }
    } catch (error) {}
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <div className="new-conversation-popup-header">
            <span className="new-conversation-popup-title-text">
              Create New Discussion
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose color="#ffffff" size={"22px"} />
            </span>
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            <div>
              <label className="new-conversation-popup-label">Title *</label>

              <input
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                className="new-conversation-popup-input"
              />
            </div>
            <div>
              <label className="new-conversation-popup-label">
                Description *{" "}
                <span className="new-conversation-popup-label-small">
                  (max 250 chars)
                </span>
              </label>

              <textarea
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                maxLength={250}
                className="new-conversation-popup-textare-input custom-scrollbar"
              />
            </div>

            <div className="d-flex gap-3 mt-2">
              <button
                className="communication-management-cancel-button"
                onClick={onClose}
              >
                Close
              </button>
              <button
                disabled={!title || !description}
                className={`communication-management-submit-button ${
                  (!title || !description) && "opacity-50"
                }`}
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateDiscussion;
