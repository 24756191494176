import betalogo from "assets/images/icons/beta.svg";
import { useState, useEffect } from "react";
import "./feesInvoice.css";
import { Footer, Header } from "./pdf-template";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const PdfContent = ({ pdfData }) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div>
        <div className="d-flex justify-space-between gap-5 ">
          <p>
            <strong>Date:</strong> {formatDate(new Date())}
          </p>
          <p style={{ marginLeft: "295px" }}>
            <strong>Receipt No:</strong> 2312BC106
          </p>
        </div>
        <h3>OFFICIAL RECEIPT (PROVISIONAL)</h3>

        <div className="d-flex justify-space-between gap-5">
          <p>
            Student Name:
            <p>
              Student ID:
              <p>
                Course:
                <p>Start Date (YYYY/MM/DD):</p>
              </p>
            </p>
          </p>
          <p>
            {pdfData?.personalData?.firstName}{" "}
            {pdfData?.personalData?.lastName}
            <p>
              {pdfData?.personalData?.studentId}
              <p>
                {pdfData?.programDetails[0]?.program}
                <p>-</p>
              </p>
            </p>
          </p>
        </div>

        <p style={{ textDecoration: "underline" }}>
          Tuition Fee towards the first academic year for the above program:
        </p>
        <div className="d-flex justify-space-between gap-5">
          <p>
            Total Fees Payable :
            <p>
              Total Fees Paid (II payment) :<p>Mode of Payment :</p>
            </p>
          </p>
          <p>
            CAD 10,200.00
            <p>
              CAD 2,000.00
              <p>Interace transfer</p>
            </p>
          </p>
        </div>
        <p>
          For any questions, please feel free to contact Accounts Officer at
          +1(905) 497 9999 or
          <p>
            <span>
              email
              <a
                href="mailto:finance@mybeta.ca"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                finance@mybeta.ca
              </a>
            </span>
          </p>
        </p>

        <br></br>
        <p>For Beta College of Business and Technology:</p>
        <br></br>
        <p>
          Monica Singh
          <p>
            <strong>Sr.Accounts Officer</strong>
          </p>
          <br></br>
          <strong>Important Information:</strong>
          <p>
            • All fees and payments are subject to Terms and Conditions and
            actual realization. Claims will only be
            <p>
              entertained once the fees/funds are deposited in the college bank
              account/financial institution. Transfer
              <p>
                proof from a financial institution or Official Bank receipt is
                mandatory to produce to admit any application
                <p>for a refund.</p>
              </p>
            </p>
          </p>
          <p>
            • This document can be verified by emailing
            <strong> admin@betacollege.ca / hello@mybeta.ca</strong>
          </p>
        </p>
      </div>
      <div className="footer" style={{ marginTop: "4rem" }}>
        <Footer />
      </div>
    </div>
  );
};

const OfficialReceiptProvisional = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark) {
        const watermarkText = "Sample";
        const fontSize = 500;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }
      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });

      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 50,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 70)) /
          overlayCanvas.width
      );

      const fileName = includeWatermark
        ? "Provisional-Receipt-sample.pdf"
        : "Provisional-Receipt.pdf";

      if (includeWatermark) {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        finalPdf.save(fileName);
        setLoad(false);
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent pdfData={pdfData} />
      )}
    </span>
  );
};

export default OfficialReceiptProvisional;
