import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React from 'react'
import MyProfile from "../components/MyProfileComponent/MyProfile";

function MyProfileRoutes() {
    return (
        <Routes>
            <Route index path="my-profile" element={<MyProfile />} />
        </Routes>
    )
}

export default MyProfileRoutes
