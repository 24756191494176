import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { CLASS_URLS } from "../constants/class-url";

function ListClass() {
  const location = useLocation();
  const navigate = useNavigate();
  const [classData, setClassData] = useState("");

  useEffect(() => {
    axiosService.get(`${CLASS_URLS.GET_ALL}`).then((response) => {
      if (response.status === 200) {
        const responseData = response?.data?.data;
        setClassData(responseData);
      }
    });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/timetable-management/add-class", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Class Name",
      field: "module.module.moduleName",
      cellStyle: { "text-decoration": "underline" },
    },
    {
      headerName: "Faculty Assigned",
      field: "facultyAssigned.firstName",
    },
    {
      headerName: "Campus",
      field: "module.programFeeMaster.campus",
    },
    {
      headerName: "Facility ID",
      field: "resource.resourceName",
    },
    {
      headerName: "Shift",
      field: "shift",
    },
    {
      headerName: "Timing",
      valueGetter: mergeStartEndTime,
    },
    {
      headerName: "Start Date",
      field: "startDate",
    },
    {
      headerName: "End Date",
      field: "endDate",
    },
  ]);
  function mergeStartEndTime(params) {
    const startTime = params.data.startTime;
    const endTime = params.data.endTime;
    return `${startTime} - ${endTime}`;
  }
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Class Schedules </span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="sps-fv-top-count sps-fv-hi">
            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-save-head-button"
                onClick={() => {
                  navigate("/timetable-management/add-class");
                }}
              >
                Add a Class
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={classData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ListClass;
