import { AppConfig } from "env.development";

export const EXAM_MODULE_URLS = {
  GET_PROGRAM_MODULE_ID: `${AppConfig.api_url}program/v1/program-module/`,
  ADD_EXAMS: `${AppConfig.api_url}faculty-exam-module/v1`,
  APPROVE_EXAMS: `${AppConfig.api_url}faculty-exam-module/v1/faculty`,
  GET_ALL_EXAMS: `${AppConfig.api_url}faculty-exam-module/v1`,
  GET_STATUS_HISTORY: `${AppConfig.api_url}faculty-history/v1`,
  GET_STUDENT_TABLE: `${AppConfig.api_url}manage-marks/v1`,
  GET_STUDENT_TABLE1: `${AppConfig.api_url}manage-marks/v1/save`,
  SAVE_STUDENT_MARK: `${AppConfig.api_url}manage-marks/v1`,

  SAVE_ALL_STUDENT_MARKS: `${AppConfig.api_url}manage-marks/v1/all`,
};
