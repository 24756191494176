import { Field, Form, Formik } from "formik";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import { useEffect, useRef, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { CLASS_CONSTANTS } from "../constants/class-constants";
import { CLASS_URLS } from "../constants/class-url";
import "./AddClass.css";
import { TIMETABLE_URLS } from "modules/timetable-management/constants/timetable-constants-url";
function AddClass() {
  const location = useLocation();
  const Data = location.state;

  const scheduleId = Data?.data?.Data?.scheduleId;

  const [scheduleData, setScheduleData] = useState("");

  let initialValues = {
    program: scheduleData?.module?.programFeeMaster?.program || "",
    semester: scheduleData?.module?.semester || "",
    resourceType: scheduleData?.resource?.resourceType || "",
    resourceType2: "",
    roomType: scheduleData?.resource?.resourceName || "",
    shift: scheduleData?.shift || "",
    campus: scheduleData?.module?.programFeeMaster?.campus || "",
    module: scheduleData?.module?.module?.moduleName || "",
    facultyAssigned: scheduleData?.facultyAssigned || "",
    startTime: scheduleData?.startTime || "",
    endTime: scheduleData?.endTime || "",
    startDate: scheduleData?.startDate || "",
    endDate: scheduleData?.endDate || "",
    description: scheduleData?.description || "",
  };
  useEffect(
    (form) => {
      if (scheduleId) {
        axiosService
          .get(`${TIMETABLE_URLS.GET_SCHEDULE_BY_SCHEDULE_ID}/${scheduleId}`)
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data.data;
              setScheduleData(responseData);
              getCampusOptions(responseData?.module?.programFeeMaster?.program);
              setFeeProgramId(
                responseData?.module?.programFeeMaster?.programFeeMasterId
              );
              setDescription(responseData?.description);
              setStartDate(responseData?.startDate);
              setEndDate(responseData?.endDate);

              getSemesterOptions(
                responseData?.module?.programFeeMaster?.program,
                responseData?.module?.programFeeMaster?.campus
              );
              getResourceValue(
                responseData?.module?.programFeeMaster?.campus,
                form
              );

              if (responseData?.module?.programFeeMaster?.programFeeMasterId) {
                const urlWithParams = `${CLASS_URLS.GET_SEMESTER}${responseData?.module?.programFeeMaster?.programFeeMasterId}/${responseData?.module?.semester}`;
                axiosService.get(urlWithParams).then((response) => {
                  const apiData = response?.data?.data;
                  if (Array.isArray(apiData)) {
                    const moduleName = apiData.map((module) => ({
                      value: module.module.moduleName,
                      label: module.module.moduleName,
                      module: module,
                      programModuleId: module.programModuleId,
                    }));
                    if (moduleName.length > 0) {
                      setProgramModuleId(moduleName[0].programModuleId);
                    }
                    setModuleOptions(moduleName);
                  }
                });
              }
              setCodeOptions(responseData?.module?.module?.moduleCode);
              setSelectedDays(responseData?.selectedDaysOfWeek.days);
              setProgramName(responseData?.module?.programFeeMaster?.program);
              setSelectedShift(responseData?.shift);
              setRoom(responseData?.resource?.resourceName);
              setRoomObject(responseData?.resource);
              setModuleObject(responseData?.module);
              setProgramModuleId(responseData?.module?.programModuleId);
            }
          });
      }
    },
    [location.pathname, scheduleId]
  );

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  const navigate = useNavigate();
  const [programOptions, setProgramOptions] = useState([]);
  const [semesterOptions, setSemesterOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [campusOptions, setCampusOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [codeOptions, setCodeOptions] = useState("");
  const [feeProgramId, setFeeProgramId] = useState("");
  const [programName, setProgramName] = useState("");
  const [facultyAssignedOptions, setFacultyAssignedOptions] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [room, setRoom] = useState();
  const [roomObject, setRoomObject] = useState();
  const [moduleObject, setModuleObject] = useState();
  const [shift, setSelectedShift] = useState();
  const [description, setDescription] = useState();
  const [startTimeDropdownValues, setStartTimeDropdownValues] = useState([]);
  const [endTimeDropdownValues, setEndTimeDropdownValues] = useState([]);
  const [selectedShiftOptions, setSelectedShiftOptions] = useState([]);
  const [programModuleId, setProgramModuleId] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);

  const shiftOptions = [
    { value: "MORNING", label: "Morning" },
    {
      value: "AFTERNOON",
      label: "Afternoon",
    },
    { value: "EVENING", label: "Evening" },
    { value: "CUSTOM", label: "Custom" },
  ];

  useEffect(() => {
    axiosService.get(`${ROLE_URLS.GET_FACULTY_URL}`).then((response) => {
      const user = response?.data?.data;
      const facultyAssignedOptions = user?.map((user) => ({
        value: user,
        label: user.firstName,
        facultyAssignedObject: user,
      }));
      if (facultyAssignedOptions) {
        setFacultyAssignedOptions(facultyAssignedOptions);
      }
    });
  }, []);

  useEffect(() => {
    axiosService.get(`${CLASS_URLS.GET_ALL_PROGRAMS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;

        if (responseData) {
          setProgramOptions(responseData);
        }
      }
    });
  }, [location.pathname]);

  const scrollContainerRef = useRef(null);
  const messageContainerRef = useRef(null);

  const onSubmit = async (formValues) => {
    formValues.startDate = startDate;
    formValues.endDate = endDate;
    formValues.resource = roomObject;
    formValues.module = moduleObject;
    formValues.description = description;
    const filteredData = Object.fromEntries(
      Object.entries(formValues).filter(
        ([key, value]) => value !== "" && key !== "resourceType"
      )
    );
    filteredData.selectedDaysOfWeek = {
      days: selectedDays,
    };
    try {
      if (scheduleId) {
        filteredData.scheduleId = scheduleId;
        const response = await axiosService.put(
          `${CLASS_URLS.POST_URL}`,
          filteredData
        );
        if (response.status === 200) {
          toast.success(CLASS_CONSTANTS.SUCCESS);
          navigate("/timetable-management/list-class");
        } else {
          toast.error(CLASS_CONSTANTS.FAILED);
        }
      } else {
        const response = await axiosService.post(
          `${CLASS_URLS.POST_URL}`,
          filteredData
        );
        if (response.status === 200) {
          toast.success(CLASS_CONSTANTS.SUCCESS);
          navigate("/timetable-management/list-class");
        } else {
          toast.error(CLASS_CONSTANTS.FAILED);
        }
      }
    } catch (error) {
      toast.error(CLASS_CONSTANTS.ERROR_OCCUR);
    }
  };

  const scrollBottom = () => {
    const container = scrollContainerRef.current;
    const messager = scrollContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight + messager.scrollHeight;

      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          scrollBottom();
          observer.unobserve(entries[0].target);
        }
      });
      const currentRef = messageContainerRef.current;
      if (currentRef) {
        observer.observe(currentRef);
      }
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }
  };

  const customStyles = {
    option: (provided, state) => {
      const backgroundColor = state.isDisabled
        ? "black !important"
        : "black !important";
      const color = state.isDisabled ? "gray !important" : "white !important";
      const cursor = state.isDisabled
        ? "not-allowed !important"
        : "default !important";
      const hoverBackgroundColor = state.isDisabled
        ? backgroundColor
        : "#f69f3a !important";
      const hoverTextColor = state.isDisabled ? color : "black !important";

      return {
        ...provided,
        backgroundColor,
        color,
        cursor,
        width: "100% !important",
        height: "33px !important",
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
          color: hoverTextColor,
        },
      };
    },
  };
  const isSaveButtonDisabled = (values) => {
    return (
      values.values.program === "" ||
      values.values.semester === "" ||
      values.values.roomType === "" ||
      values.values.shift === "" ||
      values.values.facultyAssigned === "" ||
      values.values.startTime === "" ||
      values.values.endTime === ""
    );
  };

  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    setStartDate(startDate);
  };

  const handleEndDateChange = (event) => {
    const endDate = event.target.value;
    setEndDate(endDate);
  };

  useEffect(() => {
    if (shift && startDate && endDate && room) {
      const urlWithParams = `${CLASS_URLS.GET_TIME}?shift=${shift}&startDate=${startDate}&endDate=${endDate}&resourceName=${room}&programModuleId=${programModuleId}`;

      axiosService.getForUrl(urlWithParams).then((response) => {
        const apiData = response?.data;
        if (apiData) {
          const { startTimeDropdownValues, endTimeDropdownValues } = apiData;
          const startTimeOptions = Object.entries(startTimeDropdownValues).map(
            ([value, isDisabled]) => ({
              value,
              label: value,
              isDisabled,
            })
          );

          const endTimeOptions = Object.entries(endTimeDropdownValues).map(
            ([value, isDisabled]) => ({
              value,
              label: value,
              isDisabled,
            })
          );

          setStartTimeDropdownValues(startTimeOptions);
          setEndTimeDropdownValues(endTimeOptions);
        }
      });
    }
  }, [shift, startDate, endDate, room]);

  const getCampusOptions = (programValue) => {
    if (programValue) {
      axiosService
        .get(`${CLASS_URLS.GET_CAMPUS}${programValue}`)
        .then((response) => {
          const apiData = response.data;
          setProgramName(programValue);
          setCampusOptions(apiData?.data);
        })
        .catch((error) => {});
    }
  };
  const getSemesterOptions = (programName, campusValue) => {
    const urlWithParams = `${CLASS_URLS.GET_PROGRAM_AND_SEMESTER_ID}?program=${programName}&campus=${campusValue}`;
    axiosService.get(urlWithParams).then((response) => {
      const isMorning = response?.data?.data?.isMorning === true;
      const isAfternoon = response?.data?.data?.isAfternoon === true;
      const isEvening = response?.data?.data?.isEvening === true;
      const isCustom = response?.data?.data?.isCustom === true;
      let selectedShiftOptions = [];
      if (isMorning) {
        selectedShiftOptions.push(
          shiftOptions.find((option) => option.value === "MORNING")
        );
      }

      if (isAfternoon) {
        selectedShiftOptions.push(
          shiftOptions.find((option) => option.value === "AFTERNOON")
        );
      }

      if (isEvening) {
        selectedShiftOptions.push(
          shiftOptions.find((option) => option.value === "EVENING")
        );
      }
      if (isCustom) {
        selectedShiftOptions.push(
          shiftOptions.find((option) => option.value === "CUSTOM")
        );
      }
      setSelectedShiftOptions(selectedShiftOptions);

      const programFeeMasterId = response?.data?.data?.programFeeMasterId;
      setFeeProgramId(programFeeMasterId);
      if (programFeeMasterId) {
        const urlWithParams = `${CLASS_URLS.GET_PROGRAM_MASTER_ID}/${programFeeMasterId}`;

        axiosService.get(urlWithParams).then((response) => {
          const apiData = response?.data?.data;
          if (Array.isArray(apiData)) {
            const semesterOptions = apiData.map((semester) => ({
              value: semester,
              label: semester,
            }));
            setSemesterOptions(semesterOptions);
          }
        });
      }
    });
  };
  const getResourceValue = (campusValue, form) => {
    const urlWithParams = `${CLASS_URLS.GET_RESOURCE}${campusValue}`;
    axiosService
      .get(urlWithParams)
      .then((response) => {
        const apiData = response.data;
        if (Array.isArray(apiData.data)) {
          const roomOptions = apiData.data.map((room) => ({
            value: room.resourceName,
            label: room.resourceName,
            resource: apiData,
          }));
          setRoomOptions(roomOptions);
        } else {
        }
      })
      .catch((error) => {
        toast.error(CLASS_CONSTANTS.RESOURCES_OCCUR);
        form.setFieldValue("campus", "");
        form.setFieldValue("program", "");
      });
  };
  const getModuleOptions = (semesterValue, form) => {
    if (feeProgramId) {
      const urlWithParams = `${CLASS_URLS.GET_SEMESTER}${feeProgramId}/${semesterValue}`;
      axiosService.get(urlWithParams).then((response) => {
        const apiData = response?.data?.data;
        if (Array.isArray(apiData)) {
          const moduleName = apiData.map((module) => ({
            value: module.module.moduleName,
            label: module.module.moduleName,
            module: module,
            programModuleId: module.programModuleId,
          }));
          if (moduleName.length > 0) {
            setProgramModuleId(moduleName[0].programModuleId);
          }
          setModuleOptions(moduleName);
          form.setFieldValue("module", moduleName?.module);
          setCodeOptions("");
        }
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(values) => (
        <Form>
          <div className="communication-management-main-container custom-scrollbar pb-4">
            <div className="communication-management-head-container sticky-header-communication flex-sm-row gap-2">
              <span className="communication-management-title-text">
                Add a Class
              </span>
            </div>
            <div className="communication-management-content-container row g-0 ">
              <div className="col-12 col-lg-3 add-class-left-con-border">
                <div className="home-title">Program Details</div>
                <div className="px-md-2 ps-0">
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <label className="lead-first-name">
                        Select a Program *
                      </label>
                      <Field
                        name="program"
                        render={({ field, form }) => (
                          <Select
                            id="program"
                            value={
                              field.value
                                ? programOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            onChange={(selectedOption) => {
                              const selectedValue = selectedOption
                                ? selectedOption.value
                                : null;
                              const programFeeMasterId =
                                selectedOption?.program?.master
                                  ?.programFeeMasterId;
                              setFeeProgramId(programFeeMasterId);

                              form.setFieldValue(field.name, selectedValue);
                              form.setFieldValue("campus", "");
                              form.setFieldValue("shift", "");
                              form.setFieldValue("semester", "");
                              form.setFieldValue("moduleCode", "");
                              form.setFieldValue("resourceType", "");
                              form.setFieldValue("resourceType2", "");
                              form.setFieldValue("roomType", "");
                              form.setFieldValue("moduleName", "");
                              form.setFieldValue("module", "");
                              form.setFieldValue("facultyAssigned", "");
                              form.setFieldValue("startTime", "");
                              form.setFieldValue("endTime", "");
                              form.setFieldValue("startDate", "");
                              form.setFieldValue("endDate", "");
                              setRoomOptions([]);
                              setSelectedShiftOptions([]);
                              setRoom("");
                              setRoomObject("");
                              setCodeOptions("");
                              setStartDate("");
                              setEndDate("");
                              setSelectedShift("");
                              getCampusOptions(selectedValue);
                            }}
                            styles={customStyles}
                            options={programOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label className="lead-first-name">Select Campus *</label>
                      <Field
                        name="campus"
                        render={({ field, form }) => (
                          <Select
                            id="campus"
                            value={
                              field.value
                                ? campusOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            onChange={(selectedOption) => {
                              const selectedValue = selectedOption
                                ? selectedOption.value
                                : null;

                              form.setFieldValue(field.name, selectedValue);
                              getSemesterOptions(programName, selectedValue);
                              getResourceValue(selectedValue, form);
                            }}
                            options={campusOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0 ps-2 col-12 col-lg-9 position-relative ">
                <div className="home-title">Class Details</div>
                <div className="pb-3 ps-0">
                  <div className="flex-column flex-md-row d-flex gap-3">
                    <div style={{ flex: "2" }}>
                      <label className="lead-first-name">
                        Select a Semester *
                      </label>
                      <Field
                        className="resource-input"
                        name="semester"
                        render={({ field, form }) => (
                          <Select
                            id="semester"
                            value={
                              field.value
                                ? semesterOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            onChange={(selectedOption) => {
                              const selectedValue = selectedOption
                                ? selectedOption.value
                                : null;

                              form.setFieldValue(field.name, selectedValue);
                              getModuleOptions(selectedValue, form);
                            }}
                            options={semesterOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Autofill and choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Module Name *</label>
                      <Field
                        className="resource-input"
                        name="module"
                        render={({ field, form }) => (
                          <Select
                            id="module"
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            value={
                              field.value
                                ? moduleOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                field.name,
                                selectedOption?.value
                              );
                              setModuleObject(selectedOption?.module);
                              const moduleCode = {
                                value:
                                  selectedOption?.module?.module?.moduleCode,
                                label:
                                  selectedOption?.module?.module?.moduleCode,
                              };
                              setCodeOptions(moduleCode.value);
                            }}
                            options={moduleOptions}
                            placeholder="Autofill and choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Module Code *</label>
                      <Field
                        className="lead-input col-8 col-md-10"
                        options={codeOptions}
                        value={codeOptions}
                        isDisabled={true}
                        placeholder="Select resource"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                  </div>
                </div>
                <div className="pb-3 ps-0">
                  <div className="flex-column flex-md-row d-flex gap-3">
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Shift *</label>
                      <Field
                        className="resource-input"
                        name="shift"
                        render={({ field, form }) => (
                          <Select
                            id="shift"
                            options={selectedShiftOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            value={
                              field.value
                                ? selectedShiftOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            placeholder="Autofill and choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            onChange={(selectedOption) => {
                              form.setFieldValue("shift", selectedOption.value);
                              setSelectedShift(selectedOption.value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">
                        Faculty Assigned *
                      </label>
                      <Field
                        className="resource-input"
                        name="facultyAssigned"
                        id="facultyAssigned"
                        render={({ field, form }) => (
                          <Select
                            value={
                              field.value
                                ? facultyAssignedOptions.find(
                                    (option) =>
                                      option.facultyAssignedObject
                                        ?.firstName === field.value?.firstName
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(
                                field.name,
                                option?.facultyAssignedObject
                              );
                            }}
                            options={facultyAssignedOptions}
                            id="semester"
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Autofill and choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>

                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Select a room *</label>
                      <Field
                        className="resource-input"
                        name="roomType"
                        render={({ field, form }) => (
                          <Select
                            id="roomType"
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "roomType",
                                selectedOption.value
                              );
                              setRoom(selectedOption.value);
                              const selectedDataArrays = selectedOption.resource
                                .data
                                ? selectedOption.resource.data
                                : [];
                              const matchingObject = selectedDataArrays.find(
                                (data) =>
                                  data.resourceName === selectedOption.value
                              );
                              setRoomObject(matchingObject);
                            }}
                            value={
                              field.value
                                ? roomOptions.find(
                                    (option) => option?.value === field.value
                                  )
                                : null
                            }
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            options={roomOptions}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            placeholder="Select resource"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="pb-3 ps-0 d-flex flex-column flex-md-row gap-2">
                  <div className="d-flex flex-column col-12 col-md-6 gap-3 gap-lg-0">
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Start Date *</label>
                      <Field
                        className=" lead-input ceol-8 col-md-10"
                        name="startDate"
                        id="startDate"
                        type="date"
                        value={startDate}
                        placeholder="Select Date"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">End Date *</label>
                      <Field
                        name="endDate"
                        id="endDate"
                        type="date"
                        value={endDate}
                        className=" lead-input col-8 col-md-10"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Select Date"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-6 d-flex flex-column gap-3 ps-md-4"
                    role="listbox"
                  >
                    <label className="lead-first-name">Repeat *</label>
                    <div className="d-flex flex-row gap-4">
                      {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map(
                        (day) => (
                          <span
                            key={day}
                            onClick={() => toggleDay(day)}
                            className={`custom-checkbox ${
                              selectedDays.includes(day) ? "selected" : ""
                            }`}
                            style={{
                              backgroundColor: selectedDays.includes(day)
                                ? "#F69F3A"
                                : "#ffffff",
                              color: selectedDays.includes(day)
                                ? "#ffffff"
                                : "#000000",
                              cursor: "pointer",
                            }}
                          >
                            {day.charAt(0)}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <div className="d-flex flex-column flex-md-row col-12 col-md-6 gap-3">
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">Start Time *</label>
                      <Field
                        name="startTime"
                        render={({ field, form }) => (
                          <Select
                            id="startTime"
                            value={
                              field.value
                                ? startTimeDropdownValues.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            options={startTimeDropdownValues.map(
                              ({ value, label, isDisabled }) => ({
                                value,
                                label,
                                isDisabled: isDisabled === true,
                              })
                            )}
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            styles={customStyles}
                            isSearchable={false}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            classNamePrefix="react-select-big"
                            placeholder="Select time"
                            menuPlacement="auto"
                            isOptionDisabled={(option) => option.isDisabled}
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label className="lead-first-name">End Time *</label>
                      <Field
                        className="resource-input"
                        name="endTime"
                        render={({ field, form }) => (
                          <Select
                            id="endTime"
                            value={
                              field.value
                                ? endTimeDropdownValues.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            options={endTimeDropdownValues.map(
                              ({ value, label, isDisabled }) => ({
                                value,
                                label,
                                isDisabled: isDisabled === true,
                              })
                            )}
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            styles={customStyles}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Select time"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <label className="lead-first-name">
                      Meeting Link/Description *
                    </label>
                    <Field
                      className="resource-input"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      style={{ width: "100%" }}
                      placeholder="Meeting Link/Description"
                      name="description"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 py-3">
                  <button
                    className="user-head-button"
                    onClick={() => {
                      navigate(-1);
                    }}
                    type="button"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className={`user-save-head-button ${
                      isSaveButtonDisabled(values)
                        ? "user-save-head-button-disabled"
                        : ""
                    }`}
                    disabled={isSaveButtonDisabled(values)}
                  >
                    {scheduleId ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddClass;
