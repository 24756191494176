import React, { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import sessionService from "services/sessionstorage.service";
import { SERVICE_FACULTY_VIEW } from "modules/student-portal/constants/faculty-service-urls";
import { axiosService } from "services/axios.service";

function HealthModal({ open, onClose, data }) {
  const [remarks, setRemarks] = useState("");
  const [showError, setShowError] = useState(false);
  const user = sessionService.getUserData("userData");

  const handleApproveReject = async (status) => {
    if (status === "REJECTED" && remarks === "") {
      setShowError(true);
    } else {
      let requestData = data;
      requestData.status = status;
      requestData.remarks = remarks;
      requestData.staffName = user?.firstName + (user?.lastName || "");

      const response = await axiosService.put(
        `${SERVICE_FACULTY_VIEW.PUT_SERVICE}`,
        requestData
      );
      if (response.status === 200) {
        if (response?.data?.data?.status === "REJECTED") {
          onClose();
          setRemarks("");
          setShowError(false);
          toast.success("Service Rejected");
        } else {
          onClose();
          setRemarks("");
          setShowError(false);
          toast.success("Service Approved");
        }
      }
    }
  };

  return (
    <Modal className="crm-modal" isOpen={open}>
      <div className="modal-Con">
        <div
          className="col-11 col-md-7 col-lg-5 p-3"
          style={{ height: "auto", backgroundColor: "#333333" }}
        >
          <div className="student-portal-modal-header">
            <span className="student-portal-modal-title">Health Insurance</span>

            <IoClose
              color="#ffffff"
              size={"23px"}
              onClick={() => {
                setRemarks("");
                setShowError(false);
                onClose();
              }}
            />
          </div>
          <div>
            <div className="d-flex flex-column gap-3 mt-2">
              <div className="d-flex flex-column">
                <span className="faculty-student-portal-service-popup-label">
                  Student Name
                </span>
                <span className="faculty-student-portal-service-popup-value">
                  {data?.user?.firstName} {data?.user?.lastName}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="faculty-student-portal-service-popup-label">
                  Uploaded form
                </span>
                <a
                  href={data?.paymentReceipt}
                  target="_blank"
                  style={{ color: "#F69F3A" }}
                  className="faculty-student-portal-service-popup-value text-decoration-underline"
                >
                  {data?.paymentReceiptName}
                </a>
              </div>

              <div className="d-flex flex-column">
                <span className="faculty-student-portal-service-popup-label">
                  Comments/Queries
                </span>
                <span className="faculty-student-portal-service-popup-value">
                  {data?.comments}
                </span>
              </div>
            </div>

            <div
              className="mt-3 pt-2"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <label className="faculty-student-portal-service-popup-value">
                Remarks
              </label>
              <input
                className="faculty-student-portal-service-popup-input"
                name="remarks"
                value={remarks}
                onChange={(event) => setRemarks(event.target.value)}
              />
              {showError && remarks === "" && (
                <span className="error-message-student-portal ">
                  Remark is mandatory
                </span>
              )}
            </div>
          </div>
          <div className=" d-flex gap-3 mt-4">
            <button
              className="student-portal-cancel-button"
              type="button"
              onClick={() => {
                setRemarks("");
                setShowError(false);
                onClose();
              }}
            >
              Cancel
            </button>
            {data?.status === "OPEN" && (
              <button
                className="student-portal-gray-save-head-button"
                type="submit"
                onClick={() => handleApproveReject("REJECTED")}
              >
                Reject
              </button>
            )}
            {data?.status === "OPEN" && (
              <button
                className="student-portal-submit-button"
                type="submit"
                onClick={() => handleApproveReject("APPROVED")}
              >
                Approve
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default HealthModal;
