import { Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import "./CreateAcademicProfessional.css";
import { MdDelete } from "react-icons/md";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import * as Yup from "yup";
import { Country } from "country-state-city";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import sessionService from "services/sessionstorage.service";

function CreateAcademicProfessional() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.academics[0]?.isApproved);
          setRejected(data?.academics[0]?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
        });
    }
  }, [location.pathname]);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };
    fetchCountries();
  }, []);

  let academicsInitialValues;

  if (admissionData?.academics.length > 0) {
    academicsInitialValues = admissionData.academics?.map((academic) => ({
      qualificationName: academic.qualificationName || "",
      completionYear: academic.completionYear || "",
      instituteName: academic.instituteName || "",
      country: academic.country || "",
      academicId: academic?.academicId,
    }));
  } else {
    academicsInitialValues = [
      {
        qualificationName: "",
        completionYear: "",
        instituteName: "",
        country: "",
      },
    ];
  }

  const validationSchema = Yup.object().shape({
    academics: Yup.array().of(
      Yup.object().shape({
        qualificationName: Yup.string().required(
          "Qualification Name is required"
        ),
        completionYear: Yup.string().required("Year is required"),
        instituteName: Yup.string().required("Institute Name is required"),
        country: Yup.string().required("Country is required"),
      })
    ),
  });

  const initialValues = {
    academics: academicsInitialValues,
  };

  const navigateToGuardian = () => {
    navigation("/pre-onboarding/create-guardian-details", {
      state: {
        data: programId,
      },
    });
  };
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;
  const onSubmit = async (values, { setSubmitting }) => {
    const dataType = "academic";
    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: values.academics,
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );
      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.ACADEMIC_SUCCESS);
        navigation("/pre-onboarding/create-employement-work-experience", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.ACADEMIC_FAILURE);
      }
      setSubmitting(false);
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.ACADEMIC_ERROR);
      }
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
    }),
  };

  const dateString = new Date(admissionData?.academics[0]?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleDeleteAcademic = async (index, form, remove) => {
    const academicIdToDelete = form.values.academics[index]?.academicId;

    if (academicIdToDelete) {
      try {
        const response = await axiosService.delete(
          `${ADMISSION_URL_FOR_STUDENT.DELETE_INDEX}/${academicIdToDelete}`
        );

        if (response.status === 200) {
          toast.success("Academic entry deleted successfully");
        } else {
          toast.error("Failed to delete academic entry");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    }
    remove(index);
  };

  const handleClear = async (formik) => {
    formik.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formik, setFieldValue) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>

              <div className="pre-create-employement-work-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title">
                  Academic and Professional academics
                </div>
                <div className="scroll-con">
                  <FieldArray name="academics">
                    {({ push, remove, form }) => (
                      <div>
                        {form.values?.academics?.map((qualification, index) => (
                          <div key={index}>
                            <div className="create-employement-work-main-input-container">
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Qualification Name *
                                  </label>
                                ) : null}
                                <Field
                                  name={`academics[${index}].qualificationName`}
                                  className="create-admission-input-big"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.touched.academics &&
                                formik.touched.academics[index] &&
                                formik.touched.academics[index]
                                  .qualificationName &&
                                formik.errors.academics &&
                                formik.errors.academics[index] &&
                                formik.errors.academics[index]
                                  .qualificationName &&
                                !formik.values.academics[index]
                                  .qualificationName ? (
                                  <div className="error qualificationName">
                                    {
                                      formik.errors.academics[index]
                                        ?.qualificationName
                                    }
                                  </div>
                                ) : null}
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Completion Year *
                                  </label>
                                ) : null}

                                <Field
                                  name={`academics[${index}].completionYear`}
                                  className="create-admission-input-small"
                                  value={
                                    formik.values.academics[index]
                                      .completionYear || ""
                                  }
                                  maxLength={4}
                                  onChange={(e) => {
                                    const numericValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      `academics[${index}].completionYear`,
                                      numericValue
                                    );
                                  }}
                                />

                                {form.touched &&
                                form.touched.academics &&
                                form.touched.academics[index] &&
                                form.touched.academics[index].completionYear &&
                                form.errors &&
                                form.errors.academics &&
                                form.errors.academics[index] &&
                                form.errors.academics[index].completionYear &&
                                !form.values.academics[index].completionYear ? (
                                  <div className="error completionYear">
                                    {
                                      form.errors.academics[index]
                                        ?.completionYear
                                    }
                                  </div>
                                ) : null}
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Name of Institution *
                                  </label>
                                ) : null}
                                <Field
                                  name={`academics[${index}].instituteName`}
                                  className="create-admission-input-big"
                                  value={
                                    formik.values.academics[index]
                                      .instituteName || ""
                                  }
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const filteredValue = inputValue.replace(
                                      /[^a-zA-Z\s]/g,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      `academics[${index}].instituteName`,
                                      filteredValue
                                    );
                                  }}
                                />

                                {form.touched &&
                                form.touched.academics &&
                                form.touched.academics[index] &&
                                form.touched.academics[index].instituteName &&
                                form.errors &&
                                form.errors.academics &&
                                form.errors.academics[index] &&
                                form.errors.academics[index].instituteName &&
                                !form.values.academics[index].instituteName ? (
                                  <div className="error instituteName">
                                    {
                                      form.errors.academics[index]
                                        ?.instituteName
                                    }
                                  </div>
                                ) : null}
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Country *
                                  </label>
                                ) : null}
                                <Field
                                  as="select"
                                  name={`academics[${index}].country`}
                                >
                                  {({ field, form }) => (
                                    <Select
                                      className="create-admission-select-big"
                                      options={countries}
                                      classNamePrefix="react-select"
                                      styles={selectStyles}
                                      value={
                                        field.value
                                          ? countries.find(
                                              (option) =>
                                                option.value === field.value
                                            )
                                          : null
                                      }
                                      onChange={(selectedOption) => {
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.value
                                        );
                                      }}
                                      isSearchable={true}
                                      components={{
                                        DropdownIndicator: () => (
                                          <RxTriangleDown color="#ffffff" />
                                        ),
                                        IndicatorSeparator: null,
                                      }}
                                      placeholder="Choose"
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                    />
                                  )}
                                </Field>
                                {form.touched &&
                                form.touched.academics &&
                                form.touched.academics[index] &&
                                form.touched.academics[index].country &&
                                form.errors &&
                                form.errors.academics &&
                                form.errors.academics[index] &&
                                form.errors.academics[index].country &&
                                !form.values.academics[index].country ? (
                                  <div className="error country">
                                    {form.errors.academics[index]?.country}
                                  </div>
                                ) : null}
                              </div>
                              {form.values?.academics.length > 1 &&
                                index > 0 && (
                                  <button
                                    type="button"
                                    className="create-admission-remove-row"
                                    onClick={() =>
                                      handleDeleteAcademic(index, form, remove)
                                    }
                                  >
                                    <MdDelete />
                                  </button>
                                )}
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="create-admission-add-row"
                          onClick={() =>
                            push({
                              qualificationName: "",
                              completionYear: "",
                              instituteName: "",
                              country: "",
                            })
                          }
                        >
                          + Add Qualification
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    onClick={navigateToGuardian}
                    className="create-admission-previous-button"
                    type="button"
                  >
                    Previous
                  </button>
                  {!admissionData?.academics[0]?.academicId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {!admissionData?.academics[0]?.academicId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(formik)}
                    >
                      Clear
                    </button>
                  )}
                  {(admissionData?.academics[0]?.academicId ||
                    admissionData?.academics[0]?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation(
                          "/pre-onboarding/create-employement-work-experience",
                          {
                            state: {
                              data: programId,
                            },
                          }
                        )
                      }
                    >
                      Next
                    </button>
                  )}
                </div>

                {admissionData?.academics[0]?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.academics[0]?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.academics[0]?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateAcademicProfessional;
