import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "services/axios.service";
import "./ViewAdmission.css";
import { ADMISSION_MANAGEMENT_URL } from "../create-admission/constants/admission-management-urls";
import { useLocation, useNavigate } from "react-router-dom";

function ViewAdmission() {
  const location = useLocation();
  const navigate = useNavigate();
  const [completedApplicationData, setCompletedApplicationData] = useState([]);
  function formatProgramType(programType) {
    switch (programType) {
      case "NONVOCATIONAL":
        return "Non-Vocational";
      case "VOCATIONALANDACADEMIC":
        return "Vocational, Academic";

      default:
        return programType;
    }
  }

  useEffect(() => {
    axiosService
      .get(`${ADMISSION_MANAGEMENT_URL.GET_ALL_PROGRAM}`)
      .then((response) => {
        const data = response?.data?.data;
        setCompletedApplicationData(data.reverse());
      })
      .catch(() => {});
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/admission-management/create-personal-data", {
      state: {
        data: {
          admissionId: event.data.admissionId,
          programId: event.data.programDetail?.programId,
          feeId: event.data.fee?.feeId,
        },
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Application No",
      field: "programDetail.applicationNo",
      width: 170,
    },
    {
      headerName: "Program",
      field: "programDetail.program",
      width: 320,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
      },
    },
    {
      headerName: "Student Name",
      field: "personalData.firstName",
      width: 190,
    },
    {
      headerName: "Status",
      field: "status",
      width: 200,
      cellStyle: {
        whiteSpace: "normal",
        overflowWrap: "break-word",
      },
      cellRenderer: function (params) {
        const status = params?.value;
        let statusLabel = status;
        switch (status) {
          case "APPROVED":
            statusLabel = "APPROVED";
            break;
          case "APPROVED_PENDING_CLOA_PAYMENT":
            statusLabel = "APPROVED-PENDING-CLOA";
            break;
          case "APPROVED_PENDING_APPLICATION_AND_TUTION_FEE":
            statusLabel = "APPROVED-PENDING-APP-TUITION-FEE";
            break;
          case "REJECTED":
            statusLabel = "REJECTED";
            break;
          case "PENDING":
            statusLabel = "PENDING";
            break;

          default:
            break;
        }

        return (
          <span
            className={`ag-grid-application-status-${status.toLowerCase()}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            {statusLabel}
          </span>
        );
      },
    },

    {
      headerName: "Academic Status",
      field: "programDetail.academicStatus",
      width: 180,
    },
    {
      headerName: "Type of Training Program",
      field: "programDetail.programType",
      valueFormatter: function (params) {
        return formatProgramType(params.value);
      },
      width: 230,
    },

    {
      headerName: "Created Date",
      field: "programDetail.addedOn",
      cellRenderer: function (params) {
        const date = params.data.programDetail.addedOn;
        return date ? new Date(date).toLocaleDateString("en-GB") : "";
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  const getRowHeight = (params) => {
    if (params.data?.programDetail?.program?.length > 50) {
      return 80;
    } else if (params.data?.programDetail?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Admission </span>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={completedApplicationData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
      </div>
    </div>
  );
}

export default ViewAdmission;
