import { STUDENT_PORTAL_SERVICE_URL } from "modules/student-portal/constants/student-portal-urls";
import React, { useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import AddStudentPortalService from "../add-student-portal-service/AddStudentPortalService";
import "./StudentPortalService.css";
import sessionService from "services/sessionstorage.service";
import { TiArrowUnsorted } from "react-icons/ti";

function StudentPortalService() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [sortOrder, setSortOrder] = useState({ column: null, ascending: true });

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${STUDENT_PORTAL_SERVICE_URL.GET_SERVICE_URL}`
      );

      if (response.status === 200) {
        const responseData = response.data.data.reverse();
        setDatas(responseData);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getServiceType = (value) => {
    const serviceTypes = [
      { value: "AIRPORTMEETANDGREET", label: "Airport Meet and Greet" },
      { value: "ACCOMMODATION", label: "Hostel/Accommodation" },
      { value: "HEALTHINSURANCE", label: "Health Insurance" },
    ];
    const option = serviceTypes.find((option) => option.value === value);
    return option ? option.label : value;
  };

  const getStatusClassName = (status) => {
    if (status === "APPROVED") {
      return "student-portal-table-status-approved";
    } else if (status === "REJECTED") {
      return "student-portal-table-status-pending";
    } else if (status === "OPEN") {
      return "student-portal-table-status-open";
    }
  };

  const handleSort = (column) => {
    setSortOrder((prevSortOrder) => ({
      column,
      ascending:
        column === prevSortOrder.column ? !prevSortOrder.ascending : true,
    }));
  };

  const sortedData = [...datas].sort((a, b) => {
    const column = sortOrder.column;
    const aValue = getServiceType(a[column]);
    const bValue = getServiceType(b[column]);

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (sortOrder.ascending) {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  });
  function hasPermission(permission) {
    let userPermissions = sessionService?.hasPermissionByButton(permission);
    return userPermissions;
  }

  return (
    <div className="student-portal-service-container">
      <div className="student-ps-top sticky-header">
        <span>Services</span>
        {hasPermission("Services: create") && (
          <a
            className="student-ps-top-button"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Request a Service
          </a>
        )}
      </div>
      <div className=" student-portal-inner-main-container custom-scrollbar">
        {Array.isArray(sortedData) && sortedData.length > 0 ? (
          <table
            style={{
              width: "fit-content",
              height: "fit-content",
              overflowX: "auto",
            }}
          >
            <thead className="table-header-student-portal">
              <tr>
                <th style={{ width: "250px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Request Service
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("serviceType")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
                <th style={{ width: "180px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Request Date
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("requestDate")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
                <th style={{ width: "150px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Status
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("status")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
                <th style={{ width: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Staff/Admin Contact
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("staffAdminContact")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((studentService) => (
                <tr key={studentService?.portalServiceId}>
                  <td className="table-data-student-portal text-decoration-underline">
                    {getServiceType(studentService?.serviceType)}
                  </td>
                  <td className="table-data-student-portal">
                    {studentService?.requestDate}
                  </td>
                  <td
                    className={`table-data-student-portal ${getStatusClassName(
                      studentService?.status
                    )}`}
                  >
                    {studentService?.status}
                  </td>
                  <td className="table-data-student-portal">
                    {studentService?.staffAdminContact}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-entries-made">No requests made</p>
        )}
      </div>

      <AddStudentPortalService
        open={isModalOpen}
        onClose={(data) => {
          setIsModalOpen(false);
          fetchData();
          setSelectedServiceId(null);
        }}
        selectedServiceId={selectedServiceId}
      />
    </div>
  );
}

export default StudentPortalService;
