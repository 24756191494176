import React from "react";

function AcademicsModules() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4037_14537)">
        <path
          d="M0 11.375C0 11.7231 0.138281 12.0569 0.384422 12.3031C0.630564 12.5492 0.964403 12.6875 1.3125 12.6875H12.6875C13.0356 12.6875 13.3694 12.5492 13.6156 12.3031C13.8617 12.0569 14 11.7231 14 11.375V5.25C14 4.9019 13.8617 4.56806 13.6156 4.32192C13.3694 4.07578 13.0356 3.9375 12.6875 3.9375H1.3125C0.964403 3.9375 0.630564 4.07578 0.384422 4.32192C0.138281 4.56806 0 4.9019 0 5.25L0 11.375ZM1.75 2.625C1.75 2.74103 1.79609 2.85231 1.87814 2.93436C1.96019 3.01641 2.07147 3.0625 2.1875 3.0625H11.8125C11.9285 3.0625 12.0398 3.01641 12.1219 2.93436C12.2039 2.85231 12.25 2.74103 12.25 2.625C12.25 2.50897 12.2039 2.39769 12.1219 2.31564C12.0398 2.23359 11.9285 2.1875 11.8125 2.1875H2.1875C2.07147 2.1875 1.96019 2.23359 1.87814 2.31564C1.79609 2.39769 1.75 2.50897 1.75 2.625ZM3.5 0.875C3.5 0.991032 3.54609 1.10231 3.62814 1.18436C3.71019 1.26641 3.82147 1.3125 3.9375 1.3125H10.0625C10.1785 1.3125 10.2898 1.26641 10.3719 1.18436C10.4539 1.10231 10.5 0.991032 10.5 0.875C10.5 0.758968 10.4539 0.647688 10.3719 0.565641C10.2898 0.483594 10.1785 0.4375 10.0625 0.4375H3.9375C3.82147 0.4375 3.71019 0.483594 3.62814 0.565641C3.54609 0.647688 3.5 0.758968 3.5 0.875Z"
          fill="#F69F3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_4037_14537">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AcademicsModules;
