import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ViewSponsorInvoice.css";
import { axiosService } from "services/axios.service";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import AddSponsorInvoice from "../add-sponsor-invoice/AddSponsorInvoice";

function ViewSponsorInvoice() {
  const location = useLocation();
  const [listInvoiceData, setListInvoiceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  function formatInvoiceType(invoiceType) {
    switch (invoiceType) {
      case "TUITIONFEE":
        return "Tuition Fee";
      case "APPLICATIONFEE":
        return "Application Fee";
      case "MATERIALFEE":
        return "Material Fee";
      case "INTERNATIONALFEE":
        return "International Fee";
      case "BANKFEE":
        return "Bank Fee";
      case "OTHERFEE":
        return "Other Fee";
      case "ESLFEE":
        return "ESL Fee";
      case "FIELDTRIPSFEE":
        return "FieldTrips Fee";

      default:
        return invoiceType;
    }
  }

  useEffect(() => {
    axiosService
      .get(`${FEE_URLS.GET_ALL_SPONSOR_INVOICE_FEE}`)
      .then((response) => {
        const data = response?.data?.data;
        setListInvoiceData(data);
      })
      .catch(() => {});
  }, [location.pathname]);

  const [columnDefs] = useState([
    {
      headerName: "Invoice ID",
      field: "invoiceNumber",
      width: 130,
    },
    {
      headerName: "Sponsor ",
      field: "sponsorName",
      width: 170,
    },
    {
      headerName: "Student Name ",
      valueGetter: (params) =>
        params.data?.master?.admissionManagement?.personalData?.firstName ||
        "-",
    },

    {
      headerName: "Invoice Type",
      field: "invoiceType",
      valueFormatter: function (params) {
        return formatInvoiceType(params.value);
      },
      width: 150,
    },

    {
      headerName: "Created Date",
      field: "startDate",
      width: 150,
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      width: 150,
    },

    {
      headerName: "Status",
      field: "status",
      width: 130,
      cellRenderer: function (params) {
        const status = params.value;

        return (
          <span
            className={`${
              status === "Paid" && "ag-grid-application-status-approved"
            }
            ${
              status === "Past due date" &&
              "ag-grid-application-status-rejected"
            }
            ${status === "Pending" && "ag-grid-application-status-pending"}
            `}
          >
            {status}
          </span>
        );
      },
      width: 150,
    },
    {
      headerName: "Mode of Payment",
      field: "paymentMode",
    },
  ]);

  const onRowClicked = (event) => {
    setIsModalOpen(true);
    setInvoiceData(event.data);
  };
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Student Invoices</span>
        </div>
        <div className="d-flex align-items-end">
          <button
            className="communication-save-head-button"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add New Invoice
          </button>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listInvoiceData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
      <AddSponsorInvoice
        open={isModalOpen}
        onClose={() => {
          setInvoiceData(null);
          setIsModalOpen(false);
          axiosService
            .get(`${FEE_URLS.GET_ALL_SPONSOR_INVOICE_FEE}`)
            .then((response) => {
              const data = response?.data?.data;
              setListInvoiceData(data);
            })
            .catch(() => {});
        }}
        invoiceData={invoiceData}
      />
    </div>
  );
}

export default ViewSponsorInvoice;
