import AddExtraCurricular from "modules/student-portal/components/student-portal-extra-curricular/add-exrta-curricular/AddExtraCurricular";
import { STUDENT_PORTAL_EXTRACURRICULAR_URL } from "modules/student-portal/constants/student-portal-urls";
import { useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import "./ViewExtraCurricular.css";
import sessionService from "services/sessionstorage.service";

function ViewExtraCurricular({ studentId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [selectedExtracurricularId, setSelectedExtracurricularId] =
    useState(null);

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${STUDENT_PORTAL_EXTRACURRICULAR_URL.GET_EXTRACURRICULAR_URL}`
      );
      if (response.status === 200) {
        const responseData = response.data.data.reverse();
        setDatas(responseData);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (studentId) {
      axiosService
        .get(
          `${STUDENT_PORTAL_EXTRACURRICULAR_URL.GET_EXTRACURRICULAR_BY_STUDENT_ID}${studentId}`
        )
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data.reverse();
            setDatas(responseData);
          }
        });
    } else {
      fetchData();
    }
  }, []);
  function hasPermission(permission) {
    let userPermissions = sessionService?.hasPermissionByButton(permission);
    return userPermissions;
  }
  return (
    <div className="student-portal-service-container">
      {!studentId && (
        <div className="student-ps-top">
          <span>Extracurricular</span>
          {hasPermission("Extracurricular: edit") && (
            <a
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add an activity
            </a>
          )}
        </div>
      )}

      <div className=" student-portal-inner-main-container custom-scrollbar">
        {Array.isArray(datas) && datas.length > 0 ? (
          <table>
            <thead className="table-header-student-portal">
              <tr>
                <th className="pe-3" style={{ maxWidth: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Activity Type
                    </span>
                  </div>
                </th>
                <th className="pe-3" style={{ maxWidth: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Position/Leadership
                    </span>
                  </div>
                </th>
                <th className="pe-3" style={{ maxWidth: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Grade level
                    </span>
                  </div>
                </th>
                <th className="pe-3" style={{ maxWidth: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Time of participation
                    </span>
                  </div>
                </th>
                <th className="pe-3" style={{ maxWidth: "200px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Total hours spend
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {datas.map((extraCurricularService) => (
                <tr key={extraCurricularService?.extraCurricularId}>
                  <td className="table-data-student-portal text-decoration-underline">
                    {extraCurricularService.activityType}
                  </td>
                  <td className="table-data-student-portal">
                    {extraCurricularService.position}
                  </td>
                  <td className="table-data-student-portal">
                    {extraCurricularService.gradeLevels}
                  </td>
                  <td className="table-data-student-portal">
                    {extraCurricularService.participationTime}
                  </td>
                  <td className="table-data-student-portal">
                    {extraCurricularService.totalHoursSpent}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-entries-made">No entries made</p>
        )}
      </div>

      <AddExtraCurricular
        open={isModalOpen}
        onClose={(data) => {
          setIsModalOpen(false);
          fetchData();
          setSelectedExtracurricularId(null);
        }}
        selectedExtracurricularId={selectedExtracurricularId}
      />
    </div>
  );
}
export default ViewExtraCurricular;
