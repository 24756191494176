import AddCurrentaffairs from "modules/communication-management/current-affairs/components/add-currentaffairs/AddCurrentaffairs";
import ViewCurrentaffairs from "modules/communication-management/current-affairs/components/view-current-affairs/ViewCurrentaffairs";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "utils/communication-management.css";
import "utils/forum-discussion.css";
import Management from "../circular-notice/components/view-circular-notice/Management";
import ViewCircular from "../circular-notice/components/view-circular-notice/ViewCircular";
import ViewDiscussion from "../forums-discussion/components/view-discussions/ViewDiscussion";
import ViewForumDiscussion from "../forums-discussion/components/view-forums-discussion/ViewForumDiscussion";
import ViewForums from "../forums-discussion/components/view-forums/ViewForums";
import QueryFeedbackChat from "../query-feedback/components/QueryFeedbackChat";
import QueryFeedbackChatMobile from "../query-feedback/components/QueryFeedbackChatMobile";
import NotificationList from "../notification-history/NotificationList";

function CommunicationManagmentRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/communication-management") {
      navigate("/communication-management/queries");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="queries" element={null}>
        <Route index element={<QueryFeedbackChat />} />
        <Route path="chat" element={<QueryFeedbackChatMobile />} />
      </Route>

      <Route path="forums" element={<ViewForums />} />
      <Route path="view-discussion" element={<ViewDiscussion />} />
      <Route path="view-forum-discussion" element={<ViewForumDiscussion />} />
      <Route path="current-affairs" element={<ViewCurrentaffairs />} />
      <Route path="add-currentaffairs" element={<AddCurrentaffairs />} />
      <Route path="circulars" element={<Management />} />
      <Route path="view-circular" element={<ViewCircular />} />
      <Route path="view-notifications" element={<NotificationList />} />
    </Routes>
  );
}

export default CommunicationManagmentRoutes;
