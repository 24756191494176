import React from "react";

function LocationLogo() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.875 14C3.93322 13.9224 4.00871 13.8594 4.09549 13.816C4.18228 13.7726 4.27797 13.75 4.375 13.75H7.5C7.66576 13.75 7.82473 13.8158 7.94194 13.9331C8.05915 14.0503 8.125 14.2092 8.125 14.375C8.125 14.5408 8.05915 14.6997 7.94194 14.8169C7.82473 14.9342 7.66576 15 7.5 15H4.6875L1.875 18.75H18.125L15.3125 15H12.5C12.3342 15 12.1753 14.9342 12.0581 14.8169C11.9408 14.6997 11.875 14.5408 11.875 14.375C11.875 14.2092 11.9408 14.0503 12.0581 13.9331C12.1753 13.8158 12.3342 13.75 12.5 13.75H15.625C15.722 13.75 15.8177 13.7726 15.9045 13.816C15.9913 13.8594 16.0668 13.9224 16.125 14L19.875 19C19.9446 19.0929 19.9871 19.2033 19.9975 19.3189C20.0079 19.4345 19.9859 19.5507 19.934 19.6545C19.8821 19.7583 19.8023 19.8456 19.7036 19.9067C19.6048 19.9677 19.4911 20 19.375 20H0.625C0.50893 20 0.395153 19.9677 0.296418 19.9067C0.197683 19.8456 0.117891 19.7583 0.0659833 19.6545C0.0140752 19.5507 -0.00789801 19.4345 0.00252572 19.3189C0.0129495 19.2033 0.0553583 19.0929 0.125 19L3.875 14Z"
        fill="#047BC1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 1.24954C9.50754 1.24954 9.01991 1.34653 8.56494 1.53499C8.10997 1.72344 7.69657 1.99967 7.34835 2.34789C7.00013 2.69611 6.72391 3.1095 6.53545 3.56447C6.347 4.01944 6.25 4.50708 6.25 4.99954C6.25 5.49199 6.347 5.97963 6.53545 6.4346C6.72391 6.88957 7.00013 7.30297 7.34835 7.65119C7.69657 7.99941 8.10997 8.27563 8.56494 8.46408C9.01991 8.65254 9.50754 8.74954 10 8.74954C10.9946 8.74954 11.9484 8.35445 12.6517 7.65119C13.3549 6.94793 13.75 5.9941 13.75 4.99954C13.75 4.00497 13.3549 3.05115 12.6517 2.34789C11.9484 1.64462 10.9946 1.24954 10 1.24954ZM5 4.99954C5.00009 4.03732 5.27782 3.09556 5.79987 2.28728C6.32192 1.47899 7.0661 0.838501 7.94313 0.44267C8.82016 0.0468386 9.79278 -0.087523 10.7443 0.0557075C11.6958 0.198938 12.5857 0.613676 13.3074 1.25016C14.029 1.88664 14.5517 2.71782 14.8126 3.64398C15.0736 4.57013 15.0617 5.55192 14.7786 6.47152C14.4954 7.39112 13.9529 8.20948 13.2161 8.8284C12.4794 9.44732 11.5797 9.84051 10.625 9.96079V16.8745C10.625 17.0403 10.5592 17.1993 10.4419 17.3165C10.3247 17.4337 10.1658 17.4995 10 17.4995C9.83424 17.4995 9.67527 17.4337 9.55806 17.3165C9.44085 17.1993 9.375 17.0403 9.375 16.8745V9.96204C8.16621 9.80974 7.05461 9.22133 6.24902 8.30733C5.44343 7.39333 4.99928 6.21789 5 4.99954Z"
        fill="#047BC1"
      />
    </svg>
  );
}

export default LocationLogo;
