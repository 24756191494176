import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FacultyAnswerCorrection from "../all/faculty-exams/FacultyAnswerCorrection";
import FacultyExamLists from "../all/faculty-exams/FacultyExamLists";
import StudentExamList from "../all/student-exams/StudentExamList";
import StudentAnswerSubmit from "../all/student-exams/StudentAnswerSubmit";
import sessionService from "services/sessionstorage.service";

function ExamMarksheetsRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionService.getUserData("userData");
  const isStudent = userData?.role?.name === "student";

  useEffect(() => {
    if (location.pathname === "/exam-marksheets") {
      if (isStudent) {
        navigate("/exam-marksheets/my-exams");
      } else {
        navigate("/exam-marksheets/all-student-answers");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="my-exams" element={<StudentExamList />} />
      <Route path="all-student-answers" element={<FacultyExamLists />} />
      <Route path="exam-questions" element={<StudentAnswerSubmit />} />
      <Route path="exam-answers" element={<FacultyAnswerCorrection />} />
    </Routes>
  );
}

export default ExamMarksheetsRoutes;
