import React from "react";
import EnrollmentView from "./enrollment";
import AcademicTranscript from "./academicTranscript";
import WithDrawalLetter from "./withDrawalLetter";
import BreakLetter from "./downloadBreakLetter";
import FeesInvoice from "./feesInvoice";
import OfficialReceipt from "./officialReceipt";
import TravelSupport from "./travelSupport";
import CLOAForDocument from "./cloa";
import FLOAForDocument from "./floa";
import OfficialReceiptProvisional from "./officialReceiptProvisional";

function DocumentsRoutesForPdf() {
  return (
    <div>
      <EnrollmentView />
      <AcademicTranscript />
      <WithDrawalLetter />
      <BreakLetter />
      <FeesInvoice />
      <OfficialReceipt />
      <OfficialReceiptProvisional />
      <TravelSupport />
      <CLOAForDocument />
      <FLOAForDocument />
    </div>
  );
}

export default DocumentsRoutesForPdf;
