import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./NewConversationPopup.css";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import SearchIcon from "assets/image-components/SearchIcon";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { QUERY_FEEDBACK_URLS } from "../../constants/query-feedback-urls";
import { NEW_CONVERSATION_CONSTANTS } from "../../constants/query-feedback-constants";
import { toast } from "react-toastify";

function NewConversationPopup({ open, onClose }) {
  const [receivingUser, setReceivingUser] = useState(null);
  const [receivingUserOptions, setReceivingUserOptions] = useState([]);
  const [description, setDescription] = useState();
  const userData = sessionstorageService.getUserData("userData");

  useEffect(() => {
    const roleName = "faculty";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setReceivingUserOptions(options);
        }
      });
  }, []);

  const onSubmit = async () => {
    try {
      let sendData = {
        receiver: receivingUser,
        description: description,
        sender: userData,
      };
      const response = await axiosService.post(
        `${QUERY_FEEDBACK_URLS.CREATE_NEW_CONVERSATION}`,
        sendData
      );

      if (response.status === 200) {
        toast.success(NEW_CONVERSATION_CONSTANTS.SUCCESS);
        onClose();
      } else {
        toast.error(NEW_CONVERSATION_CONSTANTS.FAILED);
      }
    } catch (error) {
      toast.error(NEW_CONVERSATION_CONSTANTS.ERROR_OCCUR);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <div className="new-conversation-popup-header">
            <span className="new-conversation-popup-title-text">
              Create New Conversation
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose color="#ffffff" size={"22px"} />
            </span>
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            <div>
              <label className="new-conversation-popup-label">
                Select a faculty
              </label>

              <Select
                noOptionsMessage={() => "No faculty found"}
                onChange={(option) => {
                  setReceivingUser(option.receivingObject);
                }}
                options={receivingUserOptions}
                isSearchable={true}
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator: () => <SearchIcon />,
                  IndicatorSeparator: null,
                }}
                placeholder="Search"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div>
              <label className="new-conversation-popup-label">
                Title{" "}
                <span className="new-conversation-popup-label-small">
                  (max 100 chars)
                </span>
              </label>

              <textarea
                maxLength={100}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                className="new-conversation-popup-textare-input"
              />
            </div>
            <div className="d-flex gap-3">
              <button
                className="communication-management-cancel-button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                disabled={!receivingUser || !description}
                className={`communication-management-submit-button ${
                  (!receivingUser || !description) && "opacity-50"
                }`}
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewConversationPopup;
