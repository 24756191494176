import React, { useEffect } from "react";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./CloaPaymentSubmission.css";
import { PaymentConstants } from "modules/pre-onboarding/components/constants/payment-constants";
import { PAYMENT_URL } from "modules/pre-onboarding/components/applications/payment-urls";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { BiCheck } from "react-icons/bi";
import * as Yup from "yup";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AdmissionAppilicationSidebar from "../../create-admission/admission-appilication-sidebar/AdmissionAppilicationSidebar";
import { FaEye } from "react-icons/fa";
import Dropzone from "react-dropzone";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";

function CloaPaymentSubmission() {
  const location = useLocation();
  const data = location?.state?.data;
  const admissionId = sessionstorageService.getAdmissionId("admissionId");

  const navigate = useNavigate();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [admissionData, setAdmissionData] = useState();
  const [enableSubmit, setEnableSubmit] = useState(false);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_CLOA_PAYMENT_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
        });
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const feeId = response?.data?.data?.feeId;

          axiosService
            .get(
              `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_AND_PROGRAM}${admissionId}?programId=${data?.programId}&feeId=${feeId}`
            )
            .then((response) => {
              const data = response.data;
              const admissionManagement = response?.data?.admissionManagement;

              if (
                admissionManagement?.personalData?.isApproved &&
                admissionManagement?.mailingAddress?.isApproved &&
                admissionManagement?.guardianDetail?.isApproved &&
                admissionManagement?.academics[0]?.isApproved &&
                admissionManagement?.employments[0]?.isApproved &&
                admissionManagement?.otherInformation?.isApproved &&
                admissionManagement?.upload?.isApproved &&
                admissionManagement?.profile?.isApproved &&
                admissionManagement?.profile?.isApproved &&
                data.programDetail?.isApproved &&
                data.fee?.isApproved
              ) {
                setEnableSubmit(true);
              } else {
                setEnableSubmit(false);
              }
            });
        });
    }
  }, [location.pathname]);
  const initialValues = {
    cloaDate: "",
    cloaUploadDocument: "",
    cloaUploadDocumentName: "",
    remarks: "",
  };
  const validationSchema = Yup.object().shape({});
  const onSubmit = async (values) => {
    const programId = {
      programId: data?.programId,
    };
    const admissionId = {
      admissionId: data?.admissionId,
    };

    const paymentUpdate = {
      cloaDate: values.cloaDate,
      cloaUploadDocument: values.cloaUploadDocument,
      cloaUploadDocumentName: values.cloaUploadDocumentName,
      remarks: values.remarks,
      programDetail2: programId,
      admissionManagement4: admissionId,
      cloaStudentPaymentId: admissionData?.cloaStudentPaymentId,
      isRejected: false,
    };

    if (enableSubmit) {
      try {
        const response = await axiosService.post(
          `${PAYMENT_URL.CLOA_PAYMENT_POST_URL}`,
          paymentUpdate
        );

        if (response.status === 200) {
          toast.success(PaymentConstants.PAYMENT_SUCCESS);
          navigate("/pre-onboarding/view-application-student");
        } else {
          toast.error(PaymentConstants.PAYMENT_FAILED);
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(PaymentConstants.ERROR_OCCUR);
        }
      }
    } else {
      toast.error(PaymentConstants.PENDING_APPROVAL);
    }
  };
  const dateString = new Date(admissionData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={admissionData || initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Create New Application
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionAppilicationSidebar
                  data={{
                    admissionId: data?.admissionId,
                    programId: data?.programId,
                  }}
                />
              </div>
              <div className="create-admission-inner-right-container col-12 col-md-8 auth-text-animation payment-update">
                <div
                  className="d-flex row g-0 paymentupdate-inner-content-container "
                  style={{ width: "100%" }}
                >
                  <div className="login-head-text-container">
                    <span className="auth-title-text paymenyupdte-content-title">
                      Please upload your CLOA payment information detail here
                    </span>
                  </div>

                  <div className="flex-column" style={{ position: "relative" }}>
                    <div className="login-input-main-container paymentupdate-input-top  col-lg-6">
                      <div className="paymentupdate-document-date">
                        <span className="password-text-container">
                          <label className="paymentupdate-document-date">
                            Date
                          </label>
                        </span>

                        <Field
                          type="date"
                          name="cloaDate"
                          className="paymentupdate-document-date-input"
                        />
                      </div>
                    </div>
                    <div className="paymentupdate-document-upload">
                      <span>
                        <label className="paymentupdate-document-upload-title">
                          Upload Document
                        </label>
                      </span>
                      <div
                        className="paymentupdate-document-upload-fields"
                        style={{ maxWidth: "455px" }}
                      >
                        <Dropzone
                          onDrop={async (acceptedFiles) => {
                            const sendFile = acceptedFiles[0];
                            setUploadLoading(true);

                            if (sendFile) {
                              if (acceptedFiles[0]?.name?.length > 150) {
                                toast.error(
                                  ADMISSION_CONSTANTS_FOR_STUDENTS.IMAGE_LENGTH
                                );
                                return;
                              }
                              try {
                                const formData = new FormData();
                                formData.append("file", sendFile);
                                const response = await axiosService.postFile(
                                  `${PAYMENT_URL.PAYMENT_UPLOAD_URL}`,
                                  sendFile,
                                  true
                                );

                                if (response.status === 200) {
                                  setUploadLoading(false);
                                  setFieldValue(
                                    "cloaUploadDocument",
                                    response.data.data
                                  );
                                  setFieldValue(
                                    "cloaUploadDocumentName",
                                    sendFile.name
                                  );
                                  toast.success(
                                    PaymentConstants.UPLOAD_FILE_SUCCESS
                                  );
                                  setUploadLoading(false);
                                } else {
                                  setUploadLoading(false);
                                  toast.error(
                                    PaymentConstants.UPLOAD_FILE_FAILURE
                                  );
                                  setUploadLoading(false);
                                }
                              } catch (error) {
                                setUploadLoading(false);
                                if (error.response) {
                                  toast.error(
                                    `${error.response.data.message
                                      .split(":")[1]
                                      ?.trim()}`
                                  );
                                } else {
                                  toast.error(
                                    PaymentConstants.UPLOAD_FILE_ERROR
                                  );
                                }
                              }
                            } else {
                              toast.error("Please select a file to upload.");
                              setUploadLoading(false);
                            }
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big paymentupload create-list-upload-input-big-one ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload"
                                name="cloaUploadDocument"
                              />

                              {values.cloaUploadDocumentName ||
                              admissionData?.cloaUploadDocument ? (
                                <span className="text-white">
                                  {values.cloaUploadDocumentName ||
                                    admissionData?.cloaUploadDocument}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        {(admissionData?.cloaUploadDocument ||
                          values.cloaUploadDocumentName) &&
                        !uploadLoading ? (
                          <BiCheck
                            color="green"
                            size={27}
                            height={30}
                            style={{
                              position: "absolute",
                              right: "-30px",
                            }}
                          />
                        ) : null}
                        {uploadLoading && (
                          <div
                            class="lds-ring"
                            style={{
                              position: "absolute",
                              right: "-42px",
                            }}
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {(values.cloaUploadDocument ||
                          admissionData?.cloaUploadDocument) && (
                          <a
                            style={{
                              position: "absolute",
                              right: "-60px",
                              top: "5px",
                            }}
                            href={
                              values.cloaUploadDocument ||
                              admissionData?.cloaUploadDocument
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    </div>

                    <div
                      className="paymentupdate-remarks "
                      style={{ position: "relative" }}
                    >
                      <span>
                        <label>Remarks </label>
                      </span>

                      <Field
                        name="remarks"
                        className="paymentupdate-remarks-input"
                        placeholder="Add a subject to task"
                      />
                    </div>
                    <div className=" col-10 mt-4 d-flex gap-3">
                      {!admissionData?.isApproved && (
                        <button
                          type="submit"
                          className="create-admission-save-button"
                        >
                          {admissionData?.studentPaymentId
                            ? "Update"
                            : "Submit"}
                        </button>
                      )}
                      {(admissionData?.studentPaymentId ||
                        admissionData?.isApproved) && (
                        <button
                          className="create-admission-save-button"
                          type="button"
                          onClick={() =>
                            navigate("/pre-onboarding/paymentsubmission")
                          }
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {admissionData?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
export default CloaPaymentSubmission;
