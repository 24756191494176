import React, { useEffect, useRef, useState } from "react";
import "./ViewProfileStudent.css";
import { TfiEmail } from "react-icons/tfi";
import { VscCallOutgoing } from "react-icons/vsc";
import LocationLogo from "assets/image-components/LocationLogo";
import useravatar from "assets/images/user-avatar.svg";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import ProfileViewIcon from "assets/image-components/ProfileViewIcon";
import { IoIosArrowBack } from "react-icons/io";

function ViewProfileStudent({ studentId }) {
  const location = useLocation();
  const id = location?.state?.studentId || studentId;
  const [studentType, setStudentType] = useState("");
  const [admissionData, setAdmissionData] = useState("");
  useEffect(() => {
    axiosService
      .get(`${STUDENT_PORTAL_URL.GET_PROFILE_DATA_BY_ID}${id}`)
      .then((response) => {
        const data = response?.data?.data;
        setAdmissionData(data);
        setStudentType(data?.admission?.user?.studentType);
      })
      .catch(() => {});
  }, [id]);
  // function hasPermission(permission) {
  //   let userPermissions = sessionService?.hasPermissionByButton(permission);
  //   return userPermissions;
  // }
  const personalDataRef = useRef(null);
  const mailingAddressRef = useRef(null);
  const guardianDetailsRef = useRef(null);
  const academicRef = useRef(null);
  const employmentRef = useRef(null);
  const uploadedFilesRef = useRef(null);
  const studentProfileRef = useRef(null);
  const otherInfoRef = useRef(null);
  const snapContainerRef = useRef(null);

  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (ref) => {
    setActiveLink(ref);
    const offsetTop = ref.current.offsetTop - 100;
    snapContainerRef.current.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const uploadedPhoto = admissionData?.admission?.upload?.passportSizePhoto;
  return (
    <div
      className="px-3 pb-4"
      style={{
        color: "#ffffff",
        height: "calc(100vh - 50px)",
        overflow: "auto",
      }}
      ref={snapContainerRef}
    >
      {!studentId && (
        <div
          className="student-portal-header-container sticky-header flex-sm-row gap-2"
          style={{ top: 0 }}
        >
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">
              <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
                {" "}
                <IoIosArrowBack size={20} />
              </span>
              Profile
            </span>
          </div>
          {/* {hasPermission("Profile: create") && (
            <div className="d-flex align-items-center gap-3">
              <button className="save-profile-button">Edit Profile</button>
            </div>
          )} */}
        </div>
      )}

      <div className="student-portal-head-container flex-sm-row gap-2 ">
        <div className="row g-0 gap-2 gap-lg-0">
          <div className="col-12 col-lg-auto gap-4 d-flex">
            <img
              alt="img"
              src={
                admissionData?.admission?.user?.profileImage ||
                (uploadedPhoto &&
                  /\.(jpg|jpeg|png)$/.test(
                    uploadedPhoto.split("?")[0].toLowerCase()
                  ))
                  ? uploadedPhoto
                  : useravatar
              }
              style={{
                height: "95px",
                borderRadius: "10px",
                color: "#283557",
                width: "95px",
              }}
            />

            <div className="home-student-alignment">
              <span className="NameText m-0 ml-4 row">
                {admissionData?.admission?.personalData ? (
                  <>
                    {admissionData.admission.personalData.title}.
                    {admissionData.admission.personalData.firstName}
                    {admissionData.admission.personalData.middleName}{" "}
                    {admissionData.admission.personalData.lastName}
                  </>
                ) : (
                  "-"
                )}
              </span>
              <span className="SubText-data m-0 row align-items-start">
                <div className="d-flex align-items-center p-0">
                  <span className="align-self-start">
                    <ProfileViewIcon />
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "2px",
                    }}
                  >
                    {admissionData?.admission?.programDetails[0]?.program ||
                      "-"}
                  </span>
                </div>
              </span>

              <span
                className="Sub-Text-home m-0 row mt-2"
                style={{ fontSize: "13px" }}
              >
                {" "}
                @{admissionData?.admission?.personalData?.studentId}
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-auto home-student-alignment">
            <div className="containerStyle column d-flex">
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Upcoming Exams</div>
                  <span className="numberStyle" style={{ color: "#047BC1" }}>
                    {admissionData?.upcomingExams}
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Pending Fees</div>
                  <span
                    className="numberStyle"
                    style={{ color: "#FFFFFF", position: "relative" }}
                  >
                    {admissionData?.pendingFees}
                    <FaStar className="star-icon" />
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Upcoming Events</div>
                  <span className="numberStyle" style={{ color: "#F63A3A" }}>
                    {admissionData?.upcomingEvents}
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Shared Documents</div>
                  <span className="numberStyle" style={{ color: "#59C37B" }}>
                    {admissionData?.sharedDocuments}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-auto">
            <div className="d-flex flex-column gap-3 ps-2">
              <div className="d-flex gap-1 home-head-text">
                <TfiEmail style={{ color: "#047BC1", marginRight: "10px" }} />

                {admissionData?.admission?.personalData?.alternateEmail
                  ? admissionData?.admission?.personalData?.alternateEmail
                  : admissionData?.admission?.personalData?.email}
              </div>
              <div className="d-flex gap-3 ">
                <div className="d-flex gap-1 home-sub-text">
                  <VscCallOutgoing
                    style={{ color: "#047BC1", marginRight: "5px" }}
                  />

                  {admissionData?.admission?.mailingAddress?.mobile1 || "-"}
                </div>
                <div className="d-flex gap-1 home-sub-text">
                  <VscCallOutgoing
                    style={{ color: "#047BC1", marginRight: "5px" }}
                  />

                  {admissionData?.admission?.mailingAddress?.mobile2 || "-"}
                </div>
              </div>
              <div className="home-sub-text gap-2 d-flex">
                <LocationLogo />

                {admissionData?.admission?.mailingAddress ? (
                  <>
                    {admissionData.admission.mailingAddress.streetNo},
                    {admissionData.admission.mailingAddress.unit},
                    {admissionData.admission.mailingAddress.street},
                    {admissionData.admission.mailingAddress.province},
                    {admissionData.admission.mailingAddress.city},
                    {admissionData.admission.mailingAddress.country} -
                    {admissionData.admission.mailingAddress.postalCode}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-10 pe-lg-5">
        <div ref={personalDataRef} className="snap-con gap-2">
          <div className="snap-sub-header">Personal Data</div>
          <div className="row g-0 snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Sex</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.sex || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Marital Status </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.maritalStatus || "-"}
              </span>
            </div>
            {admissionData?.admission?.personalData?.maritalStatus ===
              "OTHER" && (
              <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
                <span className="snap-sub-title">Other Marital Status</span>
                <span className="snap-sub-text">
                  {admissionData?.admission?.personalData?.otherMaritalStatus ||
                    "-"}
                </span>
              </div>
            )}
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Date of Birth</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.dateOfBirth || "-"}
              </span>
            </div>
          </div>
          {studentType === "OSAP" && (
            <div className="row g-0 snap-head-con d-flex">
              <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
                <span className="snap-sub-title">Access Number</span>
                <span className="snap-sub-text">
                  {admissionData?.admission?.personalData?.osapAccessNumber ||
                    "-"}
                </span>
              </div>
              <div className="sub-head col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start">
                <span className="snap-sub-title">Status</span>
                <span className="snap-sub-text">
                  {admissionData?.admission?.personalData?.studentStatus || "-"}
                </span>
              </div>
              {admissionData?.admission?.personalData?.studentStatus ===
                "PERMANENTRESIDENT" && (
                <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
                  <span className="snap-sub-title">PR Card Number</span>
                  <span className="snap-sub-text">
                    {admissionData?.admission?.personalData?.prCardNumber ||
                      "-"}
                  </span>
                </div>
              )}{" "}
              {admissionData?.admission?.personalData?.studentStatus ===
                "REFUGEE" && (
                <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
                  <span className="snap-sub-title">
                    Refugee Refugee Document No.
                  </span>
                  <span className="snap-sub-text">
                    {admissionData?.admission?.personalData
                      ?.refugeesDocumentNumber || "-"}
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="row g-0 snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Nationality</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.nationality || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Passport Number</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.passportNumber || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Expiry Date</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.expiryDate || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Place of Issue</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.passportIssuer || "-"}
              </span>
            </div>
          </div>

          <div className="row g-0 snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">National ID Number</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.nationalIdNumber ||
                  "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Driving License Number</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.personalData?.drivingLicenseNumber ||
                  "-"}
              </span>
            </div>
          </div>
        </div>
        <div ref={mailingAddressRef} className="snap-con gap-2">
          <div className="snap-sub-header">Mailing Address</div>
          <div className="row g-0 snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Street</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.street || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">No </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.streetNo || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">City</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.city || "-"}
              </span>
            </div>

            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Postal Code</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.postalCode || "-"}
              </span>
            </div>
          </div>
          <div className="row snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Province</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.province || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Apt/Unit</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.unit || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Country</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.country || "-"}
              </span>
            </div>
          </div>
          <div className="row snap-head-con d-flex">
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Mobile 1</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.mobile1 || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-md-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Mobile 2</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.mailingAddress?.mobile2 || "-"}{" "}
              </span>
            </div>
          </div>
        </div>
        <div ref={guardianDetailsRef} className="snap-con gap-2">
          <div className="snap-sub-header"> Guardian Details</div>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <table style={{ minWidth: "100%" }}>
              <thead
                className="snap-education-table-head"
                style={{ width: "100%" }}
              >
                <tr>
                  <th className="table-data-view-profile">Gaurdian Name</th>
                  <th className="table-data-view-profile">Relationship</th>
                  <th className="table-data-view-profile">Occupation</th>
                  <th className="table-data-view-profile">Telephone</th>
                  <th className="table-data-view-profile">Email</th>
                  <th className="table-data-view-profile">Address</th>
                </tr>
              </thead>
              <tbody>
                <tr className="snap-tr" style={{ height: "20px" }}>
                  <td className="table-data-view-profile">
                    {`${
                      admissionData?.admission?.guardianDetail?.firstName || "-"
                    } ${
                      admissionData?.admission?.guardianDetail?.lastName || ""
                    }`}
                  </td>
                  <td className="table-data-view-profile">
                    {admissionData?.admission?.guardianDetail?.relationship ||
                      "-"}
                  </td>
                  <td className="table-data-view-profile">
                    {admissionData?.admission?.guardianDetail?.occupation ||
                      "-"}
                  </td>
                  <td className="table-data-view-profile">
                    {admissionData?.admission?.guardianDetail?.telephone || "-"}
                  </td>
                  <td className="table-data-view-profile">
                    {admissionData?.admission?.guardianDetail?.email || "-"}
                  </td>
                  <td className="table-data-view-profile">
                    {admissionData?.admission?.guardianDetail?.address || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div ref={academicRef} className="snap-con gap-2">
          <div className="snap-sub-header">
            Academic And Professional Details
          </div>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <table style={{ minWidth: "100%" }}>
              <thead className="snap-education-table-head">
                <tr>
                  <th className="table-data-view-profile">
                    Qualification Name
                  </th>
                  <th className="table-data-view-profile">
                    Name of Institution
                  </th>
                  <th className="table-data-view-profile">Completion Year</th>
                  <th className="table-data-view-profile">Country</th>
                </tr>
              </thead>
              <tbody>
                {admissionData?.admission?.academics?.map((academic) => (
                  <tr key={academic?.academicId} className="snap-tr">
                    <td className="table-data-view-profile">
                      {academic?.qualificationName || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {academic?.instituteName || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {academic?.completionYear || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {academic?.country || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div ref={employmentRef} className="snap-con gap-2">
          <div className="snap-sub-header">Employment And Work Experience</div>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <table style={{ minWidth: "100%" }}>
              <thead className="snap-education-table-head">
                <tr>
                  <th className="table-data-view-profile">Post Held</th>
                  <th className="table-data-view-profile">Company Name </th>
                  <th className="table-data-view-profile">Company Location</th>
                  <th className="table-data-view-profile">Type</th>
                  <th className="table-data-view-profile">Joining Date</th>
                  <th className="table-data-view-profile">Releiving Date</th>
                </tr>
              </thead>
              <tbody>
                {admissionData?.admission?.employments?.map((employment) => (
                  <tr key={employment?.employmentId} className="snap-tr">
                    <td className="table-data-view-profile">
                      {employment?.postHeld || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {employment?.companyName || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {employment?.companyAddress || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {employment?.type || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {employment?.joiningDate || "-"}
                    </td>
                    <td className="table-data-view-profile">
                      {employment?.relievingDate || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div ref={uploadedFilesRef} className="snap-con gap-2 ">
          <div className="snap-sub-header">Uploaded Files</div>
          <div className="row">
            <div className="col-9">
              <div className="profile-upload-files-container">
                <div className="sub-head sub-head-Con">
                  <span className="snap-sub-title">
                    Highest Qualification/ Degree
                  </span>
                  <a
                    className="snap-sub-text"
                    href={admissionData?.admission?.upload?.degree}
                    target="_blank"
                  >
                    {admissionData?.admission?.upload?.degreeName || "-"}
                  </a>
                </div>
                <div className="sub-head sub-head-Con">
                  <span className="snap-sub-title">Passport</span>
                  <a
                    className="snap-sub-text"
                    href={admissionData?.admission?.upload?.passport}
                    target="_blank"
                  >
                    {admissionData?.admission?.upload?.passportName || "-"}
                  </a>
                </div>
                <div className="sub-head sub-head-Con">
                  <span className="snap-sub-title">Passport Size Photo</span>
                  <a
                    className="snap-sub-text"
                    href={admissionData?.admission?.upload?.passportSizePhoto}
                    target="_blank"
                  >
                    {admissionData?.admission?.upload?.passportSizePhotoName ||
                      "-"}
                  </a>
                </div>
                {studentType !== "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">English Exam Results</span>
                    <a
                      className="snap-sub-text"
                      href={
                        admissionData?.admission?.upload?.englishExamResults
                      }
                      target="_blank"
                    >
                      {admissionData?.admission?.upload
                        ?.englishExamResultsName || "-"}
                    </a>
                  </div>
                )}

                <div className="sub-head sub-head-Con">
                  <span className="snap-sub-title">Wonderlic Test</span>
                  <a
                    className="snap-sub-text"
                    href={admissionData?.admission?.upload?.wonderlicTest}
                    target="_blank"
                  >
                    {admissionData?.admission?.upload?.wonderlicTestName || "-"}
                  </a>
                </div>

                <div className="sub-head sub-head-Con">
                  <span className="snap-sub-title">CV/Resume </span>
                  <a
                    className="snap-sub-text"
                    href={admissionData?.admission?.upload?.cv}
                    target="_blank"
                  >
                    {admissionData?.admission?.upload?.cvName || "-"}
                  </a>
                </div>
                {studentType !== "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">Study Permit</span>
                    <a
                      className="snap-sub-text"
                      href={admissionData?.admission?.upload?.studyPermit}
                      target="_blank"
                    >
                      {admissionData?.admission?.upload?.studyPermitName || "-"}
                    </a>
                  </div>
                )}
                {studentType !== "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">Work Permit</span>
                    <a
                      className="snap-sub-text"
                      href={admissionData?.admission?.upload?.workPermit}
                      target="_blank"
                    >
                      {admissionData?.admission?.upload?.workPermitName || "-"}
                    </a>
                  </div>
                )}

                {studentType === "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">Photo Id </span>
                    <a
                      className="snap-sub-text"
                      href={admissionData?.admission?.upload?.photoId}
                      target="_blank"
                    >
                      {admissionData?.admission?.upload?.photoIdName || "-"}
                    </a>
                  </div>
                )}
                {studentType === "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">Declaration Form </span>
                    <a
                      className="snap-sub-text"
                      href={admissionData?.admission?.upload?.declarationForm}
                      target="_blank"
                    >
                      {admissionData?.admission?.upload?.declarationFormName ||
                        "-"}
                    </a>
                  </div>
                )}
                {studentType === "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">
                      Social Insurance Number (SIN){" "}
                    </span>
                    <a
                      className="snap-sub-text"
                      href={
                        admissionData?.admission?.upload?.socialInsuranceNumber
                      }
                      target="_blank"
                    >
                      {admissionData?.admission?.upload
                        ?.socialInsuranceNumberName || "-"}
                    </a>
                  </div>
                )}
                {studentType === "OSAP" && (
                  <div className="sub-head sub-head-Con">
                    <span className="snap-sub-title">
                      Notice Of Assessment (NOA){" "}
                    </span>
                    <a
                      className="snap-sub-text"
                      href={
                        admissionData?.admission?.upload?.noticeOfAssessment
                      }
                      target="_blank"
                    >
                      {admissionData?.admission?.upload
                        ?.noticeOfAssessmentName || "-"}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={studentProfileRef} className="snap-con gap-2">
          <div className="snap-sub-header">Student Profile</div>
          <div className="snap-head-con row d-flex">
            <div className="sub-head col-10 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Highest academic level (e.g. Completed High school, Completed
                Bachelors e.t.c .)
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.academicLevel || "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con row d-flex">
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Favorite Subjects/Fields</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.favoriteSubjects || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Practical experience (Internship/part time experience)
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.practicalExperience || "-"}
              </span>
            </div>
          </div>

          <div className="snap-head-con row d-flex">
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Preferred hobbies/extra curricular activities
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.preferredHobbies || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Additional academic achievements
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.academicAchievements || "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con row d-flex">
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                How many years have you studied in total
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.totalYears || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Reason for Leaving</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.reasonLeaving || "-"}
              </span>
            </div>
          </div>

          <div className="snap-head-con row d-flex">
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Course has been exempted </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.courseExempted || "-"}
              </span>
            </div>
            <div className="sub-head col-12 col-lg-6 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Name of the Institute for exemption
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.profile?.instituteName || "-"}
              </span>
            </div>
          </div>
        </div>
        <div ref={otherInfoRef} className="snap-con gap-2">
          <div className="snap-sub-header">Other Information</div>
          <div className="snap-head-con d-flex">
            <div className="sub-head col-12 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Special Offer</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.otherInformation?.specialOffer ||
                  "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con d-flex">
            <div className="sub-head col-12 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">
                Name of the staff enrolled by
              </span>
              <span className="snap-sub-text">
                {admissionData?.admission?.otherInformation?.enrolledStaff
                  ? admissionData?.admission?.otherInformation?.enrolledStaff
                      .enrolledStaffName
                  : "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con d-flex">
            <div className="sub-head col-12 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Consultant Address</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.otherInformation
                  ?.consultantAddress || "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con d-flex">
            <div className="sub-head col-12 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Consultant Mobile Number</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.otherInformation?.mobileNumber ||
                  "-"}
              </span>
            </div>
          </div>
          <div className="snap-head-con d-flex">
            <div className="sub-head col-12 sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Consultant Email</span>
              <span className="snap-sub-text">
                {admissionData?.admission?.otherInformation?.email || "-"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-2 d-none d-lg-flex">
        <div className="gap-1 mt-3 mr-1 stable-container">
          <a
            onClick={() => handleLinkClick(personalDataRef)}
            className={`d-flex row ${
              activeLink === personalDataRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Personal Data</span>
          </a>
          <a
            onClick={() => handleLinkClick(mailingAddressRef)}
            className={`d-flex row ${
              activeLink === mailingAddressRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Mailing Address</span>
          </a>
          <a
            onClick={() => handleLinkClick(guardianDetailsRef)}
            className={`d-flex row ${
              activeLink === guardianDetailsRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Guardian Details</span>
          </a>
          <a
            onClick={() => handleLinkClick(academicRef)}
            className={`d-flex row ${
              activeLink === academicRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box">
              Academic and Professional Qualifications
            </span>
          </a>
          <a
            onClick={() => handleLinkClick(employmentRef)}
            className={`d-flex row ${
              activeLink === employmentRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Employment and Work Experience</span>
          </a>
          <a
            onClick={() => handleLinkClick(uploadedFilesRef)}
            className={`d-flex row ${
              activeLink === uploadedFilesRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> List of Uploaded files.</span>
          </a>
          <a
            onClick={() => handleLinkClick(studentProfileRef)}
            className={`d-flex row ${
              activeLink === studentProfileRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Student Profile</span>
          </a>
          <a
            onClick={() => handleLinkClick(otherInfoRef)}
            className={`d-flex row ${
              activeLink === otherInfoRef
                ? "doc-container-link2"
                : "doc-container-link"
            }`}
          >
            <span className="link-box"> Other Information</span>
          </a>
          <a className="d-flex row doc-container-link">
            <span className="link-box">Program Details</span>
          </a>
          <a className="d-flex row doc-container-link">
            <span className="link-box">Fee/Financial</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ViewProfileStudent;
