import React from "react";

function CommunicationLogo({ color }) {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2262 15.4223C29.354 14.1499 29.984 12.5129 30.0004 10.8127C30.0004 6.65136 26.1971 3.25781 21.5197 3.25781C16.8423 3.25781 13.0391 6.64491 13.0391 10.8062C13.0391 14.9675 16.8423 18.3546 21.5197 18.3546C22.4577 18.3561 23.3907 18.2169 24.2875 17.9417L28.6423 20.8449C28.7221 20.8967 28.8149 20.9247 28.91 20.9256C28.9805 20.9248 29.05 20.9087 29.1136 20.8783C29.1772 20.8479 29.2334 20.804 29.2783 20.7496C29.3232 20.6952 29.3556 20.6317 29.3733 20.5635C29.3911 20.4952 29.3937 20.4239 29.381 20.3546L28.2262 15.4223ZM24.6326 17.0159C24.5528 16.9613 24.4583 16.9321 24.3616 16.932C24.309 16.9327 24.2568 16.9414 24.2068 16.9578C23.3408 17.2483 22.4331 17.3954 21.5197 17.3933C17.3778 17.3933 14.0068 14.4417 14.0068 10.8127C14.0068 7.18362 17.3778 4.22555 21.5197 4.22555C25.6616 4.22555 29.0326 7.18362 29.0326 10.8127C29.0171 12.3573 28.4166 13.8386 27.352 14.9578C27.2992 15.0165 27.2611 15.0868 27.2409 15.1631C27.2207 15.2394 27.219 15.3194 27.2358 15.3965L28.1616 19.3868L24.6326 17.0159Z"
        fill={color}
      />
      <path
        d="M16.5452 19C15.0432 19.6448 13.4248 19.9742 11.7903 19.9678C10.4735 19.9645 9.16605 19.7466 7.91936 19.3226C7.84909 19.2999 7.77459 19.2932 7.70142 19.3033C7.62825 19.3133 7.55829 19.3398 7.49678 19.3807L2.09677 23.0226L3.51936 16.8936C3.5353 16.8157 3.53155 16.7351 3.50846 16.6591C3.48536 16.583 3.44365 16.514 3.3871 16.4581C1.85186 14.8367 0.987467 12.6942 0.967742 10.4613C0.967742 5.22262 5.82258 0.958105 11.7903 0.958105C13.6751 0.956733 15.5338 1.39875 17.2161 2.24843C17.2727 2.27745 17.3344 2.29504 17.3978 2.30021C17.4611 2.30537 17.5249 2.29801 17.5854 2.27854C17.6459 2.25907 17.702 2.22787 17.7504 2.18673C17.7989 2.14559 17.8387 2.0953 17.8677 2.03875C17.8968 1.9822 17.9144 1.92048 17.9195 1.85713C17.9247 1.79378 17.9173 1.73003 17.8979 1.66952C17.8784 1.60901 17.8472 1.55293 17.806 1.50448C17.7649 1.45603 17.7146 1.41616 17.6581 1.38714C15.8383 0.470169 13.8281 -0.00504145 11.7903 4.0332e-05C5.2871 -0.00963709 0 4.69036 0 10.4613C0.022672 12.8464 0.916385 15.1409 2.5129 16.9129L0.867742 23.9968C0.851863 24.0677 0.852041 24.1412 0.868262 24.2121C0.884483 24.2829 0.916337 24.3492 0.96149 24.4061C1.00664 24.463 1.06395 24.5091 1.12922 24.5409C1.19449 24.5728 1.26607 24.5897 1.33871 24.5904C1.43526 24.5893 1.52943 24.5602 1.60968 24.5065L7.84194 20.3323C9.11957 20.7337 10.4511 20.9371 11.7903 20.9355C13.5589 20.9434 15.3102 20.5877 16.9355 19.8904C17.0536 19.8395 17.1466 19.7437 17.194 19.6243C17.2415 19.5048 17.2396 19.3713 17.1887 19.2533C17.1378 19.1352 17.0421 19.0422 16.9226 18.9947C16.8031 18.9472 16.6697 18.9491 16.5516 19H16.5452Z"
        fill={color}
      />
    </svg>
  );
}

export default CommunicationLogo;
