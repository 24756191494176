import { Form, Formik, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateFinancial.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import sessionService from "services/sessionstorage.service";

function CreateFinancial() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [refresh, setRefresh] = useState(false);
  const [payment, setPayment] = useState(false);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const navigateToCreateProgramDetails = () => {
    navigation("/admission-management/create-program-details", {
      state: { data: data },
    });
  };

  const [admissionData, setAdmissionData] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [bursaries, setBursaries] = useState("");
  const [internationalFee, setInternationalFee] = useState("");
  const [eslFee, setEslFee] = useState("");
  const [totalFee, setTotalFee] = useState(0);

  const calculateTotalFee = () => {
    const sum =
      parseFloat(admissionData.applicationFee || 0) +
      parseFloat(admissionData.tuitionFee || 0) +
      parseFloat(admissionData.materialFee || 0) +
      parseFloat(admissionData.fieldTripsFee || 0) +
      parseFloat(internationalFee || 0) +
      parseFloat(eslFee || 0) +
      parseFloat(admissionData.bankFee || 0) +
      parseFloat(admissionData.otherFee || 0);

    const total = sum - parseFloat(bursaries || 0);
    setTotalFee(total);
  };

  useEffect(() => {
    if (admissionData !== null) {
      calculateTotalFee();
    }
  }, [bursaries, internationalFee, eslFee, admissionData]);
  const userData = sessionService.getUserData();
  const studentType = userData?.studentType;
  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setProgramData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.mailingAddress.isApproved);
        });
    }
  }, [location.pathname, refresh]);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(`${ADMISSION_MANAGEMENT_URL.GET_FEE_BY_FEEID}${data?.feeId}`)
        .then((response) => {
          const datas = response?.data?.data;
          setAdmissionData(datas);
          setBursaries(datas?.bursaries);
          setInternationalFee(datas?.internationalFee);
          setEslFee(datas?.eslFee);
        });

      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_CLOA_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setPayment(data);
        });
    }
  }, [location.pathname]);

  const initialValues = {
    applicationFee: "",
    bursaries: "",
    materialFee: "",
    fieldTripsFee: "",
    internationalFee: "",
    eslFee: "",
    bankFee: "",
    otherFee: "",
    tuitionFee: "",
    otherFeeDetail: "",
  };

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData;
    const admissionId = {
      admissionId: data?.admissionId,
    };
    const programId = {
      programId: data?.programId,
    };
    requestData.isApproved = true;
    requestData.isRejected = false;
    requestData.admissionManagement2 = admissionId;
    requestData.programDetail = programId;
    requestData.bursaries = bursaries;
    requestData.internationalFee = internationalFee;
    requestData.eslFee = eslFee;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_FEE}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Fee/Financial Approved Successfully");
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData;
    const admissionId = {
      admissionId: data?.admissionId,
    };
    const programId = {
      programId: data?.programId,
    };
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;
    requestData.admissionManagement2 = admissionId;
    requestData.programDetail = programId;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_FEE}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Fee/Financial Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <div className="create-admission-main-container">
      <div className="progress-container">
        <div className="create-admission-head-text">
          Application #{admissionData?.programDetail?.applicationNo}
        </div>
      </div>
      <div className="row create-admission-container g-0">
        <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
          <AdmissionSideBarFaculty data={data} />
        </div>
        <div className="create-admission-inner-right-container col-12 col-lg-9 pt-2">
          <div className="create-text-title ">Fee/Financial</div>
          <Formik
            initialValues={admissionData || initialValues}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">Application Fee</label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="applicationFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">Tuition Fee</label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="tuitionFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <div className="label-text-active">
                      Scholarship/Bursaries
                    </div>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        name="bursaries"
                        disabled={admissionData?.isApproved}
                        value={bursaries}
                        onChange={(event) => setBursaries(event.target.value)}
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                </div>
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">
                      Material/Books Fee
                    </label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="materialFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">Field Trips Fee</label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="fieldTripsFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                </div>
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">
                      International Fee
                    </label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        name="internationalFee"
                        disabled={admissionData?.isApproved}
                        value={internationalFee}
                        onChange={(event) =>
                          setInternationalFee(event.target.value)
                        }
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">ESL Fee</label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={admissionData?.isApproved}
                        name="eslFee"
                        value={eslFee}
                        onChange={(event) => setEslFee(event.target.value)}
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <div className="label-text-active">Bank Fee</div>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="bankFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                </div>
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">Other Fee</label>
                    <span className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        name="otherFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>

                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <div className="label-text-active">Other Fee Detail</div>
                    <Field
                      disabled={true}
                      name="otherFeeDetail"
                      className="  create-financial-input-big"
                    />
                  </div>
                </div>
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "150px" }}
                  >
                    <label className="label-text-active">Total fee</label>
                    <span className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <Field
                        disabled={true}
                        value={totalFee}
                        name="totalFee"
                        className="form-control input-group create-financial-input-small"
                      />
                    </span>
                  </div>
                </div>

                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    className="create-admission-previous-button"
                    onClick={navigateToCreateProgramDetails}
                  >
                    Previous
                  </button>
                  {payment && (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      onClick={() => {
                        const targetPath =
                          studentType === "OSAP"
                            ? "/admission-management/payment-info"
                            : "/admission-management/cloa-payment-info";

                        navigation(targetPath, {
                          state: { data: data },
                        });
                      }}
                    >
                      Next
                    </button>
                  )}

                  {programData?.studentPayments[0]?.isApproved != true && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {!admissionData?.isApproved && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CreateFinancial;
