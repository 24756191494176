import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./PaymentSubmission.css";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import { RiCloseCircleFill } from "react-icons/ri";
import FLOA from "modules/pdf-generation/FLOA/FLOA";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import { FaEye } from "react-icons/fa";

function PaymentSubmission() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const location = useLocation();
  const data = location?.state?.data;
  const [pdfData, setPdfData] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [date, setDate] = useState(null);
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({});
  const [showRejectInput, setShowRejectInput] = useState(false);
  const [rejectComments, setRejectComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const [admissionData, setAdmissionData] = useState();
  const [programData, setProgramData] = useState(null);

  const [applicationNo, setApplicationNo] = useState(null);

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          const applicationNo =
            response?.data?.data?.programDetails[0]?.applicationNo;
          setApplicationNo(applicationNo);
          setProgramData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.isApproved);
        });
    }
  }, [location.pathname, refresh]);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const datas = response?.data?.data;
          setAdmissionData(datas);
          setRemarks(datas?.remarks);
          setDate(datas?.date);
        });

      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_AND_PROGRAM}${data?.admissionId}?programId=${data?.programId}&feeId=${data?.feeId}`
        )
        .then((response) => {
          const data = response.data;
          const admissionManagement = response?.data?.admissionManagement;
          if (
            admissionManagement?.personalData?.isApproved &&
            admissionManagement?.mailingAddress?.isApproved &&
            admissionManagement?.guardianDetail?.isApproved &&
            admissionManagement?.academics[0]?.isApproved &&
            admissionManagement?.employments[0]?.isApproved &&
            admissionManagement?.otherInformation?.isApproved &&
            admissionManagement?.upload?.isApproved &&
            admissionManagement?.profile?.isApproved &&
            admissionManagement?.profile?.isApproved &&
            data.programDetail?.isApproved &&
            data.fee?.isApproved
          ) {
            setApproveShow(true);
          } else {
            setApproveShow(false);
          }
        });
    }
  }, [location.pathname, refresh]);
  const handleApprove = async () => {
    const admissionId = {
      admissionId: data?.admissionId,
    };
    const requestData = {
      ...admissionData,
      isApproved: true,
      isRejected: false,
      admissionManagement3: admissionId,
      programDetail1: {
        ...admissionData?.programDetail1,
        programFee: {
          programFeeMasterId:
            admissionData?.programDetail1?.programFee?.programFeeMasterId,
        },
      },
    };

    if (approveShow) {
      try {
        const response = await axiosService.put(
          `${ADMISSION_MANAGEMENT_URL.PUT_PAYMENT}`,
          requestData
        );

        if (response.status === 200) {
          setPdfData(response.data.data);
          toast.success("Payment Approved Successfully");
          setLoading(true);
          setRefresh(!refresh);
        } else {
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      toast.error(
        "Please approve the rest of the application sections before final approval."
      );
    }
  };

  const handleReject = async () => {
    const admissionId = {
      admissionId: data?.admissionId,
    };
    const requestData = {
      ...admissionData,
      isApproved: false,
      isRejected: true,
      rejectComments: rejectComments,
      admissionManagement3: admissionId,
      programDetail1: {
        ...admissionData?.programDetail1,
        programFee: {
          programFeeMasterId:
            admissionData?.programDetail1?.programFee?.programFeeMasterId,
        },
      },
    };

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_PAYMENT}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Payment Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  return (
    <Formik validationSchema={validationSchema}>
      {({ setFieldValue, resetForm }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Create New Application
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>
              <div className="create-admission-inner-right-container col-12 col-md-8 auth-text-animation payment-update">
                <span className="payment-container-title">
                  <h4>Student Payment Updated Portal</h4>
                </span>
                <div
                  className="d-flex row g-0 paymentupdate-inner-content-container "
                  style={{ width: "100%" }}
                >
                  <div className="login-head-text-container">
                    <span className="auth-title-text paymenyupdte-content-title">
                      Please upload your payment information detail here
                    </span>
                  </div>
                  <div className="flex-column" style={{ position: "relative" }}>
                    <div className="login-input-main-container paymentupdate-input-top  col-lg-6">
                      <div className="paymentupdate-document-date">
                        <span className="password-text-container">
                          <label className="paymentupdate-document-date">
                            Date
                          </label>
                        </span>
                        <input
                          disabled={true}
                          className="paymentupdate-document-date-input"
                          type="date"
                          placeholder="Select a date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="paymentupdate-document-upload">
                      <span>
                        <label className="paymentupdate-document-upload-title">
                          Upload Document
                        </label>
                      </span>
                      <div
                        className="paymentupdate-document-upload-fields"
                        style={{ maxWidth: "455px" }}
                      >
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload1"
                          name="imageDocumentUpload"
                          onChange={(event) =>
                            setFieldValue(
                              "imageDocumentUpload",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <span
                          className="create-list-upload-input-big paymentupload create-list-upload-input-big-one"
                          onClick={() => {
                            document.getElementById("upload1").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.uploadDocumentName}
                          </span>
                          <div
                            className=" paymentupdate-document-upload-choosefile"
                            type="button"
                          >
                            Choose File
                          </div>
                        </span>
                        {admissionData?.uploadDocument && (
                          <a
                            style={{
                              position: "absolute",
                              right: "-30px",
                              top: "5px",
                            }}
                            href={admissionData?.uploadDocument}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="paymentupdate-remarks ">
                      <span>
                        <label>Remarks</label>
                      </span>
                      <input
                        disabled={true}
                        className="paymentupdate-remarks-input"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        placeholder="Add a subject to task"
                      />
                    </div>
                    <div>
                      <div className="mt-3 d-flex gap-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() =>
                            navigate("/admission-management/create-financial", {
                              state: { data: data },
                            })
                          }
                        >
                          Previous
                        </button>
                        {showRejectButton && (
                          <button
                            type="button"
                            className="create-admission-previous-button"
                            onClick={handleRejectButtonClick}
                          >
                            Reject
                          </button>
                        )}
                        {!admissionData?.isApproved ? (
                          <button
                            type="button"
                            className="create-admission-save-button"
                            onClick={handleApprove}
                          >
                            {loading ? (
                              <div class="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ) : (
                              "Approve"
                            )}
                          </button>
                        ) : (
                          loading && (
                            <button
                              type="button"
                              className="create-admission-save-button"
                            >
                              <div class="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </button>
                          )
                        )}
                      </div>
                      {admissionData?.isApproved && (
                        <div className="row approve-reject-comments-container">
                          <span className="admission-comments-head-text">
                            Comments
                          </span>

                          <span className="approve-textarea">
                            <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                            Approved
                          </span>

                          <span className="approve-date-text">
                            on {formattedDate}
                          </span>
                        </div>
                      )}
                      {admissionData?.isRejected && (
                        <div className="row approve-reject-comments-container">
                          <span className="admission-comments-head-text">
                            Comments
                          </span>
                          <div className="rejected-container p-0 mt-2">
                            <div className="rejcted-head-text">
                              <RiCloseCircleFill className="admission-reject-icon" />
                              Rejected
                            </div>
                            <div className="inner-reject-text">
                              {admissionData?.rejectComments}
                            </div>
                          </div>
                          <span className="approve-date-text">
                            {formattedDate}
                          </span>
                        </div>
                      )}
                      {showRejectInput && (
                        <div
                          className="row approve-reject-comments-container"
                          id="rejection-comments-container"
                        >
                          <span className="admission-comments-head-text">
                            Comments
                          </span>
                          <textarea
                            className="reject-textarea"
                            onChange={(event) =>
                              setRejectComments(event.target.value)
                            }
                          />
                          <div className=" d-flex gap-2 p-0 pt-2">
                            <button
                              type="button"
                              className="create-admission-previous-button"
                              onClick={() => setShowRejectInput(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="create-admission-save-button"
                              onClick={handleReject}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {pdfData && (
                  <FLOA
                    data={data}
                    pdfData={programData}
                    applicationNo={applicationNo}
                    setLoading={() => setLoading(false)}
                  />
                )}
              </div>
            </div>
          </div>
          {loading && <div class="overlaypage"></div>}
        </Form>
      )}
    </Formik>
  );
}
export default PaymentSubmission;
