import { AppConfig } from "env.development";

export const AUTH_URLS = {
  REGISTER_URL: `${AppConfig.api_url}user/v1`,
  REGISTER_URL_WITH_FLAG: `${AppConfig.api_url}user/v1?registration=true`,
  LOGIN_URL: `${AppConfig.api_url}auth/v1/authenticate`,
  ENQUIRY_FORM_URL: `${AppConfig.api_url}lead/v1/enquiry`,
  FORGOT_PASSWORD_URL: `${AppConfig.api_url}user/v1/forgot-password`,
  VERIFY_OTP_URL: `${AppConfig.api_url}user/v1/verify-otp`,
  UPDATE_PASSWORD_URL: `${AppConfig.api_url}user/v1/update-password`,
  RESEND_OTP_URL: `${AppConfig.api_url}user/v1/resend-otp`,
  VERIFICATION_HOME_URL: `${AppConfig.api_url}student-verify/v1`,
  MICROSOFT_URL: `${AppConfig.api_url}azure/v1`,
  GOOGLE_URL: `${AppConfig.google_url}check/google`,
  GET_ALL_CIRCULAR_NOTIFICATION: `${AppConfig.api_url}circular/v1/notification`,
  POST_PAYMENT: `${AppConfig.api_url}payment/v1`,
  PUT_PAYMENT: `${AppConfig.api_url}payment/v1`,
  MAIL_RECEIPT: `${AppConfig.api_url}payment/v1/send-mail`,
  GET_DOCUMENT_NOTIFY: `${AppConfig.api_url}student-portal/documents/v1/notification`,
  GET_SERVICE_NOTIFY: `${AppConfig.api_url}student-portal/services/v1/notification`,
  GET_TOTAL_NO_OF_NOTIFY: `${AppConfig.api_url}student-portal/documents/v1/all/notification`,
};
