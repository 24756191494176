import React from "react";

function PresentIcon() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1759 3.40913C17.2263 3.45945 17.2663 3.51922 17.2936 3.58503C17.3209 3.65084 17.335 3.72139 17.335 3.79263C17.335 3.86388 17.3209 3.93443 17.2936 4.00024C17.2663 4.06605 17.2263 4.12582 17.1759 4.17613L13.9259 7.42614C13.8755 7.47658 13.8158 7.5166 13.75 7.54391C13.6841 7.57121 13.6136 7.58527 13.5424 7.58527C13.4711 7.58527 13.4006 7.57121 13.3347 7.54391C13.2689 7.5166 13.2092 7.47658 13.1589 7.42614L11.5339 5.80113C11.4321 5.69942 11.375 5.56148 11.375 5.41763C11.375 5.27379 11.4321 5.13585 11.5339 5.03413C11.6356 4.93242 11.7735 4.87528 11.9174 4.87528C12.0612 4.87528 12.1991 4.93242 12.3009 5.03413L13.5424 6.27672L16.4089 3.40913C16.4592 3.35869 16.5189 3.31867 16.5847 3.29136C16.6506 3.26406 16.7211 3.25 16.7924 3.25C16.8636 3.25 16.9341 3.26406 17 3.29136C17.0658 3.31867 17.1255 3.35869 17.1759 3.40913Z"
        fill="#F69F3A"
      />
      <path
        d="M1.08333 13C1.08333 13 0 13 0 11.9167C0 10.8333 1.08333 7.58333 6.5 7.58333C11.9167 7.58333 13 10.8333 13 11.9167C13 13 11.9167 13 11.9167 13H1.08333ZM6.5 6.5C7.36195 6.5 8.1886 6.15759 8.7981 5.5481C9.40759 4.9386 9.75 4.11195 9.75 3.25C9.75 2.38805 9.40759 1.5614 8.7981 0.951903C8.1886 0.34241 7.36195 0 6.5 0C5.63805 0 4.8114 0.34241 4.2019 0.951903C3.59241 1.5614 3.25 2.38805 3.25 3.25C3.25 4.11195 3.59241 4.9386 4.2019 5.5481C4.8114 6.15759 5.63805 6.5 6.5 6.5Z"
        fill="#F69F3A"
      />
    </svg>
  );
}

export default PresentIcon;
