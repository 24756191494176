import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { ATTENDANCE_MANAGEMENT_URLS } from "../constants/attendance-management-urls";
import "./ViewAttendance.css";

function ViewAttendance() {
  const location = useLocation();
  const programModuleId = location.state.data.Data.module.programModuleId;
  const startTime = location.state.data.Data.startTime;
  const endTime = location.state.data.Data.endTime;
  const className = location.state.data.Data.module.module.moduleName;
  const Date = location.state.data.Data.startDate;
  const programName = location.state.data.Data.module.programFeeMaster.program;
  const shift = location.state.data.Data.shift;
  const campus = location.state.data.Data.module.programFeeMaster.campus;
  const resource = location.state.data.Data.resource.blockName;
  const scheduleId = location.state.data.Data.splitScheduleId;

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const getRadioStyle = (item) => {
    const colors = {
      a: "white",
      b: "red",
      c: "orange",
    };

    return {
      color: colors[item],
      padding: 0,
    };
  };

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const navigate = useNavigate();
  const [allStudentsData, setAllStudentsData] = useState();

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudentsForLate, setSelectedStudentsLate] = useState([]);

  // const handlePrevClass = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //   }
  // };

  // const handleNextClass = () => {
  //   if (currentIndex < classData.length - 1) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };

  useEffect(() => {
    axiosService
      .get(
        `${ATTENDANCE_MANAGEMENT_URLS.GET_ATTENDANCE_DATA_BY_ID}${scheduleId}`
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data.data;
          const presentStudents = [];
          const absentStudents = [];
          const lateStudents = [];

          responseData?.attendances?.forEach((attendance) => {
            if (attendance.attendanceType === "PRESENT") {
              presentStudents.push(attendance);
              setPresentLength(presentStudents.length);
            } else if (attendance.attendanceType === "ABSENT") {
              absentStudents.push(attendance);
              setAbsentLength(absentStudents.length);
            } else if (attendance.attendanceType === "LATE") {
              lateStudents.push(attendance);
              setLateLength(lateStudents.length);
            }
          });

          setSelectedStudents(absentStudents);
          setSelectedStudentsLate(lateStudents);
        }
      });
  }, [location.pathname]);
  useEffect(() => {
    axiosService
      .get(
        `${ATTENDANCE_MANAGEMENT_URLS.GET_ALL_STUDENTS}?programModuleId=${programModuleId}&startTime=${startTime}&endTime=${endTime}`
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data;
          setAllStudentsData(responseData);
        }
      });
  }, [location.pathname]);

  const [absentLength, setAbsentLength] = useState(0);
  const [presentLength, setPresentLength] = useState(0);
  const [LateLength, setLateLength] = useState(0);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header flex-md-row gap-2 sticky-header">
        <span className="lead-table-title-text align-items-center">
          Class Name : <u>{className}</u>
        </span>
      </div>
      <div
        className="column d-flex mt-2"
        style={{ borderBottom: "1px solid #333333" }}
      >
        <div className=" align-items-start">
          <span className="Doc-head ">Class Details</span>
          <div className="mark-attendance-detail-con">
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Class Time
              </span>
              <span className="mark-all-checkbox-label p-1">
                {startTime} - {endTime}
              </span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Date
              </span>
              <span className="mark-all-checkbox-label p-1"> {Date}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Program
              </span>
              <span className="mark-all-checkbox-label p-1">{programName}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Shift
              </span>
              <span className="mark-all-checkbox-label p-1"> {shift}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Campus
              </span>
              <span className="mark-all-checkbox-label p-1"> {campus}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Facility ID
              </span>
              <span className="mark-all-checkbox-label p-1"> {resource}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-2 gap-2 flex-wrap">
        <div>
          <span className="Doc-head ">Attendance Details</span>
        </div>
        <div className="d-flex gap-2">
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">On Time</span>
            <span className="" style={{ fontSize: "18px" }}>
              {presentLength}
            </span>
          </span>
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">Late</span>
            <span
              className="lead-table-text-orange"
              style={{ fontSize: "18px" }}
            >
              {LateLength}
            </span>
          </span>
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">Absent</span>
            <span className=" lead-table-text-red" style={{ fontSize: "18px" }}>
              {absentLength ? absentLength : "0"}
            </span>
          </span>
          <span className="d-flex gap-1 align-items-center">
            <span className="mark-all-checkbox-label">Total</span>
            <span className="" style={{ fontSize: "18px" }}>
              {allStudentsData?.length}
            </span>
          </span>
        </div>
      </div>

      <div className="mt-3">
        <p className="mark-all-checkbox-label m-0">Students who were absent</p>
        <div className="p-2 card-view d-flex flex-column gap-2 mt-1">
          <div className="d-flex flex-wrap">
            {selectedStudents?.length > 0 ? (
              <Stack direction="row" spacing={5}>
                {selectedStudents?.map((student, index) => (
                  <Chip
                    key={index}
                    label={
                      student?.student?.admissionManagement?.personalData
                        ?.firstName +
                      " " +
                      student?.student?.admissionManagement?.personalData
                        ?.lastName
                    }
                    style={{
                      color: "rgba(255, 255, 255, 1)",
                      borderColor: "rgba(246, 65, 58, 1)",
                    }}
                    variant="outlined"
                  />
                ))}
              </Stack>
            ) : (
              <p className="mark-all-checkbox-label m-0">
                No students were absent
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="mt-3">
        <p className="mark-all-checkbox-label m-0">
          Students who were late (joined after 15 minutes)
        </p>

        <div className="p-2 card-view d-flex flex-column gap-2 mt-1">
          <div className="d-flex flex-wrap">
            {selectedStudentsForLate?.length > 0 ? (
              <Stack direction="row" spacing={5}>
                {selectedStudentsForLate.map((student, index) => (
                  <Chip
                    key={index}
                    label={
                      student?.student?.admissionManagement?.personalData
                        ?.firstName +
                      " " +
                      student?.student?.admissionManagement?.personalData
                        ?.lastName
                    }
                    style={{
                      color: "rgba(255, 255, 255, 1)",
                      borderColor: "rgba(246, 159, 58, 1)",
                    }}
                    variant="outlined"
                  />
                ))}
              </Stack>
            ) : (
              <p className="mark-all-checkbox-label m-0">
                No students were late
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="card-view d-flex flex-column gap-2 mt-3 p-2">
        <p className="mark-all-checkbox-label p-0 m-0">All Students List</p>

        <div class="all-student-list-mark-attendance">
          {allStudentsData?.map((student, index) => (
            <div key={index} className="d-flex gap-2">
              <Radio
                disabled={true}
                {...controlProps("a")}
                style={getRadioStyle("a")}
                checked={
                  !selectedStudents.some(
                    (stu) => stu?.student?.studentId === student?.studentId
                  ) &&
                  !selectedStudentsForLate.some(
                    (stu) => stu?.student?.studentId === student?.studentId
                  )
                }
              />
              <Radio
                disabled={true}
                {...controlProps("b")}
                style={getRadioStyle("b")}
                checked={selectedStudents.some(
                  (stu) => stu?.student?.studentId === student?.studentId
                )}
              />
              <Radio
                disabled={true}
                {...controlProps("c")}
                style={getRadioStyle("c")}
                checked={selectedStudentsForLate.some(
                  (stu) => stu?.student?.studentId === student?.studentId
                )}
              />
              <label className="mark-all-checkbox-label">
                {`${student?.admissionManagement?.personalData?.firstName} ${student?.admissionManagement?.personalData?.lastName}`}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex align-items-center gap-3 py-3 p-2">
        <button
          className="communication-management-cancel-button"
          type="button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default ViewAttendance;
