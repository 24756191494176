import { AppConfig } from "env.development";

export const ROLE_URLS = {
  USER_ROLE_URL: `${AppConfig.api_url}role/v1`,
  USER_GET_URL: `${AppConfig.api_url}role/v1/all`,
  USER_GET_ID: `${AppConfig.api_url}user/v1/`,
  USER_ROLE_ID: `${AppConfig.api_url}role/v1/`,
  DELETE_USER_ID: `${AppConfig.api_url}user/v1/`,
  FACULTY_GET_URL: `${AppConfig.api_url}user/v1/all/role`,
  GET_FACULTY_URL: `${AppConfig.api_url}user/v1/getAllByRole?roleName=faculty`,
  GET_STUDENT_URL: `${AppConfig.api_url}user/v1/getAllByRole?roleName=student`,
  UPDATE_PASSWORD: `${AppConfig.api_url}user/v1/update-password`
};
