import React from "react";

function ApplicationLogo({color}) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7649 7.95934C26.5197 7.10165 27 5.9695 27 4.76874C27 2.12706 24.8386 0 22.2313 0C20.9962 0 19.8983 0.445997 19.0407 1.20076C17.291 0.445997 15.4384 0.0343073 13.4828 0.0343073C6.03812 0.0343073 0 6.07243 0 13.5172C0 20.9619 6.03812 27 13.4828 27C20.9276 27 26.9657 20.9619 26.9657 13.5515C26.9657 11.6302 26.554 9.77764 25.7992 8.06226C25.7992 8.02795 25.7649 7.99365 25.7649 7.95934ZM13.4828 25.6277C11.0127 25.6277 8.7141 24.9072 6.79288 23.6379C6.79288 23.5693 6.82719 23.5006 6.82719 23.4663C6.82719 19.7954 9.81194 16.8107 13.4828 16.8107C17.1537 16.8107 20.1385 19.7954 20.1385 23.4663C20.1385 23.5349 20.1385 23.6036 20.1728 23.6379C18.2516 24.9072 15.953 25.6277 13.4828 25.6277ZM10.4638 12.0762C10.4638 10.3952 11.8361 9.05718 13.4828 9.05718C15.1296 9.05718 16.5019 10.3952 16.5019 12.0762C16.5019 13.6201 15.3698 14.8895 13.8602 15.061C13.723 15.061 13.6201 15.0953 13.4828 15.0953C13.3456 15.0953 13.2427 15.0953 13.1055 15.061C11.5959 14.8895 10.4638 13.5858 10.4638 12.0762ZM21.7853 22.3342C21.3736 19.2808 19.3151 16.7421 16.5362 15.6442C17.5654 14.7865 18.2173 13.4828 18.2173 12.0762C18.2173 9.46887 16.0902 7.3418 13.4828 7.3418C10.8755 7.3418 8.74841 9.43456 8.74841 12.0419C8.74841 13.4828 9.40025 14.7865 10.4295 15.6442C7.65057 16.7421 5.59212 19.2808 5.18043 22.3342C2.84752 20.1385 1.3723 16.9822 1.3723 13.5172C1.3723 6.82719 6.79288 1.40661 13.4828 1.40661C15.0953 1.40661 16.6734 1.74968 18.1144 2.33291C17.7027 3.05337 17.4625 3.87675 17.4625 4.76874C17.4625 7.41042 19.6239 9.53748 22.2313 9.53748C23.1233 9.53748 23.9466 9.29733 24.6671 8.85133C25.2846 10.3266 25.5934 11.8704 25.5934 13.5172C25.5934 16.9822 24.1182 20.1385 21.7853 22.3342ZM22.2313 8.19949C20.3443 8.19949 18.8348 6.68996 18.8348 4.80305C18.8348 2.91614 20.3787 1.40661 22.2313 1.40661C24.0839 1.40661 25.6277 2.91614 25.6277 4.80305C25.6277 6.68996 24.1182 8.19949 22.2313 8.19949Z"
        fill={color}
      />
      <path
        d="M24.3233 4.76854C24.3233 5.14592 24.0146 5.45469 23.6372 5.45469H22.8824V6.20945C22.8824 6.58684 22.5737 6.8956 22.1963 6.8956C21.8189 6.8956 21.5101 6.58684 21.5101 6.20945V5.45469H20.7897C20.4123 5.45469 20.1035 5.14592 20.1035 4.76854C20.1035 4.39116 20.4123 4.08239 20.7897 4.08239H21.5101V3.36193C21.5101 2.98455 21.8189 2.67578 22.1963 2.67578C22.5737 2.67578 22.8824 2.98455 22.8824 3.36193V4.08239H23.6372C24.0146 4.08239 24.3233 4.39116 24.3233 4.76854Z"
        fill={color}
      />
    </svg>
  );
}

export default ApplicationLogo;
