import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { USER_URL } from "modules/user-management/constants/user-urls";
import AddEnrolledStaff from "./AddEnrolledStaff";

function ListEnrolledStaff() {
  const location = useLocation();
  const [listStaff, setListStaff] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [staffData, setStaffData] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    axiosService
      .get(`${USER_URL.GET_ALL_STAFF_NAME}`)
      .then((response) => {
        const data = response?.data?.data;

        setListStaff(data);
      })
      .catch(() => {});
  }, [location.pathname]);

  const [columnDefs] = useState([
    {
      headerName: "S.No",
      valueGetter: "node.rowIndex + 1",
      width: 90,
    },
    {
      headerName: "Staff Name",
      field: "enrolledStaffName",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const onRowClicked = (event) => {
    setModalOpen(true);
    setStaffData(event.data);
  };

  return (
    <div className="view-leads-table-container p-0">
      <div className="lead-table-head-container sticky-header flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">Enrolled Staff Name</span>
        </div>
        <div className="d-flex align-items-end gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => setModalOpen(true)}
          >
            Add Staff
          </button>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listStaff}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
      <AddEnrolledStaff
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setStaffData(null);
          axiosService
            .get(`${USER_URL.GET_ALL_STAFF_NAME}`)
            .then((response) => {
              const data = response?.data?.data;
              setListStaff(data);
            })
            .catch(() => {});
        }}
        staffEdit={staffData}
      />
    </div>
  );
}

export default ListEnrolledStaff;
