import React from "react";

function AbsentIcon() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.69697 6.5C7.58504 6.5 8.43674 6.15759 9.06471 5.5481C9.69267 4.9386 10.0455 4.11195 10.0455 3.25C10.0455 2.38805 9.69267 1.5614 9.06471 0.951903C8.43674 0.34241 7.58504 0 6.69697 0C5.8089 0 4.9572 0.34241 4.32923 0.951903C3.70127 1.5614 3.34848 2.38805 3.34848 3.25C3.34848 4.11195 3.70127 4.9386 4.32923 5.5481C4.9572 6.15759 5.8089 6.5 6.69697 6.5ZM8.92929 3.25C8.92929 3.82464 8.6941 4.37574 8.27546 4.78206C7.85682 5.18839 7.28902 5.41667 6.69697 5.41667C6.10492 5.41667 5.53712 5.18839 5.11848 4.78206C4.69984 4.37574 4.46465 3.82464 4.46465 3.25C4.46465 2.67536 4.69984 2.12426 5.11848 1.71794C5.53712 1.31161 6.10492 1.08333 6.69697 1.08333C7.28902 1.08333 7.85682 1.31161 8.27546 1.71794C8.6941 2.12426 8.92929 2.67536 8.92929 3.25ZM13.3939 11.9167C13.3939 13 12.2778 13 12.2778 13H1.11616C1.11616 13 0 13 0 11.9167C0 10.8333 1.11616 7.58333 6.69697 7.58333C12.2778 7.58333 13.3939 10.8333 13.3939 11.9167ZM12.2778 11.9123C12.2767 11.6458 12.1059 10.8442 11.3491 10.1097C10.6214 9.40333 9.25186 8.66667 6.69697 8.66667C4.14096 8.66667 2.77255 9.40333 2.04481 10.1097C1.28805 10.8442 1.11839 11.6458 1.11616 11.9123H12.2778Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1735 3.30751C13.2284 3.25709 13.2936 3.21708 13.3654 3.18978C13.4371 3.16249 13.514 3.14844 13.5917 3.14844C13.6694 3.14844 13.7464 3.16249 13.8181 3.18978C13.8899 3.21708 13.9551 3.25709 14.0099 3.30751L15.3638 4.54962L16.7176 3.30751C16.8286 3.20584 16.979 3.14872 17.1358 3.14872C17.2927 3.14872 17.4431 3.20584 17.554 3.30751C17.665 3.40918 17.7273 3.54708 17.7273 3.69087C17.7273 3.83465 17.665 3.97255 17.554 4.07422L16.199 5.31525L17.554 6.55627C17.665 6.65794 17.7273 6.79584 17.7273 6.93963C17.7273 7.08341 17.665 7.22131 17.554 7.32298C17.4431 7.42465 17.2927 7.48177 17.1358 7.48177C16.979 7.48177 16.8286 7.42465 16.7176 7.32298L15.3638 6.08087L14.0099 7.32298C13.899 7.42465 13.7486 7.48177 13.5917 7.48177C13.4349 7.48177 13.2844 7.42465 13.1735 7.32298C13.0626 7.22131 13.0003 7.08341 13.0003 6.93963C13.0003 6.79584 13.0626 6.65794 13.1735 6.55627L14.5286 5.31525L13.1735 4.07422C13.1185 4.02392 13.0749 3.96417 13.0451 3.89839C13.0153 3.83261 13 3.76209 13 3.69087C13 3.61964 13.0153 3.54912 13.0451 3.48334C13.0749 3.41756 13.1185 3.35781 13.1735 3.30751Z"
        fill="white"
      />
    </svg>
  );
}

export default AbsentIcon;
