import "chart.js/auto";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import useravatar from "../../../../../assets/images/user-avatar.png";
import { DASHBOARD_URL } from "../constants/DashboardUrl";
import { Link } from "react-router-dom";
import "./HomeLeads.css";

function HomeLeads() {
  const [datas, setDatas] = useState([]);
  const [leadSource, setLeadSource] = useState();
  const userData = sessionstorageService.getUserData("userData");
  const firstName = userData.firstName ? userData.firstName : "";
  const lastName = userData.lastName ? userData.lastName : "";
  const userName = firstName + " " + lastName;
  const userId = sessionstorageService.getUserData("userData").userId;

  const isAnyValueGreaterThanZero = (obj) => {
    for (let key in obj) {
      if (obj[key] > 0) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    axiosService
      .get(`${DASHBOARD_URL.GET_ALL_DASHBOARD}/${userId}`)
      .then((response) => {
        const data = response?.data?.data;
        setDatas(data);
        setLeadSource(data?.leadSource);
      });
  }, []);

  const data = {
    labels: ["Facebook", "Twitter", "Agent", "Website", "Enquiry"],
    datasets: [
      {
        data: [
          leadSource?.facebookCount,
          leadSource?.twitterCount,
          leadSource?.agentCount,
          leadSource?.websiteCount,
          leadSource?.enquiryCount,
        ],
        backgroundColor: [
          "#283557",
          "#2D4583",
          "#3E60C1",
          "#6B85D6",
          "#889ddf",
        ],
      },
    ],
  };

  const options = {
    cutout: 50,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",

        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0.5,
      },
    },
  };

  return (
    <div className="lead-main-container-home-leads">
      <div className="home-header">
        Welcome{" "}
        <span
          style={{
            fontWeight: "700",
          }}
        >
          {userName}
        </span>
      </div>
      <div className="view-box-container">
        <div className=" view-box ">
          <span className="dashboard-box-title">Open Leads</span>
          <h1 className="home-view-items">{datas?.openLeads}</h1>
        </div>
        <div className=" view-box">
          <span className="dashboard-box-title">My Leads</span>
          <h1 className="home-view-items">{datas?.myLeads} </h1>
        </div>
        <div className=" view-box">
          <span className="dashboard-box-title">Open Tasks</span>
          <h1 className="home-view-items">{datas?.openTasks}</h1>
        </div>
        <div className=" view-box">
          <span className="dashboard-box-title"> My Tasks</span>
          <h1 className="home-view-items">{datas?.myTasks}</h1>
        </div>
      </div>

      <div className="row d-flex justify-content-between ">
        <div className="col-12 col-lg-5">
          <div className="home-title">
            Pipeline Deals
            <Link className="view-link" to="/lead-management/view-leads-table">
              view all
            </Link>
          </div>
          <div
            className="custom-scrollbar"
            style={{ overflowX: "auto", width: "100%" }}
          >
            <table
              style={{ width: "100%", height: "100%", margin: "15px 0px" }}
            >
              <thead className="table-head">
                <tr className="home-task-table">
                  <th>Contact</th>
                  <th>Priority</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody className="table-data">
                {datas?.pipeLines?.map((pipeLine) => (
                  <tr className="home-task-table gap-5">
                    <td>
                      <div className="column d-flex ">
                        {pipeLine?.imageurl !== null ? (
                          <img
                            src={pipeLine?.imageurl}
                            className="home-name-view "
                            alt="leadiconimage"
                          />
                        ) : (
                          <img
                            src={useravatar}
                            className="home-name-view"
                            alt="leadiconimage"
                          />
                        )}

                        <div className="row">
                          <span>{pipeLine.firstName}</span>
                          <span className="email-table">
                            {" "}
                            {pipeLine?.email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {pipeLine?.priority === "HIGH" ? (
                        <button className={"High-btn"}>
                          {pipeLine?.priority}
                        </button>
                      ) : null}
                      {pipeLine?.priority === "MEDIUM" ? (
                        <button className={"Medium-btn"}>
                          {pipeLine?.priority}
                        </button>
                      ) : null}
                      {pipeLine?.priority === "LOW" ? (
                        <button className={"Low-btn"}>
                          {pipeLine?.priority}
                        </button>
                      ) : null}
                    </td>
                    <td className="table-sub-data">
                      {pipeLine?.description ? pipeLine?.description : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="home-title">
            Tasks Pending
            <Link className="view-link" to="/lead-management/view-task">
              view all
            </Link>
          </div>
          <div
            className="custom-scrollbar"
            style={{ overflowX: "auto", width: "100%" }}
          >
            <table
              style={{ width: "100%", height: "100%", margin: "15px 0px" }}
            >
              <thead className="table-head">
                <tr className="home-task-table">
                  <th>Subject</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Lead Name</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody className="table-data">
                {datas?.tasks?.map((task) => (
                  <tr className="home-task-table">
                    <td className="table-sub-data">{task.subject}</td>
                    <td>{task.dueDate}</td>
                    <td>{task.status}</td>
                    <td>{task.relatedToStr}</td>
                    <td>
                      {task.priority === "HIGH" ? (
                        <button className={"High-btn"}>{task?.priority}</button>
                      ) : null}
                      {task.priority === "MEDIUM" ? (
                        <button className={"Medium-btn"}>
                          {task?.priority}
                        </button>
                      ) : null}
                      {task.priority === "LOW" ? (
                        <button className={"Low-btn"}>{task?.priority}</button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAnyValueGreaterThanZero(leadSource) && (
        <div className="column d-flex justify-content-between">
          <div className="col-6">
            <div className="home-title">
              Leads by Source{" "}
              <span className="view-link text-underline">last quarter</span>
            </div>
            <div className="chart-container">
              <Doughnut data={data} options={options} key={Math.random()} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeLeads;
