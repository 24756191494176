import CreateLeads from "modules/lead-mangement/components/create-leads/CreateLeads";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import sessionstorageService from "services/sessionstorage.service";
import "../../../../utils/user-management.css";
import AddUsers from "../add-users/AddUsers";
import AddRole from "../role-management/components/add-role/AddRole";
import RoleList from "../role-management/components/rolelist/RoleList";
import ViewUsers from "../view-users/ViewUsers";

function UserManagementRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = sessionstorageService?.isUserLoggedIn();

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService?.hasPermissionByButton(permission);
    return userPermissions;
  }

  useEffect(() => {
    if (token && location.pathname === "/user-management") {
      navigate("/user-management/view-users");
    }
  }, [location?.pathname, navigate, token]);

  return token ? (
    <Routes>
      {hasPermission("System Administration: view") && (
        <Route path="view-users" element={<ViewUsers />} />
      )}
      {hasPermission("System Administration: view") && (
        <Route path="add-users" element={<AddUsers />} />
      )}
      {hasPermission("System Administration: view") && (
        <Route path="add-role" element={<AddRole />} />
      )}
      {hasPermission("System Administration: view") && (
        <Route path="create-leads" element={<CreateLeads />} />
      )}
      {hasPermission("System Administration: view") && (
        <Route path="role-list" element={<RoleList />} />
      )}
     
    </Routes>
  ) : null;
}

export default UserManagementRoutes;
