import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import "./enrollment.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";
import { QRCode } from "react-qr-code";
import sha256 from "crypto-js/sha256";
import betalogo from "assets/images/icons/beta.svg";

const PdfContent = ({ pdfData, includeWatermark, hashValueData }) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );
  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;
  return (
    <div style={{ color: "#ffffff" }}>
      <div className="text-content-enrollment" id="text-content-enrollment">
        <div id="header-container" className="header-ts">
          <Header />
        </div>
        <div
          style={{
            marginLeft: "10px",
            width: "100%",
            position: "relative",
          }}
        >
          <div style={{ color: "#000000" }}>
            <h3>ENROLMENT CONFIRMATION LETTER</h3>

            <br></br>
            <p style={{ fontFamily: "Times New Roman", fontSize: "13px" }}>
              <strong>Issue Date:{formatDate(new Date())}</strong>
            </p>
            <p style={{ fontFamily: "Times New Roman", fontSize: "13px" }}>
              <strong>Ref:</strong> {pdfData?.personalData?.firstName}{" "}
              {pdfData?.personalData?.lastName}
              <p style={{ fontFamily: "Times New Roman", fontSize: "13px" }}>
                <strong>Student ID No.:</strong>{" "}
                {pdfData?.personalData?.studentId}
                <p style={{ fontFamily: "Times New Roman", fontSize: "13px" }}>
                  <strong>Date of Birth (YYYY/MM/DD):</strong>{" "}
                  {pdfData?.personalData?.dateOfBirth}
                </p>
              </p>
            </p>

            <p>
              This letter confirms that{" "}
              <strong
                style={{ fontFamily: "Times New Roman", fontSize: "13px" }}
              >
                {pdfData?.personalData?.firstName}{" "}
                {pdfData?.personalData?.lastName}
              </strong>{" "}
              is registered as a full-time student in - Certificate in Personal
              Support
              <p>Worker at the {pdfData?.programDetails[0]?.program}.</p>
            </p>

            <p>
              <p>We also validate the length of the program as given below:</p>
            </p>
            <ul>
              <li>
                <strong>Start Date:</strong>{" "}
                <span
                  style={{ fontFamily: "Times New Roman", fontSize: "13px" }}
                >
                  -
                </span>
              </li>
              <li>
                <strong>Expected End Date:</strong>{" "}
                <span
                  style={{ fontFamily: "Times New Roman", fontSize: "13px" }}
                >
                  -
                </span>
              </li>
            </ul>

            <p>
              This letter aims to confirm the enrolment of the student mentioned
              above.
            </p>
            <br></br>
            <p>
              For any validation of records, please email{""}
              <a style={{ textDecoration: "underline", color: "blue" }}>
                admin@betacollege.ca
              </a>
              /
              <a style={{ textDecoration: "underline", color: "blue" }}>
                support@mybeta.ca
              </a>
            </p>
            <br></br>
            <br></br>
            <p>Yours sincerely,</p>
            <p>Signature of Institute Representative:</p>
            <br />

            <p style={{ position: "absolute" }}>
              Print Name of Institute Representative:{" "}
              <strong>Krunal Trivedi</strong>
              <p style={{ marginTop: "-.5rem" }}>
                <strong>Registrar Cum Director: Outreach and Operations</strong>
              </p>
            </p>
          </div>
        </div>
        <div
          className="footer"
          style={{
            marginTop: includeWatermark === "download" ? "16rem" : "16.5rem",
            marginLeft: "15px",
            position: "relative",
          }}
        >
          {includeWatermark === "download" && hashValueData && (
            <div
              style={{
                position: "absolute",
                top: "8px",
                left: "22px",
                zIndex: 1,
              }}
            >
              <QRCode size={70} value={url} />
            </div>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};

const EnrollmentView = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);
  const studentId = pdfData?.personalData?.studentId;
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Enrollment-Letter"}-${studentId}`).toString().substring(0, 20)
  );
  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content-enrollment");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark === "view") {
        const watermarkText = "Sample";
        const fontSize = 680;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }

      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
        open: true,
      });

      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 40,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 40)) /
          overlayCanvas.width
      );

      if (includeWatermark === "view") {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");

            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

            // setHashValue(hashValue);

            const fileName = `${
              includeWatermark
                ? "Enrollment-Letter.sample"
                : "Enrollment-Letter"
            }_${applicationNo}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Enrollment-Letter.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");

      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
        />
      )}
    </span>
  );
};

export default EnrollmentView;
