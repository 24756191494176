import betalogo from "assets/images/icons/beta.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import "./academicTranscript.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";
import { QRCode } from "react-qr-code";
import sha256 from "crypto-js/sha256";

const PdfContent = ({ pdfData, includeWatermark, hashValueData, data }) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );
  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;

  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div>
        <h3>ACADEMIC TRANSCRIPT</h3>
        <h3>BUSINESS (NON-VOCATIONAL)</h3>
        <hr
          style={{
            marginTop: "5px",
            border: "0",
            width: "100%",
            borderTop: "1px solid black",
          }}
        />
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "0", marginRight: "70px" }}
        >
          <p>
            <strong>Student Name:</strong> {pdfData?.personalData?.firstName}{" "}
            {pdfData?.personalData?.lastName}
            <p>
              <strong>Address :</strong> {pdfData?.mailingAddress?.streetNo}{" "}
              {pdfData?.mailingAddress?.street}
            </p>
            <p>
              <strong>Program :</strong> {pdfData?.programDetails[0]?.program}
              <p>
                <strong>Start Date : </strong>{" "}
                {
                  pdfData?.master?.admissionManagement?.programDetails[0]
                    ?.fromDate
                }
              </p>
            </p>
          </p>
          <p>
            <strong>Student No :</strong> {pdfData?.personalData?.studentId}
            <p>
              <strong>D.O.B.:</strong> {pdfData?.personalData?.dateOfBirth}
            </p>
            <p>
              <strong>Program Hours: </strong>
              {pdfData?.programDetails[0]?.programDuration}
              <p>
                <strong>End Date :</strong>
                {
                  pdfData?.master?.admissionManagement?.programDetails[0]
                    ?.toDate
                }
              </p>
            </p>
          </p>
        </div>
        <hr
          style={{
            marginTop: "5px",
            border: "0",
            width: "100%",
            borderTop: "1px solid black",
          }}
        />
        <br></br>
        <table class="custom-table" style={{}}>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Code
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Course
              </th>
              <th
                s
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                %
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Grade
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Passed
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Completed
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((data) => (
              <tr key={data?.markId}>
                <td style={{ textAlign: "center", fontSize: "12.5px" }}>
                  {" "}
                  {data?.module?.module?.moduleCode}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12.5px",
                  }}
                >
                  {data?.module?.module?.moduleName}
                </td>
                <td style={{ textAlign: "center", fontSize: "12.5px" }}>
                  {data?.markPercentage}
                </td>
                <td style={{ textAlign: "center", fontSize: "12.5px" }}>
                  {data?.grade}
                </td>
                <td style={{ textAlign: "center", fontSize: "12.5px" }}>
                  {data?.result}
                </td>
                <td style={{ textAlign: "center", fontSize: "12.5px" }}>
                  {data?.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br></br>
      <p>
        To successfully complete the program at Beta College of Business &
        Technology, a student must:{" "}
        <p>Receive a minimum of 70% in each of the modules.</p>
      </p>
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ marginTop: "-4rem" }}>
          <span style={{ textDecoration: "underline" }}>Final Grade</span>:{" "}
          <strong> A </strong>
        </p>

        <p>
          <span style={{ textDecoration: "underline" }}>GRADING KEY</span>:
          <br />
          <div style={{ marginTop: "-1rem" }}>
            <span style={{ marginLeft: "5.4rem" }}>
              A+ (90-100%){" "}
              <span style={{ marginLeft: ".3rem" }}>A (80-89%)</span>
            </span>
            <br />
            <span style={{ marginLeft: "5.4rem" }}>
              B+ (75-79%){" "}
              <span style={{ marginLeft: ".3rem" }}>B (70-74%)</span>
            </span>
            <br />
            <span style={{ marginLeft: "5.4rem" }}>
              C+ (65-69%){" "}
              <span style={{ marginLeft: ".2rem" }}>C (60-64%)</span>
            </span>
            <br />
            <span style={{ marginLeft: "5.4rem" }}>
              D+ (55-59%){" "}
              <span style={{ marginLeft: ".3rem" }}>D (50-54%)</span>
            </span>
            <br />
            <strong style={{ marginLeft: "1.2rem" }}>
              CREDENTIALS: PREREQUISITE I
            </strong>
          </div>
        </p>
      </div>
      <p style={{ marginTop: "80px" }}>
        Authorized Signature of Institute Representative:
      </p>
      <br></br>

      <p>(Register/Director)</p>
      <p>
        Issue Date: <strong>{formatDate(new Date())}</strong>
      </p>
      <div
        className="footer-academic"
        style={{
          marginTop: includeWatermark === "download" ? "3rem" : "3.5rem",
        }}
      >
        {includeWatermark === "download" && hashValueData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "35px",
              zIndex: 1,
            }}
          >
            <QRCode size={70} value={url} />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

const AcademicTranscript = ({ downloadType, pdfData, close, data }) => {
  const [load, setLoad] = useState(false);
  const studentId = pdfData?.personalData?.studentId;

  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Academic-Transcript"}-${studentId}`).toString().substring(0, 20)
  );

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark) {
        const watermarkText = "Sample";
        const fontSize = 700;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }

      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });
      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 40,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 40)) /
          overlayCanvas.width
      );

      if (includeWatermark) {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");
            const fileName = `${
              includeWatermark ? "BreakDown-Letter.sample" : "BreakDown-Letter"
            }_${studentId}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "academic-transcript.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);
  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
          data={data}
        />
      )}
    </span>
  );
};

export default AcademicTranscript;
