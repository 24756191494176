import React, { useEffect, useState } from "react";
import "./PaymentGatewayHome.css";
import beta_logo from "assets/images/icons/beta.svg";
import PhoneInput from "react-phone-input-2";
import { Country } from "country-state-city";
import Select from "react-select";
import { toast } from "react-toastify";
import { isValidEmail } from "shared/validation/emailValidation";
import { axiosService } from "services/axios.service";
import { AUTH_URLS } from "core/auth/constants/auth-urls";
import Dropzone from "react-dropzone";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { UserConstants } from "modules/user-management/constants/user-constants";
import sessionService from "services/sessionstorage.service";
import { AppConfig } from "env.development";

function PaymentGatewayHome() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("ca");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [paymentFor, setPaymentFor] = useState("");
  const [amount, setAmount] = useState();
  const [serviceProviderCharges, setServiceProviderCharges] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [payeeInfo, setPayeeInfo] = useState(undefined);
  const [sponsorName, setSponsorName] = useState(undefined);
  const [sponsorImage, setSponsorImage] = useState("");
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      height: "44px !important",
      borderRadius: "5px !important",
      background: "transparent !important",
      boxShadow: "none !important",
      border: "1px solid #ccc !important",
    }),
  };

  const handleSubmit = async () => {
    if (fullName === "") {
      toast.error("Please enter a full name");
    } else if (!isValidEmail(email)) {
      if (email === "") {
        toast.error("Please enter a email number");
      } else {
        toast.error("Please enter a valid email number");
      }
    } else if (mobileNumber === "") {
      toast.error("Please enter a mobile number");
    } else if (dob === "") {
      toast.error("Please enter a date or birth");
    } else if (selectedCountry === null) {
      toast.error("Please select Country of Citizenship");
    } else if (!amount) {
      toast.error("Please enter a amount ");
    } else if (!check1 || !check2 || !check3 || !check4) {
      toast.error("Please agree terms and conditions");
    } else {
      const data = {
        fullName: fullName,
        email: email,
        dateOfBirth: dob,
        mobileNumber: mobileNumber,
        country: selectedCountry?.value,
        pay: paymentFor,
        cadAmount: amount,
        serviceCharge: serviceProviderCharges,
        totalAmount: totalAmount,
        payeeInfo: payeeInfo,
        studentRelationship: payeeInfo === "sponsor" ? sponsorName : undefined,
        sponsorImage: payeeInfo === "sponsor" ? sponsorImage : undefined,
      };

      const response = await axiosService.post(
        `${AUTH_URLS.POST_PAYMENT}`,
        data
      );
      if (response.status === 200) {
        const baseUrl =
          "https://web.na.bambora.com/scripts/payment/payment.asp?";
        const merchantId = AppConfig?.merchantId;
        const hashKey = AppConfig?.hashKey;
        const params =
          "merchant_id=" +
          merchantId +
          "&trnOrderNumber=" +
          response?.data?.data?.trnOrderNumber +
          "&trnAmount=" +
          totalAmount;

        const hashcodeStr = params + hashKey;
        const buffer = new TextEncoder("utf-8").encode(hashcodeStr);
        const digest = await crypto.subtle.digest("SHA-1", buffer);
        const result = Array.from(new Uint8Array(digest))
          .map((x) => x.toString(16).padStart(2, "0"))
          .join("");

        const redirectUrl = baseUrl + params + "&hashValue=" + result;
        window.location.href = redirectUrl;
      }
    }
  };
  const handlePaymentForChange = (event) => {
    setPaymentFor(event.target.value);
  };
  const handlePayeeInfoChange = (event) => {
    setPayeeInfo(event.target.value);
  };

  useEffect(() => {
    if (amount > 0) {
      const serviceCharge = (amount * 0.05).toFixed(2);
      setServiceProviderCharges(serviceCharge);
    }
  }, [amount]);

  useEffect(() => {
    const total = Number(amount) + Number(serviceProviderCharges);
    setTotalAmount(total);
  }, [amount, serviceProviderCharges]);

  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }

        sessionService.isLoading("true");
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );
        if (response.status === 200) {
          setSponsorImage(response?.data?.data);
        }
      } catch (error) {
        toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
      }
    }
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (
      fullName === "" ||
      email === "" ||
      mobileNumber === "" ||
      dob === "" ||
      selectedCountry === null ||
      paymentFor === "" ||
      payeeInfo === "" ||
      !amount ||
      !check1 ||
      !check2 ||
      !check3 ||
      !check4
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    fullName,
    email,
    mobileNumber,
    dob,
    selectedCountry,
    amount,
    check1,
    check2,
    check3,
    check4,
    paymentFor,
    payeeInfo,
  ]);

  return (
    <div className="payment-gateway-home-container">
      <div className="row d-flex justify-content-center g-0">
        <div className="col-xl-7 col-lg-8 col-md-9 col-11">
          <div className="d-flex justify-content-center">
            <img src={beta_logo} />
          </div>

          <div className="payment-form-container">
            <h3 className="text-center text-decoration-underline">
              PAYMENT PAGE
            </h3>
            <p className="text-center fw-bold">
              Please use this page to make your application fee payment, tuition
              deposit, or a miscellaneous payment. All amounts are in Canadian
              Dollars.
            </p>

            <div className="">
              <label className="payment-gateway-input-label">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                className="payment-gateway-input"
                placeholder="As per your passport name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className="payment-gateway-input-container">
              <div>
                <label className="payment-gateway-input-label">
                  Email Address <span className="text-danger">*</span>
                </label>
                <input
                  className="payment-gateway-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label className="payment-gateway-input-label">
                  Date of Birth <span className="text-danger">*</span>
                </label>
                <input
                  className="payment-gateway-input"
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>

            <div className="payment-gateway-input-container">
              <div>
                <label className="payment-gateway-input-label">
                  Mobile number <span className="text-danger">*</span>
                </label>
                <PhoneInput
                  inputStyle={{ paddingLeft: "45px" }}
                  country={selectedCountryCode}
                  value={mobileNumber}
                  onChange={(value, country, e, formattedValue) => {
                    setMobileNumber(formattedValue);
                    setSelectedCountryCode(country.dialCode);
                  }}
                  inputProps={{
                    className: "payment-gateway-input",
                    placeholder: "",
                  }}
                />
              </div>
              <div>
                <label className="payment-gateway-input-label">
                  Country of Citizenship <span className="text-danger">*</span>
                </label>

                <Select
                  options={countries}
                  styles={selectStyles}
                  value={selectedCountry}
                  onChange={(selectedOption) => {
                    setSelectedCountry(selectedOption);
                  }}
                  isSearchable={true}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  placeholder="Choose"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            </div>

            <div>
              <h6 style={{ fontSize: "1.25rem" }}>I want to pay</h6>

              <div className="d-flex flex-column gap-1 ps-4">
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="Admissions Fee"
                    checked={paymentFor === "Admissions Fee"}
                    onChange={handlePaymentForChange}
                  />
                  <label>Admissions Fee</label>
                </div>
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="Tuition/course Fee"
                    checked={paymentFor === "Tuition/course Fee"}
                    onChange={handlePaymentForChange}
                  />
                  <label>Tuition/course Fee</label>
                </div>
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="Program Balance"
                    checked={paymentFor === "Program Balance"}
                    onChange={handlePaymentForChange}
                  />
                  <label>Program Balance</label>
                </div>
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="Student Activity Fee"
                    checked={paymentFor === "Student Activity Fee"}
                    onChange={handlePaymentForChange}
                  />
                  <label>Student Activity Fee</label>
                </div>
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="Other"
                    checked={paymentFor === "Other"}
                    onChange={handlePaymentForChange}
                  />
                  <label>Other</label>
                </div>
              </div>
            </div>

            <div className="payment-gateway-input-container">
              <div>
                <label className="payment-gateway-input-label">
                  Amount in CAD $ <span className="text-danger">*</span>
                </label>
                <input
                  className="payment-gateway-input"
                  value={amount}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/g.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setAmount(e.target.value);
                    }
                  }}
                />
              </div>
              <div>
                <label className="payment-gateway-input-label">
                  Service Provider Charges : ${" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="payment-gateway-input"
                  disabled="true"
                  type="number"
                  value={serviceProviderCharges}
                />
              </div>
              <div>
                <label
                  className="payment-gateway-input-label"
                  style={{ fontWeight: 700 }}
                >
                  Total Amount : $ <span className="text-danger">*</span>
                </label>
                <input
                  value={totalAmount}
                  className="payment-gateway-input"
                  disabled="true"
                  type="number"
                />
              </div>
            </div>

            <div>
              <h5>Payee Information</h5>

              <div className="d-flex flex-column gap-1 ps-4">
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="own"
                    checked={payeeInfo === "own"}
                    onChange={handlePayeeInfoChange}
                  />
                  <label>
                    I am using my own card, and bank details to make the payment
                  </label>
                </div>
                <div className="d-flex align-items-baseline gap-1">
                  <input
                    type="radio"
                    value="sponsor"
                    checked={payeeInfo === "sponsor"}
                    onChange={handlePayeeInfoChange}
                  />
                  <label>
                    I am using my sponsor’s card, and bank details to make the
                    payment
                  </label>
                </div>
              </div>

              {payeeInfo === "sponsor" && (
                <div className="d-flex flex-column gap-2 my-2">
                  <h4>Relationship with the student</h4>
                  <input
                    className="payment-gateway-input"
                    style={{ width: "40%" }}
                    value={sponsorName}
                    onChange={(e) => {
                      setSponsorName(e.target.value);
                    }}
                  />

                  <div>
                    <label>Upload a copy of sponsor's photo ID</label>
                    {/* <input
                      type="file"
                      className="payment-gateway-input col-6"
                      style={{ width: "60%" }}
                      value={sponsorImage}
                      onChange={(e) => {
                        setSponsorImage(e.target.value);
                      Name  setSponsorImageName(e.target.value);
                      }}
                    /> */}

                    <div>
                      <Dropzone
                        onDrop={onDrop}
                        accept="image/*"
                        multiple={false}
                        maxSize={2097152}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            // className="payment-gateway-input col-6"
                            // style={{ width: "60%" }}
                          >
                            <input
                              {...getInputProps()}
                              className="payment-gateway-input col-6"
                              style={{ width: "60%" }}
                            />
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex flex-column gap-1 ps-4">
                <div className="d-flex align-items-baseline gap-1 mt-2">
                  <input
                    type="checkbox"
                    checked={check1}
                    onChange={(e) => {
                      setCheck1(e.target.checked);
                    }}
                  />
                  <label>
                    I understand that by submitting this form I am fully
                    responsible for the entire amount of the payment and is
                    nowhere responsible for any glitch, or non-receipt of
                    payment and any other issues faced during this payment
                    process.
                  </label>
                </div>
                <div className="d-flex align-items-baseline gap-1 mt-2">
                  <input
                    type="checkbox"
                    checked={check2}
                    onChange={(e) => setCheck2(e.target.checked)}
                  />
                  <label>
                    I understand that my credit card and personal information
                    are fully protected and confidential. I further understand
                    that the personal details and payment information I have
                    provided on this form may be shared with departments of the
                    provincial and federal governments as by legislation and
                    regulation and will be received and reviewed by Beta College
                    of Business and Technology, online payment service providers
                    and banking partners. You must read the Beta College of
                    Business and Technology’s privacy policy before proceeding
                    with the payment options. This policy can be found{" "}
                    <a
                      href="https://mybeta.ca/privacy-policy/"
                      target="_blank"
                      className="here-a-link"
                      rel="noreferrer"
                    >
                      here.
                    </a>{" "}
                    By proceeding with the payment, you agree to and accept our
                    terms and conditions and policies.
                  </label>
                </div>
                <div className="d-flex align-items-baseline gap-1 mt-2">
                  <input
                    type="checkbox"
                    checked={check3}
                    onChange={(e) => setCheck3(e.target.checked)}
                  />
                  <label>
                    I understand that payments can be made using a Visa,
                    Mastercard, Maestro, and American Express. A non-refundable
                    5% service fee will apply. The service fee is billed at a
                    rate of 5% and is paid directly to and retained by the
                    online payment service provider. process.
                  </label>
                </div>
                <div className="d-flex align-items-baseline gap-1 mt-2">
                  <input
                    type="checkbox"
                    checked={check4}
                    onChange={(e) => setCheck4(e.target.checked)}
                  />
                  <label>
                    I have read and reviewed all the information and agree and
                    accept the terms and conditions and policies of Beta College
                    of Business and Technology, Ontario, Canada, and online
                    payment service provider, and the financial institution(s).
                  </label>
                </div>
              </div>
            </div>

            <div className="col-sm-6 align-items-center gap-3 d-flex flex-column justify-content-end mt-2 ms-auto">
              <button
                onClick={handleSubmit}
                disabled={disabled}
                className={`w-100 proceed-to-payment-button ${
                  disabled && "opacity-50"
                }`}
              >
                PROCEED TO PAYMENT
              </button>
              <div className="d-flex justify-content-end gap-1">
                <i
                  class="fa fa-cc-visa"
                  style={{ color: "navy", fontSize: "28px" }}
                ></i>
                <i
                  class="fa fa-cc-amex"
                  style={{ color: "blue", fontSize: "28px" }}
                ></i>
                <i
                  class="fa fa-cc-mastercard"
                  style={{ color: "red", fontSize: "28px" }}
                ></i>
                <i
                  class="fa fa-cc-discover"
                  style={{ color: "orange", fontSize: "28px" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentGatewayHome;
