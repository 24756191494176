import { Field, FieldArray, Form, Formik } from "formik";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./AddStartDateProgram.css";
import { ADMISSION_URL_FOR_STUDENT } from "../../../../../pre-onboarding/components/constants/admission-application-student-urls";
import { useEffect, useState } from "react";
import DeleteIcon from "assets/image-components/DeleteIcon";
import { USER_URL } from "modules/user-management/constants/user-urls";

import { useLocation, useNavigate } from "react-router-dom";
function ViewIndividualDate() {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state;

  const programDateId = Data?.data?.Data?.programDateId;
  const [startDate, setStartDate] = useState([""]);

  const [programDataEdit, setProgramDataEdit] = useState("");
  useEffect(() => {
    if (programDateId) {
      axiosService
        .get(`${USER_URL.POST_STARTDATE_PROGRAM}/${programDateId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setStartDate(responseData?.startDates);

            setProgramDataEdit(responseData);
          }
        });
    }
  }, [location.pathname, programDateId]);

  const initialValues = {
    program: "",
    campus: "",
    startDates: [],
  };
  const validationSchema = Yup.object().shape({});

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "210px !important",
    }),
  };
  const selectStylesProgram = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "445px !important",
    }),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form>
          <div className="create-admission-main-container px-3">
            <div className="progress-container p-0 py-2">
              <div className="create-admission-head-text">Add Dates</div>
            </div>

            <div className="container-fluid g-0">
              <div className="create-program-main-input-container">
                <div className="create-admission-inner-first-input-container">
                  <label className="label-text-active width-label-program">
                    Program
                  </label>
                  <Field
                    name="program"
                    render={({ field, form }) => (
                      <Select
                        id="program"
                        value={
                          programDataEdit
                            ? {
                                label:
                                  programDataEdit.programFeeMaster?.program,
                                value:
                                  programDataEdit.programFeeMaster?.program,
                              }
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                          form.setFieldTouched(field.name, null);
                        }}
                        isDisabled={true}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStylesProgram}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="create-admission-inner-first-input-container">
                  <label className="label-text-active width-label-program">
                    Campus
                  </label>
                  <Field
                    name="campus"
                    render={({ field, form }) => (
                      <Select
                        id="campus"
                        value={
                          programDataEdit
                            ? {
                                label: programDataEdit.programFeeMaster?.campus,
                                value: programDataEdit.programFeeMaster?.campus,
                              }
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                          form.setFieldTouched(field.name, null);
                        }}
                        isDisabled={true}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStyles}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <FieldArray name="startDates">
                {(arrayHelpers) => (
                  <div>
                    {startDate.map((date, index) => (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          {index === 0 && (
                            <label className="label-text-active width-label-program">
                              Start Date
                            </label>
                          )}
                          <div
                            style={{ marginTop: index === 0 ? "0" : "10px" }}
                          >
                            <Field
                              type="date"
                              className="form-control3"
                              name={`startDates.${index}`}
                              value={date}
                              readOnly={true}
                              onChange={(e) => {
                                const updatedStartDate = [...startDate];
                                updatedStartDate[index] = e.target.value;
                                setStartDate(updatedStartDate);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <div className="d-flex align-items-center gap-3 mt-3">
                <button
                  type="button"
                  className="communication-management-cancel-button"
                  onClick={() => navigate("/academics-management/lookup")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ViewIndividualDate;
