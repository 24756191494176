import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "services/axios.service";
import "./ViewTask.css";
import { TASK_URLS } from "../../constants/TaskUrls";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { TASK_DELETE_CONSTANTS } from "../../constants/task-constants";
import { FiExternalLink } from "react-icons/fi";

function ViewTask() {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axiosService.get(`${TASK_URLS.GET_ALL_TASKS}`).then((response) => {
      const data = response?.data?.data;
      setData(data);
    });
  }, [location.pathname, refresh]);

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: "Subject",
      field: "subject",
    },
    {
      headerName: "Due Date",
      field: "dueDate",
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Related To",
      cellRenderer: function (params) {
        return (
          <span className="text-underline-hover">
            {params?.data?.relatedTo?.firstName}{" "}
            {params?.data?.relatedTo?.lastName} <FiExternalLink />
          </span>
        );
      },

      cellClass: "cursor-pointer-ag-grid",
    },
    {
      headerName: "Task Owner",
      field: "taskOwner.firstName",
    },
    {
      headerName: "Priority",
      field: "priority",
    },
  ]);

  function onSelectionChanged() {
    var selectedNodes = gridOptions.api.getSelectedNodes();
    var selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setSelectedTask(selectedData[0].taskId);
    } else {
      setSelectedTask(null);
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const onCellClicked = (params) => {
    if (params.colDef.headerName === "Related To") {
      navigate("/lead-management/view-leads", {
        state: { leadId: params?.data?.relatedTo?.leadId },
      });
    } else {
      navigate("/lead-management/create-task", {
        state: { taskId: params.data.taskId },
      });
    }
  };
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
    onSelectionChanged: onSelectionChanged,
  };
  const deleteTask = () => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Task?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${TASK_URLS.DELETE_TASKS}${selectedTask}`)
          .then((response) => {
            if (response.status === 200) {
              setRefresh(!refresh);
              toast.success(TASK_DELETE_CONSTANTS.SUCCESS);
            } else {
              toast.error(TASK_DELETE_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            toast.error(TASK_DELETE_CONSTANTS.FAILED);
          });
      }
    });
  };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Task</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <button
            className="lead-table-head-button"
            onClick={() => navigate("/lead-management/create-task")}
          >
            Create Task
          </button>

          <Dropdown>
            <Dropdown.Toggle
              className="lead-table-head-button"
              id="dropdown-basic"
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0">
              <Dropdown.Item
                onClick={() => {
                  if (selectedTask) {
                    deleteTask();
                  } else {
                    toast.warn("Please Select Task");
                  }
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onCellClicked={onCellClicked}
          // onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ViewTask;
