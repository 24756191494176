import { AppConfig } from "env.development";

export const ATTENDANCE_MANAGEMENT_URLS = {
  GET_BY_FACULTY_ID: `${AppConfig.api_url}attendance/v1/faculty`,
  GET_BY_STUDENT_ID: `${AppConfig.api_url}attendance/v2/student`,
  GET_ALL_FACULTY: `${AppConfig.api_url}schedule/v1/faculty/all`,
  GET_ALL: `${AppConfig.api_url}schedule/v1/student-all`,
  GET_MY_CLASS_BY_ID: `${AppConfig.api_url}schedule/v1/student/all/`,
  GET_ALL_STUDENTS: `${AppConfig.api_url}schedule/v1/all/students`,
  POST_ATTENDANCE: `${AppConfig.api_url}attendance/v1`,
  POST_FACULTY_PRESENT_ATTENDANCE: `${AppConfig.api_url}attendance/v1/faculty-present`,
  POST_FACULTY_ABSENT_ATTENDANCE: `${AppConfig.api_url}attendance/v1/faculty-absent`,
  GET_ATTENDANCE_DATA_BY_ID: `${AppConfig.api_url}schedule/v1/get-split/`,
  POST_BULK_PRESENT: `${AppConfig.api_url}attendance/v1/multiple-present`,
  POST_BULK_ABSENT: `${AppConfig.api_url}attendance/v1/multiple-absent`,
};
