import React, { useEffect, useRef, useState } from "react";
import "./AdmissionSideBar.css";
import sessionstorageService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { FiExternalLink } from "react-icons/fi";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function AdmissionSideBar() {
  const location = useLocation();
  const data = location?.state?.data;
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const [allData, setAllData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [feeData, setFeeData] = useState(null);

  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const page = response?.data?.data?.pagePercentage;
          setPage(page);
          setAllData(response?.data?.data);
        });
    }

    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setPaymentData(data);
        });

      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setFeeData(data);
        });
    }
  }, []);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;

      switch (location.pathname) {
        case "/pre-onboarding/create-personal-data":
          swiper.slideTo(0);
          break;
        case "/pre-onboarding/create-mailing-address":
          swiper.slideTo(1);
          break;
        case "/pre-onboarding/create-guardian-details":
          swiper.slideTo(2);
          break;
        case "/pre-onboarding/create-academic-professional":
          swiper.slideTo(3);
          break;
        case "/pre-onboarding/create-employement-work-experience":
          swiper.slideTo(4);
          break;
        case "/pre-onboarding/create-other-information":
          swiper.slideTo(5);
          break;
        case "/pre-onboarding/create-list-upload-files":
          swiper.slideTo(6);
          break;
        case "/pre-onboarding/create-student-profile":
          swiper.slideTo(7);
          break;
        default:
          break;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <ul className="p-0 d-none flex-column d-lg-flex">
        <button
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-personal-data", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-personal-data");
            }
          }}
          className={`mt-0 ${
            page >= 0
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          }
         ${
           allData?.personalData?.isApproved &&
           "program-detail-list-text-approved"
         }
         ${
           allData?.personalData?.isRejected &&
           "program-detail-list-text-rejected"
         }
        
        ${
          location.pathname === "/pre-onboarding/create-personal-data" &&
          "program-detail-list-active"
        }`}
        >
          Personal Data
        </button>

        <button
          disabled={page < 20}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-mailing-address", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-mailing-address");
            }
          }}
          className={`${
            page >= 20
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${
          allData?.mailingAddress?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.mailingAddress?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-mailing-address" &&
          "program-detail-list-active"
        } `}
        >
          Mailing Address
        </button>

        <button
          disabled={page < 40}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-guardian-details", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-guardian-details");
            }
          }}
          className={`${
            page >= 40
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${
          allData?.guardianDetail?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.guardianDetail?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-guardian-details" &&
          "program-detail-list-active"
        } `}
        >
          Guardian Details
        </button>
        <button
          disabled={page < 50}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-academic-professional", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-academic-professional");
            }
          }}
          className={`${
            page >= 50
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${
          allData?.academics[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.academics[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname === "/pre-onboarding/create-academic-professional" &&
        "program-detail-list-active"
      } `}
        >
          Academic and Professional Qualifications
        </button>
        <button
          disabled={page < 60}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-employement-work-experience", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-employement-work-experience");
            }
          }}
          className={`${
            page >= 60
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${
          allData?.employments[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.employments[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname ===
          "/pre-onboarding/create-employement-work-experience" &&
        "program-detail-list-active"
      } `}
        >
          Employment and Work Experience
        </button>
        <button
          disabled={page < 70}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-other-information", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-other-information");
            }
          }}
          className={`${
            page >= 70
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${
          allData?.otherInformation?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.otherInformation?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-other-information" &&
          "program-detail-list-active"
        } `}
        >
          Other Information
        </button>
        <button
          disabled={page < 80}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-list-upload-files", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-list-upload-files");
            }
          }}
          className={`${
            page >= 80
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${allData?.upload?.isApproved && "program-detail-list-text-approved"}
        ${allData?.upload?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-list-upload-files" &&
          "program-detail-list-active"
        } `}
        >
          List of Uploaded files
        </button>
        <button
          disabled={page < 90}
          onClick={() => {
            if (data?.programId) {
              navigate("/pre-onboarding/create-student-profile", {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            } else {
              navigate("/pre-onboarding/create-student-profile");
            }
          }}
          className={`${
            page >= 90
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${allData?.profile?.isApproved && "program-detail-list-text-approved"}
        ${allData?.profile?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-student-profile" &&
          "program-detail-list-active"
        } `}
        >
          Student Profile
        </button>

        {data && (
          <button
            onClick={() =>
              navigate("/pre-onboarding/create-program-details", {
                state: { data: data },
              })
            }
            className={`program-detail-list-text-faculty text-decoration-underline
          ${
            feeData?.isApproved &&
            feeData?.programDetail?.isApproved &&
            paymentData?.isApproved &&
            "program-detail-list-text-approved"
          }
          ${
            (feeData?.isRejected ||
              feeData?.programDetail?.isRejected ||
              paymentData?.isRejected) &&
            "program-detail-list-text-rejected"
          }`}
          >
            Application <FiExternalLink />
          </button>
        )}
      </ul>

      <ul className="p-0 d-flex flex-column d-lg-none admission-sidebar-mobile">
        <Swiper
          ref={swiperRef}
          style={{ width: "100%", paddingRight: "10px", paddingLeft: "20px" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={10}
          slidesPerView="auto"
        >
          <SwiperSlide
            onClick={() => {
              if (data?.programId) {
                navigate("/pre-onboarding/create-personal-data", {
                  state: {
                    data: {
                      programId: data?.programId,
                    },
                  },
                });
              } else {
                navigate("/pre-onboarding/create-personal-data");
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 0
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            }
            ${
              allData?.personalData?.isApproved &&
              "program-detail-list-text-approved"
            }
            ${
              allData?.personalData?.isRejected &&
              "program-detail-list-text-rejected"
            }
        
            ${
              location.pathname === "/pre-onboarding/create-personal-data" &&
              "program-detail-list-active"
            }`}
          >
            Personal Data
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 20) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-mailing-address", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-mailing-address");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 20
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          allData?.mailingAddress?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.mailingAddress?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-mailing-address" &&
          "program-detail-list-active"
        } `}
          >
            Mailing Address
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 40) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-guardian-details", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-guardian-details");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 40
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          allData?.guardianDetail?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.guardianDetail?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-guardian-details" &&
          "program-detail-list-active"
        } `}
          >
            Guardian Details
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 50) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-academic-professional", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-academic-professional");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 50
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          allData?.academics[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.academics[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname === "/pre-onboarding/create-academic-professional" &&
        "program-detail-list-active"
      } `}
          >
            Academic and Professional Qualifications
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 60) {
                if (data?.programId) {
                  navigate(
                    "/pre-onboarding/create-employement-work-experience",
                    {
                      state: {
                        data: {
                          programId: data?.programId,
                        },
                      },
                    }
                  );
                } else {
                  navigate(
                    "/pre-onboarding/create-employement-work-experience"
                  );
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 60
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          allData?.employments[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.employments[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname ===
          "/pre-onboarding/create-employement-work-experience" &&
        "program-detail-list-active"
      } `}
          >
            Employment and Work Experience
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 70) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-other-information", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-other-information");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 70
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          allData?.otherInformation?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          allData?.otherInformation?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/create-other-information" &&
          "program-detail-list-active"
        } `}
          >
            Other Information
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 80) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-list-upload-files", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-list-upload-files");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 80
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${allData?.upload?.isApproved && "program-detail-list-text-approved"}
        ${allData?.upload?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-list-upload-files" &&
          "program-detail-list-active"
        } `}
          >
            List of Uploaded files
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              if (page > 90) {
                if (data?.programId) {
                  navigate("/pre-onboarding/create-student-profile", {
                    state: {
                      data: {
                        programId: data?.programId,
                      },
                    },
                  });
                } else {
                  navigate("/pre-onboarding/create-student-profile");
                }
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              page >= 90
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${allData?.profile?.isApproved && "program-detail-list-text-approved"}
        ${allData?.profile?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-student-profile" &&
          "program-detail-list-active"
        } `}
          >
            Student Profile
          </SwiperSlide>
          {data && (
            <SwiperSlide
              onClick={() =>
                navigate("/pre-onboarding/create-program-details", {
                  state: { data: data },
                })
              }
              className={`sidebar-mobile-swiper-text-container program-detail-list-text-faculty text-decoration-underline
          ${
            feeData?.isApproved &&
            feeData?.programDetail?.isApproved &&
            paymentData?.isApproved &&
            "program-detail-list-text-approved"
          }
          ${
            (feeData?.isRejected ||
              feeData?.programDetail?.isRejected ||
              paymentData?.isRejected) &&
            "program-detail-list-text-rejected"
          }`}
            >
              Application <FiExternalLink />
            </SwiperSlide>
          )}
        </Swiper>
      </ul>
    </>
  );
}

export default AdmissionSideBar;
