import { Field, Form, Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import sessionstorageService from "services/sessionstorage.service";
import { useEffect, useState } from "react";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "./CreateGuardianDetails.css";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import PhoneInput from "react-phone-input-2";
import sessionService from "services/sessionstorage.service";

function CreateGuardianDetails() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;

  const [admissionData, setAdmissionData] = useState(null);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.guardianDetail?.isApproved);
          setRejected(data?.guardianDetail?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
        });
    }
  }, [location.pathname]);

  const initialValues = {
    firstName: "",
    lastName: "",
    relationship: "",
    occupation: "",
    telephone: "",
    email: "",
    address: "",
    guardianId: admissionData?.guardianDetail?.guardianId,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("FirstName is required"),
    lastName: Yup.string().required("LastName is required"),
    relationship: Yup.string().required("Relationship is required"),
    occupation: Yup.string().required("Occupation is required"),
    telephone: Yup.string().required("Telephone Number is required"),
  });

  const navigateToGuardian = () => {
    navigation("/pre-onboarding/create-mailing-address", {
      state: {
        data: programId,
      },
    });
  };
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;
  const onSubmit = async (values, { setSubmitting }) => {
    const dataType = "guardian";

    values.isRejected = false;

    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: values,
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.GUARDIAN_SUCCESS);
        navigation("/pre-onboarding/create-academic-professional", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.GUARDIAN_FAILURE);
      }
      setSubmitting(false);
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.GUARDIAN_ERROR);
      }
    }
  };

  const dateString = new Date(admissionData?.guardianDetail?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleClear = async (formik) => {
    formik.resetForm();
  };
  const selectedCountryCode = "ca";
  return (
    <Formik
      initialValues={admissionData?.guardianDetail || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>

              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Guardian Details</div>
                <div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">First Name *</label>
                      <Field
                        name="firstName"
                        className="create-admission-input-big"
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="error firstNamess guardianFirstName">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Last Name *</label>
                      <Field
                        name="lastName"
                        className="create-admission-input-big"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="error lastNamess">
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Relationship *
                      </label>
                      <Field
                        name="relationship"
                        className="create-admission-input-big"
                      />
                      {formik.touched.relationship &&
                      formik.errors.relationship ? (
                        <div className="error relationshipss">
                          {formik.errors.relationship}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Occupation *</label>
                      <Field
                        name="occupation"
                        className="create-admission-input-big"
                      />
                      {formik.touched.occupation && formik.errors.occupation ? (
                        <div className="error occupationss">
                          {formik.errors.occupation}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Telephone *</label>
                      {/* <Field
                        name="telephone"
                        className="create-admission-input-big"
                      /> */}
                      <Field name="telephone">
                        {({ field, form }) => (
                          <PhoneInput
                            country={selectedCountryCode}
                            enableSearch={true}
                            value={field.value}
                            onChange={(value, country, e, formattedValue) => {
                              form.setFieldValue("telephone", formattedValue);
                            }}
                            inputProps={{
                              className:
                                "create-admission-input-big1 phone-input1",
                              placeholder: "Valid Mobile number",
                            }}
                          />
                        )}
                      </Field>
                      {formik.touched.telephone && formik.errors.telephone ? (
                        <div className="error telephoness">
                          {formik.errors.telephone}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Email</label>
                      <Field
                        name="email"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Complete Address
                      </label>
                      <Field
                        name="address"
                        component="textarea"
                        className="create-admission-input-big-2-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToGuardian}
                  >
                    Previous
                  </button>

                  {!admissionData?.guardianDetail?.guardianId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      disabled={formik.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        disabled={formik.isSubmitting}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {!admissionData?.guardianDetail?.guardianId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(formik)}
                    >
                      Clear
                    </button>
                  )}

                  {(admissionData?.guardianDetail?.guardianId ||
                    admissionData?.guardianDetail?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation(
                          "/pre-onboarding/create-academic-professional",
                          {
                            state: {
                              data: programId,
                            },
                          }
                        )
                      }
                    >
                      Next
                    </button>
                  )}
                </div>

                {admissionData?.guardianDetail?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.guardianDetail?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.guardianDetail?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateGuardianDetails;
