import { Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateAcademicProfessional.css";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";

function CreateAcademicProfessional() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const navigation = useNavigate();
  const location = useLocation();
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const data = location?.state?.data;
  const [refresh, setRefresh] = useState(false);
  const [admissionData, setAdmissionData] = useState();
  let academicsInitialValues;

  if (admissionData) {
    academicsInitialValues = admissionData?.academics?.map((academic) => ({
      qualificationName: academic.qualificationName || "",
      completionYear: academic.completionYear || "",
      instituteName: academic.instituteName || "",
      country: academic.country || "",
    }));
  } else {
    academicsInitialValues = [
      {
        qualificationName: "",
        completionYear: "",
        instituteName: "",
        country: "",
      },
    ];
  }

  const initialValues = {
    academics: academicsInitialValues,
  };
  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.academics[0]?.isApproved);
        });
    }
  }, [location.pathname, refresh]);

  const navigateToGuardian = () => {
    navigation("/admission-management/create-guardian-details", {
      state: { data: data },
    });
  };
  const navigateToEmployment = () => {
    navigation("/admission-management/create-employement-work-experience", {
      state: { data: data },
    });
  };

  const onSubmit = async (values) => {
    navigation("/admission-management/create-employement-work-experience", {
      state: { data: data },
    });
  };

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.academics;
    const admissionId = {
      admissionId: admissionData?.admissionId,
    };
    for (let i = 0; i < requestData.length; i++) {
      requestData[i].isApproved = true;
      requestData[i].isRejected = false;
      requestData[i].rejectComments = "";
      requestData[i].admission = admissionId;
    }
    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_ACADEMIC}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success(
          "Academics and Professional Qualifications Approved Successfully"
        );
        navigateToEmployment();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.academics;
    const admissionId = {
      admissionId: admissionData?.admissionId,
    };
    for (let i = 0; i < requestData.length; i++) {
      requestData[i].isApproved = false;
      requestData[i].isRejected = true;
      requestData[i].rejectComments = rejectComments;
      requestData[i].admission = admissionId;
    }
    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_ACADEMIC}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Academics and Professional Qualifications Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };
  const dateString = new Date(admissionData?.academics[0]?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails[0].applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>

              <div className="pre-create-employement-work-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title">
                  Academic and Professional Qualifications
                </div>
                <div className="scroll-con">
                  <FieldArray name="academics">
                    {({ push, remove }) => (
                      <div>
                        {values?.academics?.map((academics, index) => (
                          <div key={index}>
                            <div className="create-employement-work-main-input-container">
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Qualification Name *
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  name={`academics[${index}].qualificationName`}
                                  className="create-admission-input-big"
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Completion Year *
                                  </label>
                                ) : null}

                                <Field
                                  disabled={true}
                                  name={`academics[${index}].completionYear`}
                                  className="create-admission-input-small"
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Name of Institution *
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  name={`academics[${index}].instituteName`}
                                  className="create-admission-input-big"
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Country *
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  name={`academics[${index}].country`}
                                  className="create-admission-input-big"
                                />
                              </div>
                              {values.academics.length > 1 && (
                                <button
                                  disabled={true}
                                  type="button"
                                  className="create-admission-remove-row"
                                  onClick={() => remove(index)}
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    onClick={navigateToGuardian}
                    type="button"
                    className="create-admission-previous-button"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="create-admission-save-button"
                    onClick={navigateToEmployment}
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.academics[0]?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.academics[0]?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.academics[0]?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateAcademicProfessional;
