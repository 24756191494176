import React, { useEffect, useState } from "react";
import "./StudentPortalPartialTranscript.css";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";
import sessionService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";

function StudentPortalPartialTranscript() {
  const userData = sessionService.getUserData("userData");
  const studentId = userData?.admissionManagement?.personalData?.studentId;
  const [admissionData, setAdmissionData] = useState("");
  const [totalModules, setTotalModules] = useState();
  const [totalCredits, setTotalCredits] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosService
      .get(
        `${STUDENT_PORTAL_URL.GET_STUDENT_MODULE_MARKS}?studentId=${studentId}`
      )
      .then((response) => {
        const data = response?.data?.data;
        setData(data);
      })
      .catch(() => {});
  }, [studentId]);
  useEffect(() => {
    axiosService
      .get(`${STUDENT_PORTAL_URL.GET_PROFILE_DATA_BY_ID}${studentId}`)
      .then((response) => {
        const data = response?.data?.data?.admission?.programDetails[0];
        setAdmissionData(data);

        setTotalModules(response?.data?.data?.totalModules);
        setTotalCredits(response?.data?.data?.totalCredits);
      })
      .catch(() => {});
  }, [studentId]);
  return (
    <div className="student-portal-service-container custom-scrollbar">
      <div className="student-ps-top">
        <h4>Partial Transcript</h4>
      </div>
      <div className="row d-flex">
        <div className="col-12 col-lg-9 mt-2">
          <div className="partial-heading">Program Modules</div>
          <div className=" student-portal-inner-main-container custom-scrollbar">
            {data ? (
              <table style={{ width: "100%" }}>
                <thead className="table-header-student-portal">
                  <tr>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">Module Code</span>
                      </div>
                    </th>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">Module Name</span>
                      </div>
                    </th>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">%</span>
                      </div>
                    </th>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">Grade</span>
                      </div>
                    </th>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">Passed</span>
                      </div>
                    </th>
                    <th className="pe-3">
                      <div className="table-head-student-portal">
                        <span className="program-info-head">Completed</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((data) => (
                    <tr key={data?.markId}>
                      <td className="table-data-student-portal">
                        {data?.module?.module?.moduleCode}
                      </td>
                      <td className="table-data-student-portal">
                        {data?.module?.module?.moduleName}
                      </td>
                      <td className="table-data-student-portal">
                        {data?.markPercentage}
                      </td>
                      <td className="table-data-student-portal">
                        {data?.grade}
                      </td>
                      <td className="table-data-student-portal">
                        {data?.result}
                      </td>
                      <td className="table-data-student-portal">
                        {data?.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="no-entries-made">No entries made</p>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-3 mt-2">
          <div className="partial-heading">Program Information</div>

          <span className="d-flex row mt-3">
            <span className="program-info-head">Name</span>
            <span className="program-info-sub-head">
              {admissionData?.program || "-"}
            </span>
          </span>
          <span className="d-flex row mt-3">
            <span className="program-info-head">Duration</span>
            <span className="program-info-sub-head">
              {admissionData?.programDuration || "-"}
            </span>
          </span>
          <span className="d-flex row mt-3">
            <span className="program-info-head">Total Modules</span>
            <span className="program-info-sub-head">{totalModules}</span>
          </span>
          <span className="d-flex row mt-3">
            <span className="program-info-head">Total Credits</span>
            <span className="program-info-sub-head">{totalCredits}</span>
          </span>
          <span className="d-flex row mt-3">
            <span className="program-info-head">Grading Key</span>
            <span className="program-info-sub-head">
              A+ (90-100%) A (80-89%) <br />
              B+ (75-79%) B (70-74%)
              <br />
              C+ (65-69%) C (60-64%)
              <br />
              D+ (55-59%) D (50-54%)
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
export default StudentPortalPartialTranscript;
