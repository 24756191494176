export const NEWS_AFFAIR_CONSTANTS = {
  SUCCESS: "News saved successfully",
  UPDATE: "News updated successfully",
  FAILED: "News Failed to Save",
  ERROR_OCCUR:
    "An unexpected error occurred during News. Please contact admin/support",
  SUCCESS_IMAGE_UPLOAD: "Upload file successful!",
  UPLOAD_FILE_FAILURE: "Upload file Error!",
  IMAGE_LENGTH: "Image name exceeds the maximum length characters.",
};
