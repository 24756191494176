import React from "react";

const StudentPortalFeedbackHeader = ({ datas, studentData }) => {
  return (
    <div className="student-ps-top-feedback">
      <span className="communication-management-title-text">Feedback</span>

      <div className="d-flex align-items-center gap-3">
        <div>
          <div className="student-portal-feedback-header-right-title">
            Total Modules
          </div>
          <div className="student-portal-feedback-header-right-value">
            {datas.length > 1
              ? `${datas.length} modules`
              : `${datas.length} module`}
          </div>
        </div>
        <div>
          <div className="student-portal-feedback-header-right-title">
            Program Name
          </div>
          <div className="student-portal-feedback-header-right-value">
            {studentData?.programDetails[0]?.program}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPortalFeedbackHeader;
