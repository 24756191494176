import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";

function CreateOtherInformation() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const navigation = useNavigate();
  const location = useLocation();

  const [refresh, setRefresh] = useState(false);
  const data = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const navigateToEmployment = () => {
    navigation("/admission-management/create-employement-work-experience", {
      state: { data: data },
    });
  };
  const navigateToUpload = () => {
    navigation("/admission-management/create-list-upload-files", {
      state: { data: data },
    });
  };
  const initialValues = {
    specialOffer: admissionData?.otherInformation?.specialOffer || "",
    enrolledStaff:
      admissionData?.otherInformation?.enrolledStaff?.enrolledStaffName || "",
    consultantAddress: admissionData?.otherInformation?.consultantAddress || "",
    mobileNumber: admissionData?.otherInformation?.mobileNumber || "",
    email: admissionData?.otherInformation?.email || "",
  };

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.otherInformation.isApproved);
        });
    }
  }, [location.pathname]);

  const validationSchema = Yup.object().shape({});
  const onSubmit = async (values) => {
    navigation("/admission-management/create-list-upload-files", {
      state: { data: data },
    });
  };

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.otherInformation;
    requestData.isApproved = true;
    requestData.isRejected = false;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_OTHER}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Other Information Approved Successfully");
        navigateToUpload();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.otherInformation;
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_OTHER}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Other Information Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.otherInformation?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {() => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails[0].applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Other Information</div>
                <div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Special Offer</label>
                      <Field
                        disabled={true}
                        name="specialOffer"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Name of the Consultant/Staff enrolled by
                      </label>
                      <Field
                        disabled={true}
                        name="enrolledStaff"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Address of the consultant
                      </label>
                      <Field
                        disabled={true}
                        component="textarea"
                        name="consultantAddress"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Mobile No. of the consultant
                      </label>
                      <Field
                        disabled={true}
                        name="mobileNumber"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Email of the consultant
                      </label>
                      <Field
                        disabled={true}
                        name="email"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToEmployment}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="create-admission-save-button"
                    onClick={navigateToUpload}
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.otherInformation.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.otherInformation.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.otherInformation?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateOtherInformation;
