import "bootstrap/dist/css/bootstrap.min.css";
import { RegisterConstants } from "core/auth/constants/auth-constants";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionstorageService from "services/sessionstorage.service";
import crmlogo from "../../../../assets/images/crm-logo.svg";
import microsofticon from "../../../../assets/images/microsoft.svg";
import signupgroup from "../../../../assets/images/signup-group.png";
import { axiosService } from "../../../../services/axios.service";
import { isValidEmail } from "../../../../shared/validation/emailValidation";
import { isValidPassword } from "../../../../shared/validation/passwordValidation";
import { AUTH_URLS } from "../../constants/auth-urls";
import "./SignUp.css";

function SignUp() {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("ca");

  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    setFirstNameError("");
  };
  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    setLastNameError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    const newValue = e.target.value;
    setConfirmPassword(newValue);
    validateConfirmPassword(newValue);
  };

  const tooltipContent = (
    <span>
      <h6>Password Must</h6>
      Have one number
      <br />
      Have one uppercase character
      <br />
      Have one lowercase character
      <br />
      Have one special character
      <br />
      Have 8 characters minimum
    </span>
  );

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError(RegisterConstants.EMAIL_REQUIRED);
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError(RegisterConstants.INVALID_EMAIL);
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  const validateMobileNumber = () => {
    if (mobileNumber.trim() === "") {
      setMobileNumberError(RegisterConstants.MOBILE_NUMBER_REQUIRED);
      return false;
    } else {
      setMobileNumberError("");
      return true;
    }
  };

  const getLastSegmentFromHash = () => {
    const hash = window.location.hash;
    const segments = hash.split("/");
    return (segments.length > 2 ? segments[2] : "STAFF").toUpperCase();
  };
  const studentType = getLastSegmentFromHash();

  const validatePassword = () => {
    const validationResult = isValidPassword(password);
    if (password.trim() === "") {
      setPasswordError(RegisterConstants.PASSWORD_REQUIRED);
      return false;
    } else if (validationResult !== true) {
      setPasswordError(RegisterConstants.PASSWORD_VALID);
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const validateConfirmPassword = (newValue) => {
    if (newValue === "") {
      setConfirmPasswordError(RegisterConstants.CONFIRM_PASSWORD_REQUIRED);
      return false;
    } else if (password !== newValue) {
      setConfirmPasswordError(RegisterConstants.PASSWORD_NOT_MATCH);
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };

  const handleRegister = async () => {
    const isFirstNameValid = firstName.trim() !== "";
    const isLastNameValid = lastName.trim() !== "";
    validatePassword(password);
    validateConfirmPassword(confirmPassword);
    validateEmail(email);
    validateMobileNumber(mobileNumber);

    if (!isFirstNameValid) {
      setFirstNameError(RegisterConstants.FIRST_NAME_REQUIRED);
    }
    if (!isLastNameValid) {
      setLastNameError(RegisterConstants.LAST_NAME_REQUIRED);
    }
    if (!isChecked) {
      toast.error(RegisterConstants.AGREE_TERMS);
      return;
    }
    if (
      validateEmail(email) &&
      validatePassword(password) &&
      validateMobileNumber(mobileNumber) &&
      validateConfirmPassword(confirmPassword) &&
      isFirstNameValid &&
      isLastNameValid
    ) {
      const registrationData = {
        email,
        password,
        firstName,
        lastName,
        mobileNumber,
        confirmPassword,
        studentType,
      };
      try {
        setDisable(true);
        sessionstorageService.isLoading("true");
        sessionstorageService.removeToken();
        const registration = 1;
        const response = await axiosService.post(
          `${AUTH_URLS.REGISTER_URL}?registration=${registration}`,
          registrationData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        sessionstorageService.isLoading("true");
        if (response.status === 200) {
          sessionstorageService.isLoading("false");
          toast.success(RegisterConstants.REGISTER_SUCCESS);
          navigate(getLoginPath());
          setDisable(false);
        } else {
          sessionstorageService.isLoading("false");
          toast.error(RegisterConstants.REGISTER_FAILED);
          setDisable(false);
        }
      } catch (error) {
        setDisable(false);
        if (error.response) {
          if (error.response.data.message.split(":")[0] === "E_0001") {
            setEmailError(error.response.data.message.split(":")[1]?.trim());
          } else if (error.response.data.message.split(":")[0] === "E_0002") {
            setMobileNumberError(
              error.response.data.message.split(":")[1]?.trim()
            );
          }
          toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
        } else {
          toast.error(RegisterConstants.ERROR_OCCUR);
        }
      }
    }
  };
  const getLoginPath = () => {
    const hash = window.location.hash;

    if (hash === "#/") {
      return "/";
    } else if (hash.startsWith("#/signup/")) {
      const path = hash.replace("#/signup/", "");
      return `/${path}`;
    } else if (hash === "#/signup") {
      return "/";
    } else {
      return `/${hash.replace("#", "")}`;
    }
  };

  return (
    <div className="row g-0 auth-main-container">
      <div className="col-12 col-md-6 auth-container auth-text-animation justify-content-start">
        <span className="signup-logo-container">
          <img src={crmlogo} alt="logo" className="signup-logo-image" />
        </span>
        <div
          className="d-flex row g-0 flex-column align-items-center justify-content-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="signup-head-text-container col-10 col-lg-8 mb-3">
            {studentType !== "STAFF" && (
              <p style={{ color: "white" }}>
                Welcome{" "}
                {studentType === "INTERNATIONAL"
                  ? studentType.charAt(0).toUpperCase() +
                    studentType.slice(1).toLowerCase()
                  : studentType}{" "}
                Student
              </p>
            )}
            <span className="auth-title-text">Create an Account </span>
            <span className="auth-sub-title-text">
              Kindly fill in your details to create an account
            </span>
          </div>

          <div className="signup-input-main-container col-10  col-lg-8">
            <div className="row w-100 d-flex justify-content-between">
              <div className="col-12 col-sm-6 p-0">
                <div className="signup-input-container-half  column d-flex">
                  <label className="auth-label-text">Your firstname*</label>
                  <input
                    value={firstName}
                    onChange={handleFirstNameChange}
                    className="auth-input"
                    placeholder="John "
                  />
                  <span className="auth-login-error-text">
                    {firstNameError}
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-6 p-0">
                <div className="signup-input-container-half column d-flex">
                  <label className="auth-label-text">Your lastname*</label>
                  <input
                    value={lastName}
                    onChange={handleLastNameChange}
                    className="auth-input"
                    placeholder="James"
                  />
                  <span className="auth-login-error-text">{lastNameError}</span>
                </div>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-between">
              <div className="col-12 col-sm-6 p-0">
                <div className="signup-input-container-half column d-flex">
                  <label className="auth-label-text">Email address*</label>
                  <input
                    value={email}
                    onChange={handleEmailChange}
                    className="auth-input"
                    placeholder="james@email.com"
                  />
                  {emailError && (
                    <span className="auth-login-error-text">{emailError}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 p-0">
                <div className="signup-input-container-half column d-flex">
                  <label className="auth-label-text">Mobile Number*</label>
                  <PhoneInput
                    country={selectedCountryCode}
                    value={mobileNumber}
                    onChange={(value, country, e, formattedValue) => {
                      setMobileNumber(formattedValue);

                      setSelectedCountryCode(country.dialCode);
                    }}
                    inputProps={{
                      className: "auth-input-mobile",
                      placeholder: "",
                    }}
                  />

                  <span className="auth-login-error-text">
                    {mobileNumberError}
                  </span>
                </div>
              </div>
            </div>

            <div className="signup-input-container">
              <label className="auth-label-text">Create password*</label>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="password-tooltip" className="custom-tooltip">
                    {tooltipContent}
                  </Tooltip>
                }
                show={showTooltip}
              >
                <input
                  className="auth-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onFocus={toggleTooltip}
                  onBlur={toggleTooltip}
                  onChange={handlePasswordChange}
                />
              </OverlayTrigger>
              <span className="auth-login-error-text">{passwordError}</span>
              {showPassword ? (
                <AiFillEye
                  className="signup-eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="signup-eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>

            <div className="signup-input-container ">
              <label className="auth-label-text">Confirm password*</label>
              <input
                className="auth-input"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {confirmPasswordError && (
                <span className="auth-login-error-text">
                  {confirmPasswordError}
                </span>
              )}
              {showConfirmPassword ? (
                <AiFillEye
                  className="signup-eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="signup-eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            <div className="signup-checkbox-container ">
              <input
                onChange={handleCheckboxChange}
                checked={isChecked}
                className="auth-checkbox"
                type="checkbox"
              />
              <span className="auth-label-text">
                I agree to{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/TERMS+AND+CONDITIONS.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="auth-label-text"
                >
                  terms & conditions
                </a>
              </span>
            </div>
          </div>
          <div className="signup-button-container col-10  col-lg-8 ">
            <button
              className="auth-button"
              disabled={disable}
              onClick={handleRegister}
            >
              {disable ? (
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Register Account"
              )}
            </button>
            <div className=" text-center mt-2">
              <span className="auth-label-text">Already have an account? </span>
              <Link to={getLoginPath()} className="auth-sub-link-text">
                Log In
              </Link>
            </div>
            <span className="signup-or-container">
              <hr className="hr-or" />
              <span className="auth-label-text">Or</span>
              <hr className="hr-or" />
            </span>
            <button className="auth-google-button">
              <img
                alt="img"
                src={microsofticon}
                className="signup-google-icon"
              />
              Register with Microsoft
            </button>
          </div>
        </div>
      </div>

      <div className="d-none d-md-block col-6 signup-img-container">
        <img
          src={signupgroup}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default SignUp;
