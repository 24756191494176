import React from "react";
import "./AddStudentPortalParticipation.css";
import { Field, Formik, Form, ErrorMessage } from "formik";
import Modal from "react-modal";
import * as Yup from "yup";
import vector_image from "assets/images/StudentPortal/Add-can.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { STUDENT_PORTAL_PARTICIPATION_URL } from "modules/student-portal/constants/student-portal-urls";
import { STUDENT_PORTAL_PARTICIPATION_CONSTANTS } from "modules/student-portal/constants/student-portal-participation-constants";

function AddStudentPortalParticipation({ open, onClose }) {
  const [participationService] = useState("");
  const initialValues = {
    participationService: "",
    requestDate: new Date().toISOString().split("T")[0],
    comments: "",
  };

  const validationSchema = Yup.object().shape({
    participationService: Yup.string().required(
      "Participation type is required"
    ),
    requestDate: Yup.string().required("Request date is required"),
    comments: Yup.string().required("Comments is required"),
  });

  const onSubmit = async (values) => {
    try {
      const requestData = {
        participationService: values.participationService,
        requestDate: values.requestDate,
        comments: values.comments,
      };

      const response = await axiosService.post(
        `${STUDENT_PORTAL_PARTICIPATION_URL.POST_PARTICIPATION_URL}`,
        requestData
      );

      if (response.status === 200) {
        toast.success(STUDENT_PORTAL_PARTICIPATION_CONSTANTS.SUCCESS);
        onClose();
      } else {
        toast.error(`Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      toast.error("An error occurred while creating the Participation");
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="modal-Con justify-content-lg-end">
        <div
          className="col-11 col-md-7 col-lg-5 p-3"
          style={{ height: "auto", backgroundColor: "#333333" }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={participationService || initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <div className="asps-modal-top">
                    <span className="asps-participation-modal-title">
                      Add new Participation
                    </span>
                    <div>
                      <img
                        src={vector_image}
                        className="asps-close-icon"
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </div>
                  </div>
                  <div className="asps-participation-service">
                    <label>Participation Type *</label>
                    <Field
                      className="asps-participation-service-field"
                      name="participationService"
                      placeholder="Choose a Type"
                    />
                    <ErrorMessage
                      name="participationService"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-date">
                    <label>Date of Request *</label>
                    <Field
                      name="requestDate"
                      type="date"
                      className="asps-participation-date-field"
                      placeholder="yyyy/mm/dd"
                    />
                    <ErrorMessage
                      name="requestDate"
                      component="div"
                      className="sp-participation-error-message"
                    />
                  </div>
                  <div className="asps-participation-comments">
                    <label>
                      Comments/Queries if any *{" "}
                      <span>(max limit 250 chars)</span>
                    </label>
                    <Field
                      as="textarea"
                      className="asps-participation-comments-field"
                      maxLength={250}
                      name="comments"
                    />
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="sp-participation-comment-error-message"
                    />
                  </div>
                  <div className="asps-all-buttons d-flex gap-3">
                    <button
                      className="communication-management-cancel-button"
                      type="button"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="communication-management-submit-button"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddStudentPortalParticipation;
