import React from "react";

function ExamLogo({ color }) {
  return (
    <svg
      width="36"
      height="26"
      viewBox="26 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.406 19.9093L37.381 18.9344C37.6248 18.6906 37.6248 18.325 37.381 18.0812C37.1373 17.8375 36.7716 17.8375 36.5279 18.0812L35.5935 19.0562L34.6186 18.0812C34.3748 17.8375 34.0092 17.8375 33.7655 18.0812C33.5217 18.325 33.5217 18.6906 33.7655 18.9344L34.7404 19.9093L33.7655 20.8843C33.5217 21.1281 33.5217 21.4937 33.7655 21.7374C34.0092 21.9812 34.3748 21.9812 34.6186 21.7374L35.5935 20.7218L36.5685 21.6968C36.8123 21.9405 37.1779 21.9405 37.4216 21.6968C37.6654 21.4531 37.6654 21.0874 37.4216 20.8437L36.406 19.9093Z"
        fill={color}
      />
      <path
        d="M27.4686 9.74997H30.7186C30.9623 9.74997 31.1248 9.58747 31.1248 9.34373V8.53124C31.1248 8.2875 30.9623 8.125 30.7186 8.125H27.4686C27.2249 8.125 27.0624 8.2875 27.0624 8.53124V9.34373C27.0624 9.58747 27.2249 9.74997 27.4686 9.74997Z"
        fill={color}
      />
      <path
        d="M30.7186 17.875H27.4686C27.2249 17.875 27.0624 18.0375 27.0624 18.2812V19.0937C27.0624 19.3375 27.2249 19.5 27.4686 19.5H30.7186C30.9623 19.5 31.1248 19.3375 31.1248 19.0937V18.2812C31.1248 18.0375 30.9623 17.875 30.7186 17.875Z"
        fill={color}
      />
      <path
        d="M37.2184 14.625H27.4686C27.2249 14.625 27.0624 14.7875 27.0624 15.0312V15.8437C27.0624 16.0875 27.2249 16.25 27.4686 16.25H37.2184C37.4622 16.25 37.6247 16.0875 37.6247 15.8437V15.0312C37.6247 14.7875 37.4622 14.625 37.2184 14.625Z"
        fill={color}
      />
      <path
        d="M36.6498 8.28823L34.5779 10.3601L33.7654 9.54759C33.5217 9.30384 33.1561 9.30384 32.953 9.54759C32.7092 9.79133 32.7092 10.1569 32.953 10.3601L34.2123 11.6194C34.3342 11.7413 34.456 11.7819 34.6185 11.7819C34.781 11.7819 34.9029 11.7413 35.0248 11.6194L37.5029 9.14134C37.7466 8.8976 37.7466 8.53198 37.5029 8.32886C37.2185 8.08511 36.8529 8.08511 36.6498 8.28823Z"
        fill={color}
      />
      <path
        d="M48.9996 8.00463C48.9996 7.59839 48.8777 7.19214 48.634 6.82652L47.4559 5.12031C47.334 4.95781 47.1309 4.83594 46.9277 4.83594C46.7653 4.83594 46.6028 4.91719 46.4809 5.03906L45.3028 6.86715C45.0997 7.19214 44.9778 7.55776 44.9778 7.964V23.4418C44.9778 23.9699 45.1809 24.4981 45.5871 24.8637C45.9528 25.2293 46.4809 25.473 47.0496 25.473C48.0652 25.473 49.0808 24.4981 49.0808 23.4418L48.9996 8.00463ZM47.4965 23.9699C47.3746 24.0918 47.1715 24.1731 46.9684 24.1731C46.5621 24.1731 46.2371 23.8481 46.2371 23.4418V7.964C46.2371 7.84213 46.2778 7.67963 46.359 7.55776L46.9277 6.66403L47.5777 7.55776C47.659 7.67963 47.6996 7.80151 47.6996 7.964V23.4012C47.6996 23.645 47.6184 23.8481 47.4965 23.9699Z"
        fill={color}
      />
      <path
        d="M37.2184 4.875H27.4686C27.2249 4.875 27.0624 5.0375 27.0624 5.28124V6.09373C27.0624 6.33747 27.2249 6.49997 27.4686 6.49997H37.2184C37.4622 6.49997 37.6247 6.33747 37.6247 6.09373V5.28124C37.6247 5.0375 37.4622 4.875 37.2184 4.875Z"
        fill={color}
      />
      <path
        d="M38.4372 0.568739C38.0716 0.203121 37.5028 0 37.0154 0H24.625C23.7312 0 23 0.731236 23 1.62497V24.3745C23 25.2683 23.7312 25.9995 24.625 25.9995H40.8747C41.7684 25.9995 42.4996 25.2683 42.4996 24.3745V5.28115C42.4996 4.75303 42.2559 4.1843 41.8903 3.8593L38.4372 0.568739ZM41.1184 24.3745C41.1184 24.537 40.9965 24.6589 40.834 24.6589H24.625C24.4625 24.6589 24.3406 24.537 24.3406 24.3745V1.62497C24.3406 1.46247 24.4625 1.3406 24.625 1.3406H37.0154C37.1779 1.3406 37.381 1.42185 37.5028 1.54372L40.9559 4.79366C41.0778 4.91553 41.159 5.11865 41.159 5.24052V24.3745H41.1184Z"
        fill={color}
      />
    </svg>
  );
}

export default ExamLogo;
