import { EnqFormConstants } from "core/auth/constants/auth-constants";
import { AUTH_URLS } from "core/auth/constants/auth-urls";
import { useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { isValidEmail } from "shared/validation/emailValidation";
import { isValidPostalCode } from "shared/validation/postalCode.validation";
import crmlogo from "../../../../assets/images/crm-logo.svg";
import signupgroup from "../../../../assets/images/enquiry-student.png";
import "./EnquiryForm.css";

function SignUp() {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("ca");
  const [firstName, setFullName] = useState("");
  const [firstNameError, setFullNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeEror, setPostalCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isMailCheck, setIsMailCheck] = useState(false);

  const [number, setNumber] = useState("");
  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      isChecked !== false &&
      email !== "" &&
      number !== "" &&
      postalCode !== "" &&
      campus !== null &&
      program !== null &&
      currentStatus !== null
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [firstName, lastName, isChecked, email, phoneNumber, postalCode]);

  const validateEmail = (mail) => {
    if (mail.trim() === "") {
      setEmailError("Email is required");
    } else if (!isValidEmail(mail)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handlePhoneChange = (event, newCountry) => {
    const mobileNumber = event;
    if (mobileNumber.length < 3) {
      setPhoneNumberError("Phonenumber is required");
    } else {
      setPhoneNumberError("");
    }
    setNumber(mobileNumber);
    if (newCountry.dialCode === "1") {
      if (newCountry.dialCode === "Canada") {
        if (newCountry.countryCode === "us") {
          setSelectedCountry("us");
        } else if (newCountry.countryCode === "ca") {
          setSelectedCountry("ca");
        }
      } else {
        setSelectedCountry(newCountry.countryCode);
      }
    }
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
    validatePostalCode(event.target.value);
  };

  const validatePostalCode = (postalCode) => {
    if (postalCode.trim() === "") {
      setPostalCodeError("Postal code is required");
    } else if (!isValidPostalCode(postalCode)) {
      setPostalCodeError("Invalid postal code");
    } else {
      setPostalCodeError("");
    }
  };

  const getInfoNow = async (e) => {
    e.preventDefault();

    const validateField = (field, errorState, errorMessage) => {
      if (!field) {
        errorState(errorMessage);
        return false;
      }
      return true;
    };
    const isFullNameValid = validateField(
      firstName.trim(),
      setFullNameError,
      "FirstName is required"
    );
    const isCampusValid = validateField(
      campus,
      setSelectedCampusError,
      "Please select a Campus"
    );
    const isProgramValid = validateField(
      program,
      setSelectedProgramError,
      "Please select a Program"
    );
    const isLastNameValid = validateField(
      lastName,
      setLastNameError,
      "Lastname is required"
    );
    const isPhoneNumberValid = validateField(number, "Phonenumber is required");
    const isStatusValid = validateField(
      currentStatus,
      setSelectedStatusError,
      "Please select a Status"
    );
    const isPostalCodeValid = validateField(
      postalCode,
      setPostalCodeError,
      "Postal Code is required"
    );
    if (
      isFullNameValid &&
      isCampusValid &&
      isProgramValid &&
      isLastNameValid &&
      isPhoneNumberValid &&
      isStatusValid &&
      isPostalCodeValid
    ) {
      if (!isChecked) {
        toast.error(EnqFormConstants.AGREE_TERMS);
        return;
      }

      const enqFormData = {
        email,
        firstName,
        lastName,
        phoneNumber: number,
        postalCode,
        campus: campus ? campus.value : null,
        program: program ? program.value : null,
        currentStatus: currentStatus ? currentStatus.value : null,
      };

      try {
        const response = await axiosService.post(
          `${AUTH_URLS.ENQUIRY_FORM_URL}?isChecked=${isMailCheck}`,
          enqFormData
        );

        if (response.status === 200) {
          toast.success(EnqFormConstants.ENQUIRY_SUCCESS);

          setIsMailCheck(false);
          setFullName("");
          setLastName("");
          setEmail("");
          setSelectedCountry("ca");
          setPhoneNumber("+1");
          setPostalCode("");
          setSelectedCampus(null);
          setSelectedProgram(null);
          setSelectedStatus(null);
          setIsChecked(false);
          setFullNameError("");
          setLastNameError("");
          setEmailError("");
          setPostalCodeError("");
          setSelectedCampusError(null);
          setSelectedProgramError(null);
          setSelectedStatusError(null);
        } else {
          toast.error(EnqFormConstants.ENQUIRY_FAILED);
        }
      } catch (error) {
        if (error.response) {
          toast.error(
            `${error?.response?.data?.message.split(":")[1]?.trim()}`
          );
        } else {
          toast.error(EnqFormConstants.ERROR_OCCUR);
        }
      }
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      height: "40px",
      paddingRight: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      backgroundColor: state.isSelected ? "#000000" : "#ffffff",
      "&:hover": {
        backgroundColor: "#333333",
        color: "#ffffff",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontWeight: 400,
      color: "#BDBDBD",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#033ea1",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0em",
    }),
  };
  const options = [
    { value: "BRAMPTON", label: "Brampton" },
    { value: "MISSISSAUGA", label: "Mississauga" },
  ];
  const programOptions = [
    { value: "VOCATION", label: "Vocation" },
    { value: "NONVOCATIONAL", label: "Non-vocational" },
    { value: "CERTIFICATE", label: "Certificate" },
  ];

  const currentStatusOptions = [
    { value: "CITIZEN", label: "Citizen" },
    { value: "REFUGEE", label: "Refugee" },
    { value: "STUDYPERMIT", label: "Study Permit" },
    { value: "WORKPERMIT", label: "Work Permit" },
    { value: "INTERNATIONAL", label: "International" },
  ];

  const [campus, setSelectedCampus] = useState(null);
  const [selectedCampusError, setSelectedCampusError] = useState(null);
  const [program, setSelectedProgram] = useState(null);
  const [selectedProgramError, setSelectedProgramError] = useState(null);
  const [currentStatus, setSelectedStatus] = useState(null);
  const [selectedStatusError, setSelectedStatusError] = useState(null);

  const handleSelectChange = (selectedOption, stateKey) => {
    if (stateKey === "selectedCampus") {
      setSelectedCampus(selectedOption);
      setSelectedCampusError(null);
    } else if (stateKey === "selectedProgram") {
      setSelectedProgram(selectedOption);
      setSelectedProgramError(null);
    } else if (stateKey === "selectedStatus") {
      setSelectedStatus(selectedOption);
      setSelectedStatusError(null);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    validateLastName(value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const validateFirstName = (value) => {
    if (value.trim() === "") {
      setFullNameError("First Name is required");
    } else {
      setFullNameError("");
    }
  };

  const validateLastName = (value) => {
    if (value.trim() === "") {
      setLastNameError("Last Name is required");
    } else {
      setLastNameError("");
    }
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    validateFirstName(value);
  };
  return (
    <div className="row auth-main-container g-0">
      <div className="col-4 auth-img-container d-none d-md-block">
        <img
          src={signupgroup}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="col-12 col-md-8 enquiry-container auth-text-animation">
        <span className="enquiry-logo-container">
          <img src={crmlogo} alt="logo" className="signup-logo-image" />
        </span>
        <div
          className="d-flex flex-column justify-content-center"
          style={{ height: "100%" }}
        >
          <div className="d-flex justify-content-center mb-3">
            <div className="col-12 col-md-10 px-4 px-md-0 auth-title-text">
              Start your journey towards a new career today by requesting
              program details
            </div>
          </div>

          <div className="row enquiry-row-input-container">
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Select Campus*</label>
                <Select
                  value={campus}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "selectedCampus")
                  }
                  options={options}
                  className="auth-select"
                  components={{
                    DropdownIndicator: () => <RxTriangleDown size="20px" />,
                    IndicatorSeparator: null,
                  }}
                  isSearchable={false}
                  styles={selectStyles}
                  placeholder="Select an option"
                  menuPlacement="auto"
                  menuPosition="fixed"
                ></Select>
                <span className="auth-login-error-text ">
                  {selectedCampusError}
                </span>
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Select Program*</label>
                <Select
                  value={program}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "selectedProgram")
                  }
                  options={programOptions}
                  className="auth-select"
                  components={{
                    DropdownIndicator: () => <RxTriangleDown size="20px" />,
                    IndicatorSeparator: null,
                  }}
                  isSearchable={false}
                  styles={selectStyles}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  placeholder="Select an option"
                ></Select>
                <span className="auth-login-error-text ">
                  {selectedProgramError}
                </span>
              </div>
            </div>

            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">First Name*</label>
                <input
                  value={firstName}
                  onChange={handleFullNameChange}
                  className="auth-input"
                  placeholder="Enter your first name"
                />
                <span className="auth-login-error-text ">{firstNameError}</span>
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Last Name*</label>
                <input
                  value={lastName}
                  onChange={handleLastNameChange}
                  className="auth-input"
                  placeholder="Enter your last name"
                />
                <span className="auth-login-error-text ">{lastNameError}</span>
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Email*</label>
                <input
                  value={email}
                  onChange={handleEmailChange}
                  className="auth-input"
                  placeholder="Valid email ID"
                />
                {emailError && (
                  <span className="auth-login-error-text">{emailError}</span>
                )}
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Phone*</label>
                <PhoneInput
                  country={selectedCountry}
                  enableSearch={true}
                  value={phoneNumber}
                  international={false}
                  onChange={handlePhoneChange}
                  placeholder="Valid contact number"
                  inputProps={{
                    className: "enquiry-phone-input-mobile",
                    placeholder: "",
                  }}
                />
                {phoneNumberError && (
                  <span className="auth-login-error-text">
                    {phoneNumberError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Current Status*</label>
                <Select
                  value={currentStatus}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "selectedStatus")
                  }
                  options={currentStatusOptions}
                  className="auth-select"
                  components={{
                    DropdownIndicator: () => <RxTriangleDown size="20px" />,
                    IndicatorSeparator: null,
                  }}
                  isSearchable={false}
                  styles={selectStyles}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  placeholder="Select an option"
                ></Select>
                <span className="auth-login-error-text ">
                  {selectedStatusError}
                </span>
              </div>
            </div>
            <div className="col-10 col-md-5">
              <div className="enquiry-input-container">
                <label className="auth-label-text">Postal Code*</label>
                <input
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  className="auth-input"
                  placeholder="Enter your postal code"
                />
                <span className="auth-login-error-text ">{postalCodeEror}</span>
              </div>
            </div>
            <div className="col-10">
              <div className=" enquiry-checkbox-container ">
                <input
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                  className="auth-checkbox"
                  type="checkbox"
                />
                <span className="enquiry-term-text-container">
                  <label className="auth-label-text">
                    I understand and agree.
                  </label>

                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/TERMS+AND+CONDITIONS.pdf"
                    className="auth-sub-link-text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read Terms
                  </a>
                </span>
              </div>
              <div className="enquiry-checkbox-container ">
                <span>
                  <input
                    className="auth-checkbox"
                    type="checkbox"
                    checked={isMailCheck}
                    onChange={(e) => setIsMailCheck(e.target.checked)}
                  />
                </span>

                <label className="auth-label-text">
                  Please send me more information right away using information
                  above! I may opt-out of electronic communication at anytime.
                </label>
              </div>
            </div>
          </div>
          <div className="enquiry-button-container auth-text-animation">
            <button
              className={`auth-button ${
                isButtonDisabled ? "disabled-button" : ""
              }`}
              onClick={getInfoNow}
              disabled={isButtonDisabled}
            >
              Get Info Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
