import { Field, Form, Formik } from "formik";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateProgramDetails.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";

function CreateProgramDetials() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const navigate = useNavigate("");
  const location = useLocation();

  const data = location?.state?.data;
  const [refresh, setRefresh] = useState(false);
  const [admissionData, setAdmissionData] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setProgramData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved != true);
        });
    }
  }, [location.pathname, refresh]);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PROGRAM_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const datas = response?.data?.data;
          setAdmissionData(datas);
          setShowApproveButton(!datas?.isApproved);
        });
    }
  }, [location.pathname]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "210px !important",
    }),
  };

  const Options = [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ];
  const academicStatusOptions = [
    { value: "PARTTIME", label: "Part Time" },
    { value: "FULLTIME", label: "Full Time" },
  ];
  const trainingProgramOptions = [
    { value: "VOCATIONALANDACADEMIC", label: "Vocational,Academic" },
    { value: "PROFESSIONAL", label: "Professional" },
  ];

  const studyLevelOptions = [
    { value: "DIPLOMA", label: "Diploma" },
    { value: "CERTIFICATE", label: "Certificate" },
  ];
  const Test = [
    { value: "TOFEL", label: "TOFEL" },
    { value: "IELTS", label: "IELTS" },
    { value: "PTE", label: "PTE" },
    { value: "DUOLINGO", label: "DUOLINGO" },
    { value: "OTHER", label: "OTHER" },
  ];

  const initialValues = {
    program: "",
    campus: "",
    academicStatus: "",
    programDuration: "",
    exchangeProgram: "",
    programType: "",
    studyLevel: "",
    scholarship: "",
    internship: "",
    hoursPerWeek: "",
    classTiming: "",
    fromDate: "",
    toDate: "",
    expirationAcceptanceLetter: "",
    completion: "",
    test: "",
    otherTestName: "",
    testDate: "",
    score: "",
    credits: "",
    enrollmentDate: "",
    expectedEnrollment: "",
    country: "",
    city: "",
    midiLetter: "",
    otherInformation: "",
  };
  const validationSchema = Yup.object().shape({});

  const navigateToFee = () => {
    navigate("/admission-management/create-financial", {
      state: { data: data },
    });
  };

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData;
    const admissionId = {
      admissionId: data?.admissionId,
    };
    requestData.isApproved = true;
    requestData.isRejected = false;
    requestData.admissionManagement1 = admissionId;
    requestData.programFee = {
      programFeeMasterId: admissionData?.programFee?.programFeeMasterId,
    };

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_PROGRAM}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Program Details Approved Successfully");
        navigateToFee();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData;
    const admissionId = {
      admissionId: data?.admissionId,
    };
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;
    requestData.admissionManagement1 = admissionId;
    requestData.programFee = {
      programFeeMasterId: admissionData?.programFee?.programFeeMasterId,
    };

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_PROGRAM}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Program Details Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  return (
    <Formik
      initialValues={admissionData || initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Program Details</div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Program</label>
                    <Field
                      disabled={true}
                      name="program"
                      className="create-admission-input-for-program"
                      style={{ width: "445px" }}
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Campus</label>
                    <Field
                      disabled={true}
                      name="campus"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Academic Status</label>
                    <Field
                      disabled={true}
                      name="academicStatus"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={academicStatusOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={academicStatusOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Program Duration
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="programDuration"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Exchange Program
                    </label>
                    <Field
                      disabled={true}
                      name="exchangeProgram"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={Options.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={Options}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Type of Training Program
                    </label>
                    <Field
                      disabled={true}
                      name="programType"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={trainingProgramOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={trainingProgramOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">or Other:</label>
                    <Field
                      disabled={true}
                      type="text"
                      name="otherTrainingProgram"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Study Level</label>
                    <Field
                      disabled={true}
                      name="studyLevel"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={studyLevelOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={studyLevelOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Scholarship</label>
                    <Field
                      disabled={true}
                      type="text"
                      name="scholarship"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Internship/Practicum Length
                    </label>
                    <Field
                      disabled={true}
                      name="internship"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Hours of Instruction Per Week
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="hoursPerWeek"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Class Timing</label>
                    <Field
                      disabled={true}
                      type="text"
                      name="classTiming"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Start Date</label>
                    <Field
                      disabled={true}
                      // type="date"
                      name="fromDate"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Expected End Date
                    </label>
                    <Field
                      disabled={true}
                      // type="date"
                      name="toDate"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Expiration of Acceptance Letter
                    </label>
                    <Field
                      disabled={true}
                      type="date"
                      name="expirationAcceptanceLetter"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <label className="label-text-active">English</label>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Test *
                    </label>
                    <Field
                      disabled={true}
                      name="test"
                      className="create-admission-input-for-program"
                    />
                    {formikProps.touched.test && formikProps.errors.test && (
                      <div
                        className="program-error-message"
                        style={{ bottom: "-13px" }}
                      >
                        {formikProps.errors.test}
                      </div>
                    )}
                  </div>

                  {formikProps.values.test && (
                    <>
                      {formikProps.values.test === "OTHER" && (
                        <div className="create-admission-inner-first-input-container">
                          <label className="label-text-active width-label-program">
                            Name
                          </label>
                          <Field
                            disabled={true}
                            type="text"
                            name="otherTestName"
                            className="create-admission-input-for-program"
                            placeholder="Enter name here"
                          />
                        </div>
                      )}

                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Test Date
                        </label>
                        <Field
                          disabled={true}
                          type="date"
                          name="testDate"
                          className="create-admission-input-for-program"
                        />
                      </div>
                    </>
                  )}
                  {formikProps.values.test && (
                    <div
                      className="create-program-main-input-container"
                      style={{ marginTop: "-20px" }}
                    >
                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Literacy"
                            : "Listening"}
                        </label>
                        <Field
                          name="listening"
                          disabled={true}
                          className="create-admission-input-for-program-test"
                        />
                      </div>

                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Conversation"
                            : "Reading"}
                        </label>
                        <Field
                          disabled={true}
                          name="reading"
                          className="create-admission-input-for-program-test"
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active width-label-program ">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Comprehension"
                            : "Writing"}
                        </label>
                        <Field
                          disabled={true}
                          name="writing"
                          className="create-admission-input-for-program-test "
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Production"
                            : "Speaking"}
                        </label>
                        <Field
                          disabled={true}
                          name="speaking"
                          className="create-admission-input-for-program-test"
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Overall Band Score
                        </label>
                        <Field
                          disabled={true}
                          name="score"
                          className="create-admission-input-for-program"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container ps-0">
                    <label
                      className="label-text-active width-label-program "
                      style={{ width: "200px", textWrap: "wrap" }}
                    >
                      Credits accepted from previous institute
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="credits"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Enrollment Date</label>
                    <Field
                      disabled={true}
                      type="date"
                      name="enrollmentDate"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ps-0">
                    <label className="label-text-active">
                      Expected Enrollment
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="expectedEnrollment"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">MIDI Letter</label>
                    <Field
                      disabled={true}
                      type="text"
                      name="midiLetter"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">
                      Other Information
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="otherInformation"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={() =>
                      navigate("/admission-management/create-student-profile", {
                        state: { data: data },
                      })
                    }
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={navigateToFee}
                    className="create-admission-save-button"
                  >
                    Next
                  </button>

                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateProgramDetials;
