import { AppConfig } from "env.development";

export const ADMISSION_URL_FOR_STUDENT = {
  ADMISSION_URL: `${AppConfig.api_url}admission/data`,
  FILE_UPLOAD_URL: `${AppConfig.api_url}admission/upload`,
  IMAGE_UPLOAD_URL: `${AppConfig.api_url}admission/image/upload`,
  GET_ADMISSION_URL_FOR_STUDENT: `${AppConfig.api_url}admission/v1/status/`,
  GET_ADMISSION_URL_FOR_OSAP_STUDENT: `${AppConfig.api_url}admission/v2/status/`,
  GET_PROGRAM_OPTIONS: `${AppConfig.api_url}program-fee/v1/all`,
  GET_PROGRAM_OPTIONS_FOR_PROGRAM_DETAIL: `${AppConfig.api_url}program-fee/v1/program/all`,
  GET_PROGRAM_BY_PROGRAMID_FOR_STUDENT: `${AppConfig.api_url}admission/v1/program/`,
  GET_FEE_BY_PROGRAMID_FOR_STUDENT: `${AppConfig.api_url}admission/v1/getFee/`,
  GET_PAYMENT_BY_PROGRAMID_FOR_STUDENT: `${AppConfig.api_url}admission/v1/student-payment/`,
  GET_CLOA_PAYMENT_BY_PROGRAMID_FOR_STUDENT: `${AppConfig.api_url}admission/v1/cloa/student-payment/`,
  DELETE_INDEX: `${AppConfig.api_url}admission/v1/academic`,
  DELETE_EMPLOYMENT: `${AppConfig.api_url}admission/v1/employment`,
  GET_PROGRAM_AND_FEE_POPULATED_VALUES: `${AppConfig.api_url}admission/v1/program-fee`,
  GET_PROGRAM_CAMPUS_OPTIONS: `${AppConfig.api_url}program-fee/v1/campus/`,
  GET_PROGRAM_CAMPUS_OPTIONS_FOR_PROGRAM_DETAIL: `${AppConfig.api_url}program-fee/v1/program-campus/`,
  GET_END_DATE_BY_PARAMS: `${AppConfig.api_url}admission/v1/duration/endDate`,
  GET_ENROLLED_STAFF_DATA: `${AppConfig.api_url}staff/v1/all`,
  SEND_DOC_SIGN_DATA: `${AppConfig.api_url_}docusign/send`,
  DOWNLOAD_DOC_SIGN_DATA: `${AppConfig.api_url_}docusign/callback`,
  GET_ADMISSION_BY_ID: `${AppConfig.api_url}admission/v1/`,
};
