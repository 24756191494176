import "./AddCurrentaffairs.css";
import { Field, Formik, Form, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import Modal from "react-modal";
import { NEWS_AFFAIR_CONSTANTS } from "modules/communication-management/current-affairs/constants/news-affair-constants";
import { BiCheck } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import Dropzone from "react-dropzone";
import { NEWS_AFFAIR_URLS } from "../../constants/news-affair-urls";
import { IoClose } from "react-icons/io5";

function AddCurrentaffairs({ open, onClose, selectedNewsId }) {
  const maxSize = 2 * 1024 * 1024;
  const [uploadLoading, setUploadLoading] = useState(false);
  const [newsData, setNewsData] = useState(null);
  const [fileSizeError, setFileSizeError] = useState("");
  const [fileTypeError, setFileTypeError] = useState("");
  const [successmessage, setSuccessMessage] = useState("");
  const displayDuration = 5000;

  const initialValues = {
    title: "",
    articleDate: "",
    articleLink: "",
    uploadImage: "",
    articleDescription: "",
    uploadImageName: "",
  };
  useEffect(() => {
    if (selectedNewsId) {
      axiosService
        .get(
          `${NEWS_AFFAIR_URLS.GET_NEWS_CURRENT_AFFAIR_BY_ID}/${selectedNewsId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setNewsData(data);
        })
        .catch((error) => {});
    } else {
      setNewsData(null);
    }
  }, [selectedNewsId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    articleDate: Yup.string().required("ArticleDate is required"),
    articleLink: Yup.string().required("ArticleLink is required"),
    articleDescription: Yup.string().required("ArticleDescription is required"),
  });

  const onSubmit = async (values) => {
    if (selectedNewsId) {
      try {
        const requestData = {
          title: values.title,
          articleDate: values.articleDate,
          articleLink: values.articleLink,
          uploadImage: values.uploadImage,
          uploadImageName: values.uploadImageName,
          articleDescription: values.articleDescription,
          newsId: selectedNewsId,
        };
        const response = await axiosService.put(
          `${NEWS_AFFAIR_URLS.NEWS_CURRENT_AFFAIR_POST}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("News updated successfully");
          onClose();
        } else {
          toast.error(`Error: ${response.status} - ${response.data}`);
        }
      } catch (error) {
        toast.error("An error occurred while creating the task");
      }
    } else {
      try {
        const requestData = {
          title: values.title,
          articleDate: values.articleDate,
          articleLink: values.articleLink,
          uploadImage: values.uploadImage,
          uploadImageName: values.uploadImageName,
          articleDescription: values.articleDescription,
        };

        const response = await axiosService.post(
          `${NEWS_AFFAIR_URLS.NEWS_CURRENT_AFFAIR_POST}`,
          requestData
        );
        if (response.status === 200) {
          toast.success(NEWS_AFFAIR_CONSTANTS.SUCCESS);
          onClose();
        } else {
          toast.error(`Error: ${response.status} - ${response.data}`);
        }
      } catch (error) {
        toast.error(NEWS_AFFAIR_CONSTANTS.ERROR_OCCUR);
      }
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-12 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={newsData || initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <div className="flex-sm-row justify-content-between">
                    <div className="new-conversation-popup-header">
                      <span className="add-news-current-affair-title-text">
                        {selectedNewsId
                          ? "Update Current Affair  "
                          : "Add Current Affair"}
                      </span>

                      <span className="d-flex justify-content-center align-items-center">
                        <IoClose
                          color="#ffffff"
                          size={"23px"}
                          onClick={() => {
                            onClose();
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                      marginTop: "10px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Title *
                    </label>
                    <Field
                      name="title"
                      className="new-conversation-popup-input w-100 rounded-0"
                      placeholder="Add Title"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Article Date *
                    </label>
                    <Field
                      name="articleDate"
                      className="new-conversation-popup-input w-100 rounded-0 w-50"
                      placeholder="Add Date"
                      type="date"
                    />
                    <ErrorMessage
                      name="articleDate"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Article Link *
                    </label>
                    <Field
                      name="articleLink"
                      className="new-conversation-popup-input w-100 rounded-0"
                      placeholder="Add article link"
                    />
                    <ErrorMessage
                      name="articleLink"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                  <div className="position-relative d-flex flex-column mb-3 w-100">
                    <span>
                      <label className="add-news-current-affair-label-text">
                        Upload Image
                      </label>
                    </span>
                    <div
                      className="paymentupdate-document-upload-fields w-100"
                      style={{ maxWidth: "100%" }}
                    >
                      {fileSizeError && (
                        <div className="add-news-current-affair-error-message">
                          {fileSizeError}
                        </div>
                      )}

                      {fileTypeError && (
                        <div className="add-news-current-affair-error-message">
                          {fileTypeError}
                        </div>
                      )}

                      {successmessage && (
                        <div className="add-news-current-affair-success-message">
                          {successmessage}
                        </div>
                      )}
                      <Dropzone
                        onDrop={async (acceptedFiles) => {
                          if (acceptedFiles[0]?.name?.length > 150) {
                            toast.error(NEWS_AFFAIR_CONSTANTS.IMAGE_LENGTH);
                            return;
                          }
                          const sendFile = acceptedFiles[0];
                          setUploadLoading(true);
                          if (sendFile) {
                            if (sendFile.size > maxSize) {
                              setFileSizeError(
                                "File size exceeds the limit (2MB)."
                              );
                              setUploadLoading(false);
                              setTimeout(() => {
                                setFileSizeError("");
                              }, displayDuration);
                              return;
                            } else {
                              setFileSizeError("");
                            }

                            const allowedTypes = [
                              "image/png",
                              "image/jpeg",
                              "image/jpg",
                            ];
                            if (!allowedTypes.includes(sendFile.type)) {
                              setFileTypeError(
                                "Invalid file type. Please upload a PNG, JPEG, or JPG image."
                              );
                              setUploadLoading(false);
                              setTimeout(() => {
                                setFileTypeError("");
                              }, displayDuration);
                              return;
                            } else {
                              setFileTypeError("");
                            }

                            try {
                              const formData = new FormData();
                              formData.append("file", sendFile);
                              const response = await axiosService.postFile(
                                `${NEWS_AFFAIR_URLS.NEWS_CURRENT_AFFAIR_UPLOAD_URL}`,
                                sendFile,
                                true
                              );

                              if (response.status === 200) {
                                setUploadLoading(false);
                                setFieldValue(
                                  "uploadImage",
                                  response.data.data
                                );
                                setFieldValue("uploadImageName", sendFile.name);
                                setSuccessMessage("Upload file Successfull!");
                                setTimeout(() => {
                                  setSuccessMessage("");
                                }, displayDuration);
                                setUploadLoading(false);
                              } else {
                                setUploadLoading(false);
                                toast.error(
                                  NEWS_AFFAIR_CONSTANTS.UPLOAD_FILE_FAILURE
                                );
                              }
                            } catch (error) {
                              setUploadLoading(false);
                              if (error.response) {
                                toast.error(
                                  `${error.response.data.message
                                    .split(":")[1]
                                    ?.trim()}`
                                );
                              } else {
                                toast.error(
                                  NEWS_AFFAIR_CONSTANTS.UPLOAD_FILE_ERROR
                                );
                              }
                            }
                            setUploadLoading(false);
                          } else {
                            toast.error("Please select a file to upload.");
                            setUploadLoading(false);
                          }
                        }}
                        accept="image/*"
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            {...getRootProps()}
                            className={`create-list-upload-input-big paymentupload create-list-upload-input-big-one ${
                              isDragActive ? "drag-active" : ""
                            }`}
                          >
                            <input
                              {...getInputProps()}
                              className="d-none"
                              type="file"
                              id="upload"
                              name="uploadImage"
                            />

                            {values.uploadImageName || newsData?.uploadImage ? (
                              <span
                                className="text-white cm-cam-text-white"
                                style={{
                                  width: "calc(100% - 70px)",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {values.uploadImageName ||
                                  newsData?.uploadImage}
                              </span>
                            ) : (
                              "No file chosen"
                            )}
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      {(newsData?.uploadImage || values?.uploadImageName) &&
                      !uploadLoading ? (
                        <BiCheck
                          color="green"
                          size={32}
                          height={32}
                          style={{
                            position: "absolute",
                            right: "35px",
                          }}
                        />
                      ) : null}
                      {uploadLoading && (
                        <div
                          class="lds-ring"
                          size={32}
                          height={32}
                          style={{
                            position: "absolute",
                            right: "-4px",
                          }}
                        >
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(values.uploadImageName ||
                        newsData?.uploadImageName) && (
                        <a
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "7px",
                          }}
                          href={values.uploadImage || newsData?.uploadImage}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Article Description * (max 135 chars)
                    </label>
                    <Field
                      as="textarea"
                      className="new-conversation-popup-textare-input rounded-0 custom-scrollbar"
                      name="articleDescription"
                      style={{ verticalAlign: "top" }}
                      maxLength={135}
                    />
                    <ErrorMessage
                      name="articleDescription"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                </div>
                <div className=" d-flex align-items-center gap-3 mt-4">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="communication-management-submit-button "
                    type="submit"
                  >
                    {selectedNewsId ? "Update" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddCurrentaffairs;
