import React, { useState } from "react";
import "./LookUpNavbar.css";
import ViewStartDateProgram from "../start-date/view-start-date/ViewStartDateProgram";
import ListEnrolledStaff from "../enrolled-staff/ListEnrolledStaff";

function LookUpNavbar() {
  const [activeTab, setActiveTab] = useState("StartDate");

  return (
    <div className="view-leads-table-container">
      <div className="sticky-header lead-table-head-container">
        <div className="d-flex align-items-center gap-1">
          <span className="faculty-all-student-navbar-title-text">Lookup</span>
          <div className="d-flex gap-1 ps-1">
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "StartDate" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("StartDate");
              }}
            >
              Start Date
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "StaffName" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("StaffName");
              }}
            >
              Enrolled Staff
            </span>
          </div>
        </div>
      </div>

      <div className="faculty-all-student-navbar-inner-container">
        {activeTab === "StartDate" && <ViewStartDateProgram />}
        {activeTab === "StaffName" && <ListEnrolledStaff />}
      </div>
    </div>
  );
}

export default LookUpNavbar;
