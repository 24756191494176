import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import TextEditor from "utils/components/text-editor/TextEditor";

function EmailBodyContent({ isOpen, onClose, onSave, initialHtmlContent }) {
  const [htmlContent, setHtmlContent] = useState("");

  const handleHtmlContentChange = (content) => {
    setHtmlContent(content);
  };
  useEffect(() => {
    setHtmlContent(initialHtmlContent);
  }, [isOpen]);

  const handleSave = () => {
    const plainTextContent = htmlContent;
    onSave(plainTextContent);
    onClose();
  };

  return (
    <Modal className="crm-modal" isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-Con">
        <div
          className="col-11 col-md-8 p-3 d-flex flex-column gap-2"
          style={{
            maxHeight: "100%",
            backgroundColor: "#212226",
          }}
        >
          <div className="new-conversation-popup-header">
            <span style={{ fontSize: "20px", fontWeight: 400, color: "#fff" }}>
              Body Content
            </span>

            <IoClose
              color="#ffffff"
              size={"23px"}
              onClick={() => {
                onClose();
              }}
            />
          </div>

          <div>
            <div className="Description">Description</div>

            <TextEditor
              onHtmlContentChange={handleHtmlContentChange}
              initialHtmlContent={htmlContent}
            ></TextEditor>
          </div>

          <div className="d-flex align-items-center gap-3">
            <button
              className="communication-management-cancel-button"
              type="button"
              onClick={() => onClose()}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="communication-save-head-button"
              onClick={handleSave}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmailBodyContent;
