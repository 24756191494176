import crmlogo from "../../../../assets/images/crm-logo.svg";
import logingroup from "../../../../assets/images/login-group.png";
import { Link } from "react-router-dom";
import { GoCheckCircleFill } from "react-icons/go";

function UserActivate() {
  return (
    <div className="row g-0 auth-main-container ">
      <div className="d-none d-md-block col-6 auth-img-container d-none d-md-block ">
        <img
          src={logingroup}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="col-12 col-md-6 auth-container-user auth-text-animation">
        <span className="login-logo-container">
          <img src={crmlogo} alt="logo" className="login-logo-image" />
        </span>

        <div className="user-activate-con d-flex row">
          <span className="user-success">
            <GoCheckCircleFill style={{ color: "green", fontSize: "10em" }} />
          </span>
          <div className="row text-center text-user-activate">
            <span className="p-3">
              Your user account is successfully activated.
            </span>
            <span>
              Please proceed to
              <Link to="/" className=" user-link">
                Log In
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserActivate;
