import React, { useEffect, useState } from "react";
import "./ViewFaculty.css";
import { FaRegEdit } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { VscCallOutgoing } from "react-icons/vsc";
import { BsFillPinMapFill } from "react-icons/bs";
import { BsCalendar3 } from "react-icons/bs";
import EditEmployeePopup from "../edit-employee-popup/EditEmployeePopup";
import ReportingToPopup from "../reporting-to-popup/ReportingToPopup";
import DirectReportsPopup from "../direct-reports-popup/DirectReportsPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import { axiosService } from "services/axios.service";
import useravatar from "assets/images/user-avatar.svg";
import AddIcon from "assets/image-components/AddIcon";

const ViewFaculty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cycle, setCycle] = useState(true);
  let empId = location.state.empId;
  const [employeeData, setEmployeeData] = useState(null);
  const [recentNotes, setRecentNotes] = useState([
    {
      title: "Note Title",
      notes:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices.",
    },
    {
      title: "Note Title",
      notes:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices.",
    },
    {
      title: "Note Title",
      notes:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices.",
    },
  ]);

  useEffect(() => {
    if (empId) {
      axiosService
        .get(`${HR_MODULE_URLS.GET_EMPLOYEE_BY_ID}/${empId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setEmployeeData(responseData);
          }
        });
    }
  }, [empId, cycle]);

  const [editEmployeeInformationPopUp, setEditEmployeeInformationPopUp] =
    useState(false);
  const [reportingToPopUp, setReportingToPopUp] = useState(false);
  const [directReportsPopUp, setDirectReportsPopUp] = useState(false);

  return (
    <div className="d-flex flex-column gap-4 p-3">
      <div className="d-flex flex-column">
        <div className="view-faculty-header">
          <div className="view-faculty-header-left">
            <img
              src={
                employeeData?.employee?.profileImage
                  ? employeeData?.employee?.profileImage
                  : useravatar
              }
              className="hr-view-faculty-img"
            />
            <div className="d-flex flex-column">
              <span className="view-faculty-header-name">
                {employeeData?.employee?.gender === "MALE"
                  ? "Mr. "
                  : employeeData?.employee?.gender === "FEMALE"
                  ? "Mrs. "
                  : ""}
                {employeeData?.employee?.employeeFirstName +
                  " " +
                  employeeData?.employee?.employeeLastName}
              </span>
              <span className="view-faculty-header-left-position">
                Position: {employeeData?.employee?.position}
              </span>
            </div>
            <div className="d-none d-md-flex flex-column">
              <span className="view-faculty-header-left-grey-title">
                Campus Locations
              </span>
              <span className="view-faculty-header-left-content">
                {employeeData?.employee?.campusLocation}
              </span>
            </div>
            <div className="d-none d-md-flex flex-column">
              <span className="view-faculty-header-left-grey-title">
                Status
              </span>
              <span className="view-faculty-header-left-content">
                {employeeData?.employee?.status}
              </span>
            </div>
          </div>
          <div
            className="d-flex align-items-center ps-2"
            onClick={() =>
              navigate("/hr-module/view-faculty-attendance", {
                state: {
                  employeeData: employeeData,
                },
              })
            }
          >
            <BsCalendar3 size={"26px"} color="#ffffff" />

            <span
              style={{
                color: "#F7931D",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
              className="d-flex flex-column"
            >
              <span style={{ textWrap: "nowrap" }}>view faculty</span>
              <span style={{ textWrap: "nowrap" }}>attendance</span>
            </span>
          </div>
        </div>

        <div className="d-flex gap-3 flex-wrap d-md-none mt-2 mt-md-0">
          <div className="d-flex flex-column">
            <span className="view-faculty-header-left-grey-title">
              Campus Locations
            </span>
            <span className="view-faculty-header-left-content">
              {employeeData?.employee?.campusLocation}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span className="view-faculty-header-left-grey-title">Status</span>
            <span className="view-faculty-header-left-content">
              {employeeData?.employee?.status}
            </span>
          </div>
        </div>
      </div>

      <div className="view-faculty-basic-info">
        <div className="view-faculty-basic-info-1 col-7 row g-0">
          <div>
            <div className="view-faculty-basic-info-1-header">
              <span className="view-faculty-header-name">
                Contact Information
              </span>
              <span
                onClick={() => {
                  setEditEmployeeInformationPopUp(true);
                }}
              >
                <FaRegEdit />
              </span>
            </div>
            <div className="d-flex flex-row py-2 p-2 row g-0">
              <div
                className="d-flex flex-column g-2 col-12 col-sm-auto px-2"
                style={{
                  borderRight: "1px solid #212226",
                  fontSize: "13px",
                }}
              >
                <span className="d-flex align-items-center gap-1">
                  <GoMail />{" "}
                  <u
                    style={{
                      textWrap: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {employeeData?.employee?.email}
                  </u>
                </span>
                <span className="d-flex align-items-center gap-1">
                  <VscCallOutgoing />{" "}
                  <u>{employeeData?.employee?.contactNumber1}</u>
                </span>
                <span className="d-flex align-items-center gap-1">
                  <VscCallOutgoing />{" "}
                  <u>{employeeData?.employee?.contactNumber2}</u>
                </span>
              </div>
              <div className="d-flex flex-column px-2 g-2 col-12 col-sm-auto px-2">
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#F69F3A",
                  }}
                >
                  Residential Address
                </span>
                <span
                  style={{ display: "flex", gap: "10px", fontSize: "13px" }}
                >
                  <BsFillPinMapFill />

                  <span>{employeeData?.employee?.residentialAddress}</span>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="view-faculty-basic-info-1-header">
              <span className="view-faculty-header-name">Recent Notes</span>
              <span>
                <AddIcon />
                <span
                  style={{
                    color: "#F69F3A",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  Add new note
                </span>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 10px",
                gap: "10px",
              }}
            >
              {recentNotes.map((a, i) => {
                const isLastItem = i === recentNotes.length - 1;
                return (
                  <div
                    key={i}
                    style={{
                      borderBottom: isLastItem ? "none" : "1px solid black",
                      paddingBottom: isLastItem ? "0" : "10px",
                    }}
                  >
                    <div className="view-faculty-recent-notes-title">
                      {a.title}
                    </div>
                    <div className="view-faculty-recent-notes-content">
                      {a.notes}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="view-faculty-basic-info-2 col-12 col-md-5 row g-0"
          style={{ rowGap: "10px" }}
        >
          <div className="col-12 col-md-6 px-md-2">
            <div className="">
              <div className="view-faculty-basic-info-2-header ">
                <span className="view-faculty-header-name">Reports To</span>
                <span onClick={() => setReportingToPopUp(true)}>
                  <FaRegEdit />
                </span>
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {employeeData?.reportsTo && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <span>
                      <img
                        src={useravatar}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                    </span>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#F69F3A",
                        }}
                      >
                        {employeeData?.reportsTo?.gender === "MALE"
                          ? "Mr. "
                          : employeeData?.reportsTo?.gender === "FEMALE"
                          ? "Mrs. "
                          : ""}
                        {employeeData?.reportsTo?.employeeFirstName +
                          " " +
                          (employeeData?.reportsTo?.employeeLastName
                            ? employeeData?.reportsTo?.employeeLastName
                            : "")}
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          textDecoration: "UnderLine",
                        }}
                      >
                        Code: #{employeeData?.reportsTo?.employeeId}
                      </span>
                    </span>
                  </div>
                )}
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#7A7A7A",
                    textDecoration: "underline",
                  }}
                >
                  view history
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-md-2 ">
            <div className="">
              <div className="view-faculty-basic-info-2-header">
                <span className="view-faculty-header-name">Direct Reports</span>
                <span onClick={() => setDirectReportsPopUp(true)}>
                  <FaRegEdit />
                </span>
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <>
                  {employeeData?.employee?.directEmployee?.length > 0 && (
                    <>
                      {employeeData?.employee?.directEmployee
                        .slice(0, 3)
                        .map((a) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <span>
                              <img
                                src={useravatar}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#F69F3A",
                                }}
                              >
                                {a.gender === "MALE"
                                  ? "Mr. "
                                  : a.gender === "FEMALE"
                                  ? "Mrs. "
                                  : ""}
                                {a.employeeName}
                              </span>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  textDecoration: "UnderLine",
                                }}
                              >
                                Code: #{a.employeeId}
                              </span>
                            </span>
                          </div>
                        ))}
                    </>
                  )}
                </>
                {employeeData?.directEmployee?.length > 3 && (
                  <div
                    style={{ fontSize: "11px", textDecoration: "underline" }}
                  >
                    {employeeData?.directEmployee?.length - 3} more direct
                    reports
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {editEmployeeInformationPopUp && (
        <EditEmployeePopup
          employeeData={employeeData}
          open={editEmployeeInformationPopUp}
          setCycle={setCycle}
          cycle={cycle}
          onClose={() => {
            setEditEmployeeInformationPopUp(false);
          }}
        />
      )}
      {reportingToPopUp && (
        <ReportingToPopup
          open={reportingToPopUp}
          empId={empId}
          employeeData={employeeData}
          setCycle={setCycle}
          cycle={cycle}
          onClose={() => {
            setReportingToPopUp(false);
          }}
        />
      )}
      {directReportsPopUp && (
        <DirectReportsPopup
          open={directReportsPopUp}
          empId={empId}
          employeeData={employeeData}
          setCycle={setCycle}
          cycle={cycle}
          onClose={() => {
            setDirectReportsPopUp(false);
          }}
        />
      )}
    </div>
  );
};

export default ViewFaculty;
