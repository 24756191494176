import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./ConsentFileDocx.css";

const PdfContent = ({ studentName }) => {
  const containerRef = useRef(null);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const currentDate = new Date();

  return (
    <div
      style={{
        color: "#000000",
        fontSize: "14px",
      }}
    >
      <div
        className="text-content-consent"
        id="pdf-container"
        ref={containerRef}
      >
        <strong>
          DECLARATION REGARDING THE ACCURACY OF INFORMATION PROVIDED AND CONSENT
          TO <br></br>GATHER, KEEP, USE, AND DISCLOSE PERSONAL INFORMATION
        </strong>
        <br />
        <br />
        <strong>WAIVER, INDEMNITY AND RELEASE</strong>
        <br />
        <br />
        <p>
          I <strong>{studentName}</strong>, personally or as the representative,
          declare that the information <br></br>provided to apply for the
          vocational OR any other program(s) at Beta College of Business and
          <br></br> Technology (BCBT) is accurate, correct, valid, and complete
          in all respects. To allow BCBT to<br></br> analyze the Application for
          processing, determine its eligibility, and ensure compliance with
          <br></br>
          grant conditions or reject the application for such a program, I
          hereby authorize BCBT to <br></br>obtain personal information that
          concerns me from any federal, provincial, OR private <br></br>
          institution, as well as any other institution(s) globally, enterprise
          or other (“third parties”). If <br></br>any information is found
          false, untrue, or fabricated, the student will be reported to{" "}
          <br></br>competent authorities and can be dismissed from studies
          without any refund of fee(s) paid.
        </p>
        <p>
          I <strong>{studentName}</strong>, hereby consent to and authorize the
          use by BCBT, its officers, and<br></br> employees of my image, voice,
          and/or likeness and information about my<br></br> student/employment
          status (i.e., program name/year of graduation/former and current
          <br></br> employers) and my work (i.e., images, artwork, videos,
          photographs, reports, poems, written<br></br>
          material, music, audio recordings, and other related
          materials/projects/works). BCBT shall<br></br> have the right to
          photograph, publish, adapt, exhibit, perform, reproduce, edit,
          distribute,<br></br>
          display, or otherwise use my image, voice, and/or likeness and my work
          in connection with any<br></br> product or service in all markets,
          media or technology now known or hereafter developed in<br></br> the
          College’s products or services, as long as there is no intent to use
          the image, voice and/or<br></br> likeness, and information about my
          student/ employment status, disparagingly.{" "}
        </p>
        <p>
          I also consent to have this information shared by BCBT with third
          parties for these same <br></br>express purposes. BCBT is not
          responsible for misusing or altering any such audio/video or<br></br>{" "}
          photographs by third parties. I hereby release BCBT and any of its
          officers and employees<br></br>
          from any and all actions, claims, loss, or causes of action arising
          from using or misusing such<br></br> images.
        </p>
        <p>
          I am 18 years of age or older, and I am competent to contract in my
          own name. I read this<br></br> document before signing below, and I
          fully understand its contents, meaning, and impact. This<br></br>{" "}
          consent, waiver, indemnity, and release are binding on me, my heirs,
          executors,<br></br>
          administrators, and assigns.{" "}
        </p>{" "}
        <br />
        <span className="d-flex column">
          <p className="col-5">SIGNATURE: </p>{" "}
          <p className="col-5"> DATE:{formatDate(currentDate)}</p>
        </span>
        <span className="d-flex column">
          <p className="col-8">
            SIGNATURE OF PARENT/GUARDIAN (IF UNDER AGE 18):{" "}
          </p>
          <p className="col-4"> DATE:{formatDate(currentDate)}</p>
        </span>
        <p>
          Following sections 21, 39, and 49 of the Freedom of Information and
          Protection of Privacy Act,<br></br> personal information, including
          images and recordings in connection with this form, is<br></br>{" "}
          collected under the authority of the Ministry of Colleges and
          Universities Act, R.S.O. 1990. If <br></br>you have any questions
          about the collection of personal information, please get in touch with
          <br></br> the Administration Office at 905.497.9999 or email{" "}
          <a
            href="mailto:admin@betacollege.ca"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            admin@betacollege.ca
          </a>
        </p>
      </div>
    </div>
  );
};

const ConsentFileDocx = ({ studentName, setPdfBlob, setHandlePdf }) => {
  const [load, setLoad] = useState(false);
  const generatePdf = async () => {
    try {
      const textContentRef = document.getElementById("pdf-container");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });

      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 40,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 40)) /
          overlayCanvas.width
      );

      const pdfBlob = finalPdf.output("blob");

      setPdfBlob(pdfBlob);
      setHandlePdf(false);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    generatePdf();
  }, []);
  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <p>Loading...</p>
        </div>
      )}
      <PdfContent studentName={studentName} />
    </span>
  );
};

export default ConsentFileDocx;
