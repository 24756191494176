import { City, Country, State } from "country-state-city";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import { RxTriangleDown } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import * as Yup from "yup";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import "./CreateMailingAddress.css";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import sessionService from "services/sessionstorage.service";

function CreateMailingAddress() {
  const userData = sessionService.getUserData();
  const studentType = userData?.studentType;
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);

  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.mailingAddress?.isApproved);
          setRejected(data?.mailingAddress?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
          if (data?.mailingAddress?.country) {
            setSelectedCountry(data?.mailingAddress?.country);
          }
        });
    }
  }, [location.pathname]);

  const [selectedCountry, setSelectedCountry] = useState(
    admissionData?.mailingAddress?.country || ""
  );
  const [selectedState, setSelectedState] = useState(
    admissionData?.mailingAddress?.province || ""
  );
  const [selectedCity, setSelectedCity] = useState(
    admissionData?.mailingAddress?.city || ""
  );

  const [cities, setCities] = useState([]);

  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };

    fetchCountries();
  }, []);
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const selectedCountryInfo = allCountries.find(
          (country) => country.name === selectedCountry
        );

        if (selectedCountryInfo) {
          const allStates = State.getAllStates();
          const countryStates = allStates.filter(
            (state) => state.countryCode === selectedCountryInfo.isoCode
          );
          const StateOptions = countryStates.map((state) => ({
            label: state.name,
            value: state.name,
          }));
          setStates(StateOptions);

          if (admissionData?.mailingAddress?.province) {
            setSelectedState(admissionData.mailingAddress.province);
          }
        }
      } catch (error) {}
    };

    fetchStates();
  }, [selectedCountry, admissionData?.mailingAddress?.province]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const allStates = State.getAllStates();
        const selectedStateInfo = allStates.find(
          (state) => state.name === selectedState
        );

        if (selectedStateInfo) {
          const allCities = City.getAllCities();
          const stateCities = allCities.filter(
            (city) => city.stateCode === selectedStateInfo.isoCode
          );

          const cityOptions = stateCities.map((city) => ({
            label: city.name,
            value: city.name,
          }));

          setCities(cityOptions);

          if (admissionData?.mailingAddress?.city) {
            setSelectedCity(admissionData.mailingAddress.city);
          }
        }
      } catch (error) {}
    };

    fetchCities();
  }, [selectedState, admissionData?.mailingAddress?.city]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
    }),
  };

  const selectedCountryCode = "ca";

  const initialValues = {
    street: "",
    streetNo: "",
    city: "",
    postalCode: "",
    province: "",
    unit: "",
    country: "",
    mobile1: "",
    mobile2: "",
    mailingId: admissionData?.mailingAddress?.mailingId || "",
  };

  const validationSchema = Yup.object().shape({
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("PostalCode is required"),
    province: Yup.string().required("Province/State is required"),
    country: Yup.string().required("Country is required"),
    mobile1: Yup.string().required("Mobile number is required"),
  });

  const navigateToPersonalData = () => {
    navigation("/pre-onboarding/create-personal-data", {
      state: {
        data: programId,
      },
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    values.isRejected = false;

    const dataType = "mailing";

    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personalData: admissionData?.personalData,
        mailing: values,
      },
    };
    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.MAILING_SUCCESS);
        navigation("/pre-onboarding/create-guardian-details", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.MAILING_FAILURE);
      }
      setSubmitting(false);
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.MAILING_ERROR_OCCUR);
      }
    }
  };
  const dateString = new Date(admissionData?.mailingAddress?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleClear = async (formik) => {
    formik.resetForm();
  };

  return (
    <Formik
      initialValues={admissionData?.mailingAddress || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Mailing Address</div>

                <div className="">
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Street *</label>
                      <Field
                        className="create-admission-input-big"
                        name="street"
                      />
                      {formik.touched.street && formik.errors.street ? (
                        <div className="error street">
                          {formik.errors.street}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">No </label>
                      <Field
                        className="pre-onboard-create-admission-input-small-no"
                        name="streetNo"
                        style={{ width: "200px !important" }}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Country *</label>

                      <Field
                        name="country"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        render={({ field, form }) => (
                          <>
                            <Select
                              className="create-admission-select-big"
                              options={countries}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              value={{ label: field.value, value: field.value }}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                );
                                form.setFieldValue("province", "");
                                form.setFieldValue("city", "");
                                setSelectedCountry(selectedOption.value);
                              }}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                            {formik.touched.country &&
                            formik.errors.country &&
                            !formik.values.country ? (
                              <div className="error country">
                                {formik.errors.country}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Postal Code *</label>
                      <Field
                        className="pre-onboard-create-admission-input-small-Pcode"
                        name="postalCode"
                      />
                      {formik.touched.postalCode && formik.errors.postalCode ? (
                        <div className="error postalCode">
                          {formik.errors.postalCode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Province/State *
                      </label>
                      <Field
                        name="province"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        render={({ field, form }) => (
                          <>
                            <Select
                              className="create-admission-select-big"
                              options={states}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              value={{ label: field.value, value: field.value }}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                );
                                form.setFieldValue("city", "");
                                setSelectedState(selectedOption.value);
                              }}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                            {formik.touched.province &&
                            formik.errors.province &&
                            !formik.values.province ? (
                              <div className="error province">
                                {formik.errors.province}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Apt/Unit </label>
                      <Field
                        className="pre-onboard-create-admission-input-small-aptUnit"
                        name="unit"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container">
                      <label className="label-text-active">City *</label>
                      <Field
                        name="city"
                        render={({ field, form }) => (
                          <>
                            <Select
                              className="create-admission-select-big"
                              options={cities}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              value={{ label: field.value, value: field.value }}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                );

                                setSelectedCity(selectedOption.value);
                              }}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="error city">
                                {formik.errors.city}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Mobile 1 *</label>

                      <Field name="mobile1">
                        {({ field, form }) => (
                          <PhoneInput
                            country={selectedCountryCode}
                            enableSearch={true}
                            value={field.value}
                            onChange={(value, country, e, formattedValue) => {
                              form.setFieldValue("mobile1", formattedValue);
                            }}
                            inputProps={{
                              className:
                                "create-admission-input-big1 phone-input1",
                              placeholder: "Valid Mobile number",
                            }}
                          />
                        )}
                      </Field>
                      {formik.touched.mobile1 && formik.errors.mobile1 ? (
                        <div className="error mobile1">
                          {formik.errors.mobile1}
                        </div>
                      ) : null}
                    </div>

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Mobile 2</label>

                      <Field name="mobile2">
                        {({ field, form }) => (
                          <PhoneInput
                            country={selectedCountryCode}
                            enableSearch={true}
                            value={field.value}
                            onChange={(value, country, e, formattedValue) => {
                              form.setFieldValue("mobile2", formattedValue);
                            }}
                            inputProps={{
                              className:
                                "create-admission-input-big2 phone-input2",
                              placeholder: "Valid Mobile number",
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToPersonalData}
                  >
                    Previous
                  </button>

                  {!admissionData?.mailingAddress?.mailingId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      disabled={formik.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        disabled={formik.isSubmitting}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {(admissionData?.mailingAddress?.mailingId ||
                    admissionData?.mailingAddress?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation("/pre-onboarding/create-guardian-details", {
                          state: {
                            data: programId,
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}

                  {!admissionData?.mailingAddress?.mailingId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(formik)}
                    >
                      Clear
                    </button>
                  )}
                </div>

                {admissionData?.mailingAddress?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.mailingAddress?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.mailingAddress?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateMailingAddress;
