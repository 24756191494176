import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { Typeahead } from "react-bootstrap-typeahead";
import warningicon from "../../../../assets/images/Vectorwarning.png";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import { axiosService } from "services/axios.service";
import useravatar from "assets/images/user-avatar.svg";
import { toast } from "react-toastify";

const DirectReportsPopup = ({
  open,
  onClose,
  employeeData,
  empId,
  cycle,
  setCycle,
}) => {
  const [cards, setCards] = useState(employeeData?.employee?.directEmployee);

  const [loadedData, setLoadedData] = useState([]);

  const [singleSelections, setSingleSelections] = useState([]);
  const [options, setOptions] = useState([]);

  function findOddOne(array1, array2) {
    const oddOnes = [];

    const setArray2 = new Set(
      array2?.map((obj) => obj.employeeId || obj.empId)
    );

    array1.forEach((obj) => {
      const id = obj.employeeId || obj.empId;
      if (!setArray2.has(id)) {
        oddOnes.push(obj);
      }
    });

    return oddOnes;
  }

  useEffect(() => {
    axiosService.get(`${HR_MODULE_URLS.GET_ALL_EMPLOYEES}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;

        let filteredResults = responseData?.filter((a) => a.empId !== empId);
        setLoadedData(filteredResults);

        const oddOnes = findOddOne(
          filteredResults,
          employeeData?.employee?.directEmployee
        );

        let options = oddOnes?.map(
          (a) =>
            a.employeeFirstName +
            " " +
            a.employeeLastName +
            " (" +
            a.employeeId +
            ")"
        );
        setOptions(options);
      }
    });
  }, []);

  const handleCurrentReporteeChange = (a) => {
    if (a.length > 0) {
      let id = a[0]?.split("(");
      let chosenEmployeeId = id[1].slice(0, -1);
      let FilteredData = loadedData.filter(
        (a) => a.employeeId === chosenEmployeeId
      );

      let FilteredObject = {
        employeeName:
          FilteredData[0].employeeFirstName +
          " " +
          FilteredData[0].employeeLastName,
        employeeId: FilteredData[0].employeeId,
        gender: FilteredData[0].gender,
        empId: FilteredData[0].empId,
      };

      setCards([...cards, FilteredObject]);

      let removeFromOption = `${FilteredData[0].employeeFirstName} ${FilteredData[0].employeeLastName} (${FilteredData[0].employeeId})`;

      let newOptions = options.filter((a) => a !== removeFromOption);
      setOptions(newOptions);
      setSingleSelections([]);
    }
  };

  function handleDeleteCard(empId, employeeId, gender, employeeName) {
    let cardsAfterDelete = cards.filter((a) => a.empId !== empId);

    setCards(cardsAfterDelete);

    let option = `${employeeName} (${employeeId})`;

    setOptions([...options, option]);
  }

  const handleSubmit = () => {
    let directReports = cards?.map((a) => a.empId);
    let reportsTo = { userId: employeeData?.employee?.reportsTo?.userId };

    let Data = employeeData?.employee;
    Data.directReports = directReports;
    Data.reportsTo = reportsTo;

    try {
      axiosService
        .put(`${HR_MODULE_URLS.UPDATE_EMPLOYEE}`, Data)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Employee Updated successfully!");
            onClose();
            setCycle(!cycle);
          }
        });
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-sm-8 col-md-6 col-lg-5 p-3"
          style={{
            height: "auto",
            maxHeight: "90vh",
            backgroundColor: "#212226",
          }}
        >
          <div className="new-conversation-popup-header sticky-header top-0">
            <span className="new-conversation-popup-title-text">
              Direct Reports
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose color="#ffffff" size={"22px"} />
            </span>
          </div>
          <div className="d-flex flex-column mt-2">
            {cards?.length > 0 && (
              <div className="reporting-popup">
                {" "}
                List of current direct reports to the faculty
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {cards?.map((a) => {
                return (
                  <div
                    key={a}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      margin: "20px 0px",
                      width: "50%",
                    }}
                  >
                    <span>
                      {/* <div style={{ width: '50px', height: '50px', borderRadius: '50%' }}> */}
                      <img
                        src={useravatar}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                      {/* </div> */}
                    </span>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#F69F3A",
                        }}
                      >
                        {a.gender === "MALE"
                          ? "Mr. "
                          : a.gender === "FEMALE"
                          ? "Mrs. "
                          : ""}
                        {a.employeeName}
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#FFFFFF",
                        }}
                      >
                        Code: #{a.employeeId}
                      </span>
                    </span>
                    <span
                      onClick={() =>
                        handleDeleteCard(
                          a.empId,
                          a.employeeId,
                          a.gender,
                          a.employeeName
                        )
                      }
                    >
                      <IoClose color="#ffffff" size={"18px"} />{" "}
                    </span>
                  </div>
                );
              })}
            </div>
            {/* <div className=" d-flex flex-column" style={{ width: "100%" }}>
                                <label className="lead-middle-name">Change reporting manager</label>
                                <input
                                    style={{
                                        height: "33px",
                                        borderRadius: "3px",
                                        fontSize: "12px",
                                        color: "#7A7A7A",
                                        backgroundColor: '#333333'
                                    }}
                                    // onChange={(e) => setBankName(e.target.value)}
                                    type="text"
                                    placeholder='Type to search the list'
                                    className=" lead-input col-8 col-md-10"
                                // value={bankName || ""}
                                />
                            </div> */}
            <div className=" m-2 d-flex flex-column">
              <div className="d-flex mb-2  flex-wrap">
                <label className="lead-middle-name">
                  Add new direct reports here
                </label>
              </div>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                style={{
                  height: "30px",
                  width: "100%",
                  backgroundColor: "#333333 !important",
                }}
                onChange={(a) => {
                  setSingleSelections(a);
                  handleCurrentReporteeChange(a);
                }}
                options={options}
                placeholder="Type to search"
                selected={singleSelections}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 10px 0px",
              }}
            >
              <span style={{ margin: "0px 10px" }}>
                <img
                  src={warningicon}
                  alt="logo"
                  className="error-icon"
                  style={{ height: "18px", width: "18px" }}
                />
              </span>
              <span
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "400",
                  opacity: "0.5",
                }}
              >
                Submitting this form will send automated emails to the reporting
                person and the faculty.
              </span>
            </div>
          </div>

          <div
            className="d-flex gap-3 pt-4"
            style={{ borderTop: "1px solid #7A7A7A" }}
          >
            <button
              className="communication-management-cancel-button"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>

            <button
              // disabled={!employeeId || !employeeType}
              className={`communication-management-submit-button`}
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DirectReportsPopup;
