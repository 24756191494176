import ViewAdmission from "modules/admission-management/admission-management/components/view-admission/ViewAdmission";
import CreateAcademicProfessional from "modules/pre-onboarding/components/create-admission/create-academic-professional/CreateAcademicProfessional";
import CreateEmployementWorkExperience from "modules/pre-onboarding/components/create-admission/create-employement-work-experience/CreateEmployementWorkExperience";
import CreateFinancial from "modules/pre-onboarding/components/create-admission/create-financial/CreateFinancial";
import CreateGuardianDetails from "modules/pre-onboarding/components/create-admission/create-guardian-details/CreateGuardianDetails";
import CreateListUploadsFiles from "modules/pre-onboarding/components/create-admission/create-list-uploads-files/CreateListUploadsFiles";
import CreateMailingAddress from "modules/pre-onboarding/components/create-admission/create-mailing-address/CreateMailingAddress";
import CreateOtherInformation from "modules/pre-onboarding/components/create-admission/create-other-information/CreateOtherInformation";
import CreatePersonalData from "modules/pre-onboarding/components/create-admission/create-personal-data/CreatePersonalData";
import CreateProgramDetials from "modules/pre-onboarding/components/create-admission/create-program-details/CreateProgramDetails";
import CreateStudentProfile from "modules/pre-onboarding/components/create-admission/create-student-profile/CreateStudentProfile";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import sessionstorageService from "services/sessionstorage.service";
import ViewApplicationForStudent from "../applications/components/student-view-applications/ViewApplicationForStudent";
import PaymentSubmission from "../update-payment/payment-submission/PaymentSubmission";
import { ADMISSION_URL_FOR_STUDENT } from "../constants/admission-application-student-urls";
import { axiosService } from "services/axios.service";
import AdmissionSideBar from "../create-admission/admission-side-bar/AdmissionSideBar";
import CloaPaymentSubmission from "../create-admission/cloa-payment-submission/CloaPaymentSubmission";

function PreOnboardingRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = sessionstorageService?.isUserLoggedIn();

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const admissionId = sessionstorageService.getAdmissionId("admissionId");

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            if (
              token &&
              data.page === "STUDENTPROFILE" &&
              location.pathname === "/pre-onboarding"
            ) {
              navigate("/pre-onboarding/view-application-student");
            } else if (
              token &&
              data.page === "PERSONAL" &&
              location.pathname === "/pre-onboarding"
            ) {
              navigate("/pre-onboarding/create-mailing-address");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "MAILING"
            ) {
              navigate("/pre-onboarding/create-guardian-details");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "GUARDIAN"
            ) {
              navigate("/pre-onboarding/create-academic-professional");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "ACADEMIC"
            ) {
              navigate("/pre-onboarding/create-employement-work-experience");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "EMPLOYMENT"
            ) {
              navigate("/pre-onboarding/create-other-information");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "OTHER"
            ) {
              navigate("/pre-onboarding/create-list-upload-files");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              data.page === "FILEUPLOAD"
            ) {
              navigate("/pre-onboarding/create-student-profile");
            } else if (
              token &&
              location.pathname === "/pre-onboarding" &&
              (data.page === "STUDENTPROFILE" ||
                data.page === "PROGRAM" ||
                data.page === "FEE" ||
                data.page === "PAYMENT")
            ) {
              navigate("/pre-onboarding/view-application-student");
            }
          }
        });
    } else if (location.pathname === "/pre-onboarding") {
      navigate("/pre-onboarding/create-personal-data");
    }
  }, [location.pathname]);

  return (
    <Routes>
      {hasPermission("Pre-onBoarding: view") && (
        <Route path="admission-sidebar" element={<AdmissionSideBar />} />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="view-application-student"
          element={<ViewApplicationForStudent />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-program-details"
          element={<CreateProgramDetials />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route path="create-financial" element={<CreateFinancial />} />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route path="create-personal-data" element={<CreatePersonalData />} />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-mailing-address"
          element={<CreateMailingAddress />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-guardian-details"
          element={<CreateGuardianDetails />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-academic-professional"
          element={<CreateAcademicProfessional />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-employement-work-experience"
          element={<CreateEmployementWorkExperience />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-other-information"
          element={<CreateOtherInformation />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-list-upload-files"
          element={<CreateListUploadsFiles />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="create-student-profile"
          element={<CreateStudentProfile />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route path="view-admission" element={<ViewAdmission />} />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route
          path="cloa-paymentsubmission"
          element={<CloaPaymentSubmission />}
        />
      )}
      {hasPermission("Pre-onBoarding: view") && (
        <Route path="paymentsubmission" element={<PaymentSubmission />} />
      )}
    </Routes>
  );
}

export default PreOnboardingRoutes;
