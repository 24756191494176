export const TASK_CONSTANTS = {
  SUCCESS: "Task Created successfully",
  FAILED: "Task Failed to Create",
  ERROR_OCCUR:
    "An unexpected error occurred during Task creating. Please contact admin/support",
};
export const TASK_UPDATE_CONSTANTS = {
  SUCCESS: "Task Updated successfully",
  FAILED: "Task Failed to Update",
  ERROR_OCCUR:
    "An unexpected error occurred during Task Updating. Please contact admin/support",
};
export const PIPELINE_UPDATE_CONSTANTS = {
  SUCCESS: "Lead Updated successfully",
  FAILED: "Lead Failed to Update",
  ERROR_OCCUR:
    "An unexpected error occurred during Lead Updates. Please contact admin/support",
  ERROR_EMPTY: "Please Fill all field.",
};

export const TASK_DELETE_CONSTANTS = {
  SUCCESS: "Task deleted  successfully",
  FAILED: "Task Failed to Delete",
  ERROR_OCCUR:
    "An unexpected error occurred during Task deleting. Please contact admin/support",
};
