import betalogo from "assets/images/icons/beta.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import "./academicTranscript.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";
import { QRCode } from "react-qr-code";
import sha256 from "crypto-js/sha256";

const PdfContent = ({ pdfData, includeWatermark, hashValueData }) => {
  const generateUniqueHash = () => {
    const uniqueId = Math.random().toString(36).substring(7);
    return `beta_${uniqueId}`;
  };
  const hashValue = generateUniqueHash();
  const qrCodeUrl = `https://erp.lmsmybeta.com/?hashvalue=${hashValue}`;
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );
  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;
  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div
        style={{
          marginLeft: "10px",
          width: "100%",
          position: "relative",
        }}
      >
        <h3>Withdrawal LETTER</h3>
        <br></br>
        <p>Date: {formatDate(new Date())}</p>
        <p>
          REF:
          <strong>
            {" "}
            {pdfData?.personalData?.firstName} {pdfData?.personalData?.lastName}
          </strong>
        </p>
        <p>Student ID No.: {pdfData?.personalData?.studentId}</p>
        <p>
          This letter is to confirm that{" "}
          <strong>
            {pdfData?.personalData?.firstName} {pdfData?.personalData?.lastName}{" "}
          </strong>
          has been granted a withdrawal from{" "}
          {pdfData?.programDetails[0]?.program}
          <p> Management taking effect from September 11, 2023.</p>
        </p>
        <br></br>
        <p>
          <p>
            The purpose of this letter is confirmation of withdrawal of the
            student.
          </p>
        </p>
        <br></br>
        <p>Yours sincerely,</p>
        <p>Signature of Institute Representative:</p>
        <br></br>
        <br></br>
        <p>
          Print Name of Institute Representative:{" "}
          <strong>Krunal Trivedi</strong>
        </p>

        <p>
          <strong>Registrar Cum Director: Outreach and Operations</strong>
        </p>
      </div>
      <div
        className="footer"
        style={{
          marginTop: includeWatermark === "download" ? "19rem" : "19.5rem",
          marginLeft: "15px",
          position: "relative",
        }}
      >
        {includeWatermark === "download" && hashValueData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "22px",
              zIndex: 1,
            }}
          >
            <QRCode size={70} value={url} />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

const WithDrawalLetter = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);
  const studentId = pdfData?.personalData?.studentId;
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const [hashValueData, setHashValue] = useState(
    sha256(`${"WithDrawal-Letter"}-${studentId}`).toString().substring(0, 20)
  );

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark) {
        const watermarkText = "Sample";
        const fontSize = 600;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2.5;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }

      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });
      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 10,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 10)) /
          overlayCanvas.width
      );

      if (includeWatermark) {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");

            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

            // setHashValue(hashValue);

            const fileName = `${
              includeWatermark
                ? "WithDrawal-Letter.sample"
                : "WithDrawal-Letter"
            }_${studentId}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "withDrawal-Letter.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
        />
      )}
    </span>
  );
};

export default WithDrawalLetter;
