import { LoginConstants } from "core/auth/constants/auth-constants";
import { AUTH_URLS } from "core/auth/constants/auth-urls";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionstorageService from "services/sessionstorage.service";
import crmlogo from "../../../../assets/images/crm-logo.svg";
import logingroup from "../../../../assets/images/login-group.png";
import microsofticon from "../../../../assets/images/microsoft.svg";
import googleicon from "../../../../assets/images/google.png";
import { axiosService } from "../../../../services/axios.service";
import "../login/Login.css";
import axios from "axios";

function hasPermission(permission) {
  let userPermissions = sessionstorageService.hasPermissionByButton(permission);
  return userPermissions;
}
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword] = useState(false);
  const [loginRequired, setLoginRequiredError] = useState("");
  const navigate = useNavigate();
  const getLastSegmentFromHash = () => {
    const hash = window.location.hash;
    const segments = hash.substring(2).split("/");
    return (
      segments.length > 0 && segments[0] !== "" ? segments[0] : "STAFF"
    ).toUpperCase();
  };
  const studentType = getLastSegmentFromHash();
  const getSignupPath = () => {
    const hash = window.location.hash;

    if (hash === "#/") {
      return "/signup";
    } else {
      return `/signup${hash.replace("#", "")}`;
    }
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleMicrosoftLogin = () => {
    axios
      .get(`${AUTH_URLS.MICROSOFT_URL}`)
      .then((response) => {})
      .catch((error) => {
        console.error("Error calling Microsoft API:", error);
      });
  };

  const handleGoogleLogin = () => {
    axios
      .get(`${AUTH_URLS.GOOGLE_URL}`)
      .then((response) => {})
      .catch((error) => {
        console.error("Error calling Google API:", error);
      });
  };
  const handleLogin = async () => {
    if (!email || !password) {
      setLoginRequiredError(LoginConstants.LOGIN_REQUIRED);
      return;
    } else {
      setLoginRequiredError("");
    }
    const loginData = {
      email,
      password,
      studentType,
    };
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.LOGIN_URL}`,
        loginData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        const permissions = response.headers["permissions"];
        const token = response.headers["authorization"];
        sessionstorageService.setPermissions(permissions);
        sessionstorageService.setToken(token);
        sessionstorageService.setUserData(response.data.userData);

        if (response.data.userData?.admissionManagement?.admissionId) {
          sessionstorageService.setAdmissionId(
            response.data.userData?.admissionManagement?.admissionId
          );
        } else {
          sessionstorageService.setAdmissionId(null);
        }

        if (hasPermission("System Administration: view")) {
          navigate("/user-management");
        } else if (hasPermission("Pre-onBoarding: view")) {
          navigate("/pre-onboarding");
        } else if (hasPermission("Admission Management: view")) {
          navigate("/admission-management");
        } else if (hasPermission("CRM: view")) {
          navigate("/lead-management");
        } else if (hasPermission("Student Portal: view")) {
          navigate("/student-portal");
        }
      } else {
        toast.error(LoginConstants.LOGIN_FAILED);
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
      } else if (error.response) {
        setLoginRequiredError(
          `${"You have entered an invalid email or password"}`
        );
      }
      if (error.message === "Network Error") {
        toast.error(LoginConstants.NETWORK_ERROR);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className="row g-0 auth-main-container ">
      <div className="d-none d-md-block col-6 auth-img-container d-none d-md-block ">
        <img
          src={logingroup}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="col-12 col-md-6 auth-container auth-text-animation">
        <span className="login-logo-container">
          <img src={crmlogo} alt="logo" className="login-logo-image" />
        </span>
        <div
          className="d-flex row g-0 gap-2 flex-column align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <div className="login-head-text-container col-10  col-lg-6">
            {studentType !== "STAFF" && (
              <p style={{ color: "white" }}>
                Welcome{" "}
                {studentType === "INTERNATIONAL"
                  ? studentType.charAt(0).toUpperCase() +
                    studentType.slice(1).toLowerCase()
                  : studentType}{" "}
                Student
              </p>
            )}
            <span className="auth-title-text">Sign in</span>
          </div>

          <div className="login-input-main-container col-10  col-lg-6">
            <div className="auth-input-container ">
              <label className="auth-label-text">Email Address</label>
              <input
                value={email}
                onChange={handleEmailChange}
                className="auth-input"
                placeholder="john@gmail.com"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="auth-input-container">
              <span className="password-text-container">
                <label className="auth-label-text">Password</label>

                <Link
                  to="forgot-password"
                  style={{ textDecoration: "underline" }}
                  className="auth-label-text"
                >
                  Forgot Password?
                </Link>
              </span>
              <input
                className="auth-input"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                placeholder="password"
                onKeyDown={handleKeyDown}
              />
              <span className="auth-login-error-text-verify">
                {loginRequired}
              </span>
            </div>
          </div>
          <div className="login-button-container col-10  col-lg-6 col-10  col-lg-6">
            <button className="auth-button" onClick={handleLogin}>
              Login
            </button>
            {getSignupPath() !== "/signup" && (
              <div className=" text-center mt-2">
                <span className="auth-label-text">Don’t have an account? </span>
                <Link to={getSignupPath()} className="auth-sub-link-text">
                  Register here.
                </Link>
              </div>
            )}

            <span className="login-or-container">
              <hr className="hr-or" />
              <span className="auth-label-text">Or</span>
              <hr className="hr-or" />
            </span>
            <button
              className="auth-google-button"
              onClick={handleMicrosoftLogin}
            >
              <img alt="img" src={microsofticon} />
              Login with Microsoft
            </button>
            <span className="login-or-container">
              <hr />
            </span>
            <button className="auth-google-button" onClick={handleGoogleLogin}>
              <img alt="img" src={googleicon} />
              Login with Google
            </button>
          </div>
        </div>
        <div className="policy-text-container">
          <span className="d-flex flex-column flex-md-row">
            <a
              style={{ textDecoration: "underline" }}
              href="https://mybeta.ca/privacy-policy/"
              className="auth-label-text"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noreferrer"
              href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/GDPR_Policy_Statement.pdf"
              className=" ms-2 auth-label-text"
            >
              GDPR Policy
            </a>
          </span>
          <a
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
            href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/TERMS+AND+CONDITIONS.pdf"
            className="auth-label-text"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
