import { RxTriangleDown } from "react-icons/rx";
import "./AddProgram.css";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import EditorModal from "./EditorModal";
import { useEffect, useState } from "react";
import EditIcon from "assets/image-components/EditIcon";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import AddModulesProgram from "./AddModulesProgram";
import { IoIosArrowBack } from "react-icons/io";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function AddProgram() {
  const navigate = useNavigate();
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [programOverview, setProgramOverview] = useState("");
  const [careerPath, setCareerPath] = useState("");
  const [admissionRequirements, setAdmissionRequirements] = useState("");
  const [englishRequirements, setEnglishRequirements] = useState("");
  const [courseOutcome, setCourseOutcome] = useState("");
  const [currentLabel, setCurrentLabel] = useState("");
  const [initialEditorContent, setInitialEditorContent] = useState("");
  const [selectedSemesterCount, setSelectedSemesterCount] = useState("1");
  const [isMorningChecked, setIsMorningChecked] = useState(false);
  const [isAfternoonChecked, setIsAfternoonChecked] = useState(false);
  const [isEveningChecked, setIsEveningChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const handleClassTimingChange = (option) => {
    if (option.value === "MORNING") {
      setIsMorningChecked(!isMorningChecked);
      setCustomChecked(false);
      setStartTime("");
      setEndTime("");
    } else if (option.value === "AFTERNOON") {
      setIsAfternoonChecked(!isAfternoonChecked);
      setCustomChecked(false);
      setStartTime("");
      setEndTime("");
    } else if (option.value === "EVENING") {
      setIsEveningChecked(!isEveningChecked);
      setCustomChecked(false);
      setStartTime("");
      setEndTime("");
    } else if (option.value === "CUSTOM") {
      setCustomChecked(!customChecked);
      setIsMorningChecked(false);
      setIsEveningChecked(false);
      setIsAfternoonChecked(false);
    }
  };

  const location = useLocation();
  const Data = location.state;
  const programId = Data?.programId;

  const [totalModulesCount, setTotalModulesCount] = useState("");
  const [modulesCreditCount, setModulesCreditCount] = useState("");

  const [moduleDatas, setModuleDatas] = useState([]);
  const handleFormSubmit = (selectedModules) => {
    setModuleDatas(selectedModules);
  };

  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_ALL_PROGRAMS}`).then((response) => {
      if (response.status === 200) {
        setTotalModulesCount(response.data.data.totalModules);
        setModulesCreditCount(response.data.data.totalPrograms);
      }
    });
  }, [location.pathname]);

  const [programData, setProgramData] = useState("");

  const openEditorModal = (label, initialContent) => {
    setIsEditorModalOpen(true);
    setCurrentLabel(label);
    setInitialEditorContent(initialContent);
  };

  const handleEditorSave = (label, plainTextContent) => {
    if (label === "Program Overview") {
      setProgramOverview(plainTextContent);
    } else if (label === "Career Path") {
      setCareerPath(plainTextContent);
    } else if (label === "Admission Requirements") {
      setAdmissionRequirements(plainTextContent);
    } else if (label === "English Proficiency Requirement") {
      setEnglishRequirements(plainTextContent);
    } else if (label === "Course Outcome") {
      setCourseOutcome(plainTextContent);
    } else {
    }
  };

  const [maxModulesPerSemester, setMaxModulesPerSemester] = useState("");
  const handleMaxModulesChange = (e) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && value >= 1 && value <= 30) {
      setMaxModulesPerSemester(value);
    } else if (value === "") {
      setMaxModulesPerSemester(value);
    }
  };

  const closeEditorModal = () => {
    setIsEditorModalOpen(false);
  };

  let initialValues = {
    programName: programData?.program || "",
    programCode: programData?.programCode || "",
    academicStatus: programData?.academicStatus || "",
    duration: programData?.programDuration || "",
    exchangeProgram: programData?.exchangeProgram || "",
    programType: programData?.programType || "",
    otherProgram: programData?.otherProgram || "",
    studyLevel: programData?.studyLevel || "",
    scholarship: programData?.scholarship || "",
    workPracticum: programData?.workPracticum || "",
    hoursPerWeek: programData?.hoursPerWeek || "",
    selectedSemesterCount: programData?.noOfSemesters || "",
    maxModulesPerSemester: programData?.semester || "",
    campus: programData?.campus || "",
    programCoordinator: programData?.programCoordinator || null,
    studentAdvisor: programData?.studentAdvisor || "",
    isMorning: programData?.isMorning || "",
    isAfternoon: programData?.isAfternoon || "",
    isEvening: programData?.isEvening || "",
    startTime: programData?.startTime || "",
    endTime: programData?.endTime || "",
    modeOfLearning: programData?.modeOfLearning || "",
  };

  const MaxModules = [
    { value: "1", label: 1 },
    { value: "2", label: 2 },
    { value: "3", label: 3 },
  ];
  const StudyLevel = [
    { value: "DIPLOMA", label: "Diploma" },
    { value: "CERTIFICATE", label: "Certificate" },
    { value: "POSTGRADUATEDIPLOMA", label: " Post Graduation Diploma" },
  ];
  const AcademicStatus = [
    { value: "FULLTIME", label: "Full Time" },
    { value: "PARTTIME", label: "Part Time" },
  ];
  const Campus = [
    { value: "BRAMPTON", label: "Brampton" },
    { value: "MISSISSAUGA", label: "Mississauga" },
    { value: "BRAMPTONANDMISSISSAUGA", label: "Both" },
  ];
  const ModeOfLearning = [
    { value: "CAMPUS", label: "Campus" },
    { value: "ONLINE", label: "Online" },
    { value: "HYBRID", label: "Hybrid" },
  ];

  const ProgramType = [
    { value: "VOCATIONALANDACADEMIC", label: "Vocational,Academic" },

    { value: "NONVOCATIONAL", label: "Non-Vocational" },
  ];
  const ExchangeProgram = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];
  const Scholarship = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
    { value: "NA", label: "NA" },
  ];

  const SemesterCountOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
  ];

  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const ampm = i < 12 ? "AM" : "PM";
    return [
      {
        label: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:00 ${ampm}`,
        value: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:00 ${ampm}`,
      },
      {
        label: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:15 ${ampm}`,
        value: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:15 ${ampm}`,
      },
      {
        label: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:30 ${ampm}`,
        value: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:30 ${ampm}`,
      },
      {
        label: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:45 ${ampm}`,
        value: `${hour < 10 ? "0" : ""}${hour === 0 ? 12 : hour}:45 ${ampm}`,
      },
    ];
  }).flat();

  const [studentAdvisorOptions, setStudentAdvisorOptions] = useState([]);
  useEffect(() => {
    axiosService.get(`${ROLE_URLS.GET_STUDENT_URL}`).then((response) => {
      const user = response?.data?.data;

      const studentAdvisorOptions = user?.map((user) => ({
        value: user.firstName,
        label: user.firstName,
        studentAdvisorObject: { userId: user?.userId },
      }));
      if (studentAdvisorOptions) {
        setStudentAdvisorOptions(studentAdvisorOptions);
      }
    });
  }, []);

  const [programCoordinatorOptions, setProgramCoordinatorOptions] = useState(
    []
  );

  useEffect(() => {
    axiosService.get(`${ROLE_URLS.GET_FACULTY_URL}`).then((response) => {
      const user = response?.data?.data;

      const programCoordinatorOptions = user?.map((user) => ({
        value: user.firstName,
        label: user.firstName,
        programCoordinatorObject: { userId: user?.userId },
      }));
      if (programCoordinatorOptions) {
        setProgramCoordinatorOptions(programCoordinatorOptions);
      }
    });
  }, []);

  const handleSemesterCountChange = (option) => {
    setSelectedSemesterCount(option.value);
  };
  const handleMaximumSemester = (option) => {
    setMaxModulesPerSemester(option.value);
  };

  const isSaveButtonDisabled = (values) => {
    return (
      values.programName === "" ||
      values.programCode === "" ||
      values.studyLevel === "" ||
      values.academicStatus === "" ||
      values.modeOfLearning === "" ||
      values.duration === "" ||
      values.scholarship === "" ||
      values.exchangeProgram === "" ||
      values.campus === "" ||
      values.programType === "" ||
      values.programCoordinator === "" ||
      values.studentAdvisor === "" ||
      selectedSemesterCount === "" ||
      maxModulesPerSemester === ""
    );
  };
  const isModuleButtondisabled = () => {
    return selectedSemesterCount === "" || maxModulesPerSemester === "";
  };

  useEffect(() => {
    if (programId) {
      axiosService
        .get(`${PROGRAM_URLS.GET_PROGRAM_BY_PROGRAM_ID}/${programId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response?.data?.data?.master;
            const moduleData = response?.data?.data?.response;
            setProgramData(responseData);
            setProgramOverview(responseData?.programOverview);
            setCareerPath(responseData?.careerPath);
            setEnglishRequirements(responseData?.englishProficiencyRequirement);
            setAdmissionRequirements(responseData?.admissionRequirements);
            setCourseOutcome(responseData?.courseOutcome);
            setSelectedSemesterCount(responseData?.noOfSemesters);
            setMaxModulesPerSemester(responseData?.semester);
            setIsAfternoonChecked(responseData?.isAfternoon);
            setIsMorningChecked(responseData?.isMorning);
            setIsEveningChecked(responseData?.isEvening);
            setModuleDatas(moduleData);
            setStartTime(responseData?.startTime);
            setEndTime(responseData?.endTime);
            if (responseData?.startTime && responseData?.endTime) {
              setCustomChecked(true);
            }
          }
        });
    }
  }, [location.pathname, programId]);

  const modifiedModuleDatas = moduleDatas.map((moduleGroup) => {
    return moduleGroup.map((module) => {
      return {
        ...module,
        facultyAssigned: { userId: module?.facultyAssigned?.userId },
      };
    });
  });

  const onSubmit = async (formValues) => {
    const masterData = {
      program: formValues?.programName.trim(),
      programCode: formValues?.programCode,
      academicStatus: formValues?.academicStatus,
      programDuration: formValues?.duration,
      exchangeProgram: formValues?.exchangeProgram,
      programType: formValues?.programType,
      otherProgram: formValues?.ifOthers,
      studyLevel: formValues?.studyLevel,
      modeOfLearning: formValues?.modeOfLearning,
      scholarship: formValues?.scholarship,
      workPracticum: formValues?.workPracticum,
      hoursPerWeek: formValues?.hoursPerWeek,
      campus: formValues?.campus,
      programCoordinator: formValues?.programCoordinator,
      studentAdvisor: formValues?.studentAdvisor,
      noOfSemesters: selectedSemesterCount,
      semester: maxModulesPerSemester,
      isMorning: isMorningChecked,
      isAfternoon: isAfternoonChecked,
      isEvening: isEveningChecked,
      isCustom: customChecked,
      programOverview: programOverview,
      careerPath: careerPath,
      admissionRequirements: admissionRequirements,
      englishProficiencyRequirement: englishRequirements,
      courseOutcome: courseOutcome,
      startTime: startTime,
      endTime: endTime,
    };
    const requestData = {
      master: masterData,
      response: modifiedModuleDatas,
    };
    try {
      if (programData) {
        requestData.master.programFeeMasterId = programData?.programFeeMasterId;

        const response = await axiosService.put(
          `${PROGRAM_URLS.ADD_PROGRAM_ACADEMICS}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Updated successfully");
          navigate("/academics-management/all-program");
        } else {
          toast.error("Failed to update");
        }
      } else {
        const response = await axiosService.post(
          `${PROGRAM_URLS.ADD_PROGRAM_ACADEMICS}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Saved successfully");
          navigate("/academics-management/all-program");
        } else {
          toast.error("failed");
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="view-leads-table-container ">
      <div className="sticky-header lead-table-head-container align-items-center justify-content-sm-start flex-row flex-wrap gap-2">
        <h6 className="lead-table-title-text">
          <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
            <IoIosArrowBack size={20} />
          </span>
          Add a Program
        </h6>

        <div className="d-flex ms-sm-auto align-items-center gap-3">
          <div className="academic-all-program-module-count">
            Total Module<span>{totalModulesCount || "00"}</span>
          </div>
          <div className="academic-all-program-module-count border-0">
            *Total Module Credits<span>{modulesCreditCount || "00"}</span>
          </div>
        </div>

        <div className="d-flex align-items-center gap-3">
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip className="custom-tooltip academic-tooltip" color="red">
                Number of semesters is empty
              </Tooltip>
            }
          >
            <button
              className={`communication-save-head-button ${
                isModuleButtondisabled() ? "user-save-head-button-disabled" : ""
              }`}
              onClick={() => {
                setIsModalOpen(true);
              }}
              disabled={isModuleButtondisabled()}
            >
              Manage Modules
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div className="mt-2">
        <h6 style={{ color: "#F69F3A", fontSize: "18px", fontWeight: 400 }}>
          Program Details
        </h6>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <Form>
                <div className="d-flex flex-wrap gap-3">
                  <div className="academics-add-program-input-con-big">
                    <label className="">Program Name *</label>
                    <Field
                      className="program-input"
                      placeholder="Enter Program Name"
                      name="programName"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label className="">Program Code *</label>
                    <Field
                      className="program-input"
                      placeholder="Enter Program Code"
                      name="programCode"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Academic Status *</label>
                    <Field
                      className="resource-input"
                      name="academicStatus"
                      render={({ field, form }) => (
                        <Select
                          id="academicStatus"
                          value={
                            field.value
                              ? AcademicStatus.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={AcademicStatus}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label className="">
                      Program Duration *
                      <span style={{ fontSize: "12px" }}>(in weeks)</span>
                    </label>
                    <Field
                      className="program-input"
                      placeholder="Enter Program Duration"
                      name="duration"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Exchange Program *</label>
                    <Field
                      className="resource-input"
                      name="exchangeProgram"
                      render={({ field, form }) => (
                        <Select
                          id="exchangeProgram"
                          value={
                            field.value
                              ? ExchangeProgram.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={ExchangeProgram}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Type of Program *</label>
                    <Field
                      className="resource-input"
                      name="programType"
                      render={({ field, form }) => (
                        <Select
                          id="programType"
                          value={
                            field.value
                              ? ProgramType.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={ProgramType}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label className="">if, Others </label>
                    <Field
                      className="program-input"
                      placeholder="Enter Type of Program"
                      name="otherProgram"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Study Level *</label>
                    <Field
                      className="resource-input"
                      name="studyLevel"
                      render={({ field, form }) => (
                        <Select
                          id="studyLevel"
                          value={
                            field.value
                              ? StudyLevel.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={StudyLevel}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Scholarship (if applicable) *</label>
                    <Field
                      className="resource-input"
                      name="scholarship"
                      render={({ field, form }) => (
                        <Select
                          id="scholarship"
                          value={
                            field.value
                              ? Scholarship.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={Scholarship}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label className="">Work Practicum (Hours)</label>
                    <Field
                      className="program-input"
                      placeholder="enter NA if not applicable"
                      name="workPracticum"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label className="">Hours per week</label>
                    <Field
                      className="program-input"
                      placeholder="Only numbers"
                      name="hoursPerWeek"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>No. of Semesters *</label>
                    <Select
                      id="semesterCount"
                      value={SemesterCountOptions.find(
                        (option) => option.value === selectedSemesterCount
                      )}
                      onChange={handleSemesterCountChange}
                      options={SemesterCountOptions}
                      isSearchable={false}
                      classNamePrefix="react-select-big"
                      components={{
                        DropdownIndicator: () => (
                          <RxTriangleDown color="#ffffff" />
                        ),
                        IndicatorSeparator: null,
                      }}
                      isDisabled={true}
                      placeholder="Choose an Option"
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Max. modules / Semester *</label>

                    <Field
                      className="program-input"
                      name="maxModules"
                      value={maxModulesPerSemester}
                      onChange={handleMaxModulesChange}
                      /* 
                     render={({ field, form }) => (
                        <Select
                          id="maxModules"
                          value={
                            maxModulesPerSemester
                              ? MaxModules.find(
                                  (option) =>
                                    option.value ===
                                    maxModulesPerSemester.toString()
                                )
                              : null
                          }
                          onChange={handleMaximumSemester}
                          options={MaxModules}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )} 
                      */
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Campus *</label>
                    <Field
                      className="resource-input"
                      name="campus"
                      render={({ field, form }) => (
                        <Select
                          id="campus"
                          value={
                            field.value
                              ? Campus.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={Campus}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>

                  <div className="academics-add-program-input-con">
                    <label>Program Coordinator *</label>

                    <Field
                      className="lead-select-input"
                      name="programCoordinator"
                      render={({ field, form }) => (
                        <Select
                          value={
                            field.value && field.value.userId
                              ? programCoordinatorOptions.find(
                                  (option) =>
                                    option.programCoordinatorObject?.userId ===
                                    field.value.userId
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(
                              field.name,
                              option ? option.programCoordinatorObject : null
                            );
                          }}
                          options={programCoordinatorOptions}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Student Advisor *</label>

                    <Field
                      className="lead-select-input"
                      name="studentAdvisor"
                      render={({ field, form }) => (
                        <Select
                          value={
                            field.value && field.value.userId
                              ? studentAdvisorOptions.find(
                                  (option) =>
                                    option.studentAdvisorObject?.userId ===
                                    field.value.userId
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(
                              field.name,
                              option ? option.studentAdvisorObject : null
                            );
                          }}
                          options={studentAdvisorOptions}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="academics-add-program-input-con">
                    <label>Mode of Learning *</label>
                    <Field
                      className="resource-input"
                      name="modeOfLearning"
                      render={({ field, form }) => (
                        <Select
                          id="modeOfLearning"
                          value={
                            field.value
                              ? ModeOfLearning.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={ModeOfLearning}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>

                  <div className="d-flex flex-column">
                    <label>Class Timings</label>
                    <div className="d-flex flex-wrap gap-2 w-100 mt-2">
                      <span className="">
                        <div className="column checkbox-con d-flex items-center gap-2">
                          <Field
                            type="checkbox"
                            className="program-checkbox"
                            name="isMorning"
                            checked={isMorningChecked}
                            onChange={() =>
                              handleClassTimingChange({
                                value: "MORNING",
                              })
                            }
                          />
                          <span className="program-checkbox-text-head">
                            Morning
                          </span>
                        </div>

                        <span className="program-checkbox-text">
                          06:30 AM - 10:30 AM
                        </span>
                      </span>
                      <span className="">
                        <div className="column checkbox-con d-flex items-center gap-2">
                          <Field
                            type="checkbox"
                            className="program-checkbox"
                            name="isAfternoon"
                            checked={isAfternoonChecked}
                            onChange={() =>
                              handleClassTimingChange({
                                value: "AFTERNOON",
                              })
                            }
                          />
                          <span className="program-checkbox-text-head">
                            Afternoon
                          </span>
                        </div>

                        <span className="program-checkbox-text">
                          02:30 PM - 06:30 PM
                        </span>
                      </span>
                      <span className="">
                        <div className="column checkbox-con d-flex items-center gap-2">
                          <Field
                            type="checkbox"
                            className="program-checkbox"
                            name="isEvening"
                            checked={isEveningChecked}
                            onChange={() =>
                              handleClassTimingChange({
                                value: "EVENING",
                              })
                            }
                          />
                          <span className="program-checkbox-text-head">
                            Evening
                          </span>
                        </div>

                        <span className="program-checkbox-text">
                          05:30 PM - 09:30 PM
                        </span>
                      </span>

                      <span className="d-flex flex-column">
                        <div className="column checkbox-con d-flex items-center gap-2">
                          <Field
                            type="checkbox"
                            className="program-checkbox"
                            name="custom"
                            checked={customChecked}
                            onChange={() =>
                              handleClassTimingChange({
                                value: "CUSTOM",
                              })
                            }
                          />
                          <span className="program-checkbox-text-head">
                            Custom
                          </span>
                        </div>

                        {customChecked && (
                          <div className="d-flex gap-2 align-items-center mt-2">
                            <div className="d-flex align-items-center gap-2">
                              <label
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 300,
                                }}
                              >
                                Start Time
                              </label>

                              <Select
                                id="startTime"
                                options={hours}
                                onChange={(option) =>
                                  setStartTime(option.value)
                                }
                                isSearchable={false}
                                classNamePrefix="react-select-time"
                                components={{
                                  DropdownIndicator: () => (
                                    <RxTriangleDown color="#ffffff" />
                                  ),
                                  IndicatorSeparator: null,
                                }}
                                placeholder="Choose"
                                menuPlacement="auto"
                                menuPosition="fixed"
                                value={hours.find(
                                  (hour) => hour.value === startTime
                                )}
                              />
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <label
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 300,
                                }}
                              >
                                End Time
                              </label>

                              <Select
                                id="endTime"
                                options={hours.filter(
                                  (hour, index) =>
                                    index >
                                    hours.findIndex(
                                      (h) => h.value === startTime
                                    )
                                )}
                                onChange={(option) => setEndTime(option.value)}
                                isSearchable={false}
                                classNamePrefix="react-select-time"
                                components={{
                                  DropdownIndicator: () => (
                                    <RxTriangleDown color="#ffffff" />
                                  ),
                                  IndicatorSeparator: null,
                                }}
                                placeholder="Choose"
                                menuPlacement="auto"
                                menuPosition="fixed"
                                value={hours.find(
                                  (hour) => hour.value === endTime
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="col-12 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Program Overview
                      </label>
                      {programOverview && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal("Program Overview", programOverview)
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          programOverview
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {programOverview ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: programOverview,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => openEditorModal("Program Overview")}
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="col-12 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Career Path
                      </label>
                      {careerPath && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal("Career Path", careerPath)
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          careerPath
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {careerPath ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: careerPath,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => openEditorModal("Career Path")}
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="col-12 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Admission Requirements
                      </label>
                      {admissionRequirements && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal(
                              "Admission Requirements",
                              admissionRequirements
                            )
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          admissionRequirements
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {admissionRequirements ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: admissionRequirements,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() =>
                              openEditorModal("Admission Requirements")
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="col-12 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        English Proficiency Requirement
                      </label>
                      {englishRequirements && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal(
                              "English Proficiency Requirement",
                              englishRequirements
                            )
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          englishRequirements
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {englishRequirements ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: englishRequirements,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() =>
                              openEditorModal("English Proficiency Requirement")
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="col-12 d-flex flex-column">
                      <label className="forum-accordian-title-text">
                        Course Outcome
                      </label>
                      {courseOutcome && (
                        <div
                          className="edit-program-text-editor-head"
                          onClick={() =>
                            openEditorModal("Course Outcome", courseOutcome)
                          }
                        >
                          <EditIcon />
                          <span className="edit-text-icon">
                            Click to edit content
                          </span>
                        </div>
                      )}
                      <div
                        className={`${
                          courseOutcome
                            ? "edit-program-text-editor"
                            : "add-program-text-editor"
                        }`}
                      >
                        {courseOutcome ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: courseOutcome,
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => openEditorModal("Course Outcome")}
                            dangerouslySetInnerHTML={{
                              __html:
                                '<span class="placeholder-text">Click to open text editor</span>&nbsp;',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3 py-3">
                  <button
                    type="button"
                    className="user-head-button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className={`user-save-head-button ${
                      isSaveButtonDisabled(values)
                        ? "user-save-head-button-disabled"
                        : ""
                    }`}
                    disabled={isSaveButtonDisabled(values)}
                  >
                    {programId ? "Update" : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AddModulesProgram
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        selectedSemesterCount={selectedSemesterCount}
        SemesterCountOptions={SemesterCountOptions}
        maxModulesPerSemester={maxModulesPerSemester}
        editModuleDatas={[moduleDatas]}
      />

      <EditorModal
        isOpen={isEditorModalOpen}
        onClose={closeEditorModal}
        onSave={handleEditorSave}
        label={currentLabel}
        initialHtmlContent={initialEditorContent}
      />
    </div>
  );
}

export default AddProgram;
