import { Field, Form, Formik } from "formik";
import CLOA from "modules/pdf-generation/CLOA/CLOA";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import AdmissionAppilicationSidebar from "../admission-appilication-sidebar/AdmissionAppilicationSidebar";
import "./CreateFinancial.css";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import sessionService from "services/sessionstorage.service";

function CreateFinancial() {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;

  const [admissionData, setAdmissionData] = useState();
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [totalFee, setTotalFee] = useState(null);
  const feeData = data?.feeData;
  const userData = sessionService.getUserData();
  const studentType = userData?.studentType;
  useEffect(() => {
    if (data?.programId != undefined) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const feeId = response?.data?.data?.feeId;

          if (feeId != undefined) {
            axiosService
              .get(
                `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_AND_PROGRAM}${admissionId}?programId=${data?.programId}&feeId=${feeId}`
              )
              .then((response) => {
                const data = response.data;
                const admissionManagement = response?.data?.admissionManagement;

                if (
                  admissionManagement?.personalData?.isApproved &&
                  admissionManagement?.mailingAddress?.isApproved &&
                  admissionManagement?.guardianDetail?.isApproved &&
                  admissionManagement?.academics[0]?.isApproved &&
                  admissionManagement?.employments[0]?.isApproved &&
                  admissionManagement?.otherInformation?.isApproved &&
                  admissionManagement?.upload?.isApproved &&
                  admissionManagement?.profile?.isApproved &&
                  admissionManagement?.profile?.isApproved &&
                  data.programDetail?.isApproved &&
                  data.fee?.isApproved
                ) {
                  setEnableSubmit(true);
                } else {
                  setEnableSubmit(false);
                }
              });
          }
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
        });
    }
  }, [location.pathname]);

  const calculateTotalFee = () => {
    if (admissionData !== undefined) {
      let sum =
        parseFloat(admissionData.applicationFee || 0) +
        parseFloat(admissionData.tuitionFee || 0) +
        parseFloat(admissionData.materialFee || 0) +
        parseFloat(admissionData.fieldTripsFee || 0) +
        parseFloat(admissionData.eslFee || 0) +
        parseFloat(admissionData.bankFee || 0) +
        parseFloat(admissionData.otherFee || 0);

      if (studentType === "INTERNATIONAL") {
        sum += parseFloat(admissionData.internationalFee || 0);
      }

      const total = sum - parseFloat(admissionData.bursaries || 0);
      setTotalFee(total);
    }
  };

  const calculateTotalFeeByFeeData = () => {
    if (feeData !== undefined) {
      let sum =
        parseFloat(feeData.applicationFee || 0) +
        parseFloat(feeData.tuitionFee || 0) +
        parseFloat(feeData.materialFee || 0) +
        parseFloat(feeData.fieldTripsFee || 0) +
        parseFloat(feeData.eslFee || 0) +
        parseFloat(feeData.bankFee || 0) +
        parseFloat(feeData.otherFee || 0);

      if (studentType === "INTERNATIONAL") {
        sum += parseFloat(feeData.internationalFee || 0);
      }
      const total = sum - parseFloat(feeData.bursaries || 0);
      setTotalFee(total);
    }
  };

  useEffect(() => {
    if (admissionData !== null) {
      calculateTotalFee();
      calculateTotalFeeByFeeData();
    }
  }, [admissionData]);

  const initialValues = {
    applicationFee: "",
    bursaries: "",
    materialFee: "",
    fieldTripsFee: "",
    internationalFee: "",
    eslFee: "",
    bankFee: "",
    otherFee: "",
    tuitionFee: "",
    otherFeeDetail: "",
    feeId: admissionData?.feeId,
    totalFee: "",
  };

  const onSubmit = async (values) => {
    const dataType = "fees";
    values.isRejected = false;

    const programDetail = {
      programId: data?.programId,
    };
    const { feeMasterId, ...valuesWithoutFeeMasterId } = values;

    const sendData = {
      ...valuesWithoutFeeMasterId,
      programDetail,
    };

    const saveData = {
      dataType,
      data: {
        admissionId: admissionId,
        fees: [sendData],
      },
    };
    setDisable(true);

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );
      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.FEE_SUCCESS);
        navigate("/pre-onboarding/view-application-student");
      } else {
        sessionstorageService.isLoading("false");

        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.FEE_FAILURE);
      }
    } catch (error) {
      setDisable(false);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.FEE_ERROR_OCCUR);
      }
    }
  };

  const goToPrevious = () => {
    navigation("/pre-onboarding/create-program-details", {
      state: { data: data },
    });
  };

  const dateString = new Date(admissionData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleAlert = () => {
    Swal.fire({
      title: "Confirm Submit",
      html: "Are you sure you want to submit the application?<br><span style='font-size: 13px;'>Please note that once submitted, changes cannot be made.</span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(admissionData || data?.feeData || initialValues);
      }
    });
  };

  return (
    <div className="create-admission-main-container">
      <div className="progress-container">
        <div className="create-admission-head-text">Create New Application</div>
      </div>
      <div className="row create-admission-container g-0">
        <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
          <AdmissionAppilicationSidebar
            data={{
              admissionId: admissionId,
              programId: data?.programId,
            }}
          />
        </div>
        <div className="create-admission-inner-right-container col-12 col-lg-9 pt-2">
          <div className="create-text-title ">Fee/Financial</div>
          <Formik
            initialValues={admissionData || data?.feeData || initialValues}
            enableReinitialize={true}
          >
            {() => {
              return (
                <Form>
                  <div className="create-financial-main-input-container">
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">
                        Application Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="applicationFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">Tuition Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="tuitionFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <div className="label-text-active">
                        Scholarship/Bursaries
                      </div>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="bursaries"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="create-financial-main-input-container">
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">
                        Material/Books Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="materialFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">
                        Field Trips Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="fieldTripsFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="create-financial-main-input-container">
                    {studentType === "INTERNATIONAL" && (
                      <div
                        className="create-admission-inner-first-input-container"
                        style={{ width: "150px" }}
                      >
                        <label className="label-text-active">
                          International Fee
                        </label>
                        <span className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Field
                            disabled={true}
                            name="internationalFee"
                            className="form-control input-group create-financial-input-small"
                          />
                        </span>
                      </div>
                    )}
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">ESL Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="eslFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <div className="label-text-active">Bank Fee</div>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="bankFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="create-financial-main-input-container">
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">Other Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={true}
                          name="otherFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>

                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <div className="label-text-active">Other Fee Detail</div>
                      <Field
                        disabled={true}
                        name="otherFeeDetail"
                        className="  create-financial-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-financial-main-input-container">
                    <div
                      className="create-admission-inner-first-input-container"
                      style={{ width: "150px" }}
                    >
                      <label className="label-text-active">Total fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          value={totalFee}
                          disabled={true}
                          name="totalFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex gap-3 my-3 flex-wrap">
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={goToPrevious}
                    >
                      Previous
                    </button>

                    {admissionData === undefined ||
                    admissionData?.isRejected ? (
                      <button
                        disabled={disable}
                        className="create-admission-save-button"
                        onClick={handleAlert}
                      >
                        Submit
                      </button>
                    ) : null}

                    {enableSubmit && (
                      <button
                        type="button"
                        className=" create-admission-next-button"
                        onClick={() => {
                          const targetPath =
                            studentType === "OSAP"
                              ? "/pre-onboarding/paymentsubmission"
                              : "/pre-onboarding/cloa-paymentsubmission";

                          navigation(targetPath, {
                            state: {
                              data: {
                                admissionId: admissionId,
                                programId: data?.programId,
                              },
                            },
                          });
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                  {admissionData?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {loading && <div class="overlaypage"></div>}
    </div>
  );
}

export default CreateFinancial;
