import React from "react";
import useravatar from "assets/images/user-avatar.svg";
import { BsCalendar3 } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const ViewFacultyAttendance = () => {
  const location = useLocation();
  const employeeData = location?.state?.employeeData;

  return (
    <div
      style={{
        padding: "15px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div className="view-faculty-header">
        <div className="view-faculty-header-left">
          <img
            src={useravatar}
            style={{ height: "80px", width: "80px", borderRadius: "50%" }}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="view-faculty-header-name">
              {employeeData?.gender === "MALE"
                ? "Mr. "
                : employeeData?.gender === "FEMALE"
                ? "Mrs. "
                : ""}
              {employeeData?.employeeFirstName +
                " " +
                employeeData?.employeeLastName}
            </span>
            <span className="view-faculty-header-left-position">
              Position: {employeeData?.position}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="view-faculty-header-left-grey-title">
              Campus Locations
            </span>
            <span className="view-faculty-header-left-content">
              {employeeData?.campusLocation}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="view-faculty-header-left-grey-title">
              Department
            </span>
            <span className="view-faculty-header-left-content">
              {employeeData?.campusLocation}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="view-faculty-header-left-grey-title">DOJ</span>
            <span className="view-faculty-header-left-content">
              {employeeData?.acceptedDateJoining}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="view-faculty-header-left-grey-title">Status</span>
            <span className="view-faculty-header-left-content">
              {employeeData?.status}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#FFFFFF",
            fontSize: "12px",
            paddingLeft: "10px",
          }}
        >
          <span>
            <BsCalendar3 style={{ height: "26px", width: "26px" }} />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "#F7931D",
              fontSize: "12px",
              paddingLeft: "10px",
            }}
          >
            <span>view faculty</span>
            <span>attendance</span>
          </span>
        </div>
      </div>

      <div className="view-faculty-basic-info"></div>
    </div>
  );
};

export default ViewFacultyAttendance;
