import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "utils/communication-management.css";
import "utils/forum-discussion.css";
import AcademicTranscript from "../components/enrollment-letter/academicTranscript";
import CLOAForDocument from "../components/enrollment-letter/cloa";
import DocumentsRoutesForPdf from "../components/enrollment-letter/commonPdfViewer";
import BreakLetter from "../components/enrollment-letter/downloadBreakLetter";
import EnrollmentView from "../components/enrollment-letter/enrollment";
import FeesInvoice from "../components/enrollment-letter/feesInvoice";
import FLOAForDocument from "../components/enrollment-letter/floa";
import OfficialReceipt from "../components/enrollment-letter/officialReceipt";
import OfficialReceiptProvisional from "../components/enrollment-letter/officialReceiptProvisional";
import TravelSupport from "../components/enrollment-letter/travelSupport";
import WithDrawalLetter from "../components/enrollment-letter/withDrawalLetter";
import QrComponent from "../components/QrComponent";

function DocumentsRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/documents") {
      navigate("/documents/enrolment");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="enrolment" element={<EnrollmentView />} />
      <Route path="academic-transcript" element={<AcademicTranscript />} />
      <Route path="withdrawal-letter" element={<WithDrawalLetter />} />
      <Route path="break-letter" element={<BreakLetter />} />
      <Route path="fees-invoice" element={<FeesInvoice />} />
      <Route path="official-receipt" element={<OfficialReceipt />} />
      <Route
        path="official-receipt-provisional"
        element={<OfficialReceiptProvisional />}
      />
      <Route path="travel-support" element={<TravelSupport />} />
      <Route path="CLOA" element={<CLOAForDocument />} />
      <Route path="FLOA" element={<FLOAForDocument />} />
      <Route path="documents-view" element={<DocumentsRoutesForPdf />} />
    </Routes>
  );
}

export default DocumentsRoutes;
