import { AppConfig } from "env.development";

export const CIRCULAR_URLS = {
  LISTCREATE_CIRCULAR: `${AppConfig.api_url}circular/v1/all`,
  VIEW_CIRCULAR: `${AppConfig.api_url}circular/v1`,
  DELETE_CIRCULAR: `${AppConfig.api_url}circular/v1/`,
  GET_ALL_STUDENTS: `${AppConfig.api_url}circular/v1/get-students`,
  GET_NOTIFICATION_BY_USER_ID: `${AppConfig.api_url}circular/v1/notification/`,
  UPDATE_CIRCULAR_NOTIFICATION: `${AppConfig.api_url}circular/v1/update-notification/`,
  GET_NOTIFICATION_BY_USER_ID: `${AppConfig.api_url}user/v1/notification-history/all/`,
};
