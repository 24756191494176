import { AppConfig } from "env.development";

export const USER_URL = {
  USER: `${AppConfig.api_url}user/v1`,
  USERS_GET_URL: `${AppConfig.api_url}user/v1/all`,
  USERS_OF_GET_URL: `${AppConfig.api_url}user/v1/getAll`,
  GET_USER_BY_ROLENAME: `${AppConfig.api_url}user/v1/getAllByRole`,
  POST_STARTDATE_PROGRAM: `${AppConfig.api_url}program-date/v1`,
  GET_ALL_STARTDATE_PROGRAM: `${AppConfig.api_url}program-date/v1/all`,
  GET_ALL_STAFF_NAME: `${AppConfig.api_url}staff/v1/all`,
  POST_STAFF_NAME: `${AppConfig.api_url}staff/v1`,
  GET_STAFF_NAME_BY_ID: `${AppConfig.api_url}staff/v1/`,
  GET_ALL_USERS_EXCEPT_STUDENTS_AND_PRESTUDENTS: `${AppConfig.api_url}user/v1/get-user/all`,
};
