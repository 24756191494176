import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { axiosService } from "services/axios.service";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import { toast } from "react-toastify";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import { useNavigate } from "react-router-dom";

function UpdatePasswordPopup({ open, onClose, userId }) {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const navigate = useNavigate();

  function ChangePassword() {
    if (password === confirmPassword) {
      let Data = {
        userId: userId,
        password: password,
      };

      try {
        axiosService
          .post(`${ROLE_URLS.UPDATE_PASSWORD}`, Data)
          .then((response) => {
            if (response.status === 200) {
              // sessionStorage.clear();
              // navigate("/");
            }
          })
          .catch((error) => {
            toast.error(
              `${error?.response?.data?.message.split(":")[1]?.trim()}`
            );
          });
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Password and Confirm password differs");
      console.error("password differs");
    }
  }

  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-4 p-3"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                Change Password
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                <IoClose color="#ffffff" size={"22px"} />
              </span>
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div>
                <label className="new-conversation-popup-label">
                  Password*
                </label>

                <input
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div>
                <label className="new-conversation-popup-label">
                  Confirm Password*
                </label>

                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="d-flex gap-3 mt-2">
                <button
                  className="communication-management-cancel-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={!password || !confirmPassword}
                  className={`communication-management-submit-button ${
                    (!password || !confirmPassword) && "opacity-50"
                  }`}
                  onClick={() => ChangePassword()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UpdatePasswordPopup;
