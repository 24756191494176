export const RegisterConstants = {
  REGISTER_SUCCESS:
    "Registration is successful! Please verify your email to be able to login",
  REGISTER_FAILED: "Registration Failed",
  AGREE_TERMS: "Please agree to the terms and conditions before proceeding.",
  ERROR_OCCUR:
    "An unexpected error occurred during registration. Please contact admin/support",
  EMAIL_REQUIRED: "Email is required",
  INVALID_EMAIL: "Invalid email address",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_VALID: "Password is not valid",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  PASSWORD_NOT_MATCH: "Passwords do not match",
  FIRST_NAME_REQUIRED: "Firstname is required",
  LAST_NAME_REQUIRED: "Lastname is required",
  MOBILE_NUMBER_REQUIRED: "Mobile Number is required",
  STUDENT_VERIFICATION_INVALID: "Error: Invalid Student Information",
};

export const LoginConstants = {
  LOGIN_SUCCESS: "Login is successful",
  LOGIN_FAILED: "Login Failed",
  ERROR_OCCUR:
    "An unexpected error occurred during Login. Please contact admin/support",
  LOGIN_REQUIRED: "Please fill in both email and password.",
  NETWORK_ERROR: "Server not connected. Please contact admin/support",
};

export const EnqFormConstants = {
  ENQUIRY_SUCCESS:
    "Enquiry Form submitted successfully! We will get back to you",
  ENQUIRY_FAILED: "Enquiry Form Failed",
  ERROR_OCCUR:
    "An unexpected error occurred during Enquiry. Please contact admin/support",
  AGREE_TERMS: "Please agree to the terms and conditions before proceeding.",
};
