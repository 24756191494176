import React from "react";

function AcademicsLogo({ color }) {
  return (
    <svg
      width="37"
      height="24"
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2427 1.30558H32.6862V0.493472C32.6862 0.221357 32.4648 0 32.1927 0H22.1569C22.1498 0 22.1414 0 22.1343 0C20.0744 0.0930547 18.7435 1.24073 18.1175 1.94005C17.4915 1.24073 16.1605 0.0930547 14.1006 0C14.0936 0 14.0851 0 14.078 0H4.04224C3.77013 0 3.54877 0.221357 3.54877 0.493472V1.30558H1.99222C0.89389 1.30558 0 2.19947 0 3.2978V21.9609C0 23.0592 0.89389 23.9531 1.99222 23.9531H15.2708H20.9627H34.2413C35.3396 23.9531 36.2335 23.0592 36.2335 21.9609V3.2978C36.2349 2.19947 35.341 1.30558 34.2427 1.30558ZM22.1682 0.986944H31.6992V18.9691H22.1569C22.1498 18.9691 22.1414 18.9691 22.1343 18.9691C20.6158 19.0381 19.4935 19.6909 18.783 20.2732H18.6109V2.89174C18.9155 2.48146 20.0942 1.08564 22.1682 0.986944ZM4.53571 0.986944H14.0668C16.1422 1.08564 17.3209 2.48287 17.624 2.89174V20.2746H17.452C16.747 19.6952 15.6233 19.0396 14.1006 18.9705C14.0936 18.9705 14.0851 18.9705 14.078 18.9705H4.53571V0.986944ZM0.986944 21.9623V3.2978C0.986944 2.7437 1.43812 2.29253 1.99222 2.29253H3.54877V19.4625C3.54877 19.7346 3.77013 19.956 4.04224 19.956H14.0668C14.6223 19.9828 15.1129 20.1055 15.5387 20.2746H15.2723C15.0001 20.2746 14.7788 20.496 14.7788 20.7681V22.9676H1.99363C1.43812 22.9676 0.986944 22.5164 0.986944 21.9623ZM15.7657 22.9676V21.2616H20.4706V22.9676H15.7657ZM35.248 21.9623C35.248 22.5164 34.7968 22.9676 34.2427 22.9676H21.4576V20.7681C21.4576 20.496 21.2362 20.2746 20.9641 20.2746H20.6976C21.1234 20.1055 21.6141 19.9828 22.1696 19.956H32.1941C32.4662 19.956 32.6876 19.7346 32.6876 19.4625V2.29253H34.2441C34.7982 2.29253 35.2494 2.7437 35.2494 3.2978V21.9623H35.248Z"
        fill={color}
      />
      <path
        d="M8.48175 5.47913H13.6773C13.9494 5.47913 14.1708 5.25777 14.1708 4.98566C14.1708 4.71354 13.9494 4.49219 13.6773 4.49219H8.48175C8.20964 4.49219 7.98828 4.71354 7.98828 4.98566C7.98828 5.25777 8.20964 5.47913 8.48175 5.47913Z"
        fill={color}
      />
      <path
        d="M8.48175 9.24476H13.6773C13.9494 9.24476 14.1708 9.0234 14.1708 8.75128C14.1708 8.47917 13.9494 8.25781 13.6773 8.25781H8.48175C8.20964 8.25781 7.98828 8.47917 7.98828 8.75128C7.98828 9.0234 8.20964 9.24476 8.48175 9.24476Z"
        fill={color}
      />
      <path
        d="M8.48175 13.0026H13.6773C13.9494 13.0026 14.1708 12.7812 14.1708 12.5091C14.1708 12.237 13.9494 12.0156 13.6773 12.0156H8.48175C8.20964 12.0156 7.98828 12.237 7.98828 12.5091C7.98828 12.7812 8.20964 13.0026 8.48175 13.0026Z"
        fill={color}
      />
      <path
        d="M8.48175 16.776H13.6773C13.9494 16.776 14.1708 16.5546 14.1708 16.2825C14.1708 16.0104 13.9494 15.7891 13.6773 15.7891H8.48175C8.20964 15.7891 7.98828 16.0104 7.98828 16.2825C7.98828 16.5546 8.20964 16.776 8.48175 16.776Z"
        fill={color}
      />
      <path
        d="M22.5579 5.47913H27.7535C28.0256 5.47913 28.247 5.25777 28.247 4.98566C28.247 4.71354 28.0256 4.49219 27.7535 4.49219H22.5579C22.2858 4.49219 22.0645 4.71354 22.0645 4.98566C22.0645 5.25777 22.2858 5.47913 22.5579 5.47913Z"
        fill={color}
      />
      <path
        d="M22.5579 9.24476H27.7535C28.0256 9.24476 28.247 9.0234 28.247 8.75128C28.247 8.47917 28.0256 8.25781 27.7535 8.25781H22.5579C22.2858 8.25781 22.0645 8.47917 22.0645 8.75128C22.0645 9.0234 22.2858 9.24476 22.5579 9.24476Z"
        fill={color}
      />
      <path
        d="M22.5579 13.0026H27.7535C28.0256 13.0026 28.247 12.7812 28.247 12.5091C28.247 12.237 28.0256 12.0156 27.7535 12.0156H22.5579C22.2858 12.0156 22.0645 12.237 22.0645 12.5091C22.0645 12.7812 22.2858 13.0026 22.5579 13.0026Z"
        fill={color}
      />
      <path
        d="M22.5579 16.776H27.7535C28.0256 16.776 28.247 16.5546 28.247 16.2825C28.247 16.0104 28.0256 15.7891 27.7535 15.7891H22.5579C22.2858 15.7891 22.0645 16.0104 22.0645 16.2825C22.0645 16.5546 22.2858 16.776 22.5579 16.776Z"
        fill={color}
      />
    </svg>
  );
}

export default AcademicsLogo;
