import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";

const Header = () => (
  <div style={{}}>
    <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
  </div>
);

const Footer = () => (
  <div>
    <img alt="img" src={footer} style={{ width: "100%", height: "80px" }} />
  </div>
);
export { Footer, Header };
