import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import ViewCampaign from "../all-campaigns/components/view-campaigns/ViewCampaign";
import CreateCampaign from "../all-campaigns/components/create-new-campaign/CreateCampaign";
import ViewCampaignMessages from "../all-campaigns/components/view-campaign-messages/ViewCampaignMessages";
import CreateNewMessage from "../all-campaigns/components/create-new-message/CreateNewMessage";
import ContactList from "../all-campaigns/components/contact-lists/ContactList";

function CampaignRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/campaigns") {
      navigate("/campaigns/all-campaigns");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="all-campaigns" element={<ViewCampaign />} />
      <Route path="create-campaign" element={<CreateCampaign />} />
      <Route path="all-campaign-messages" element={<ViewCampaignMessages />} />
      <Route path="create-campaign-message" element={<CreateNewMessage />} />
      <Route path="contact-list" element={<ContactList />} />
    </Routes>
  );
}

export default CampaignRoutes;
