import { Route, Routes } from "react-router-dom";
import "../../../../utils/auth.css";
import EnquiryForm from "../enquiry-form/EnquiryForm";
import Login from "../login/Login";
import SignUp from "../signup/SignUp";
import UserActivate from "../user-activate/UserActivate";
import ForgotPassword from "../forgot-password/ForgotPassword";
import StudentVerificationHome from "../student-verification-home/StudentVerificationHome";
import StudentVerificationSuccess from "../student-verification-success/StudentVerificationSuccess";
import QrComponent from "modules/documents/components/QrComponent";
import PaymentGatewayHome from "../payment-gateway-home/PaymentGatewayHome";
import PaymentStatus from "../payment-gateway-home/PaymentStatus";

function AuthRoutes() {
  const paths = ["/", "/OSAP", "/NVQ", "/INTERNATIONAL", "/ONTARIO"];

  const signupPaths = [
    "/signup",
    "/signup/OSAP",
    "/signup/NVQ",
    "/signup/INTERNATIONAL",
    "/signup/ONTARIO",
  ];
  return (
    <Routes>
      {paths.map((path) => (
        <Route key={path} path={path} element={<Login />} />
      ))}
      {signupPaths.map((path) => (
        <Route key={path} path={path} element={<SignUp />} />
      ))}
      <Route path="user-activate" element={<UserActivate />} />
      <Route path="enquiry-form" element={<EnquiryForm />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="verification-home" element={<StudentVerificationHome />} />
      <Route path="qr-code" element={<QrComponent />} />
      <Route
        path="verification-success"
        element={<StudentVerificationSuccess />}
      />
      <Route path="payment" element={<PaymentGatewayHome />} />
      <Route path="payment-result" element={<PaymentStatus />} />
    </Routes>
  );
}

export default AuthRoutes;
