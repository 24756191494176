import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import footer from "assets/images/Beta_New_Footer.png";
import sha256 from "crypto-js/sha256";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosService } from "services/axios.service";
import beta from "assets/images/Beta_New_Header.png";
import { PDF_URLS } from "../constants/pdf-urls";
import qrcode from "qrcode";

const FLOA = ({ data, setLoading, applicationNo }) => {

  const [pdfData, setPdfData] = useState(null);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      return "";
    }
  };

  const HeaderForFloa = () => (
    <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
      <Image alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </View>
  );

  const FooterForFloa = () => (
    <View
      style={{ position: "absolute", top: "-80px", left: "-50px", right: 0 }}
    >
      <Image alt="img" src={footer} style={{ width: "110%", height: "80px" }} />
    </View>
  );

  const location = useLocation();
  useEffect(() => {
    axiosService
      .get(
        `${PDF_URLS.GET_ADMISSION_AND_PROGRAM}${data?.admissionId}?programId=${data?.programId}&feeId=${data?.feeId}`
      )
      .then((response) => {
        setPdfData(response?.data);
      });
  }, [location.pathname]);

  const currentDate = new Date();

  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);

  const formattedIssueDate = formatDate(currentDate);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    generatePDFLink();
  }, [pdfData !== null]);

  const feeTableText = "";
  const Issue = formattedIssueDate;
  const Expiry = pdfData?.fee?.programDetail?.expirationAcceptanceLetter;
  const Name = pdfData?.admissionManagement?.personalData?.lastName;
  const FirstName = pdfData?.admissionManagement?.personalData?.firstName;
  const Yes = "";
  const CAQ = "";
  const Expiry1 = "";
  const Term1 =
    pdfData?.fee?.programDetail?.programFee?.installment1 !== ""
      ? pdfData?.fee?.programDetail?.programFee?.installment1
      : "-";
  const Term2 =
    pdfData?.fee?.programDetail?.programFee?.installment2 !== ""
      ? pdfData?.fee?.programDetail?.programFee?.installment2
      : "-";
  const Term3 =
    pdfData?.fee?.programDetail?.programFee?.installment3 !== ""
      ? pdfData?.fee?.programDetail?.programFee?.installment3
      : "-";
  const DOB = pdfData?.admissionManagement?.personalData?.dateOfBirth;
  const College = "Beta College of Business & Technology, Canada";
  const PCC = " ";
  const Contact = "";
  const Position = "";
  const Email = "";
  const DLI = "";
  const applicationFee = pdfData?.fee?.applicationFee;
  const tuitionFee = pdfData?.fee?.tuitionFee;
  const bankFee = pdfData?.fee?.bankFee;
  const internationalFee = pdfData?.fee?.internationalFee;
  const materialFee = pdfData?.fee?.materialFee;
  const eslFee = pdfData?.fee?.eslFee;
  const otherFee = pdfData?.fee?.otherFee;
  const fieldTripsFee = pdfData?.fee?.fieldTripsFee;
  const Notes = "";
  const StudentName =
    pdfData?.admissionManagement?.personalData?.firstName +
    " " +
    pdfData?.admissionManagement?.personalData?.lastName;
  const StudentId = pdfData?.admissionManagement?.personalData?.studentId;

  const Address1 = pdfData?.admissionManagement?.mailingAddress?.streetNo;
  const Address2 = pdfData?.admissionManagement?.mailingAddress?.street;
  const Address3 = pdfData?.admissionManagement?.mailingAddress?.city;
  const Address4 = pdfData?.admissionManagement?.mailingAddress?.postalCode;
  const Address5 = pdfData?.admissionManagement?.mailingAddress?.country;

  const Program =
    pdfData?.programDetail?.program +
    " - " +
    pdfData?.programDetail?.programDuration;
  const Date2 = formattedIssueDate;
  const feeTableText1 = "";

  const styles = StyleSheet.create({
    Document: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      padding: 20,
    },
    top: {
      left: 40,
      right: 40,
      color: "grey",
    },
    watermarkContainer: {
      position: "absolute",
      top: "40%",
      left: "30%",
      transform: "translate(-50%, -50%) rotate(-45deg)",
      opacity: 0.5,
    },
    watermarkText: {
      fontSize: 80,
      color: "gray",
    },
    heading: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 10,
      position: "fixed",
      flexWrap: "wrap",
      fontFamily: "Helvetica-Bold",
    },
    header: {
      fontSize: 10,
      marginBottom: 20,
      position: "fixed",
      flexWrap: "wrap",
      fontFamily: "Helvetica-Bold",
      top: -8,
    },
    betacollegelink: {
      fontSize: 10,
      marginBottom: 20,
      position: "fixed",
      flexWrap: "wrap",
      color: "#0563c1",
      fontFamily: "Helvetica-Bold",
      textDecoration: "underline",
      padding: "0px 5px",
      marginRight: "10px",
    },
    text: {
      left: 15,
      fontSize: 9,
      width: 190,
      color: "grey",
    },
    footer: {
      position: "absolute",
      bottom: 40,
      left: 52,
      right: 40,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 1,
    },
    boldText: {
      fontFamily: "Helvetica-Bold",
      fontSize: 11,
    },
    footerheading: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      left: 13,
      right: 40,
    },
    address: {
      fontSize: 10,
      textAlign: "right",
      left: 61,
      paddingLeft: 4,
      fontFamily: "Helvetica-Bold",
    },
    column: {
      width: "50%",
      flexDirection: "column",
      flexGrow: 1,
    },
    addressaligment: {
      fontSize: 9,
      width: 170,
      textAlign: "right",
      color: "grey",
    },
    conditionalletterheadding: {
      fontSize: 14,
      left: 40,
      right: 40,
      fontFamily: "Helvetica-Bold",
    },
    rednotice: {
      left: 40,
      right: 40,
      fontSize: 9,
      color: "red",
      paddingRight: 60,
      fontFamily: "Helvetica-Bold",
    },
    issueletternotice: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 16,
    },
    receiveletternotice: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 46,
    },
    Name: {},
    actualValue: {
      fontSize: 10,
      fontFamily: "Helvetica",
    },
    admissionnotice: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 56,
    },
    studentstatusnotes: {
      fontSize: 11,
      top: 60,
    },
    qrCodeImage: {
      width: 80,
      top: 140,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    qrCodeImageFor2: {
      width: 80,
      top: 517,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    qrCodeImageFor3: {
      width: 80,
      top: 190,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    qrCodeImageFor4: {
      width: 80,
      top: 395,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    issuedate: {
      fontSize: 11,
      paddingRight: 60,
      paddingBottom: 5,
      fontFamily: "Helvetica-Bold",
    },
    expirydate: {
      fontSize: 11,
      alignSelf: "flex-end",
      marginBottom: 5,
      paddingRight: 80,
      fontFamily: "Helvetica-Bold",
    },
    date: {
      flexDirection: "row",
      left: 40,
      right: 40,
      top: 10,
      justifyContent: "space-between",
    },
    headertable: {
      top: 10,
      fontSize: 12,
      color: "white",
      marginBottom: 10,
      borderTop: "1",
      borderRight: "1",
      borderLeft: "1",
      marginLeft: 40,
      marginRight: 40,
      backgroundColor: "#2F5496",
      fontFamily: "Helvetica-Bold",
      paddingTop: 1,
    },
    infoContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 5,
    },
    Signature: {
      fontSize: 11,
      marginLeft: 39,
      top: 15,
      fontFamily: "Helvetica-Bold",
    },
    departmentfolder: {
      fontSize: 11,
      marginLeft: 40,
      marginRight: 88,
      top: 98,
      fontFamily: "Helvetica-Bold",
    },
    page2: {
      fontSize: 11,
      marginLeft: 39,
      top: -5,
      fontFamily: "Helvetica-Bold",
    },
    feeTableText1: {
      fontSize: 11,
      marginLeft: 39,
      top: 22,
      paddingBottom: 7,
    },
    feeTable: {
      top: 15,
      paddingBottom: 5,
      fontSize: 11,
      marginLeft: 39,
    },
    Signature1: {
      fontSize: 11,
      marginLeft: 39,
      top: 5,
      fontFamily: "Helvetica-Bold",
    },
    bulletpoint1: {
      fontSize: 11,
      marginLeft: 59,
      top: 25,
    },
    bulletpoint: {
      fontSize: 11,
      marginLeft: 41,
      top: 77,
    },
    remarks: {
      fontSize: 11,
      marginLeft: 43,
      top: 88,
      bottom: 8,
    },
    informationlink: {
      fontSize: 11,
      marginLeft: 43,
      top: 78,
      color: "#0563c1",
    },
    mybeta: {
      fontSize: 10,
      marginLeft: 43,
      top: 78,
      color: "#0563c1",
    },
    logo: {
      width: 130,
      height: 40,
      marginLeft: "74%",
      resizeMode: "cover",
    },
    flexWrap: {
      top: 10,
    },
    border: {
      borderRightWidth: 1,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "black",
      marginLeft: 40,
      marginRight: 40,
    },
    Condition: {
      borderRightWidth: 1,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "black",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 14,
    },
    infoValue: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      flexDirection: "row",
    },
    Notes: {
      fontSize: 10,

      flexDirection: "row",
    },
    Programs: {
      width: "65%",
      fontSize: 10,
      flexDirection: "row",
      top: 20,
      right: 160,
    },
    separator: {
      borderBottomWidth: 1,
      marginTop: 10,
      marginBottom: 10,
    },
    verticalLine: {
      borderRightWidth: 1,
      borderColor: "black",
      height: "100",
    },
    right: {
      borderRightWidth: 1,
      marginLeft: 130,
      justifyContent: "flex-end",
    },
    table: {
      marginLeft: 40,
      marginRight: 40,
      top: 10,
      borderWidth: 1,
      fontSize: 9,
    },
    table1: {
      marginLeft: 40,
      marginRight: 40,
      borderWidth: 1,
      fontSize: 10,
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    tableCol: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      paddingLeft: 3,
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
    },
    RegisteredtableCol: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      paddingLeft: 3,
      fontSize: 10,
      fontFamily: "Times-Italic",
    },
    RegisteredtableCol1: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      paddingLeft: 3,
      fontSize: 11,
    },
    Registeredaligment: {
      top: 20,
    },
    Registered: {
      fontFamily: "Helvetica-Bold",
    },
    body: {
      top: 95,
    },
    alignment: {
      top: -17,
    },
    tablealigment: {
      top: 6,
    },
    Date: {
      top: 27,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    StudentName: {
      top: 47,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    Address: {
      top: 41,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 2,
    },
    Block: {
      top: 50,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    letter: {
      top: 55,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    acceptedinto: {
      top: 55,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 1,
    },
    Programinfo: {
      top: 56,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 60,
      marginRight: 40,
      paddingBottom: 10,
    },
    Beta: {
      top: 56,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    steps: {
      top: 57,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 7,
    },
    tuition: {
      top: 57,
      fontSize: 11,
      marginLeft: 52,
      marginRight: 40,
      paddingBottom: 2,
    },
    deposit: {
      top: 63,
      fontSize: 11,
      fontFamily: "Helvetica-Bold",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 9,
    },
    deposit1: {
      top: 63,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
    },
    permit: {
      top: 63,
      fontSize: 11,
      marginLeft: 40,
      fontFamily: "Helvetica-Bold",
      marginRight: 40,
      paddingBottom: 10,
    },
    applicable: {
      top: 63,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
    },
    services: {
      top: 63,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 10,
      color: "#0563c1",
    },
    qrCodeContainer: {
      backgroundColor: "violet",
    },
    file: {
      top: 63,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
    },
    Starting: {
      top: 20,
      fontSize: 11,
      marginLeft: 40,
      fontFamily: "Helvetica-Bold",
      marginRight: 40,
      paddingBottom: 2,
    },
    betacollege: {
      top: 18,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 8,
    },
    betacollege1: {
      top: 29,
      fontSize: 11,
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 1,
    },
    styles1: {
      top: 45,
    },
    studies: {
      top: 65,
    },
    newText: {
      borderTopWidth: 1,
      borderTopColor: "#000",
      paddingLeft: 3,
    },
    tableColNew: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
    },
    subText: {
      fontWeight: "normal",
    },
    link3: {
      fontSize: 10,
      marginBottom: 20,
      position: "fixed",
      flexWrap: "wrap",
      color: "#0563c1",
      fontFamily: "Helvetica-Bold",
      textDecoration: "underline",
    },
  });
  const [hashValueData, setHashValue] = useState("");

  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `FLOA-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);
 
    setHashValue(hashValue);
  }, [applicationNo]);
  const qrCodeDataURL = generateQRCodeDataURL(
    `https://erp.lmsmybeta.com/#/qr-code?hashValue=${hashValueData}`
  );

  const handleSubmit = async (blobToSend) => {
    const pdfFileName = "FLOA.pdf";

    if (hashValueData) {
      try {;
        const pdf = new File([blobToSend], pdfFileName, {
          type: "application/pdf",
        });
        const fileName = `${"FLOA"}_${applicationNo}.pdf`;
        const pdfFile = new File([pdf], fileName, {
          type: "application/pdf",
        });
        const url = `${PDF_URLS.FLOA_GENERATE}?hashValue=${hashValueData}&admissionId=${data?.admissionId}&applicationNo=${applicationNo}`;
     
        const formData = new FormData();
        formData.append("file", pdfFile);
        const response = await axiosService.postFile(url, pdfFile, {});
        if (response.status === 200) {
          setLoading();
        } else {
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const generatePDFLink = async () => {
    const pdfBlob = await new Promise((resolve, reject) => {
      pdf(
        <Document>
          <Page size="A4" style={styles.Document}>
            <HeaderForFloa />

            <View style={styles.styles1}>
              <View style={styles.Date}>
                <Text>
                  <Text style={styles.FirstName}>{Date2}</Text>
                </Text>
              </View>
              <View style={styles.Date}>
                <Text>
                  Student ID: <Text style={styles.FirstName}>{StudentId}</Text>
                </Text>
              </View>
              <View style={styles.StudentName}>
                <Text style={styles.FirstName}>{StudentName}</Text>
              </View>
              <Text style={styles.Address}>
                {Address1},{Address2}
              </Text>
              <Text style={styles.Address}>{Address3}</Text>
              <Text style={styles.Address}>{Address4}</Text>
              <Text style={styles.Address}>{Address5}</Text>
              <View>
                <Text style={styles.Block}>
                  Dear <Text>{StudentName},</Text>
                </Text>
              </View>
              <Text style={styles.letter}>
                Congratulations to you for being accepted into the under given
                program(s) at Beta College of Business & Technology! Your
                <Text style={styles.boldText}> Letter of Acceptance</Text> is
                enclosed with this letter.
              </Text>
              <Text style={styles.acceptedinto}>
                Program(s) that you are accepted into:
              </Text>
              <Text style={styles.Programinfo}>{Program}</Text>
              <Text style={styles.Beta}>
                Pursuing education at Beta College of Business & Technology will
                provide you with an outstanding experience and equip you with
                all the skills required to successfully launch yourself into the
                career that you are targeting. We are confident that our
                high-quality education combined with our superior instruction
                delivery infrastructure will ensure your success and reaffirm
                the faith that you have reposed in Beta College of Business &
                Technology.
              </Text>
              <Text style={styles.steps}> Your next steps </Text>
              <Text style={styles.tuition}>
                • Pay your tuition fee deposit.
              </Text>
              <Text style={styles.tuition}>
                • Apply for a student visa/ study permit .
              </Text>
              <Text style={styles.deposit}>
                Paying your tuition fee deposit
              </Text>
              <Text style={styles.deposit1}>
                It is important that you pay the deposit towards tuition fee by
                the date specified in the enclosed Letter of Acceptance, to
                secure a seat for yourself in the program(s) that you have been
                accepted into. Late payments will only be accepted if there are
                seats available, at that point, in the offered programs(s).
              </Text>
              <Text style={styles.deposit1}>
                Please refer to the enclosed Letter of Acceptance for the
                different methods available for fee payment.
              </Text>
              <Text style={styles.permit}>
                Applying for a student visa/ study permit
              </Text>
              <Text style={styles.applicable}>
                The enclosed Letter of Acceptance and the
                <Text style={styles.boldText}> Fee Receipt </Text>issued by us
                for the deposit made by you towards tuition fees, should be
                submitted along with your student visa/ study permit
                application, to the nearest Canadian embassy or consulate in
                your home country. Processing times vary from country to country
                and the times applicable in your case can be seen by visiting
                the following link:
              </Text>
              <View>
                <Text style={styles.services}>
                  https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html
                </Text>
                <Text style={styles.file}>
                  It is important that you file your application well in time,
                  keeping the processing time applicable to you in mind, so as
                  to be in Canada before your studies at CIMT College are due to
                  commence.
                </Text>
              </View>
            </View>
            <View>
              <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
            </View>
            <View style={styles.footer}>
              <View style={styles.qrCodeContainer}></View>
              <FooterForFloa></FooterForFloa>
            </View>
          </Page>
          <Page size="A4" style={styles.Document}>
            <HeaderForFloa />
            <View style={styles.studies}>
              <Text style={styles.Starting}>
                Starting your studies at Beta College
              </Text>
              <Text style={styles.betacollege}>
                Once your student visa/ study permit is approved, write to us at{" "}
                <Text style={styles.betacollegelink}>info@betacollege.ca</Text>,
                enclosing a copy of the approval letter issued by the
                Immigration, Refugees and Citizenship Canada (IRCC). Our
                International Centre will interact with you to facilitate your
                arrival in Canada to begin your studies at Beta College. There
                are various
                <Text style={styles.boldText}> Settlement Services </Text>that
                we provide to our international students that you will be made
                aware of, at that stage.
              </Text>
              <Text style={styles.betacollege}>
                If you have any questions, do not hesitate to get in touch by
                writing to us at{" "}
                <Text style={styles.betacollegelink}>info@betacollege.ca</Text>{" "}
                or calling our International Centre helpline on +1 905 848 5050.
              </Text>
              <Text style={styles.betacollege}>
                We now look forward to welcoming you to Canada and to the
                beginning of a new chapter in your life at Beta College!
              </Text>
              <Text style={styles.betacollege1}>Krunal</Text>
              <Text style={styles.betacollege1}>
                International Admissions Manager
              </Text>
              <Text style={styles.betacollege1}>Beta College</Text>
            </View>
            <View>
              <Image style={styles.qrCodeImageFor2} src={qrCodeDataURL} />
            </View>
            <View style={styles.footer}>
              <View style={styles.column}></View>
              <FooterForFloa></FooterForFloa>
            </View>
          </Page>
          <Page size="A4" style={styles.Document}>
            <HeaderForFloa />

            <View style={styles.body}>
              <View style={styles.alignment}>
                <View>
                  <Text style={styles.conditionalletterheadding}>
                    CONDITIONAL LETTER OF ACCEPTANCE
                  </Text>
                </View>
                <Text style={styles.rednotice}>
                  THIS LETTER CANNOT BE USED FOR VISA PURPOSES. PLEASE ENSURE
                  THAT ANY FEES ASKED FOR IN THIS LETTER ARE PAID BY THE
                  SPECIFIED DUE DATES TO SECURE A SEAT IN THE PROGRAM(S) BEING
                  OFFERED.
                </Text>
                <View style={styles.date}>
                  <Text style={styles.issuedate}>
                    Date of Issue:<Text> {Issue}</Text>
                  </Text>
                  <Text style={styles.expirydate}>
                    Date of Expiry:<Text> {Expiry}</Text>
                  </Text>
                </View>
                <View style={styles.tablealigment}>
                  <Text style={styles.headertable}> STUDENT INFORMATION</Text>
                  <View style={styles.table1}>
                    <View></View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColNew}>
                        <Text style={{ paddingLeft: 3 }}>
                          Family Name:{" "}
                          <Text style={styles.actualValue}> {Name}</Text>
                        </Text>

                        <Text style={styles.newText}>
                          Given Name:
                          <Text style={styles.actualValue}> {FirstName}</Text>
                        </Text>

                        <Text
                          style={{
                            paddingLeft: 3,
                            borderTop: 1,
                            borderTopColor: "#000",
                          }}
                        >
                          CAQ or MIDI:
                          <Text style={styles.actualValue}> {Yes}</Text>
                        </Text>
                        <Text style={{ paddingLeft: 3 }}>
                          CAQ No: <Text style={styles.actualValue}> {CAQ}</Text>
                        </Text>
                        <Text style={{ paddingLeft: 3 }}>
                          Expiry:
                          <Text style={styles.actualValue}> {Expiry1}</Text>
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.tableColNew,
                          { flexDirection: "column" },
                        ]}
                      >
                        <Text style={{ paddingLeft: 3 }}>Mailing Address:</Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Address1},{Address2}
                        </Text>

                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Address3}
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Address4}
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Address5}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text>
                          DOB: <Text style={styles.actualValue}>{DOB}</Text>
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text>
                          Student ID:
                          <Text style={styles.actualValue}> {StudentId}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <Text style={styles.headertable}>INSTITUIONAL INFORMATION</Text>
                <View style={styles.table1}>
                  <View></View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.Registered}>
                        College Name & Address:
                      </Text>
                      <Text style={styles.actualValue}>{College}</Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.boldText}>
                        Website:
                        <Text style={styles.mybeta}> www.mybeta.ca</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol}>
                      <Text style={styles.Registered}>
                        Type of Institution:
                        <Text style={styles.actualValue}> Private</Text>
                      </Text>
                      <Text style={styles.Registered}>
                        PCC Id:<Text style={styles.actualValue}> {PCC}</Text>
                      </Text>
                      <Text>
                        Registered as Private Career College under the PCC Act,
                        2005
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text>
                        Name of Contact:
                        <Text style={styles.actualValue}> {Contact}</Text>
                      </Text>
                      <Text>
                        Position:
                        <Text style={styles.actualValue}> {Position}</Text>
                      </Text>
                      <Text>
                        Name:<Text style={styles.actualValue}> {Email}</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text>
                        DLI Number:{" "}
                        <Text style={styles.actualValue}>{DLI}</Text>
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text>
                        Name of Contact:
                        <Text style={styles.actualValue}> {Contact}</Text>
                      </Text>
                      <Text>
                        Position:
                        <Text style={styles.actualValue}> {Position}</Text>
                      </Text>
                      <Text>
                        Name:<Text style={styles.actualValue}> {Email}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
                <Text style={styles.headertable}> PROGRAM INFORMATION</Text>
                <View style={styles.table1}>
                  <View></View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>Program details as per shared PDF </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.Registered}> Fee Structure: </Text>

                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Application Fee:
                        <Text> {applicationFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Tuition Fee:
                        <Text> {tuitionFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Material/Book Fee:
                        <Text> {materialFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        International Fee:
                        <Text> {internationalFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Bank Fee:
                        <Text> {bankFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        ESL Fee:
                        <Text> {eslFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Field Trips Fee:
                        <Text> {fieldTripsFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Other Fee:
                        <Text> {otherFee}</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.Condition}>
                  <View style={styles.infoContainer}>
                    <Text style={styles.infoValue}>
                      Condition of Acceptance:
                    </Text>
                    <Text style={styles.Programs}>
                      For PG Programs, prior to starting the class, student must
                      provide foreign credential assessed using WES or ICAS.
                    </Text>
                  </View>
                </View>
                <View style={styles.border}>
                  <View style={styles.infoContainer}>
                    <View style={styles.column1}>
                      <Text style={styles.infoValue}>Notes: </Text>
                      <Text style={styles.Notes}>{Notes}</Text>
                    </View>
                    <View style={styles.rightColumn1}></View>
                  </View>
                </View>
                <View>
                  <Text style={styles.Signature}>
                    Signature of Institution Representative:
                  </Text>
                </View>
                <View style={styles.flexWrap}>
                  <Text style={styles.Signature}>
                    Name of Institution Representative:
                    <Text style={styles.actualValue}> {Contact}</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Image style={styles.qrCodeImageFor3} src={qrCodeDataURL} />
            </View>
            <View style={styles.footer}>
              <FooterForFloa></FooterForFloa>
            </View>
          </Page>
          <Page size="A4" style={styles.Document}>
            <View style={styles.body}>
              <View>
                <Text style={styles.page2}>
                  FEE RELATED INFORMATION FOR INTERNATIONAL STUDENTS
                </Text>
              </View>
              <View>
                <Text style={styles.Signature1}>
                  Tuition Fee Payment Schedule
                </Text>
                <View style={styles.feeTable}>
                  <Text> {feeTableText} </Text>
                </View>
              </View>
              <View Registeredaligment>
                <View style={styles.table}>
                  <View></View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>1st Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term1}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>2nd Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term2}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>3rd Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term3}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text style={styles.feeTableText1}> {feeTableText1} </Text>
              <View style={styles.flexWrap}>
                <Text style={styles.Signature}>
                  ISSUE OF LETTER OF ACCEPTANCE
                </Text>
                <Text style={styles.issueletternotice}>
                  Please note that this letter is NOT a Letter of Acceptance and
                  cannot be used to apply for student visa/study permit. A
                  separate Letter of Acceptance will be issued, once all of the
                  following apply:
                </Text>
              </View>
              <View style={styles.flexWrap}>
                <Text style={styles.bulletpoint1}>
                  • You have paid the asked for
                  <Text style={styles.boldText}> Application Fee </Text> by the
                  specified due date.
                </Text>
                <Text style={styles.bulletpoint1}>
                  • You have met with any
                  <Text style={styles.boldText}> other condition(s) </Text>
                  specified above, by the specified due date.
                </Text>
              </View>
              <Text style={styles.receiveletternotice}>
                Once you receive your Letter of Acceptance and Payment Receipt,
                you can apply for student visa/study permit at the nearest
                Canadian embassy or consulate in your home country.
              </Text>
              <Text style={styles.admissionnotice}>
                To complete your admission process, you are also expected to pay
                the
                <Text style={styles.boldText}>
                  {" "}
                  deposit towards Program Tuition Fee{" "}
                </Text>
                by the specified due date, as given in the
                <Text style={styles.boldText}> Fee Structure</Text> column of
                the <Text style={styles.boldText}> Program Information </Text>
                section above.
              </Text>
              <View style={styles.studentstatusnotes}>
                <Text style={styles.Signature}>VERIFY YOUR STUDENT STATUS</Text>
              </View>
              <Text style={styles.bulletpoint}>
                At any point during your application process at CIMT College,
                you can check the current status of your application by
                visiting:
              </Text>
              <View style={{ textDecoration: "underline" }}>
                <Text style={styles.informationlink}>
                  https://erp.lmsmybeta.com/#/verification-home
                </Text>
              </View>
              <Text style={styles.remarks}>
                Please note that all international students upon arrival in
                Canada must report directly to
              </Text>
              <Text style={styles.departmentfolder}>
                International Department, Beta College of Business & Technology
              </Text>
              <Text style={styles.departmentfolder}>
                4275 Village Centre Court, Unit 201, Mississauga L4Z 1V3,
                Ontario, Canada
              </Text>
            </View>
            <View>
              <Image style={styles.qrCodeImageFor4} src={qrCodeDataURL} />
            </View>
            <View style={styles.footer}>
              <View style={styles.column}>
                <FooterForFloa></FooterForFloa>
              </View>
            </View>
          </Page>
        </Document>
      )
        .toBlob()
        .then(resolve)
        .catch(reject);
    });

    const pdfFile = new File([pdfBlob], "FLOA.pdf", {
      type: "application/pdf",
    });
    if ((pdfData, applicationNo)) {
      handleSubmit(pdfFile, applicationNo);
    }
  };
};
export default FLOA;
