const { AppConfig } = require("env.development");

export const TASK_URLS = {
  GET_ALL_TASKS: `${AppConfig.api_url}task/v1/all`,
  GET_TASK_BY_ID: `${AppConfig.api_url}task/v1/`,
  CREATE_TASKS: `${AppConfig.api_url}task/v1`,
  CREATE_CIRCULAR: `${AppConfig.api_url}circular/v1`,
  UPDATE_TASKS: `${AppConfig.api_url}task/v1`,
  DELETE_TASKS: `${AppConfig.api_url}task/v1/`,
  PIPELINE_URL: `${AppConfig.api_url}lead-audit/v1/`,
  PIPELINE_UPDATE_URL: `${AppConfig.api_url}lead-audit/v1`,
};
