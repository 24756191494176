import AddStudentPortalParticipation from "modules/student-portal/components/student-portal-participation/add-participation/AddStudentPortalParticipation";
import { STUDENT_PORTAL_PARTICIPATION_URL } from "modules/student-portal/constants/student-portal-urls";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import "./ViewStudentPortalParticipation.css";
import sessionService from "services/sessionstorage.service";
import { TiArrowUnsorted } from "react-icons/ti";

function ViewStudentPortalParticipation({ studentId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [selectedParticipationId, setSelectedParticipationId] = useState(null);
  const [sortOrder, setSortOrder] = useState({ column: null, ascending: true });

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${STUDENT_PORTAL_PARTICIPATION_URL.GET_PARTICIPATION_URL}`
      );

      if (response.status === 200) {
        const responseData = response.data.data.reverse();
        setDatas(responseData);
      } else {
        toast.error("Error fetching data:", response.status);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (studentId) {
      axiosService
        .get(
          `${STUDENT_PORTAL_PARTICIPATION_URL.GET_PARTICIPATION_BY_STUDENT_ID}${studentId}`
        )
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data.reverse();
            setDatas(responseData);
          }
        });
    } else {
      fetchData();
    }
  }, []);

  const handleSort = (column) => {
    setSortOrder((prevSortOrder) => ({
      column,
      ascending:
        column === prevSortOrder.column ? !prevSortOrder.ascending : true,
    }));
  };

  const sortedData = [...datas].sort((a, b) => {
    const column = sortOrder.column;
    const aValue = a[column];
    const bValue = b[column];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (sortOrder.ascending) {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  });

  function hasPermission(permission) {
    let userPermissions = sessionService?.hasPermissionByButton(permission);
    return userPermissions;
  }

  return (
    <div className="student-portal-service-container">
      {!studentId && (
        <div className="student-ps-top">
          <span>Participation</span>
          {hasPermission("Participation: create") && (
            <a
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add new Participaion
            </a>
          )}
        </div>
      )}

      <div className=" student-portal-inner-main-container custom-scrollbar">
        {Array.isArray(sortedData) && sortedData.length > 0 ? (
          <table>
            <thead className="table-header-student-portal">
              <tr>
                <th style={{ width: "250px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Participation Service
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("participationService")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
                <th style={{ width: "180px" }}>
                  <div className="table-head-student-portal">
                    <span className="table-head-student-portal-text">
                      Date Of request
                    </span>
                    <span
                      className="student-portal-service-list-service"
                      onClick={() => handleSort("requestDate")}
                    >
                      <TiArrowUnsorted color={"#606060"} />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((participationService) => (
                <tr key={participationService?.portalServiceId}>
                  <td className="table-data-student-portal text-decoration-underline">
                    {participationService.participationService}
                  </td>
                  <td className="table-data-student-portal">
                    {participationService.requestDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-entries-made">No participation</p>
        )}
      </div>

      <AddStudentPortalParticipation
        open={isModalOpen}
        onClose={(data) => {
          setIsModalOpen(false);
          fetchData();
          setSelectedParticipationId(null);
        }}
        selectedParticipationId={selectedParticipationId}
      />
    </div>
  );
}
export default ViewStudentPortalParticipation;
