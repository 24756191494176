import { AgGridReact } from "ag-grid-react";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";

function ViewCampaignMessages({}) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axiosService
      .get(`${CAMPAIGN_URLS.ADD_MEESAGE_CAMPAIGN}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;
          setData(responseData);
        }
      });
  }, [location.pathname]);

  const [columnDefs] = useState([
    {
      headerName: "S.No",
      valueGetter: "node.rowIndex + 1",
      width: 100,
      suppressSizeToFit: true,
    },
    {
      headerName: "Message Name",
      field: "messageName",
    },

    {
      headerName: "Subject Line",
      field: "mailSubject",
    },
    {
      field: "Actions",
      cellRenderer: function (params) {
        const rowData = params.data;

        return (
          <span>
            <FaEye
              style={{
                marginRight: "20px",
                height: "1em",
                width: "1em",
                color: "white",
              }}
              onClick={(event) => {
                navigate("/campaigns/create-campaign-message", {
                  state: {
                    data: rowData,
                    type: "view",
                  },
                });
              }}
            />
            <FaPen
              style={{
                marginRight: "20px",
                height: "1em",
                width: "1em",
                color: "#0d3585",
              }}
              onClick={() => {
                navigate("/campaigns/create-campaign-message", {
                  state: {
                    data: rowData,
                    type: "edit",
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ]);
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight: 38,
  };
  // const onRowClicked = (event) => {
  //   navigate("/campaigns/create-campaign-message", {
  //     state: {
  //       data: {
  //         Data: event.data,
  //       },
  //     },
  //   });
  // };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container align-items-center flex-wrap sticky-header flex-row gap-2">
        <span className="communication-management-title-text">
          All Campaign Messages
        </span>

        <div className="d-flex align-items-center gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => {
              navigate("/campaigns/create-campaign-message");
            }}
          >
            Create New Message
          </button>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          // onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ViewCampaignMessages;
