export const ADMISSION_CONSTANTS_FOR_STUDENTS = {
  PROGRAM_SUCCESS: "Program Details submitted successfully!",
  PROGRAM_FAILED: "Program Details Failed",
  PROGRAM_CAMPUS_NOT_MATCH: "Please Select proper program and campus",
  IMAGE_LENGTH: "Image name exceeds the maximum length characters.",
  ERROR_OCCUR:
    "An unexpected error occurred during Admission. Please contact admin/support",
  FEE_SUCCESS: "Application submitted successfully !",
  FEE_FAILURE: "Fee Form Failed ",
  FEE_ERROR_OCCUR:
    "An unexpected error occurred during Fee. Please contact admin/support",
  PERSONAL_DATA_SUCCESS: "Personal Data submitted successfully!",
  PERSONAL_DATA_FAILURE: "Personal Data Failed",
  PERSONAL_DATA_ERROR: "An error occurred during Personal Data.",
  MAILING_SUCCESS: "Mailing Details submitted successfully!",
  MAILING_FAILURE: "Mailing Details Failed",
  MAILING_ERROR_OCCUR:
    "An unexpected error occurred during Mailing. Please contact admin/support",
  GUARDIAN_SUCCESS: "Guardian Details submitted successfully!",
  GUARDIAN_FAILURE: "Guardian Data Failed",
  GUARDIAN_ERROR: "An error occurred during Guardian",
  ACADEMIC_SUCCESS: "Academic Details submitted successfully!",
  ACADEMIC_FAILURE: "Academic Data Failed",
  ACADEMIC_ERROR: "An error occurred during Academic",
  EMPLOYMENT_SUCCESS:
    "Employment and Work Experience Details submitted successfully!",
  EMPLOYMENT_FAILURE: "Employment and Work Experience Data Failed",
  EMPLOYMENT_ERROR: "An error occurred during Employment and Work Experience",
  OTHER_INFO_SUCCESS: "Other Information submitted successfully!",
  OTHER_INFO_FAILURE: "Other Information Data Failed",
  OTHER_INFO_ERROR: "An error occurred during Other Information",
  UPLOAD_FILE_SUCCESS: "Uploaded successfully!",
  UPLOAD_FILE_FAILURE: "Other Uploaded Failed",
  UPLOAD_FILE_ERROR: "An error occurred during Other Uploaded",
  LIST_FILE_SUCCESS: "List of Files Uploaded successfully!",
  LIST_FILE_SUCCESS_FILE_FAILURE: "List of Files Upload Failed",
  LIST_FILE_ERROR: "An error occurred during List of Files Upload",
  STUDENT_PROFILE_SUCCESS: "Registration completed successfully!",
  STUDENT_PROFILE_FAILURE: "Student Profile Upload Failed",
  STUDENT_PROFILE_ERROR: "An error occurred during Student Profile",

  GET_ALL_PROGRAM_AND_FEE_VALUE_ERROR: " not available in ",
};
