import React from "react";

function StudentPortalLogo({ color }) {
  return (
    <svg
      width="32"
      height="21"
      viewBox="0 0 32 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 12.6068C18.3855 12.6068 20.3192 10.6731 20.3192 8.28775C20.3192 5.90243 18.3855 3.96875 16.0002 3.96875C13.6148 3.96875 11.6812 5.90243 11.6812 8.28775C11.6812 10.6731 13.6148 12.6068 16.0002 12.6068Z"
        fill={color}
      />
      <path
        d="M9.16113 20.989H22.8381C22.7631 16.863 19.7331 13.543 15.9991 13.543C12.2651 13.543 9.23613 16.864 9.16113 20.989Z"
        fill={color}
      />
      <path
        d="M5.13807 6.49C6.93023 6.49 8.38307 5.03716 8.38307 3.245C8.38307 1.45284 6.93023 0 5.13807 0C3.3459 0 1.89307 1.45284 1.89307 3.245C1.89307 5.03716 3.3459 6.49 5.13807 6.49Z"
        fill={color}
      />
      <path
        d="M0 12.7874H10.275C10.219 9.68836 7.943 7.19336 5.137 7.19336C2.332 7.19336 0.056 9.68836 0 12.7874Z"
        fill={color}
      />
      <path
        d="M26.8619 6.49C28.6541 6.49 30.1069 5.03716 30.1069 3.245C30.1069 1.45284 28.6541 0 26.8619 0C25.0698 0 23.6169 1.45284 23.6169 3.245C23.6169 5.03716 25.0698 6.49 26.8619 6.49Z"
        fill={color}
      />
      <path
        d="M26.8621 7.19336C24.0571 7.19336 21.7811 9.68836 21.7241 12.7874H32.0001C31.9441 9.68836 29.6681 7.19336 26.8621 7.19336Z"
        fill={color}
      />
    </svg>
  );
}

export default StudentPortalLogo;
