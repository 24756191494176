import { AppConfig } from "env.development";

export const PROGRAM_URLS = {
  GET_ALL_PROGRAMS: `${AppConfig.api_url}program/v1/all`,
  GET_ALL_MODULES: `${AppConfig.api_url}module/v1/all`,
  ADD_PROGRAM_ACADEMICS: `${AppConfig.api_url}program/v1`,
  ADD_MODULES_ACADEMICS: `${AppConfig.api_url}module/v1`,
  UPDATE_MODULES_ACADEMICS: `${AppConfig.api_url}module/v1`,
  UPDATE_PROGRAM_ACADEMICS: `${AppConfig.api_url}program/v1`,
  GET_MODULE_BY_MODULE_ID: `${AppConfig.api_url}module/v1`,
  GET_PROGRAM_BY_PROGRAM_ID: `${AppConfig.api_url}program/v1`,
  GET_HOME_DETAILS: `${AppConfig.api_url}program/v1/home`,
  GET_PROGRAM_BY_STUDENT_ID: `${AppConfig.api_url}admission/v1/program/master`,
  GET_MODULES_BY_PROGRAM_ID: `${AppConfig.api_url}program/v1/get-module/`,
  DELETE_MODULES_BY_MODULE_ID: `${AppConfig.api_url}module/v1/`,
};
