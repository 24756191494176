import betalogo from "assets/images/icons/beta.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import "./travelSupport.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { QRCode } from "react-qr-code";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";

const PdfContent = ({ pdfData, includeWatermark, hashValueData }) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );
  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;
  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div>
        <p>
          <strong>Date:</strong> {formatDate(new Date())}
        </p>
        <p>To whom it may concern</p>
        <p>Sub : Travel Support Letter</p>
        <p>
          This is to confirm{" "}
          <strong>
            {" "}
            {pdfData?.personalData?.firstName} {pdfData?.personalData?.lastName}
          </strong>
          , <strong>Student ID: {pdfData?.personalData?.studentId}</strong>
          is enrolled in the program- {
            pdfData?.programDetails[0]?.program
          } in <br></br>Business Management program at Beta College of Business
          and Technology from January 08, 2024.
          <br></br> He would be travelling on November 12, 2023 and will follow
          the required guidelines from the date of arrival in<br></br> Ontario,
          Canada, as per provincial guidelines for the Ministry of Colleges and
          Universities (MCU).
        </p>

        <p>Feel free to contact the undersigned via email for any questions.</p>
        <p>Yours sincerely,</p>
        <br></br>
        <p>For Beta College of Business and Technology:</p>

        <p>
          Krunal Trivedi
          <p>
            Registrar / Director of Admissions & Outreach
            <p>
              Beta College of Business and Technology
              <p>
                +1 905.497.9999 | +1 647.573.3660
                <p>
                  hello@mybeta.ca | info@betacollege.ca
                  <p>www.mybeta.ca | www.betacollege.ca</p>
                </p>
              </p>
            </p>
          </p>
        </p>
        <p>
          I hereby acknowledge and confirm that I will abide by all terms and
          conditions mentioned here including meeting<br></br> all the updated
          guidelines at the time of arrival.
        </p>
      </div>
      <div className="custom-table-travel">
        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="pe-5 px-2"
        >
          <span>Student Name : </span>
          <span
            style={{
              marginTop: "-20px",
              height: "100%",
              width: "200px",
              borderBottom: "1px solid black",
            }}
          ></span>
        </div>
        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="pe-5 px-2"
        >
          <span>Signature : </span>
          <span
            style={{
              marginTop: "-20px",
              height: "100%",
              width: "200px",
              borderBottom: "1px solid black",
            }}
          ></span>
        </div>
        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="pe-5 px-2"
        >
          <span>Date : </span>
          <span
            style={{
              marginTop: "-20px",
              height: "100%",
              width: "200px",
              borderBottom: "1px solid black",
            }}
          ></span>
        </div>
      </div>
      <div
        className="footer"
        style={{
          marginTop: includeWatermark === "download" ? "14rem" : "14rem",
          marginLeft: "20px",
          position: "relative",
        }}
      >
        {includeWatermark === "download" && hashValueData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "19px",
              zIndex: 1,
            }}
          >
            <QRCode size={70} value={url} />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

const TravelSupport = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);
  const studentId = pdfData?.personalData?.studentId;
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Travel-Support"}-${studentId}`).toString().substring(0, 20)
  );

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark) {
        const watermarkText = "Sample";
        const fontSize = 550;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }
      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });

      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 20,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 20)) /
          overlayCanvas.width
      );

      const fileName = includeWatermark
        ? "Travel-Support-sample.pdf"
        : "Travel-Support.pdf";

      if (includeWatermark) {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");

            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

            // setHashValue(hashValue);

            const fileName = `${
              includeWatermark ? "travel-Support.sample" : "travel-Support"
            }_${studentId}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Travel-Support.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
        />
      )}
    </span>
  );
};

export default TravelSupport;
