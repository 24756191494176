import { AppConfig } from "env.development";

export const CAMPAIGN_URLS = {
  ADD_MEESAGE_CAMPAIGN: `${AppConfig.api_url}message/v1`,
  GET_ALL_CONTACT_LIST: `${AppConfig.api_url}list/v1/getAll`,
  ADD_CAMPAIGN: `${AppConfig.api_url}campaign/v1`,
  RUN_CAMPAIGN: `${AppConfig.api_url}campaign/v1/run`,
  GET_ALL_CAMPAIGN: `${AppConfig.api_url}campaign/v1/all`,
  ADD_CONTACT_LIST: `${AppConfig.api_url}list/v1/addList`,
  EDIT_CONTACT_LIST: `${AppConfig.api_url}list/v1/addList`,
  DELETE_CONTACT_LIST: `${AppConfig.api_url}list/v1/`,
  ADD_CONTACT: `${AppConfig.api_url}list/v1/addContact/`,
  GET_CONTACTS_FOR_SELECTED_LIST: `${AppConfig.api_url}list/v1/`,
  Delete_CONTACT_BY_CONTACT_ID: `${AppConfig.api_url}list/v1/contact/`,
  UPDATE_CONTACT: `${AppConfig.api_url}list/v1/updateContact/`,
  RETRY_EMAIL: `${AppConfig.api_url}campaign/v1/resend-mail/`
};
