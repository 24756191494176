import { USER_URL } from "modules/user-management/constants/user-urls";
import { useEffect, useState } from "react";
import { RxTriangleDown, RxTriangleUp } from "react-icons/rx";
import { axiosService } from "services/axios.service";
import "./ViewUsers.css";
import { useNavigate } from "react-router-dom";
import useravatar from "../../../../assets/images/user-avatar.svg";

function ViewUsers() {
  const navigate = useNavigate();
  const [openRoles, setOpenRoles] = useState({});

  const toggleRole = (roleName) => {
    setOpenRoles((prevOpenRoles) => ({
      ...prevOpenRoles,
      [roleName]: !prevOpenRoles[roleName],
    }));
  };

  const [dataa, setDataa] = useState([]);

  useEffect(() => {
    axiosService.get(`${USER_URL.USERS_GET_URL}`).then((response) => {
      const data = response?.data;
      if (data) {
        const allRoleData = [];
        let firstRoleName;

        for (const roleName in data?.data) {
          if (data?.data.hasOwnProperty(roleName)) {
            const users = data?.data[roleName];
            const roleData = {
              roleName: roleName,
              userData: users,
            };
            allRoleData.push(roleData);

            if (!firstRoleName) {
              firstRoleName = roleName;
            }
          }
        }

        setDataa(allRoleData);

        setOpenRoles(
          Object.fromEntries(allRoleData.map((role) => [role.roleName, true]))
        );
      }
    });
  }, []);

  return (
    <div className="user-main-container">
      <div className="user-head-container sticky-header flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">All Users</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <a className="user-head-button" href="/#/user-management/add-users">
            Add User
          </a>
        </div>
      </div>

      {dataa?.map((data) => (
        <>
          <div class="mt-3">
            <button
              class="view-user-sub-title-text"
              onClick={() => toggleRole(data.roleName)}
            >
              {data.roleName}
              {openRoles[data.roleName] ? (
                <RxTriangleDown className="user-dropdown-icon" />
              ) : (
                <RxTriangleUp className="user-dropdown-icon" />
              )}
            </button>

            <div
              className="view-user-content-container"
              style={{
                display: openRoles[data.roleName] ? "" : "none",
              }}
            >
              {data.userData?.map((user) => (
                <div
                  className="view-user-card-container"
                  key={user?.id}
                  onClick={() =>
                    navigate(`/user-management/add-users`, {
                      state: { userId: user.userId },
                    })
                  }
                >
                  <div className="view-user-card-profile-container">
                    <img
                      alt="img"
                      src={user.profileImage || useravatar}
                      style={{
                        height: "100%",
                        width: "auto",
                        borderRadius: 6,
                      }}
                    />
                  </div>
                  <div className="view-user-card-text-container">
                    <div className="d-flex flex-column">
                      <span className="view-user-card-head-text">
                        {user?.firstName} {user?.lastName}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default ViewUsers;
