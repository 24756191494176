import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddClass from "../class-schedules/add-class/AddClass";
import ListClass from "../class-schedules/list-class/ListClass";
import AddResource from "../resources/add-resouce/AddResource";
import ListResources from "../resources/list-resource/ListResources";
import StudentCalendar from "../student-calendar/StudentCalendar";
import sessionService from "services/sessionstorage.service";
import MyCalendar from "../my-calender/MyCalendar";

function TimeTableManagementRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  const userData = sessionService.getUserData();
  const role = userData?.role?.name;
  useEffect(() => {
    if (location.pathname === "/timetable-management") {
      if (role === "faculty") {
        navigate("/timetable-management/my-calendar");
      } else if (role === "student") {
        navigate("/timetable-management/student-calendar");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="all-resources" element={<ListResources />} />
      <Route path="add-resource" element={<AddResource />} />
      <Route path="add-class" element={<AddClass />} />
      <Route path="list-class" element={<ListClass />} />
      <Route path="my-calendar" element={<MyCalendar />} />
      <Route path="student-calendar" element={<StudentCalendar />} />
    </Routes>
  );
}

export default TimeTableManagementRoutes;
