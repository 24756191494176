export const isValidPostalCode = (countryCode, postalCode) => {
  let postalCodePattern;
  switch (countryCode) {
    case "US":
      postalCodePattern = /^\d{5}(-\d{4})?$/;
      break;
    case "UK":
      postalCodePattern = /^([A-Za-z]\d[A-Za-z]\d\d|\d{5})$/;
      break;
    default:
      postalCodePattern = /^.{0,50}$/;
  }

  return postalCodePattern.test(postalCode);
};

const postalCodeUS = "12345";
const isValidUS = isValidPostalCode("US", postalCodeUS);

const postalCodeUK = "SW1A 1AA";
const isValidUK = isValidPostalCode("UK", postalCodeUK);

if (isValidUS) {
} else {
}

if (isValidUK) {
} else {
}
