import React, { useEffect, useRef, useState } from "react";
import "../admission-side-bar/AdmissionSideBar.css";
import sessionstorageService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { FiExternalLink } from "react-icons/fi";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import sessionService from "services/sessionstorage.service";

function AdmissionAppilicationSidebar({ data }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [payment, setPayment] = useState(null);
  const [cloaPayment, setCloaPayment] = useState(null);
  const [fee, setFee] = useState(null);

  const [programPaymentData, setProgramPaymentData] = useState(null);
  const [cloaProgramPaymentData, setCloaProgramPaymentData] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [feeData, setFeeData] = useState(null);

  const [registrationRejectedLink, setRegistrationRejectedLink] =
    useState(null);
  const [rejected, setRejected] = useState(false);
  const [approve, setApprove] = useState(false);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [isFeeApproved, setIsFeeApproved] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [enablePayment, setEnablePayment] = useState(false);
  const [osapPayment, setOsapPayment] = useState(false);
  const userData = sessionstorageService.getUserData("userData");
  const studentType = userData?.studentType;
  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const feeId = response?.data?.data?.feeId;
          if (feeId != undefined) {
            axiosService
              .get(
                `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_AND_PROGRAM}${admissionId}?programId=${data?.programId}&feeId=${feeId}`
              )
              .then((response) => {
                const data = response.data;
                const admissionManagement = response?.data?.admissionManagement;

                if (
                  admissionManagement?.personalData?.isApproved &&
                  admissionManagement?.mailingAddress?.isApproved &&
                  admissionManagement?.guardianDetail?.isApproved &&
                  admissionManagement?.academics[0]?.isApproved &&
                  admissionManagement?.employments[0]?.isApproved &&
                  admissionManagement?.otherInformation?.isApproved &&
                  admissionManagement?.upload?.isApproved &&
                  admissionManagement?.profile?.isApproved &&
                  data.programDetail?.isApproved &&
                  data.fee?.isApproved
                ) {
                  setEnableSubmit(true);
                  setOsapPayment(true);
                } else {
                  setEnableSubmit(false);
                  setOsapPayment(false);
                }
                if (admissionManagement?.cloaStudentPayments[0]) {
                  if (
                    admissionManagement?.personalData?.isApproved &&
                    admissionManagement?.mailingAddress?.isApproved &&
                    admissionManagement?.guardianDetail?.isApproved &&
                    admissionManagement?.academics[0]?.isApproved &&
                    admissionManagement?.employments[0]?.isApproved &&
                    admissionManagement?.otherInformation?.isApproved &&
                    admissionManagement?.upload?.isApproved &&
                    admissionManagement?.profile?.isApproved &&
                    data.programDetail?.isApproved &&
                    data.fee?.isApproved &&
                    admissionManagement?.cloaStudentPayments[0].isApproved
                  ) {
                    setEnablePayment(true);
                  } else {
                    setEnablePayment(false);
                  }
                }
              });
          }
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          if (
            data?.personalData?.isApproved &&
            data?.mailingAddress?.isApproved &&
            data?.guardianDetail?.isApproved &&
            data?.academics[0]?.isApproved &&
            data?.employments[0]?.isApproved &&
            data?.otherInformation?.isApproved &&
            data?.upload?.isApproved &&
            data?.profile?.isApproved
          ) {
            setApprove(true);
          } else {
            setApprove(false);
          }

          if (data?.personalData?.isRejected) {
            setRegistrationRejectedLink("/pre-onboarding/create-personal-data");
            setRejected(true);
          } else if (data?.mailingAddress?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-mailing-address"
            );
            setRejected(true);
          } else if (data?.guardianDetail?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-guardian-details"
            );
            setRejected(true);
          } else if (data?.academics[0]?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-academic-professional"
            );
            setRejected(true);
          } else if (data?.employments[0]?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-employement-work-experience"
            );
            setRejected(true);
          } else if (data?.otherInformation?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-other-information"
            );
            setRejected(true);
          } else if (data?.upload?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-list-upload-files"
            );
            setRejected(true);
          } else if (data?.profile?.isRejected) {
            setRegistrationRejectedLink(
              "/pre-onboarding/create-student-profile"
            );
            setRejected(true);
          } else {
            setRegistrationRejectedLink("/pre-onboarding/create-personal-data");
          }
        });
    }
  }, [admissionId]);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setProgramData(data);
        });

      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setPayment(data?.studentPaymentId);
          setProgramPaymentData(data);
        });
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_CLOA_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setCloaPayment(data?.studentPaymentId);
          setCloaProgramPaymentData(data);
        });

      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setFee(data?.feeId);
          setFeeData(data);
          setIsFeeApproved(data?.isApproved);
        });
    }
  }, [data?.programId]);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;

      switch (location.pathname) {
        case "/pre-onboarding/create-program-details":
          swiper.slideTo(1);
          break;
        case "/pre-onboarding/create-financial":
          swiper.slideTo(2);
          break;
        case "/pre-onboarding/cloa-paymentsubmission":
          swiper.slideTo(3);
          break;
        case "/pre-onboarding/paymentsubmission":
          swiper.slideTo(4);
          break;
      }
    }
  }, [location.pathname]);
  const isButtonDisabled = () => {
    if (studentType === "OSAP") {
      return !osapPayment;
    } else {
      return !enablePayment;
    }
  };

  return (
    <>
      <ul className="p-0 d-none flex-column d-lg-flex">
        <button
          type="button"
          onClick={() => {
            navigate(registrationRejectedLink, {
              state: {
                data: {
                  programId: data?.programId,
                },
              },
            });
          }}
          className={`text-decoration-underline program-detail-list-text-faculty
         ${rejected && "program-detail-list-text-rejected"}
         ${approve && "program-detail-list-text-approved"}
        `}
        >
          Registration <FiExternalLink />
        </button>

        <button
          type="button"
          onClick={() => {
            navigate("/pre-onboarding/create-program-details", {
              state: {
                data: data,
              },
            });
          }}
          className={` ${
            data?.programId
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${programData?.isApproved && "program-detail-list-text-approved"}
        ${programData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-program-details" &&
          "program-detail-list-active"
        }`}
        >
          Program Details
        </button>

        <button
          type="button"
          disabled={!fee}
          onClick={() => {
            navigate("/pre-onboarding/create-financial", {
              state: {
                data: data,
              },
            });
          }}
          className={`${
            fee ? "program-detail-list-complete" : "program-detail-list-text"
          } 
        ${feeData?.isApproved && "program-detail-list-text-approved"}
        ${feeData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-financial" &&
          "program-detail-list-active"
        } `}
        >
          Fee/Financial
        </button>
        {studentType !== "OSAP" && (
          <button
            type="button"
            disabled={!enableSubmit}
            onClick={() => {
              navigate("/pre-onboarding/cloa-paymentsubmission", {
                state: {
                  data: data,
                },
              });
            }}
            className={`${
              enableSubmit
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${
          cloaProgramPaymentData?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          cloaProgramPaymentData?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/cloa-paymentsubmission" &&
          "program-detail-list-active"
        } `}
          >
            CLOA Payment Info
          </button>
        )}

        <button
          type="button"
          disabled={isButtonDisabled()}
          onClick={() => {
            navigate("/pre-onboarding/paymentsubmission", {
              state: {
                data: data,
              },
            });
          }}
          className={`${
            (enablePayment && studentType !== "OSAP") ||
            (osapPayment && studentType === "OSAP")
              ? "program-detail-list-complete"
              : "program-detail-list-text"
          } 
        ${programPaymentData?.isApproved && "program-detail-list-text-approved"}
        ${programPaymentData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/paymentsubmission" &&
          "program-detail-list-active"
        } `}
        >
          Update Payment Info
        </button>
      </ul>
      <ul className="p-0 d-flex flex-column d-lg-none admission-sidebar-mobile">
        <Swiper
          ref={swiperRef}
          style={{ width: "100%", paddingRight: "10px", paddingLeft: "20px" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={10}
          slidesPerView="auto"
        >
          <SwiperSlide
            onClick={() => {
              navigate(registrationRejectedLink, {
                state: {
                  data: {
                    programId: data?.programId,
                  },
                },
              });
            }}
            className={`sidebar-mobile-swiper-text-container text-decoration-underline program-detail-list-text-faculty
         ${rejected && "program-detail-list-text-rejected"}
         ${approve && "program-detail-list-text-approved"}
        `}
          >
            Registration <FiExternalLink />
          </SwiperSlide>

          <SwiperSlide
            onClick={() => {
              navigate("/pre-onboarding/create-program-details", {
                state: {
                  data: data,
                },
              });
            }}
            className={`sidebar-mobile-swiper-text-container  ${
              data?.programId
                ? "program-detail-list-complete"
                : "program-detail-list-text"
            } 
        ${programData?.isApproved && "program-detail-list-text-approved"}
        ${programData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-program-details" &&
          "program-detail-list-active"
        }`}
          >
            Program Details
          </SwiperSlide>

          <SwiperSlide
            onClick={() => {
              if (fee) {
                navigate("/pre-onboarding/create-financial", {
                  state: {
                    data: data,
                  },
                });
              }
            }}
            className={`sidebar-mobile-swiper-text-container ${
              fee ? "program-detail-list-complete" : "program-detail-list-text"
            } 
        ${feeData?.isApproved && "program-detail-list-text-approved"}
        ${feeData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/create-financial" &&
          "program-detail-list-active"
        } `}
          >
            Fee/Financial
          </SwiperSlide>
          {studentType !== "OSAP" && (
            <SwiperSlide
              onClick={() => {
                if (enableSubmit) {
                  navigate("/pre-onboarding/cloa-paymentsubmission", {
                    state: {
                      data: data,
                    },
                  });
                }
              }}
              className={`sidebar-mobile-swiper-text-container ${
                enableSubmit
                  ? "program-detail-list-complete"
                  : "program-detail-list-text"
              } 
        ${
          cloaProgramPaymentData?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          cloaProgramPaymentData?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname === "/pre-onboarding/cloa-paymentsubmission" &&
          "program-detail-list-active"
        } `}
            >
              CLOA Payment Info
            </SwiperSlide>
          )}
          {studentType !== "OSAP" && (
            <SwiperSlide
              onClick={() => {
                if (enablePayment) {
                  navigate("/pre-onboarding/paymentsubmission", {
                    state: {
                      data: data,
                    },
                  });
                }
              }}
              className={`sidebar-mobile-swiper-text-container ${
                enablePayment
                  ? "program-detail-list-complete"
                  : "program-detail-list-text"
              } 
        ${programPaymentData?.isApproved && "program-detail-list-text-approved"}
        ${programPaymentData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/pre-onboarding/paymentsubmission" &&
          "program-detail-list-active"
        } `}
            >
              Update Payment Info
            </SwiperSlide>
          )}
        </Swiper>
      </ul>
    </>
  );
}

export default AdmissionAppilicationSidebar;
