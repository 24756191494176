import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { Formik, Form, Field } from "formik";
import TextEditor from "utils/components/text-editor/TextEditor";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
Modal.setAppElement("#root");

function QuestionModal({
  isOpen,
  onClose,
  onSave,
  initialHtmlContent,
  isEditing,
}) {
  const [htmlContent, setHtmlContent] = useState("");
  const [selectedQuestionType, setSelectedQuestionType] = useState("");

  useEffect(() => {
    setHtmlContent(initialHtmlContent?.question);
    setSelectedQuestionType(initialHtmlContent?.questionType);
  }, [isOpen, initialHtmlContent]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "277px !important",
    }),
  };
  const QuestionOptions = [
    { value: "Fill in the blanks", label: "Fill in the blanks" },
    {
      value: "Multi-choice questions (MCQ)",
      label: "Multi-choice questions (MCQ)",
    },
    { value: "Match the following", label: "Match the following" },
    {
      value: "Write short notes on the following",
      label: "Write short notes on the following",
    },
    {
      value: "Multi-select questions (MSQ)",
      label: "Multi-select questions (MSQ)",
    },
    {
      value: "True or False",
      label: "True or False",
    },
    {
      value: "Essay Writing",
      label: "Essay Writing",
    },
  ];
  const initialValues = {
    questionType: initialHtmlContent?.questionType || "",
    marks: initialHtmlContent?.marks || "",
    question: initialHtmlContent?.question || null,
    questions: {
      textAnswer: initialHtmlContent?.questions?.textAnswer || null,
      correctedAnswer: initialHtmlContent?.questions?.correctedAnswer || null,
      textParaAnswer: null,
      textParaCorrectAnswer: null,
      options: initialHtmlContent?.questions?.options || null,
      correctedOption: initialHtmlContent?.questions?.correctedOption || null,
      selectedOption: null,
      leftSideOptions: initialHtmlContent?.questions?.leftSideOptions || null,
      studentAnsweredMatchOptions: null,
      answeredMatchingOptions:
        initialHtmlContent?.questions?.answeredMatchingOptions || null,
      multipleOptions: initialHtmlContent?.questions?.multipleOptions || [],
      selectedOptions: null,
      correctedOptions: initialHtmlContent?.questions?.correctedOptions || [],
      correctedBooleanOption:
        initialHtmlContent?.questions?.correctedBooleanOption || null,
    },
    maxWords: initialHtmlContent?.maxWords || null,
  };

  const handleHtmlContentChange = (content, formik) => {
    setHtmlContent(content);
    formik.setValues({ ...formik.values, question: content });
  };
  const tfOptions = ["True", "False"];
  return (
    <Modal className="crm-modal" isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-Con">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSave(values);
            onClose();
          }}
        >
          {(formik) => (
            <Form>
              <div
                className="col-12  p-3 d-flex flex-column gap-2 custom-scrollbar"
                style={{
                  maxHeight: "90vh",
                  backgroundColor: "#212226",
                }}
              >
                <div className="new-conversation-popup-header">
                  <span className="Question-header-text">
                    {isEditing ? "Edit Question" : "Add a Question"}{" "}
                    <span
                      className="label-text-question"
                      style={{ color: "#8c8c8c" }}
                    >
                      Choose from question bank?
                    </span>
                  </span>

                  <IoClose
                    color="#ffffff"
                    size={"23px"}
                    onClick={() => {
                      onClose();
                    }}
                  />
                </div>
                <div className="create-personal-data-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-question">
                      Type of question *
                    </label>
                    <Field
                      name="questionType"
                      as="select"
                      component={({ field, form }) => (
                        <>
                          <Select
                            value={QuestionOptions.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              setSelectedQuestionType(option.value);
                              if (
                                option.value === "Multi-select questions (MSQ)"
                              ) {
                                form.setFieldValue(
                                  "questions.multipleOptions",
                                  ["", "", "", ""]
                                );
                                form.setFieldValue(
                                  "questions.correctedOption",
                                  null
                                );
                                form.setFieldValue(
                                  "questions.correctedOptions",
                                  null
                                );
                              } else if (
                                option.value === "Multi-choice questions (MCQ)"
                              ) {
                                form.setFieldValue("questions.options", [
                                  "",
                                  "",
                                  "",
                                  "",
                                ]);
                                form.setFieldValue(
                                  "questions.correctedOption",
                                  null
                                );
                                form.setFieldValue(
                                  "questions.correctedOptions",
                                  null
                                );
                              } else {
                                form.setFieldValue("questions.options", null);
                                form.setFieldValue(
                                  "questions.multipleOptions",
                                  null
                                );
                                form.setFieldValue(
                                  "questions.correctedOption",
                                  null
                                );
                                form.setFieldValue(
                                  "questions.correctedOptions",
                                  null
                                );
                              }
                            }}
                            options={QuestionOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-question">Marks *</label>
                    <Field
                      className="create-admission-input-big "
                      style={{ width: "128px" }}
                      name="marks"
                    />
                  </div>
                </div>
                <div className="question-editor-container">
                  <label className="label-text-question">Question *</label>
                  <TextEditor
                    onHtmlContentChange={(content) =>
                      handleHtmlContentChange(content, formik)
                    }
                    initialHtmlContent={htmlContent}
                  />
                </div>
                {selectedQuestionType === "Match the following" && (
                  <div className="match-question-container">
                    <div className="form-group d-flex gap-2">
                      <span className="label-text-question col-6">
                        Left Side *
                      </span>
                      <span className="label-text-question col-6">
                        Right Side *
                      </span>
                    </div>
                    {[0, 1, 2, 3].map((index) => (
                      <div className="form-group d-flex gap-2" key={index}>
                        <Field
                          type="text"
                          className="question-inner-input"
                          name={`questions.leftSideOptions[${index}].question`}
                          placeholder={`Type your option ${String.fromCharCode(
                            65 + index
                          )}`}
                        />
                        <Field
                          type="text"
                          className="question-inner-input"
                          name={`questions.leftSideOptions[${index}].answer`}
                          placeholder={`Type your answer ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {selectedQuestionType === "Multi-select questions (MSQ)" && (
                  <div>
                    <div className="label-text-question">
                      Choices * (check all the correct answers)
                    </div>
                    <div className="choose-container">
                      {formik.values.questions.multipleOptions.map(
                        (option, index) => (
                          <div key={index} className="choose-input">
                            <Field
                              type="checkbox"
                              name={`questions.correctedOptions[${index}]`}
                              value={option}
                              checked={(
                                formik.values.questions.correctedOptions || []
                              ).includes(option)}
                              onChange={(e) => {
                                const correctedOptionsCopy = [
                                  ...(formik.values.questions
                                    .correctedOptions || []),
                                ];
                                if (e.target.checked) {
                                  correctedOptionsCopy.push(option);
                                } else {
                                  const optionIndex =
                                    correctedOptionsCopy.indexOf(option);
                                  if (optionIndex !== -1) {
                                    correctedOptionsCopy.splice(optionIndex, 1);
                                  }
                                }
                                formik.setFieldValue(
                                  "questions.correctedOptions",
                                  correctedOptionsCopy
                                );
                              }}
                            />
                            <input
                              type="text"
                              className="question-inner-input"
                              placeholder={`Type Your Option ${index + 1}`}
                              name={`questions.multipleOptions[${index}]`}
                              value={option}
                              onChange={(e) => {
                                const newOptions = [
                                  ...formik.values.questions.multipleOptions,
                                ];
                                newOptions[index] = e.target.value;
                                formik.setFieldValue(
                                  "questions.multipleOptions",
                                  newOptions
                                );
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {selectedQuestionType === "Multi-choice questions (MCQ)" && (
                  <div className="choose-container">
                    {formik.values.questions.options.map((option, index) => (
                      <label key={index} className="choose-input">
                        <Field
                          type="radio"
                          name="questions.correctedOption"
                          value={option}
                          checked={
                            formik.values.questions.correctedOption === option
                          }
                          onChange={(e) => {
                            formik.setFieldValue(
                              "questions.correctedOption",
                              e.target.value
                            );
                          }}
                        />
                        <input
                          type="text"
                          className="question-inner-input"
                          placeholder={`Type Your Option ${index + 1}`}
                          name={`questions.options[${index}]`}
                          value={option}
                          onChange={(e) => {
                            const newOptions = [
                              ...formik.values.questions.options,
                            ];
                            newOptions[index] = e.target.value;
                            formik.setFieldValue(
                              "questions.options",
                              newOptions
                            );
                          }}
                        />
                      </label>
                    ))}
                  </div>
                )}
                {selectedQuestionType === "True or False" && (
                  <div className="choose-container">
                    {tfOptions.map((option, index) => (
                      <label key={index} className="choose-input">
                        <Field
                          type="radio"
                          name="questions.correctedBooleanOption"
                          value={option}
                          checked={
                            formik.values.questions.correctedBooleanOption ===
                            option
                          }
                          onChange={(e) => {
                            formik.setFieldValue(
                              "questions.correctedBooleanOption",
                              e.target.value
                            );
                          }}
                        />
                        <input
                          type="text"
                          className="question-inner-input"
                          // name={`questions.tfOptions`}
                          value={option}
                        />
                      </label>
                    ))}
                  </div>
                )}

                {selectedQuestionType ===
                  "Write short notes on the following" && (
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-question">
                      Max words(optional)
                    </label>
                    <Field
                      className="create-admission-input-big "
                      style={{ width: "128px" }}
                      name="maxWords"
                    />
                  </div>
                )}
                {selectedQuestionType === "Fill in the blanks" && (
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-question">
                      Correct Answer
                    </label>
                    <Field
                      className="question-inner-input"
                      name="questions.correctedAnswer"
                      placeholder="Type the correct answer"
                    />
                  </div>
                )}
                <div className="d-flex align-items-center gap-3 question-bottom-con">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="communication-save-head-button"
                  >
                    Save Question
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default QuestionModal;
