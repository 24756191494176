import "./AddModule.css";
import { Field, Formik, Form, ErrorMessage } from "formik";
import React from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";

function ModuleOutcomeModal({ open, onClose, onSubmit, editValues }) {
  const initialValues = {
    coursework: "",
    outcome: "",
    exam: "",
    inPerson: "",
  };
  const validationSchema = Yup.object().shape({
    coursework: Yup.string().required("Coursework is required"),
    exam: Yup.string().required("Exam is required"),
    inPerson: Yup.string().required("In-Person is required"),
  });

  const InPerson = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];

  const Exam = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];

  const CourseWork = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={editValues || initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
              onSubmit(values);
              onClose();
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <div className="flex-sm-row justify-content-between">
                    <div className="new-conversation-popup-header">
                      <span className="add-news-current-affair-title-text">
                        Module Outcome & Assessment
                      </span>
                      <span className="d-flex justify-content-center align-items-center">
                        <IoClose
                          color="#ffffff"
                          size={"23px"}
                          onClick={() => {
                            onClose();
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                      marginTop: "10px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Coursework
                    </label>
                    <Field
                      className="new-conversation-popup-input rounded-0"
                      name="coursework"
                      render={({ field, form }) => (
                        <Select
                          id="coursework"
                          value={
                            CourseWork.find(
                              (option) => option.value === values.coursework
                            ) || null
                          }
                          onChange={(option) =>
                            form.setFieldValue("coursework", option.value)
                          }
                          options={CourseWork}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />

                    <ErrorMessage
                      name="coursework"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Outcome{" "}
                      <span style={{ color: "#7B7B7B", fontSize: "12px" }}>
                        (max 135 chars)
                      </span>
                    </label>
                    <Field
                      as="textarea"
                      className="new-conversation-popup-textare-input rounded-0 custom-scrollbar"
                      name="outcome"
                      style={{ verticalAlign: "top" }}
                      maxLength={135}
                      value={values.outcome || ""}
                    />
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Exam
                    </label>
                    <Field
                      className="new-conversation-popup-input rounded-0"
                      name="exam"
                      render={({ field, form }) => (
                        <Select
                          id="exam"
                          value={
                            Exam.find(
                              (option) => option.value === values.exam
                            ) || null
                          }
                          onChange={(option) =>
                            form.setFieldValue("exam", option.value)
                          }
                          options={Exam}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="exam"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      In-Person
                    </label>
                    <Field
                      className="new-conversation-popup-input rounded-0"
                      name="inPerson"
                      render={({ field, form }) => (
                        <Select
                          id="inPerson"
                          value={
                            InPerson.find(
                              (option) => option.value === values.inPerson
                            ) || null
                          }
                          onChange={(option) =>
                            form.setFieldValue("inPerson", option.value)
                          }
                          options={InPerson}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="inPerson"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>
                  <div
                    className="paymentupdate-document-upload"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  ></div>
                </div>
                <div className="d-flex align-items-center gap-3 mt-4">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="communication-management-submit-button"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default ModuleOutcomeModal;
