import { Form, Formik, Field } from "formik";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import "./CreatePersonalData.css";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Country } from "country-state-city";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";

function CreatePersonalData() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const userData = sessionstorageService.getUserData("userData");
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.personalData?.isApproved);
          setRejected(data?.personalData?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);

          if (data?.personalData?.nationality) {
            setSelectedCountry(data.personalData.nationality);
          }
          if (data?.personalData) {
          }
        });
    }
  }, [admissionId]);

  const [formValues, setFormValues] = useState({
    email: userData?.email || "",
    title: null,
    firstName: "",
    middleName: "",
    lastName: "",
    sex: null,
    maritalStatus: null,
    dateOfBirth: "",
    nationality: selectedCountry || "",
    passportNumber: "",
    expiryDate: "",
    passportIssuer: "",
    nationalIdNumber: "",
    drivingLicenseNumber: "",
    personalId: "",
    otherMaritalStatus: "",
    osapAccessNumber: "",
    studentStatus: null,
    prCardNumber: "",
    refugeesDocumentNumber: "",
  });

  const initialValues = {
    email: userData?.email || "",
    title: formValues.title || null,
    firstName: formValues.firstName || "",
    middleName: formValues.middleName || "",
    lastName: formValues.lastName || "",
    sex: formValues.sex || null,
    maritalStatus: formValues.maritalStatus || null,
    dateOfBirth: formValues.dateOfBirth || "",
    nationality: formValues.nationality || "",
    passportNumber: formValues.passportNumber || "",
    expiryDate: formValues.expiryDate || "",
    passportIssuer: formValues.passportIssuer || "",
    nationalIdNumber: formValues.nationalIdNumber || "",
    drivingLicenseNumber: formValues.drivingLicenseNumber || "",
    personalId: formValues.personalId || "",
    otherMaritalStatus: formValues.otherMaritalStatus || "",
    osapAccessNumber: formValues.osapAccessNumber || "",
    studentStatus: formValues.studentStatus || null,
    prCardNumber: formValues.prCardNumber || "",
    refugeesDocumentNumber: formValues.refugeesDocumentNumber || "",

    isRejected: false,
  };

  const studentType = userData?.studentType;

  const validate = (values) => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = "Firstname is required";
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Date of Birth is required";
    }

    if (!values.sex) {
      errors.sex = "Gender is required";
    }

    if (
      studentType !== "OSAP" ||
      (studentType === "OSAP" && values.studentStatus === "CITIZEN")
    ) {
      if (!values.nationality) {
        errors.nationality = "Nationality is required";
      }
      if (!values.passportNumber) {
        errors.passportNumber = "Passport is required";
      }
      if (!values.expiryDate) {
        errors.expiryDate = "Expirydate is required";
      }
    }
    if (studentType === "OSAP") {
      if (!values.maritalStatus) {
        errors.maritalStatus = "Marital Status is required";
      }
      if (!values.osapAccessNumber) {
        errors.osapAccessNumber = "Access Number is required";
      }

      if (!values.studentStatus) {
        errors.studentStatus = "Status is Required";
      }
    }

    if (studentType === "OSAP" && values.maritalStatus === "OTHER") {
      if (!values.otherMaritalStatus) {
        errors.otherMaritalStatus = "Please Specify";
      }
    }
    if (
      studentType === "OSAP" &&
      values.studentStatus === "PERMANENTRESIDENT"
    ) {
      if (!values.prCardNumber) {
        errors.prCardNumber = "PR Card No. is required";
      }
    }
    if (studentType === "OSAP" && values.studentStatus === "REFUGEE") {
      if (!values.refugeesDocumentNumber) {
        errors.refugeesDocumentNumber = "Refugee Document No. is required";
      }
    }

    return errors;
  };

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };

    fetchCountries();
  }, []);
  const onSubmit = async (values, { setSubmitting }) => {
    values.isRejected = false;
    const dataType = "personal";
    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personalData: {
          ...values,
        },
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        sessionstorageService.setAdmissionId(response.data.data.admissionId);
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.PERSONAL_DATA_SUCCESS);
        setFormValues(values);
        navigation("/pre-onboarding/create-mailing-address", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.PERSONAL_DATA_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.PERSONAL_DATA_FAILURE);
      }
    }
  };

  const handleClear = async (formik) => {
    formik.resetForm();
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "230px !important",
    }),
  };

  const MaritalStatusOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "DIVORCED", label: "Divorced" },
  ];
  const MaritalStatusOSAPOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "COMMONLAW", label: "Common Law" },
    { value: "OTHER", label: "Other" },
  ];
  const TitleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
  ];
  const statusOptions = [
    { value: "CITIZEN", label: "Citizen" },
    { value: "PERMANENTRESIDENT", label: "Permanent Resident" },
    { value: "REFUGEE", label: "Refugee" },
  ];

  const sexOption = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "PREFERNOTTOSAY", label: "Prefer not to say" },
  ];
  const dateString = new Date(admissionData?.personalData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setMinDate(currentDate);
  }, []);
  return (
    <div className="create-admission-main-container">
      <div className="progress-container">
        <div className="create-admission-head-text">Registration</div>
        <div className="col">
          <ProgressBar
            now={admissionData?.pagePercentage || 0}
            label={`${admissionData?.pagePercentage || 0}%`}
          />
        </div>
      </div>
      <div className="row create-admission-container g-0">
        <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
          <AdmissionSideBar />
        </div>
        <div className=" create-admission-inner-right-container col-12 col-lg-9 py-2">
          <div className="create-text-title ">Personal Data</div>
          <Formik
            initialValues={admissionData?.personalData || initialValues}
            enableReinitialize={true}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Email</label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-personal-data"
                        name="email"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Title </label>
                      <Field
                        name="title"
                        as="select"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        component={({ field, form }) => (
                          <>
                            <Select
                              value={TitleOptions.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={TitleOptions}
                              isSearchable={false}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          </>
                        )}
                      />
                      {formik.touched.title &&
                      formik.errors.title &&
                      !formik.values.title ? (
                        <div className="error title">{formik.errors.title}</div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">First name *</label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-fname"
                        name="firstName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />

                      {formik.touched.firstName &&
                      formik.errors.firstName &&
                      !formik.values.firstName ? (
                        <div className="error firstname">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Middle name</label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-mname"
                        name="middleName"
                      />
                    </div>

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Last name </label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                        name="lastName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="personal-note">
                    (Note: Please ensure that the First Name, Middle Name, and
                    Last Name match the information exactly as it appears on
                    your passport)
                  </div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Gender *</label>
                      <Field
                        name="sex"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        component={({ field, form }) => (
                          <>
                            <Select
                              value={sexOption.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={sexOption}
                              isSearchable={false}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            ></Select>

                            {formik.touched.sex &&
                            formik.errors.sex &&
                            !formik.values.sex ? (
                              <div className="error sex">
                                {formik.errors.sex}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Marital Status {studentType === "OSAP" && "*"}
                      </label>

                      <Field
                        name="maritalStatus"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        component={({ field, form }) => (
                          <>
                            <Select
                              options={
                                studentType === "OSAP"
                                  ? MaritalStatusOSAPOptions
                                  : MaritalStatusOptions
                              }
                              value={
                                MaritalStatusOptions.find(
                                  (option) => option.value === field.value
                                ) ||
                                MaritalStatusOSAPOptions.find(
                                  (option) => option.value === field.value
                                )
                              }
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              isSearchable={false}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            ></Select>

                            {formik.touched.maritalStatus &&
                            formik.errors.maritalStatus &&
                            !formik.values.maritalStatus ? (
                              <div className="error maritalstatus">
                                {formik.errors.maritalStatus}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    {formik.values.maritalStatus === "OTHER" && (
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">Others *</label>

                        <Field
                          className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                          name="otherMaritalStatus"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.otherMaritalStatus &&
                        formik.errors.otherMaritalStatus &&
                        !formik.values.otherMaritalStatus ? (
                          <div className="error dateofbirth">
                            {formik.errors.otherMaritalStatus}
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Date of Birth *
                      </label>
                      <Field
                        type="date"
                        className="create-personal-data-date-input-small pre-onboard-create-personal-data-date-input-small-dob"
                        name="dateOfBirth"
                        placeholder="Choose"
                        max={getCurrentDate()}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.dateOfBirth &&
                      formik.errors.dateOfBirth &&
                      !formik.values.dateOfBirth ? (
                        <div className="error dateofbirth">
                          {formik.errors.dateOfBirth}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {studentType === "OSAP" && (
                    <div className="create-personal-data-main-input-container">
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">
                          Access Number *
                        </label>
                        <Field
                          name="osapAccessNumber"
                          className="create-admission-input-big pre-onboard-create-admission-input-big-mname"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            formik.setFieldValue(
                              "osapAccessNumber",
                              numericValue
                            );
                          }}
                        />
                        {formik.touched.osapAccessNumber &&
                        formik.errors.osapAccessNumber &&
                        !formik.values.osapAccessNumber ? (
                          <div className="error maritalstatus">
                            {formik.errors.osapAccessNumber}
                          </div>
                        ) : null}
                      </div>

                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">Status *</label>

                        <Field
                          name="studentStatus"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          component={({ field, form }) => (
                            <>
                              <Select
                                options={statusOptions}
                                value={statusOptions.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(option) => {
                                  formik.setFieldValue(
                                    field.name,
                                    option.value
                                  );

                                  if (option.value === "CITIZEN") {
                                    formik.setFieldValue(
                                      "nationality",
                                      "Canada"
                                    );
                                    setSelectedCountry("Canada");
                                  } else {
                                    formik.setFieldValue("nationality", "");
                                    setSelectedCountry(null);
                                  }
                                }}
                                isSearchable={false}
                                classNamePrefix="react-select"
                                styles={selectStyles}
                                components={{
                                  DropdownIndicator: () => (
                                    <RxTriangleDown color="#ffffff" />
                                  ),
                                  IndicatorSeparator: null,
                                }}
                                placeholder="Choose"
                                menuPlacement="auto"
                                menuPosition="fixed"
                              ></Select>

                              {formik.touched.studentStatus &&
                              formik.errors.studentStatus &&
                              !formik.values.studentStatus ? (
                                <div className="error maritalstatus">
                                  {formik.errors.studentStatus}
                                </div>
                              ) : null}
                            </>
                          )}
                        />
                      </div>

                      {formik.values.studentStatus === "PERMANENTRESIDENT" && (
                        <div className="create-admission-inner-first-input-container ">
                          <label className="label-text-active">
                            PR Number *
                          </label>

                          <Field
                            className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                            name="prCardNumber"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.prCardNumber &&
                          formik.errors.prCardNumber &&
                          !formik.values.prCardNumber ? (
                            <div className="error dateofbirth">
                              {formik.errors.prCardNumber}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {formik.values.studentStatus === "REFUGEE" && (
                        <div className="create-admission-inner-first-input-container ">
                          <label className="label-text-active">
                            Refugee Document No. *
                          </label>

                          <Field
                            className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                            name="refugeesDocumentNumber"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.refugeesDocumentNumber &&
                          formik.errors.refugeesDocumentNumber &&
                          !formik.values.refugeesDocumentNumber ? (
                            <div className="error dateofbirth">
                              {formik.errors.refugeesDocumentNumber}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Nationality{" "}
                        {!(
                          studentType === "OSAP" &&
                          (formik.values.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            formik.values.studentStatus === "REFUGEE")
                        ) && "*"}
                      </label>

                      <Field
                        name="nationality"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        component={({ field, form }) => (
                          <>
                            <Select
                              options={countries}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              value={countries.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  field.name,
                                  selectedOption ? selectedOption.value : ""
                                );
                                setSelectedCountry(selectedOption.value);
                              }}
                              isSearchable={true}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                            {formik.touched.nationality &&
                            formik.errors.nationality &&
                            !formik.values.nationality ? (
                              <div className="error nationality">
                                {formik.errors.nationality}
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Passport Number{" "}
                        {!(
                          studentType === "OSAP" &&
                          (formik.values.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            formik.values.studentStatus === "REFUGEE")
                        ) && "*"}
                      </label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-PNumber"
                        name="passportNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />

                      {formik.touched.passportNumber &&
                      formik.errors.passportNumber &&
                      !formik.values.passportNumber ? (
                        <div className="error passportNumber">
                          {formik.errors.passportNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Expiry Date{" "}
                        {!(
                          studentType === "OSAP" &&
                          (formik.values.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            formik.values.studentStatus === "REFUGEE")
                        ) && "*"}
                      </label>

                      <Field
                        type="date"
                        className="create-personal-data-date-input-small pre-onboard-create-personal-data-date-input-small-EDate"
                        name="expiryDate"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        min={minDate}
                      />

                      {formik.touched.expiryDate &&
                      formik.errors.expiryDate &&
                      !formik.values.expiryDate ? (
                        <div className="error expiryDate">
                          {formik.errors.expiryDate}
                        </div>
                      ) : null}
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Place Of Issue
                      </label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-PIssue"
                        name="passportIssuer"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>

                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        National ID Number
                      </label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-NId"
                        name="nationalIdNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Driving License Number
                      </label>

                      <Field
                        className="create-admission-input-big pre-onboard-create-admission-input-big-dlnumber"
                        name="drivingLicenseNumber"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  {!admissionData && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(formik)}
                    >
                      Clear
                    </button>
                  )}
                  {!admissionData?.personalData?.personalId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      disabled={formik.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        disabled={formik.isSubmitting}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {(admissionData?.personalData?.personalId ||
                    admissionData?.personalData?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation("/pre-onboarding/create-mailing-address", {
                          state: {
                            data: programId,
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}
                </div>
                {admissionData?.personalData.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.personalData.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.personalData?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CreatePersonalData;
