import React from "react";
import "./ViewApplicationForStudent.css";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "services/axios.service";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import sessionstorageService from "services/sessionstorage.service";
import { toast } from "react-toastify";

function ViewApplicationForStudent() {
  const navigation = useNavigate();
  const [data, setData] = useState();
  const [viewConfirm, setViewConfirm] = useState(null);
  const [approved, setApproved] = useState(null);

  useEffect(() => {
    const admissionId = sessionstorageService.getAdmissionId("userData");
    const studentData = sessionstorageService.getUserData("userData");
    const studentType = studentData?.studentType;
    if (admissionId && studentType !== "OSAP") {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const admissionData = response?.data?.data;
          setData(admissionData?.fees);
          setViewConfirm(admissionData?.isViewConfirm);
          setApproved(admissionData?.isCreated);
        })
        .catch((error) => {});
    } else if (admissionId && studentType === "OSAP") {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_OSAP_STUDENT}${admissionId}`
        )
        .then((response) => {
          const admissionData = response?.data?.data;
          setData(admissionData?.fees);
          setViewConfirm(admissionData?.isViewConfirm);
          setApproved(admissionData?.isCreated);
        })
        .catch((error) => {});
    } else {
      setData(null);
      setViewConfirm(false);
    }
  }, []);
  function formatProgramType(programType) {
    switch (programType) {
      case "NONVOCATIONAL":
        return "Non-Vocational";
      case "VOCATIONALANDACADEMIC":
        return "Vocational, Academic";

      default:
        return programType;
    }
  }
  const getRowHeight = (params) => {
    if (params.data?.programDetail?.program?.length > 50) {
      return 80;
    } else if (params.data?.programDetail?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Program",
      field: "programDetail.program",
      width: 320,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
        textDecoration: "underline",
      },
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: function (params) {
        const status = params.value;

        return (
          <span
            className={`${
              status === "APPROVED" && "ag-grid-application-status-approved"
            }
          ${status === "REJECTED" && "ag-grid-application-status-rejected"}
          ${status === "PENDING" && "ag-grid-application-status-pending"}
          `}
          >
            {status}
          </span>
        );
      },
    },

    {
      headerName: "Exchange Program",
      field: "programDetail.exchangeProgram",
    },
    {
      headerName: "Academic Status",
      field: "programDetail.academicStatus",
    },
    {
      headerName: "Type of Training Program",
      field: "programDetail.programType",
      valueFormatter: function (params) {
        return formatProgramType(params.value);
      },
      width: 250,
    },

    {
      headerName: "Created Date",
      field: "programDetail.addedOn",
      cellRenderer: function (params) {
        const date = params.data.programDetail.addedOn;
        return date ? new Date(date).toLocaleDateString("en-GB") : "";
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const CreateApplication = () => {
    if (viewConfirm) {
      if (approved) {
        toast.warn(
          "An application has already been processed and approved.Cannot accept further submissions"
        );
      } else {
        navigation("/pre-onboarding/create-program-details");
      }
    } else {
      toast.error("Please Complete Registration !");
    }
  };
  const onRowClicked = (event) => {
    if (approved) {
      if (event.data.status === "APPROVED") {
        navigation("/pre-onboarding/create-program-details", {
          state: { data: event.data.programDetail },
        });
      }
    } else {
      navigation("/pre-onboarding/create-program-details", {
        state: { data: event.data.programDetail },
      });
    }
  };
  return (
    <div className="create-student-admission-main-container">
      <div className="application-title-container">
        <div className="create-admission-head-text">Applications</div>
        <button
          className="create-application-button"
          onClick={CreateApplication}
        >
          Create Application
        </button>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
        <div>
          <span style={{ color: "#F7931D" }}>Note:</span> After your application
          has been approved, please proceed to the documents page to submit the
          enrollment letter.
        </div>
      </div>
    </div>
  );
}

export default ViewApplicationForStudent;
