import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "../../../../utils/lead-management.css";
import CreateLeads from "../create-leads/CreateLeads";
import ViewIndividualLeads from "../view-leads/ViewIndividualLeads";
import PipelineLeads from "../pipeline-leads/PipelineLeads";
import "../../../../utils/lead-management.css";
import HomeLeads from "../Dashboard/home-leads/HomeLeads";
import ViewTask from "../task-management/components/view-task/ViewTask";
import CreateTask from "../task-management/components/create-task/CreateTask";
import ViewLeads from "../view-leads-table/ViewLeads";
import sessionstorageService from "services/sessionstorage.service";
import ViewReports from "../view-reports/ViewReports";
function LeadManagementRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  useEffect(() => {
    if (location.pathname === "/lead-management") {
      navigate("/lead-management/home-leads");
    }
  }, [location.pathname, navigate]);
  return (
    <Routes>
      {hasPermission("CRM: view") && (
        <Route path="create-leads" element={<CreateLeads />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="view-leads" element={<ViewIndividualLeads />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="view-leads-table" element={<ViewLeads />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="pipeline-leads" element={<PipelineLeads />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="home-leads" element={<HomeLeads />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="view-task" element={<ViewTask />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="create-task" element={<CreateTask />} />
      )}
      {hasPermission("CRM: view") && (
        <Route path="view-reports" element={<ViewReports />} />
      )}
    </Routes>
  );
}

export default LeadManagementRoutes;
