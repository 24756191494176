export const PaymentConstants = {
  PAYMENT_SUCCESS: "Payment saved successfully",
  PAYMENT_UPDATE_SUCCESS: "Payment updated successfully",
  PAYMENT_FAILED: "Faild to save payment detail.",
  ERROR_OCCUR:
    "An unexpected error occured during payment. Please contact admin/support",
  ROLE_REQUIRED: "RoleName is required",
  PAYMENT_WARNING: "Please fill all fields",
  PAYMENT_WARNIN_ONE: "Do not provide space or any special character at first",
  UPLOAD_FILE_FAILURE: "Please try, File Does not upload",
  UPLOAD_FILE_ERROR: "Upload file not supported",
  PENDING_APPROVAL:
    "Application is still pending approval. Please contact office.",
  ALREADY_PAYMENT_SUBMITTED:
    "Already another application payment still pending.Please contact admin/support",
};
