import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ViewAllFee from "../fee-plans/view-all-fee/ViewAllFee";
import ViewStudentInvoices from "../fee-plans/view-student-invoices/ViewStudentInvoices";
import MyInvoices from "../invoice-student-view/MyInvoices";
import ViewSponsorInvoice from "../fee-plans/sponser-invoice/view-sponsor-invoice/ViewSponsorInvoice";
import sessionService from "services/sessionstorage.service";
import MySponsors from "../invoice-student-view/MyReceipts";
import MyReceipts from "../invoice-student-view/MyReceipts";

function FeeFinanceRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionService.getUserData();
  const role = userData?.role?.name;

  useEffect(() => {
    if (location.pathname === "/fee-finance") {
      if (role === "student") {
        navigate("/fee-finance/my-invoice");
      } else {
        navigate("/fee-finance/view-all-fee");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="view-all-fee" element={<ViewAllFee />} />
      <Route path="view-student-invoice" element={<ViewStudentInvoices />} />
      <Route path="view-sponsor-invoice" element={<ViewSponsorInvoice />} />
      <Route path="my-invoice" element={<MyInvoices />} />
      <Route path="my-receipt" element={<MyReceipts />} />
    </Routes>
  );
}

export default FeeFinanceRoutes;
