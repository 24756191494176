import { useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import { TASK_URLS } from "../task-management/constants/TaskUrls";
import "./PipelineLeads.css";
import { LEAD_URL } from "modules/lead-mangement/constants/leads-url";
import { PIPELINE_UPDATE_CONSTANTS } from "../task-management/constants/task-constants";
import { toast } from "react-toastify";

function PipelineLeads() {
  const location = useLocation();
  const leadId = location.state?.leadId;
  const [historyData, setHistoryData] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [date, setDate] = useState(null);
  const [showError, setShowError] = useState(false);
  const [comments, setComments] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axiosService
      .get(`${LEAD_URL.GET_LEAD_BY_LEAD_ID}${leadId}`)
      .then((response) => {
        const data = response?.data?.data;
        setLeadData(data);
      });
  }, [location.pathname, refresh]);

  useEffect(() => {
    if (leadId) {
      axiosService
        .get(`${TASK_URLS.PIPELINE_URL}${leadId}`)
        .then((response) => {
          const data = response.data.data;

          data.sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn));
          setHistoryData(data);
        });
    }
  }, [location.pathname, refresh]);

  const resetFormValues = () => {
    setDate("");
    setComments("");
    setSelectedContact(null);
    setShowError(false);
  };

  const onSubmit = async () => {
    if (contact?.value && comments && date && leadId) {
      try {
        const requestData = {
          contactMode: contact?.value,
          comments: comments,
          date: date,
          lead1: {
            leadId: leadId,
          },
        };
        const response = await axiosService.post(
          `${TASK_URLS.PIPELINE_UPDATE_URL}`,
          requestData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 200) {
          setRefresh(!refresh);
          resetFormValues();
          toast.success(PIPELINE_UPDATE_CONSTANTS.SUCCESS);
        } else {
          toast.error(PIPELINE_UPDATE_CONSTANTS.FAILED);
        }
      } catch (error) {
        toast.error(PIPELINE_UPDATE_CONSTANTS.ERROR_OCCUR);
      }
    } else {
      setShowError(true);
    }
  };

  const modeOfContactOptions = [
    { value: "CALL", label: "CALL" },
    { value: "EMAIL", label: "EMAIL" },
    { value: "WHATSAPP", label: "WHATSAPP" },
    { value: "SMS", label: "SMS" },
  ];

  const [contact, setSelectedContact] = useState("");
  const handleSelectChange = (selectedOption, stateKey) => {
    if (stateKey === "selectedCampus") {
      setSelectedContact(selectedOption);
    }
  };

  const tasks = leadData.tasks || [];

  const getPriorityClassName = (priority) => {
    switch (priority) {
      case "HIGH":
        return "High-btn";
      case "MEDIUM":
        return "Medium-btn";
      case "LOW":
        return "Low-btn";
      default:
        return "Default-btn";
    }
  };
  return (
    <div className="lead-main-container p-0">
      <div className="row g-0 info-history">
        <div className="col-12 col-lg-4">
          <div>
            {historyData &&
              historyData.map((dataItem, index) => {
                const isNewItem = index === 0;
                return (
                  <div key={dataItem.leadHistoryId}>
                    <span className="history-date">
                      {new Date(dataItem.addedOn).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}{" "}
                    </span>
                    <div
                      className={`history-info-Con ${
                        isNewItem ? "new-item" : ""
                      }`}
                    >
                      <span
                        className={`ellipse ${
                          isNewItem ? "new-item-ellipse" : ""
                        }`}
                        title={dataItem.comments}
                      ></span>
                      <div
                        className="row d-flex history-info-container pb-3"
                        title={dataItem.comments}
                      >
                        <span className="history-text col-12 d-flex">
                          {dataItem.auditDescription}
                        </span>
                        <span className="history-sub-text">
                          by{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "context-menu",
                            }}
                          >
                            {dataItem.updatedBy}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="col-12 col-lg-7 justify-content-end pb-4">
          <div className="history-title">Updates </div>

          <div className="row g-0 d-flex update-input-container p-3 gap-md-3">
            <div className="col-12 col-md-5 row g-0 date-con">
              <label className="update-label">Date *</label>
              <input
                className="update-input"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              {!date && showError && (
                <span className="pipline-update-error-message">
                  Date is required
                </span>
              )}
            </div>
            <div
              className="col-12 col-md-5 row g-0 contact-con"
              style={{ position: "relative" }}
            >
              <label className="update-label">Mode of contact</label>
              <Select
                value={contact}
                className="contact-input contact-con"
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, "selectedCampus")
                }
                options={modeOfContactOptions}
                components={{
                  DropdownIndicator: () => (
                    <RxTriangleDown size="20px" color="#FFFFFF" />
                  ),
                  IndicatorSeparator: null,
                }}
                isSearchable={false}
                classNamePrefix="react-select-big"
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              ></Select>
              {!contact && showError && (
                <span className="pipline-update-error-message">
                  Mode of contact is required
                </span>
              )}
            </div>
            <div className="col-12 row g-0" style={{ position: "relative" }}>
              <label className="update-label"> Comments</label>
              <textarea
                name="comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="update-input-comment"
                placeholder="Your comments"
              />
              {!comments && showError && (
                <span
                  className="pipline-update-error-message"
                  style={{ bottom: "-20px", left: "0px" }}
                >
                  Comments is required
                </span>
              )}
            </div>

            <div className="col-12 column justify-content-end d-flex">
              <button
                className="update-cancel-button"
                onClick={() => resetFormValues()}
              >
                Cancel
              </button>
              <button
                className="update-submit-button"
                type="submit"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>

          <div
            className="table-con col-12 custom-scrollbar"
            style={{ overflowX: "auto" }}
          >
            <div className="history-title">Upcoming Tasks </div>
            <table style={{ width: "100%", height: "100%" }}>
              <thead className="table-head">
                <tr>
                  <th>Subject</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Lead Name</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody className="table-data">
                {tasks.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No data to display.
                    </td>
                  </tr>
                ) : (
                  tasks.map((task, index) => (
                    <tr key={index}>
                      <td>{task.subject || "-"}</td>
                      <td>{task.dueDate || "-"}</td>
                      <td>{task.status || "-"}</td>
                      <td>
                        {leadData.firstName} {leadData.lastName}
                      </td>
                      <td>
                        <button className={getPriorityClassName(task.priority)}>
                          {task.priority || "-"}
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PipelineLeads;
