import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import "./EditLeavePopup.css";
import EditIcon2 from "assets/image-components/EditIcon2";
import { LEAVE_MASTER_URLS } from "modules/hr-module/constants/leave-master-urls";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";

const EditLeavePopup = ({ open, onClose }) => {
  const [disable, setdisable] = useState(true);
  const [invalidData, setInvalidData] = useState(false);

  const [leaveMasterId, setLeaveMasterId] = useState(null);
  const [totalLeave, setTotalLeave] = useState(0);
  const [sickLeave, setSickLeave] = useState("");
  const [unplannedLeave, setUnplannedLeave] = useState("");
  const [otherLeave, setOtherLeave] = useState("");
  const [earnedLeave, setEarnedLeave] = useState("");
  const [emergencyLeave, setEmergencyLeave] = useState("");

  useEffect(() => {
    try {
      const response = axiosService
        .get(`${LEAVE_MASTER_URLS.GET_ALL_LEAVES}`)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.data.length === 0) {
              setInvalidData(true);
              setdisable(false);
            } else {
              let Data = response?.data?.data;
              setLeaveMasterId(Data[0].leaveMasterId);
              setEarnedLeave(Data[0].earnedLeave);
              setEmergencyLeave(Data[0].emergencyLeave);
              setOtherLeave(Data[0].otherLeave);
              setSickLeave(Data[0].sickLeave);
              setUnplannedLeave(Data[0].unplannedLeave);
              setTotalLeave(Data[0].totalLeave);
            }
          } else {
            // toast.error("failed");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  }, []);

  useEffect(() => {
    const total =
      Number(sickLeave) +
      Number(unplannedLeave) +
      Number(otherLeave) +
      Number(earnedLeave) +
      Number(emergencyLeave);
    setTotalLeave(total);
  }, [sickLeave, unplannedLeave, otherLeave, earnedLeave, emergencyLeave]);

  const handleSave = async () => {
    const Data = {
      totalLeave,
      sickLeave,
      unplannedLeave,
      otherLeave,
      earnedLeave,
      emergencyLeave,
    };

    try {
      const response = await axiosService.post(
        `${LEAVE_MASTER_URLS.SAVE_LEAVES}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Leave added successfully");
        onClose();
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };

  const handleEdit = async () => {
    const Data = {
      leaveMasterId,
      totalLeave,
      sickLeave,
      unplannedLeave,
      otherLeave,
      earnedLeave,
      emergencyLeave,
    };

    try {
      const response = await axiosService.put(
        `${LEAVE_MASTER_URLS.UPDATE_LEAVE}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Leave updated successfully");
        onClose();
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };

  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-10 col-md-4 p-3"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                2024 Leave Table
              </span>
              <span style={{ display: "flex", gap: "10px" }}>
                {!invalidData && disable && (
                  <span
                    className="d-flex justify-content-end align-items-center"
                    onClick={() => setdisable(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon2 color="#ffffff" size={"22px"} />
                  </span>
                )}
                <span
                  className="d-flex justify-content-end align-items-center"
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                >
                  <IoClose color="#ffffff" size={"22px"} />
                </span>
              </span>
            </div>
            <div className="edit-leave-form-container">
              <div style={{ fontWeight: "600", color: "#F69F3A" }}>
                <div>Leave Name</div>
                <div>Total Leaves</div>
              </div>

              <div style={{ fontWeight: "400" }}>
                <div>Sick Leave</div>
                <div>
                  <input
                    disabled={disable}
                    value={sickLeave}
                    onChange={(e) => setSickLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
              <div style={{ fontWeight: "400" }}>
                <div>Unplanned Leave</div>
                <div>
                  <input
                    disabled={disable}
                    value={unplannedLeave}
                    onChange={(e) => setUnplannedLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
              <div style={{ fontWeight: "400" }}>
                <div>Other Leave</div>
                <div>
                  <input
                    disabled={disable}
                    value={otherLeave}
                    onChange={(e) => setOtherLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
              <div style={{ fontWeight: "400" }}>
                <div>Earned Leave</div>
                <div>
                  <input
                    disabled={disable}
                    value={earnedLeave}
                    onChange={(e) => setEarnedLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
              <div style={{ fontWeight: "400" }}>
                <div>Emergency Leave</div>
                <div>
                  <input
                    disabled={disable}
                    value={emergencyLeave}
                    onChange={(e) => setEmergencyLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
              <div style={{ fontWeight: "400" }}>
                <div>Total Leave</div>
                <div>
                  <input
                    disabled={true}
                    value={totalLeave}
                    onChange={(e) => setTotalLeave(e.target.value)}
                    style={{
                      height: "33px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      backgroundColor: "#484848",
                    }}
                    type="number"
                    className=" lead-input col-8 col-md-10 edit-leave-input"
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex gap-3 pt-4"
              style={{ borderTop: "1px solid #7A7A7A" }}
            >
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              {disable === false && invalidData === false && (
                <button
                  // disabled={!employeeId || !employeeType}
                  className={`communication-management-submit-button`}
                  onClick={() => {
                    handleEdit();
                  }}
                >
                  Submit
                </button>
              )}
              {disable === false && invalidData === true && (
                <button
                  // disabled={!employeeId || !employeeType}
                  className={`communication-management-submit-button`}
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditLeavePopup;
