import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { RxTriangleUp, RxTriangleDown } from "react-icons/rx";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { axiosService } from "services/axios.service";
import { useLocation } from "react-router-dom";

function AddModulesProgram({
  isOpen,
  onClose,
  onSubmit,
  selectedSemesterCount,
  SemesterCountOptions,
  maxModulesPerSemester,
  editModuleDatas,
}) {
  const [selectedModules, setSelectedModules] = useState(
    Array(selectedSemesterCount).fill([])
  );

  useEffect(() => {
    setSelectedModules(editModuleDatas.flat());
  }, [editModuleDatas]);

  const location = useLocation();
  const [moduleData, setModuleData] = useState([]);
  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_ALL_MODULES}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data.modules;
        setModuleData(responseData);
      }
    });
  }, [location.pathname]);

  const [isSemesterCollapsed, setIsSemesterCollapsed] = useState(
    Array(SemesterCountOptions.length).fill(true)
  );

  const toggleAccordion = (index) => {
    setIsSemesterCollapsed((prevIsSemesterCollapsed) => {
      const newIsSemesterCollapsed = [...prevIsSemesterCollapsed];
      newIsSemesterCollapsed[index] = !newIsSemesterCollapsed[index];
      return newIsSemesterCollapsed;
    });
  };
  const [isAllSemestersExpanded, setIsAllSemestersExpanded] = useState(true);

  const toggleAllSemesters = () => {
    setIsAllSemestersExpanded(
      (prevIsAllSemestersExpanded) => !prevIsAllSemestersExpanded
    );
    setIsSemesterCollapsed((prevIsSemesterCollapsed) =>
      prevIsSemesterCollapsed.map(() => !isAllSemestersExpanded)
    );
  };
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,

      display: "none",
    }),
  };
  const allModuleOptions = moduleData.map((module) => ({
    value: module,
    label: `${module.moduleName} - ${module.moduleCode} (${module.betaCredits} Credits)`,
    moduleName: module.moduleName,
    moduleCode: module.moduleCode,
    betaCredits: module.betaCredits,
  }));

  const [totalCredits, setTotalCredits] = useState(
    Array(selectedSemesterCount).fill(0)
  );

  useEffect(() => {
    const newTotalCredits = selectedModules.map((modules, index) =>
      modules.reduce((total, module) => {
        return total + module?.betaCredits;
      }, 0)
    );
    setTotalCredits(newTotalCredits);
  }, [selectedModules, allModuleOptions]);

  return (
    <Modal
      className="crm-modal"
      isOpen={isOpen}
      onRequestClose={() => onClose(selectedModules)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con custom-scrollbar">
        <div
          className="col-12 col-sm-11 col-md-6 px-3 pb-3"
          style={{ maxHeight: "90vh", backgroundColor: "#212226" }}
        >
          <div className="new-conversation-popup-header top-0 sticky-header py-2">
            <span className="new-conversation-popup-title-text">
              Manage Modules
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose color="#ffffff" size={"22px"} />
            </span>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={{
              selectedModules: Array(selectedSemesterCount).fill([]),
            }}
            onSubmit={() => {
              onSubmit(selectedModules);
              onClose();
            }}
          >
            {() => (
              <Form>
                <div className="col-12 ">
                  <div className="add-program-title-container p-0">
                    <span className="forum-accordian-title-text">
                      Module Details
                    </span>
                    <span className="d-flex align-items-center gap-1 cursor-poniter-event">
                      <span
                        className="forum-accordian-view-all-text"
                        onClick={toggleAllSemesters}
                      >
                        {isAllSemestersExpanded ? "expand all" : "collapse all"}
                      </span>
                    </span>
                  </div>
                  <div>
                    {selectedSemesterCount === "" ? (
                      <div className="forum-discussion-online-staff-container">
                        <div className="academics-module-details-view ">
                          <span>Number of semesters is empty.</span>
                        </div>
                      </div>
                    ) : (
                      Array.from(
                        { length: selectedSemesterCount },
                        (_, index) => (
                          <div key={index}>
                            <div style={{ borderBottom: "2px solid #333333" }}>
                              <div className="add-program-title-container-sem p-0">
                                <span className="forum-accordian-sub-text">
                                  Semester {index + 1}
                                </span>
                                <span
                                  className="d-flex align-items-center gap-1 cursor-poniter-event"
                                  onClick={() => toggleAccordion(index)}
                                >
                                  <span className="add-program-view-all-text">
                                    Total Credits{" "}
                                    <span className="semester-counts">
                                      {totalCredits[index]}
                                    </span>{" "}
                                    <span
                                      className={`program-dropdown-icon ${
                                        isSemesterCollapsed[index]
                                          ? "rotate-180"
                                          : ""
                                      }`}
                                    >
                                      <RxTriangleUp />
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  display: isSemesterCollapsed[index]
                                    ? "none"
                                    : "block",
                                }}
                              >
                                <Select
                                  isMulti
                                  value={allModuleOptions.filter((option) =>
                                    (selectedModules[index] || []).includes(
                                      option.value
                                    )
                                  )}
                                  onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(
                                      (option) => option.value
                                    );

                                    setSelectedModules(
                                      (prevSelectedModules) => {
                                        const newSelectedModules = [
                                          ...prevSelectedModules,
                                        ];
                                        while (
                                          newSelectedModules.length <= index
                                        ) {
                                          newSelectedModules.push([]);
                                        }
                                        newSelectedModules[index] =
                                          selectedValues;

                                        return newSelectedModules;
                                      }
                                    );
                                  }}
                                  options={allModuleOptions.filter((module) => {
                                    for (
                                      let i = 0;
                                      i < selectedSemesterCount;
                                      i++
                                    ) {
                                      if (
                                        i !== index &&
                                        (selectedModules[i] || []).includes(
                                          module.value
                                        )
                                      ) {
                                        return false;
                                      }
                                    }

                                    return (
                                      (selectedModules[index] || []).length <
                                      parseInt(maxModulesPerSemester)
                                    );
                                  })}
                                  isSearchable={true}
                                  classNamePrefix="react-select"
                                  components={{
                                    DropdownIndicator: () => (
                                      <RxTriangleDown color="#ffffff" />
                                    ),
                                    IndicatorSeparator: null,
                                  }}
                                  placeholder="Search or choose modules"
                                  menuPlacement="auto"
                                  menuPosition="fixed"
                                  className={
                                    isSemesterCollapsed[index]
                                      ? "collapsed"
                                      : ""
                                  }
                                  styles={customStyles}
                                />
                              </div>

                              {selectedModules[index]?.length > 0 ? (
                                selectedModules[index].map(
                                  (selectedModule, moduleIndex) => (
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        className="modules-view-container row"
                                        key={moduleIndex}
                                        style={{
                                          borderBottom: "2px solid #333333",
                                          marginBottom: "5px",
                                          display: isSemesterCollapsed[index]
                                            ? "none"
                                            : "block",
                                        }}
                                      >
                                        <span className="module-name col-12 d-flex justify-content-start">
                                          {allModuleOptions.find(
                                            (option) =>
                                              option.value === selectedModule
                                          )?.moduleName ||
                                            selectedModule?.moduleName}
                                        </span>
                                        <div className="column d-flex justify-content-between">
                                          <span className="-">
                                            {allModuleOptions.find(
                                              (option) =>
                                                option.value === selectedModule
                                            )?.moduleCode ||
                                              selectedModule?.moduleCode}
                                          </span>
                                          <span className="beta-credits">
                                            {allModuleOptions.find(
                                              (option) =>
                                                option.value === selectedModule
                                            )?.betaCredits ||
                                              selectedModule?.betaCredits}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div
                                  className="modules-view-container-none"
                                  style={{
                                    display: isSemesterCollapsed[index]
                                      ? "none"
                                      : "block",
                                  }}
                                >
                                  No modules added yet.
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                  <div className=" d-flex align-items-center gap-3 mt-4">
                    <button
                      className="communication-management-cancel-button"
                      type="button"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="communication-management-submit-button "
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddModulesProgram;
