import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./AcademicsHome.css";
import AcademicsCalendar from "assets/images/AcademicsCalender";
import AcademicsModules from "assets/images/AcademicsModules";
import { RxTriangleDown } from "react-icons/rx";
import { axiosService } from "services/axios.service";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import sessionService from "services/sessionstorage.service";

function AcademicsHome() {
  const location = useLocation();
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_HOME_DETAILS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        setHomeData(responseData);
      }
    });
  }, [location.pathname]);

  const userData = sessionService.getUserData();
  const role = userData?.role?.name;

  return (
    <div className="communication-management-main-container custom-scrollbar">
      <div className="communication-management-head-container sticky-header-communication flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">Home</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <button className="academics-save-head-button">Academic Year</button>
          <button className="academics-save-head-button-right ">
            {homeData?.academicYear}
            <RxTriangleDown style={{ marginTop: "-5px" }} />
          </button>
        </div>
      </div>
      <div className="row g-0 d-flex justify-content-between gap-3 mt-2 ">
        <div className="col-12 col-lg-auto pe-lg-2">
          <span className="Doc-head p-0">Overview</span>
          <div className="academics-home-card-container mt-2">
            <div className="home-view-box">
              <p className="academics-home-box-title">Total Programs</p>
              <h6 className="academics-home-show-items">
                {homeData?.totalPrograms}
              </h6>
            </div>
            <div className="home-view-box">
              <p className="academics-home-box-title">Total Modules</p>
              <h6 className="academics-home-show-items">
                {homeData?.totalModules}
              </h6>
            </div>
            <div className="home-view-box">
              <p className="academics-home-box-title">Online Programs</p>
              <h6 className="academics-home-show-items">
                {homeData?.onlinePrograms}
              </h6>
            </div>
            <div className="home-view-box">
              <p className="academics-home-box-title">Vocational Programs</p>
              <h6 className="academics-home-show-items">
                {homeData?.vocationalPrograms}
              </h6>
            </div>
            <div className="home-view-box">
              <p className="academics-home-box-title">Total Students</p>
              <h6 className="academics-home-show-items">
                {homeData?.totalStudents}
              </h6>
            </div>
          </div>
        </div>
        {role !== "student" && (
          <div className="col-12 col-sm-8 col-md-6 col-xl d-flex flex-column ps-lg-2">
            <span className="Doc-head p-0">New Programs</span>
            <div className="academics-home-new-program-container mt-2">
              {homeData?.programs?.length > 0 &&
                homeData?.programs?.map((data) => (
                  <div className="d-flex academics-doc-container">
                    <p className="academics-home-doc-text">{data.program}</p>

                    <div className="w-100 d-flex align-items-center gap-2 flex-wrap justify-content-between">
                      <span className="home-sub-text-academics ">
                        <AcademicsCalendar /> {data.programDuration}
                      </span>
                      <span className="home-sub-text-academics ">
                        <AcademicsModules />
                        {data.module} modules
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AcademicsHome;
