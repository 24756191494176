export const isValidPassword = (password) => {
  const uppercaseRegex = /(?=.*[A-Z])/;
  const symbolRegex = /(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?])/;
  const numberRegex = /(?=.*\d)/;
  const errors = [];

  if (!uppercaseRegex.test(password)) {
    errors.push("Password must have at least one uppercase letter.");
  }

  if (!symbolRegex.test(password)) {
    errors.push("Password must have at least one symbol.");
  }

  if (!numberRegex.test(password)) {
    errors.push("Password must have at least one number.");
  }

  return errors.length === 0 ? true : errors;
};
