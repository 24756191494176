import React from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
// import './ReportingToPopup.css'

const MeetingLinkPopup = ({
  open,
  onClose,
  meetingLink,
  setMeetingLink,
  program,
}) => {
  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                Class Scheduled
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={() => {
                  onClose();
                  setMeetingLink("");
                }}
                style={{ cursor: "pointer" }}
              >
                <IoClose color="#ffffff" size={"22px"} />
              </span>
            </div>
            <div
              className="d-flex flex-column mt-2"
              style={{ padding: "20px 0px", gap: "15px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ color: "rgba(246, 159, 58, 1)" }}>Title</div>
                <div style={{ color: "#FFFFFF", fontSize: "14px" }}>
                  {meetingLink.title}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ color: "rgba(246, 159, 58, 1)" }}>
                  Program Name
                </div>
                <div style={{ color: "#FFFFFF", fontSize: "14px" }}>
                  {program}
                </div>
              </div>
              <div className="d-flex flex-row mt-2" style={{ gap: "50px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "rgba(246, 159, 58, 1)" }}>
                    Start Time
                  </div>
                  <div style={{ color: "#FFFFFF", fontSize: "14px" }}>
                    {meetingLink.extendedProps.stime}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "rgba(246, 159, 58, 1)" }}>End Time</div>
                  <div style={{ color: "#FFFFFF", fontSize: "14px" }}>
                    {meetingLink.extendedProps.etime}
                  </div>
                </div>
              </div>
              {meetingLink.extendedProps.description && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "rgba(246, 159, 58, 1)" }}>
                    Meeting Link/Description
                  </div>
                  <div>
                    <a
                      href={meetingLink.extendedProps.description}
                      target="_blank"
                      style={{
                        color: "#3366CC",
                        fontSize: "14px",
                        textDecoration: "underline",
                      }}
                    >
                      {meetingLink.extendedProps.description}
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div
              className="d-flex gap-3 pt-4"
              style={{ borderTop: "1px solid #7A7A7A" }}
            >
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  onClose();
                  setMeetingLink("");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MeetingLinkPopup;
