import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import React, { useEffect, useMemo, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { axiosService } from "services/axios.service";
import { CRM_REPORT_URLS } from "./constants/reports-urls";
import "./ViewReports.css";
import { AgGridReact } from "ag-grid-react";
import useravatar from "../../../../assets/images/user-avatar.svg";
import "./ViewReports.css";
import Select from "react-select";

function ViewReports() {
  const [leadData, setLeadData] = useState([]);
  const [leadStatus, setLeadStatus] = useState(null);
  const [leadSource, setLeadSource] = useState(null);
  const [leadOwner, setLeadOwner] = useState(null);
  const [date, setDate] = useState(null);

  const [leadOwnerOptions, setLeadOwnerOptions] = useState();
  const leadStatusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "WARM", label: "WARM" },
    { value: "COLD", label: "COLD" },
    { value: "CLOSEDWON", label: "CLOSEDWON" },
    { value: "CLOSEDLOST", label: "CLOSEDLOST" },
  ];

  const leadSourceOptions = [
    { value: "FACEBOOK", label: "FACEBOOK" },
    { value: "TWITTER", label: "TWITTER" },
    { value: "WEBSITE", label: "WEBSITE" },
    { value: "AGENT", label: "AGENT" },
    { value: "ENQUIRY", label: "ENQUIRY" },
  ];

  useEffect(() => {
    axiosService.get(`${ROLE_URLS.FACULTY_GET_URL}`).then((response) => {
      const user = response?.data?.data;
      const leadOwnerOpt = user?.map((user) => ({
        value: user.firstName,
        label: user.firstName,
        taskOwnerObject: user,
      }));

      if (leadOwnerOpt) {
        setLeadOwnerOptions(leadOwnerOpt);
      }
    });
  }, []);
  const exportLead = () => {
    axiosService
      .getFile(`${CRM_REPORT_URLS.EXPORT_LEAD}`)
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "exported-lead.xlsx";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
        }
      })
      .catch(() => {});
  };
  // const [loading, setLoading] = useState(false);
  // const sendEmail = async () => {
  //   if (leadData.length === 0) {
  //     toast.warning(LEAD_REPORTS_CONSTANTS.NO_MAIL_SEND_FAILED);
  //   } else {
  //     setLoading(true);
  //     await axiosService
  //       .post(`${CRM_REPORT_URLS.SEND_EMAIL}`)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           toast.success(LEAD_REPORTS_CONSTANTS.MAIL_SEND_SUCCESS);
  //           setLoading(false);
  //         } else {
  //           toast.success(LEAD_REPORTS_CONSTANTS.MAIL_SEND_FAILED);
  //           setLoading(false);
  //         }
  //       });
  //   }
  // };
  const handleSearch = () => {
    const searchString = `?${leadStatus ? `leadStatus=${leadStatus}&` : ""}${
      leadOwner ? `leadOwner=${leadOwner}&` : ""
    }${leadSource ? `leadSource=${leadSource}&` : ""}${
      date ? `date=${date}` : ""
    }`;
    const finalSearchString = searchString.endsWith("&")
      ? searchString.slice(0, -1)
      : searchString;

    axiosService
      .get(`${CRM_REPORT_URLS.SEARCH_FILTER_REPORT}${finalSearchString}`)
      .then((response) => {
        const data = response?.data?.data;
        setLeadData(data);
      });
  };
  const handleReset = () => {
    setLeadOwner(null);
    setLeadSource(null);
    setDate(null);
    setLeadStatus(null);
    setLeadData([]);
  };
  const LeadCellRenderer = (params) => {
    return (
      <div className="d-flex gap-2">
        <span className="d-flex justify-content-center align-items-center ">
          {params.data.leadOwner?.profileImage ? (
            <img
              src={params.data.leadOwner?.profileImage}
              className="lead-icon-image"
            />
          ) : (
            <img src={useravatar} className="lead-icon-image" />
          )}
        </span>
        <span className="d-flex flex-column">
          <span className="tabel-cell-text-1">{params.data?.firstName}</span>
          <span className="tabel-cell-text-2">{params.data?.email}</span>
        </span>
      </div>
    );
  };
  const LocationCellRenderer = (params) => {
    return (
      <div className="d-flex flex-column">
        <span className="tabel-cell-text-1">{params?.data?.city}</span>
        <span className="tabel-cell-text-2">{params?.data?.country}</span>
      </div>
    );
  };
  function customLocationFilter(params) {
    const locationData = params.data.location;
    const filterText = params.filter;

    if (locationData && filterText) {
      const city = locationData.city || "";
      const country = locationData.country || "";
      const locationFields = (city + " " + country).toLowerCase();
      return locationFields.includes(filterText.toLowerCase());
    }

    return true;
  }
  function customLeadFilter(params) {
    const leadData = params.data.lead;
    const filterText = params.filter;

    if (leadData && filterText) {
      const name = leadData.name || "";
      const email = leadData.email || "";
      const leadFields = (name + " " + email).toLowerCase();
      return leadFields.includes(filterText.toLowerCase());
    }

    return true;
  }

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      floatingFilter: false,
      filter: false,
    },

    {
      headerName: "Lead Details",
      filterParams: {
        applyButton: true,
      },
      valueGetter: function (params) {
        const data = params.data.lead;
        const name = data?.name || "";
        const email = data?.email || "";
        return name + " " + email;
      },
      customFilterFunction: customLeadFilter,
      cellRenderer: LeadCellRenderer,
    },
    {
      headerName: "Location",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
      },
      valueGetter: function (params) {
        const locationData = params.data.location;
        const city = locationData?.city || "";
        const country = locationData?.country || "";
        return city + " " + country;
      },
      customFilterFunction: customLocationFilter,
      cellRenderer: LocationCellRenderer,
    },
    {
      headerName: "Status",
      field: "leadStatus",
    },
    {
      headerName: "Phone No",
      field: "phoneNumber",
    },
    {
      headerName: "Lead Source",
      field: "leadSource",
    },

    {
      headerName: "Lead Owner",
      field: "leadOwner.firstName",
    },
    {
      headerName: "Mode of Contact",
      field: "contactMode",
    },
    {
      headerName: "Course of Interest",
      field: "program",
    },
  ]);
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  return (
    <div>
      <div className="user-main-container pb-4">
        <div className="user-head-container sticky-header flex-row gap-2">
          <div className="d-flex align-items-center gap-1">
            <span className="user-title-text">Lead Reports</span>
          </div>
          <div className="d-flex align-items-center gap-3">
            <button
              className={`lead-table-head-button ${
                leadData.length === 0 && "opacity-50"
              }`}
              disabled={leadData.length === 0}
              onClick={exportLead}
            >
              Export
            </button>

            {/* <button className="user-save-head-button" onClick={sendEmail}>
              {loading ? (
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Send Email"
              )}
            </button> */}
          </div>
        </div>

        <div className="user-head-container  flex-sm-row gap-2 px-1">
          <div className="row">
            <div className=" d-flex flex-column reports-filter-input-select">
              <label className="lead-first-name">Lead Status</label>
              <Select
                value={
                  leadStatus ? { value: leadStatus, label: leadStatus } : null
                }
                onChange={(option) => {
                  setLeadStatus(option?.value);
                }}
                options={leadStatusOptions}
                isSearchable={false}
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className=" d-flex flex-column reports-filter-input-select">
              <label className="lead-first-name">Lead Owner</label>
              <Select
                value={
                  leadOwner ? { value: leadOwner, label: leadOwner } : null
                }
                onChange={(option) => {
                  setLeadOwner(option?.value);
                }}
                options={leadOwnerOptions}
                isSearchable={false}
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className=" d-flex flex-column reports-filter-input-select">
              <label className="lead-middle-name">Lead Source</label>
              <Select
                value={
                  leadSource ? { value: leadSource, label: leadSource } : null
                }
                onChange={(option) => {
                  setLeadSource(option?.value);
                }}
                options={leadSourceOptions}
                isSearchable={false}
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className=" d-flex flex-column reports-filter-input-select">
              <label className="lead-last-name">Lead Date</label>
              <input
                style={{
                  height: "33px",
                  borderRadius: "3px",
                  fontSize: "12px",
                }}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                className=" lead-input col-8 col-md-10"
                value={date || ""}
              />
            </div>
          </div>
          <div className="d-flex gap-4">
            <div className="d-flex align-items-end">
              <button className="lead-table-head-button" onClick={handleReset}>
                Reset
              </button>
            </div>
            <div className="d-flex align-items-end">
              <button className="user-save-head-button" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="ag-theme-alpine-dark">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={leadData}
            rowSelection="single"
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            gridOptions={gridOptions}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewReports;
