import React from "react";

function EditIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4037_16553)">
        <path
          d="M11.2471 0.128094C11.165 0.0460755 11.0538 0 10.9378 0C10.8218 0 10.7105 0.0460755 10.6285 0.128094L9.18733 1.56922L12.431 4.81284L13.8721 3.37259C13.9128 3.33195 13.9451 3.28368 13.9672 3.23052C13.9893 3.17737 14.0006 3.12039 14.0006 3.06284C14.0006 3.0053 13.9893 2.94832 13.9672 2.89516C13.9451 2.84201 13.9128 2.79373 13.8721 2.75309L11.2471 0.128094ZM11.8123 5.43147L8.5687 2.18784L2.88121 7.87534H3.06233C3.17836 7.87534 3.28964 7.92144 3.37169 8.00348C3.45374 8.08553 3.49983 8.19681 3.49983 8.31284V8.75034H3.93733C4.05336 8.75034 4.16464 8.79644 4.24669 8.87848C4.32874 8.96053 4.37483 9.07181 4.37483 9.18784V9.62534H4.81233C4.92836 9.62534 5.03964 9.67144 5.12169 9.75348C5.20374 9.83553 5.24983 9.94681 5.24983 10.0628V10.5003H5.68733C5.80336 10.5003 5.91464 10.5464 5.99669 10.6285C6.07874 10.7105 6.12483 10.8218 6.12483 10.9378V11.119L11.8123 5.43147ZM5.27783 11.966C5.25942 11.917 5.24993 11.8652 5.24983 11.8128V11.3753H4.81233C4.6963 11.3753 4.58502 11.3293 4.50297 11.2472C4.42092 11.1652 4.37483 11.0539 4.37483 10.9378V10.5003H3.93733C3.8213 10.5003 3.71002 10.4543 3.62797 10.3722C3.54592 10.2902 3.49983 10.1789 3.49983 10.0628V9.62534H3.06233C2.9463 9.62534 2.83502 9.57925 2.75297 9.4972C2.67092 9.41516 2.62483 9.30388 2.62483 9.18784V8.75034H2.18733C2.13502 8.75026 2.08316 8.74078 2.03421 8.72234L1.87758 8.87809C1.83589 8.92008 1.80314 8.97009 1.78133 9.02509L0.0313303 13.4001C-0.00049402 13.4796 -0.00828458 13.5667 0.00892444 13.6506C0.0261335 13.7345 0.0675853 13.8115 0.128141 13.872C0.188697 13.9326 0.265693 13.974 0.349585 13.9913C0.433477 14.0085 0.520574 14.0007 0.60008 13.9688L4.97508 12.2188C5.03008 12.197 5.08009 12.1643 5.12208 12.1226L5.27783 11.966Z"
          fill="#F69F3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_4037_16553">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditIcon;
