import React, { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";

function ConfirmExamModal({
  open,
  onClose,
  onConfirm,
  totalQuestions,
  totalSections,
}) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const isSaveButtonDisabled = () => {
    return !checkboxChecked;
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-6 p-3 custom-scrollbar"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <div className="flex-sm-row justify-content-between">
            <div className="new-conversation-popup-header">
              <span className="add-news-current-affair-title-text">
                Ready to start exam?
              </span>

              <span
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IoClose
                  color="#ffffff"
                  size={"23px"}
                  onClick={() => {
                    onClose();
                  }}
                />
              </span>
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #7a7a7a",
              fontSize: "14px",
              color: "#ffffff",
            }}
          >
            <div className="create-admission-inner-first-input-container">
              <div className="label-text-active ">
                Please read the following notes and guides to take this exam.
              </div>
              <ul style={{ listStyleType: "disc" }} className="m-0">
                <li className="exam-list-text">
                  Below are the details about the exam structure:
                </li>
                <div className="d-flex column col-12 col-md-8 exam-list-text">
                  <div className="d-flex row col-6">
                    <span className="exam-list-question-head">
                      Total Sections
                    </span>
                    <span>{totalSections}</span>
                  </div>
                  <div className="d-flex row col-6">
                    <span className="exam-list-question-head">
                      Total Question
                    </span>
                    <span>{totalQuestions}</span>
                  </div>
                </div>
                <li className="exam-list-text">
                  Complete the exam within the given time.
                </li>
                <li className="exam-list-text">
                  You can’t go back once you move forward.
                </li>
                <li className="exam-list-text">
                  Don’t navigate away or close the browser; your answers might
                  not be saved.
                </li>
              </ul>
              <div className="checkbox-condition">
                <input
                  type="checkbox"
                  checked={checkboxChecked}
                  onChange={() => setCheckboxChecked(!checkboxChecked)}
                />
                <span className="publish-exam-check">
                  I read all the instructions and ready to Take Exam
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 mt-3">
            <button
              className="communication-management-cancel-button"
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>

            <button
              type="button"
              className={`user-save-head-button ${
                isSaveButtonDisabled() ? "user-save-head-button-disabled" : ""
              }`}
              disabled={isSaveButtonDisabled()}
              onClick={onConfirm}
            >
              Take Exam
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmExamModal;
