import axios from "axios";
import sessionstorageService from "./sessionstorage.service";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionstorageService.getToken();
    if (token) {
      config.headers["Authorization"] = ` ${token}`;
    }
    return config;
  },
  (error) => {
    sessionstorageService.isLoading("false");
    return Promise.reject(error);
  }
);

export const axiosService = {
  get: (url, params) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.get(url, { params }).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },
  getForUrl: (url) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.get(url).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },
  getForLoad: (url, params) => {
    return axiosInstance.get(url, { params }).finally(() => {});
  },
  getByTime: (url, params) => {
    return axiosInstance.get(url, { params }).finally(() => {});
  },
  post: (url, data) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.post(url, data).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },

  postReg: (url, data) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.post(url, data).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },

  postFile: (url, file) => {
    sessionstorageService.isLoading("true");
    const formData = new FormData();
    formData.append("file", file);
    return axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },
  postFile2: (url, recipientName, recipientEmail, file) => {
    sessionstorageService.isLoading("true");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("recipientName", recipientName);
    formData.append("recipientEmail", recipientEmail);

    return axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },
  // postFile3: (url, file) => {
  //   sessionstorageService.isLoading("true");
  //   const formData = new FormData();
  //   formData.append("files", file);
  //   return axiosInstance
  //     .post(url, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .finally(() => {
  //       sessionstorageService.isLoading("false");
  //     });
  // },
  postFile3: (url, formData) => {
    sessionstorageService.isLoading("true");

    return axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },

  postFilePdf: (url, pdfFile, admissionId) => {
    sessionstorageService.isLoading("true");
    const formData = new FormData();
    formData.append("file", pdfFile);
    formData.append("admissionId", admissionId);
    return axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },

  put: (url, data) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.put(url, data).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },

  getFile: (url, params) => {
    sessionstorageService.isLoading("true");
    return axiosInstance
      .get(url, { params, responseType: "arraybuffer" })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },

  postFileImport: (url, file) => {
    sessionstorageService.isLoading("true");
    const formData = new FormData();
    formData.append("importFile", file);
    return axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      })
      .finally(() => {
        sessionstorageService.isLoading("false");
      });
  },

  delete: (url) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.delete(url).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },
};
