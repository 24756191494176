import { AppConfig } from "env.development";

export const LEAD_URL = {
  CREATE_LEAD: `${AppConfig.api_url}lead/v1`,
  UPDATE_LEAD: `${AppConfig.api_url}lead/v1`,
  DELETE_LEAD: `${AppConfig.api_url}lead/v1/`,
  GET_ALL_LEADS: `${AppConfig.api_url}lead/v1/all`,
  GET_LEAD_BY_LEAD_ID: `${AppConfig.api_url}lead/v1/`,
  CONVERT_LEAD: `${AppConfig.api_url}lead/v1/status/`,
  IMPORT_LEAD: `${AppConfig.api_url}lead/v1/import`,
  SEND_EMAIL: `${AppConfig.api_url}lead/v1/send-emails`,
};
