import { AppConfig } from "env.development";

export const ADMISSION_MANAGEMENT_URL = {
  ADMISSION_URL: `${AppConfig.api_url}admission/data`,
  FILE_UPLOAD_URL: `${AppConfig.api_url}admission/upload`,
  FILE_UPLOAD_URL1: `${AppConfig.api_url}admission/v1/uploadCourseFiles`,
  GET_ADMISSION_URL_FOR_VIEW: `${AppConfig.api_url}admission/v1/all`,
  GET_ADMISSION_URL_BY_ID: `${AppConfig.api_url}admission/v1/`,

  GET_ALL_PROGRAM: `${AppConfig.api_url}beta/v3/status/all`,
  GET_PROGRAM_BY_PROGRAMID: `${AppConfig.api_url}admission/v1/program/`,
  GET_FEE_BY_FEEID: `${AppConfig.api_url}admission/v1/fee/`,
  GET_PAYMENT_BY_PROGRAMID: `${AppConfig.api_url}admission/v1/student-payment/`,
  GET_CLOA_PAYMENT_BY_PROGRAMID: `${AppConfig.api_url}admission/v1/cloa/student-payment/`,

  PUT_PAYMENT: `${AppConfig.api_url}admission/v1/studentPayment`,
  PUT_CLOA_PAYMENT: `${AppConfig.api_url}admission/v1/cloaStudentPayment`,
  PUT_PERSONAL_DATA: `${AppConfig.api_url}admission/v1/personal`,
  PUT_MAILING: `${AppConfig.api_url}admission/v1/mailing`,
  PUT_ACADEMIC: `${AppConfig.api_url}admission/v1/academic`,
  PUT_EMPLOYMENT: `${AppConfig.api_url}admission/v1/employment`,
  PUT_FEE: `${AppConfig.api_url}admission/v1/fee`,
  PUT_GUARDIAN: `${AppConfig.api_url}admission/v1/guardian`,
  PUT_OTHER: `${AppConfig.api_url}admission/v1/other`,
  PUT_UPLOADFILE: `${AppConfig.api_url}admission/v1/uploadFile`,
  PUT_STUDENT_PROFILE: `${AppConfig.api_url}admission/v1/studentProfile`,
  PUT_PROGRAM: `${AppConfig.api_url}admission/v1/program`,

  GET_ADMISSION_AND_PROGRAM: `${AppConfig.api_url}admission/v2/details/`,
};
