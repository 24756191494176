export const UserConstants = {
  USER_SUCCESS: "User saved successfully",
  USER_UPDATE_SUCCESS: "User updated successfully",
  USER_FAILED: "User Failed to Save",
  ERROR_OCCUR:
    "An unexpected error occurred during User. Please contact admin/support",
  EMAIL_REQUIRED: "Email is required",
  INVALID_EMAIL: "Invalid email address",
  PASSWORD_REQUIRED: "Password is required",
  FULL_NAME_REQUIRED: "Firstname is required",
  LAST_NAME_REQUIRED: "Lastname is required",
  MOB_NUM_REQUIRED: "Phonenumber is required",
  ROLE_REQUIRED: "RoleName is required",
  IMAGE_LENGTH: "file name exceeds the maximum length characters.",
};
