import React, { useRef, useState } from "react";
import "./FacultyAllStudentNavbar.css";
import { useLocation } from "react-router-dom/dist";
import ViewExtraCurricular from "../student-portal-extra-curricular/view-extra-curricular/ViewExtraCurricular";
import ViewStudentPortalParticipation from "../student-portal-participation/view-participation/ViewStudentPortalParticipation";
import ViewProfileStudent from "../home/viewprofile/ViewProfileStudent";
import useravatar from "assets/images/user-avatar.svg";
import HomeStudent from "../home/viewprofile/homestudent/HomeStudent";
import StudentPortalPartialTranscript from "../student-portal-partial-transcript/StudentPortalPartialTranscript";
import StudentPortalFeedback from "../student-portal-feedback/StudentPortalFeedback";
import Documents from "../student-portal-documents/Documents";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Select from "react-select";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { RxTriangleDown } from "react-icons/rx";
import { SERVICE_FACULTY_VIEW } from "modules/student-portal/constants/faculty-service-urls";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function FacultyAllStudentNavbar() {
  const location = useLocation();
  const studentData = location.state;
  const [activeTab, setActiveTab] = useState("Snapshot");
  const swiperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [type, setType] = useState("");
  const [isChecked, setChecked] = useState(studentData?.isActive);

  const reasonTypeOptions = [
    { value: "Medical Issues", label: "Medical Issues" },
    { value: "Family Issues", label: "Family Issues" },
    { value: "Legal Issues", label: "Legal Issues" },
    { value: "Financial Issues", label: "Financial Issues" },
    {
      value: "Transferred to Another College/University",
      label: "Transferred to Another College/University",
    },
    { value: "Approved Leave", label: "Approved Leave" },
    {
      value: "Leave without Notice/approval",
      label: "Leave without Notice/approval",
    },
    { value: "Academic Challenges", label: "Academic Challenges" },
    { value: "Withdrawal/Drop", label: "Withdrawal/Drop" },
    { value: "Other", label: "Other" },
  ];
  const handleReasonTypeChange = (option) => {
    setType(option.value);
  };

  const isSubmitButtonDisabled = () => {
    return reason === "" || type === "";
  };
  const onClose = () => {
    setModalOpen(false);
    setReason("");
    setType("");
  };
  const handleActive = () => {
    Swal.fire({
      text: `Are you sure to Activate this Student?`,
      // text: `Do you want to mark this attendee as ${type}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, Activate`,
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleActiveStudent(type);
      }
    });
  };
  const handleSave = async () => {
    const requestData = {
      userId: studentData?.userId,
      nonActiveType: type,
      nonActiveReason: reason,
    };
    try {
      const response = await axiosService.put(
        `${SERVICE_FACULTY_VIEW.INACTIVE_STUDENT}`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Student Inactivated Successfully!");
        fetchData();
        onClose();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const handleActiveStudent = async () => {
    try {
      const response = await axiosService.put(
        `${SERVICE_FACULTY_VIEW.INACTIVE_STUDENT}/${studentData?.userId}`
      );

      if (response.status === 200) {
        toast.success("Student Activated Successfully!");
        fetchData();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${SERVICE_FACULTY_VIEW.GET_STUDENT_DATA}${studentData?.userId}`
      );
      const data = response?.data?.data;
      if (data) {
        setChecked(data?.isActive);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div
      className="faculty-all-student-navbar-main-container-student"
      style={activeTab === "ViewFullProfile" ? { overflow: "hidden" } : {}}
    >
      <div
        className="faculty-all-student-navbar-container sticky-header flex-row gap-2 px-3"
        style={{ top: 0, left: 0 }}
      >
        <div className="d-flex align-items-center gap-1">
          <span className="faculty-all-student-navbar-title-text">
            {studentData?.personalData?.firstName}{" "}
            {studentData?.personalData?.lastName}
          </span>
          <div className="d-none d-lg-flex gap-1 ps-1">
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "Snapshot" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Snapshot");
              }}
            >
              Snapshot
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "Documents" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Documents");
              }}
            >
              Documents
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "Extracurricular" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Extracurricular");
              }}
            >
              Extracurricular
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "PartialTranscript" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("PartialTranscript");
              }}
            >
              Partial Transcript
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "Feedback" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Feedback");
              }}
            >
              Feedback
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "Participation" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Participation");
              }}
            >
              Participation
            </span>
            <span
              className={`faculty-all-student-navbar-text ${
                activeTab === "ViewFullProfile" &&
                "faculty-all-student-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("ViewFullProfile");
              }}
            >
              View full profile
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onClick={() => (isChecked ? setModalOpen(true) : handleActive())}
            />
            <span className="slider round"></span>
          </label>
          <img
            src={studentData?.imageUrl || useravatar}
            className="student-portal-nav-profile-image"
          />
        </div>
      </div>

      <div
        className="d-lg-none py-2"
        style={{
          position: "sticky",
          top: "52.67px",
          background: "#212226",
          zIndex: 9,
        }}
      >
        <Swiper
          ref={swiperRef}
          style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={5}
          slidesPerView="auto"
        >
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "Snapshot" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("Snapshot");
            }}
          >
            Snapshot
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "Documents" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("Documents");
            }}
          >
            Documents
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "Extracurricular" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("Extracurricular");
            }}
          >
            Extracurricular
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "PartialTranscript" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("PartialTranscript");
            }}
          >
            Partial Transcript
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "Feedback" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("Feedback");
            }}
          >
            Feedback
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "Participation" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("Participation");
            }}
          >
            Participation
          </SwiperSlide>
          <SwiperSlide
            className={`faculty-all-student-navbar-text-mobile ${
              activeTab === "ViewFullProfile" &&
              "faculty-all-student-navbar-text-mobile-active"
            }`}
            onClick={() => {
              setActiveTab("ViewFullProfile");
            }}
          >
            View full profile
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="faculty-all-student-navbar-inner-container">
        {activeTab === "Snapshot" && (
          <HomeStudent studentId={studentData?.personalData?.studentId} />
        )}
        {activeTab === "Documents" && (
          <Documents
            admissionId={studentData?.admissionId}
            studentId={studentData?.personalData?.studentId}
            studentType={studentData?.studentType}
            userId={studentData?.userId}
          />
        )}
        {activeTab === "Extracurricular" && (
          <ViewExtraCurricular
            studentId={studentData?.personalData?.studentId}
          />
        )}
        {activeTab === "PartialTranscript" && (
          <StudentPortalPartialTranscript />
        )}
        {activeTab === "Feedback" && (
          <StudentPortalFeedback
            studentId={studentData?.personalData?.studentId}
          />
        )}
        {activeTab === "Participation" && (
          <ViewStudentPortalParticipation
            studentId={studentData?.personalData?.studentId}
          />
        )}
        {activeTab === "ViewFullProfile" && (
          <ViewProfileStudent
            studentId={studentData?.personalData?.studentId}
          />
        )}
      </div>

      <Modal className="crm-modal" isOpen={modalOpen} onRequestClose={onClose}>
        <div className="modal-Con">
          <div
            className="col-12 col-md-8 p-3 d-flex flex-column gap-2"
            style={{
              maxHeight: "100%",
              backgroundColor: "#212226",
            }}
          >
            <div className="new-conversation-popup-header d-flex justify-content-between align-items-center">
              <span
                style={{ fontSize: "18px", fontWeight: 400, color: "#fff" }}
              >
                Reason For Inactivate Student
              </span>

              <IoClose color="#ffffff" size={"23px"} onClick={onClose} />
            </div>
            <div>
              <div className="academics-add-program-input-con">
                <label>Type *</label>
                <Select
                  value={reasonTypeOptions.find(
                    (option) => option.value === reason
                  )}
                  onChange={handleReasonTypeChange}
                  options={reasonTypeOptions}
                  isSearchable={false}
                  classNamePrefix="react-select-big"
                  components={{
                    DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                    IndicatorSeparator: null,
                  }}
                  placeholder="Choose an Option"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
              <div className="academics-add-program-input-con">
                <label>Reason *</label>
                <input
                  className="faculty-student-portal-reason-popup-input"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  classname=""
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-management-cancel-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                className={`communication-save-head-button ${
                  isSubmitButtonDisabled()
                    ? "user-save-head-button-disabled"
                    : ""
                }`}
                onClick={() => {
                  handleSave();
                }}
                disabled={isSubmitButtonDisabled()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FacultyAllStudentNavbar;
