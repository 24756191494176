import React from "react";

function TimeTableLogo({ color }) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1716 22.5639H5.34439C3.49745 22.5639 1.99473 21.0611 1.99473 19.2142V9.86524H21.0585V12.677C21.0585 13.0605 21.3695 13.3716 21.7531 13.3716C22.1367 13.3716 22.4478 13.0605 22.4478 12.677V6.84977C22.4478 4.23641 20.3222 2.11084 17.7088 2.11084H17.3391V1.64776C17.3391 1.26417 17.0281 0.953125 16.6445 0.953125C16.2609 0.953125 15.9499 1.26417 15.9499 1.64776V2.11084H7.10335V1.64776C7.10335 1.26417 6.79232 0.953125 6.40872 0.953125C6.02513 0.953125 5.71409 1.26417 5.71409 1.64776V2.11084H5.34439C2.73104 2.11084 0.605469 4.23641 0.605469 6.84977V19.2142C0.605469 21.8276 2.73104 23.9531 5.34439 23.9531H11.1716C11.5552 23.9531 11.8662 23.6421 11.8662 23.2585C11.8662 22.8749 11.5552 22.5639 11.1716 22.5639ZM5.34439 3.5001H5.71409V3.96165C5.71409 4.34524 6.02513 4.65628 6.40872 4.65628C6.79232 4.65628 7.10335 4.34524 7.10335 3.96165V3.5001H15.9499V3.96165C15.9499 4.34524 16.2609 4.65628 16.6445 4.65628C17.0281 4.65628 17.3391 4.34524 17.3391 3.96165V3.5001H17.7088C19.5558 3.5001 21.0585 5.00282 21.0585 6.84977V8.47598H1.99473V6.84977C1.99473 5.00282 3.49745 3.5001 5.34439 3.5001Z"
        fill={color}
      />
      <path
        d="M7.1786 13.2422H6.09112C5.70753 13.2422 5.39648 13.5532 5.39648 13.9368C5.39648 14.3204 5.70753 14.6314 6.09112 14.6314H7.1786C7.56219 14.6314 7.87323 14.3204 7.87323 13.9368C7.87323 13.5532 7.56219 13.2422 7.1786 13.2422Z"
        fill={color}
      />
      <path
        d="M12.0712 13.2422H10.9837C10.6001 13.2422 10.2891 13.5532 10.2891 13.9368C10.2891 14.3204 10.6001 14.6314 10.9837 14.6314H12.0712C12.4548 14.6314 12.7658 14.3204 12.7658 13.9368C12.7658 13.5532 12.4548 13.2422 12.0712 13.2422Z"
        fill={color}
      />
      <path
        d="M7.1786 17.8047H6.09112C5.70753 17.8047 5.39648 18.1157 5.39648 18.4993C5.39648 18.8829 5.70753 19.1939 6.09112 19.1939H7.1786C7.56219 19.1939 7.87323 18.8829 7.87323 18.4993C7.87323 18.1157 7.56219 17.8047 7.1786 17.8047Z"
        fill={color}
      />
      <path
        d="M17.5728 14.2031C14.8846 14.2031 12.6973 16.3897 12.6973 19.0787C12.6973 21.7669 14.8838 23.9534 17.5728 23.9534C20.261 23.9534 22.4476 21.7669 22.4476 19.0787C22.4476 16.3897 20.261 14.2031 17.5728 14.2031ZM17.5728 22.5642C15.6502 22.5642 14.0865 21.0005 14.0865 19.0779C14.0865 17.1553 15.6502 15.5916 17.5728 15.5916C19.4946 15.5916 21.0591 17.1553 21.0591 19.0779C21.0591 21.0005 19.4946 22.5642 17.5728 22.5642Z"
        fill={color}
      />
      <path
        d="M18.2682 18.7866V17.1087C18.2682 16.7251 17.9571 16.4141 17.5735 16.4141C17.1899 16.4141 16.8789 16.7251 16.8789 17.1087V19.0737C16.8789 19.2582 16.9522 19.4349 17.0827 19.5646L18.015 20.4969C18.1509 20.6328 18.3284 20.7007 18.5059 20.7007C18.6834 20.7007 18.8609 20.6328 18.9968 20.4969C19.2684 20.226 19.2684 19.7861 18.9968 19.5152L18.2682 18.7866Z"
        fill={color}
      />
    </svg>
  );
}

export default TimeTableLogo;
