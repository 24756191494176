import { City, Country } from "country-state-city";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  LEAD_CONSTANTS,
  LEAD_UPDATE_CONSTANTS,
} from "modules/lead-mangement/constants/leads-constants";
import { LEAD_URL } from "modules/lead-mangement/constants/leads-url";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import { useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./CreateLeads.css";
import { isValidEmail } from "shared/validation/emailValidation";

function CreateLeads() {
  const location = useLocation();
  const navigate = useNavigate();
  const leadId = location.state?.leadId;
  const [leadData, setLeadData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isMailChecked, setIsMailChecked] = useState(false);
  const [isSmsChecked, setIsSmsChecked] = useState(false);
  const [isCallChecked, setIsCallChecked] = useState(false);

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };

    fetchCountries();
  }, [location.pathname, refresh]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const selectedCountryInfo = allCountries.find(
          (country) => country.name === selectedCountry
        );

        if (selectedCountryInfo) {
          const allCities = City.getAllCities();
          const countryCities = allCities.filter(
            (city) => city.countryCode === selectedCountryInfo.isoCode
          );

          const cityOptions = countryCities.map((city) => ({
            label: city.name,
            value: city.name,
          }));
          setCities(cityOptions);
        }
      } catch {}
    };

    fetchCities();
  }, [selectedCountry]);
  const [leadOwner, setLeadOwner] = useState(
    leadData?.leadOwner?.taskOwnerObject || null
  );
  const [leadOwnerOptions, setLeadOwnerOptions] = useState([]);
  useEffect(() => {
    axiosService.get(`${ROLE_URLS.FACULTY_GET_URL}`).then((response) => {
      const user = response?.data?.data;

      const leadOwnerOptions = user?.map((user) => ({
        value: user.firstName,
        label: user.firstName,
        taskOwnerObject: user,
      }));
      if (leadOwnerOptions) {
        setLeadOwnerOptions(leadOwnerOptions);
      }
    });
  }, []);

  const [hasReferral, setHasReferral] = useState();
  const [error, setError] = useState(false);

  const handleContactModeChange = (option) => {
    if (option.value === "SMS") {
      setIsSmsChecked(!isSmsChecked);
    } else if (option.value === "CALL") {
      setIsCallChecked(!isCallChecked);
    } else if (option.value === "EMAIL") {
      setIsMailChecked(!isMailChecked);
    }
  };

  useEffect(() => {
    if (leadId) {
      axiosService
        .get(`${LEAD_URL.GET_LEAD_BY_LEAD_ID}${leadId}`)
        .then((response) => {
          const data = response?.data?.data;
          setLeadData(data);
          setSelectedCountry(data?.country);
          setSelectedCity(data?.city);
          setLeadOwner(data?.leadOwner);
          setHasReferral(data?.hasReferral);
        });
    } else {
      setLeadData(null);
    }
  }, [location.pathname]);

  let initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    priority: "",
    city: "",
    country: "",
    phoneNumber: "",
    secondaryPhoneNumber: "",
    email: "",
    leadSource: "",
    leadOwner: "",
    hasReferral: "",
    referralName: "",
    referralPhoneNumber: "",
    referralEmail: "",
    referralRelationship: "",
    contactMode: "",
    isMail: "",
    isSms: "",
    isCall: "",
    program: "",
    campus: "",
    comments: "",
    roleData: "",
    leadStatus: "",
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email format")
      .required("Email is required"),
    referralName:
      hasReferral === "YES" &&
      Yup.string().required("Referral name is required"),
    referralEmail:
      hasReferral === "YES" &&
      Yup.string()
        .matches(emailRegex, "Invalid email format")
        .required("Referral email is required"),
    referralRelationship:
      hasReferral === "YES" &&
      Yup.string().required("Referral relationship is required"),
    referralPhoneNumber:
      hasReferral === "YES" &&
      Yup.string()
        .required("Referral phonenumber is required")
        .min(3, "Referral phonenumber is required"),
  });

  const onSubmit = async (values, action, setFieldValue, resetForm) => {
    const requestData = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      priority: values.priority,
      city: selectedCity,
      country: selectedCountry,
      phoneNumber: values.phoneNumber,
      secondaryPhoneNumber: values.secondaryPhoneNumber,
      email: values.email,
      leadSource: values.leadSource,
      leadOwner: leadOwner,
      hasReferral: values.hasReferral,
      referralName: values.referralName,
      referralPhoneNumber:
        values.hasReferral === "NO" ? "" : values.referralPhoneNumber,
      referralEmail: values.referralEmail,
      referralRelationship: values.referralRelationship,
      contactMode: values.contactMode,
      isMail: isMailChecked,
      isSms: isSmsChecked,
      isCall: isCallChecked,
      program: values.program,
      campus: values.campus,
      comments: values.comments,
      roleData: values.roleData,
      leadId: leadId,
      leadStatus: values.leadStatus,
    };
    if (isValidEmail(leadData?.email || values?.email)) {
      if (
        values.hasReferral === "YES" &&
        (!values.referralName ||
          !values.referralEmail ||
          !values.referralPhoneNumber ||
          !values.referralPhoneNumber)
      ) {
        setError(true);
      } else {
        setError(false);
        if (leadId) {
          try {
            const response = await axiosService.put(
              `${LEAD_URL.UPDATE_LEAD}`,
              requestData
            );

            if (response.status === 200) {
              toast.success(LEAD_UPDATE_CONSTANTS.SUCCESS);

              if (action === "saveAndNew") {
                setRefresh(!refresh);
                setLeadData(null);
              } else {
                navigate("/lead-management/view-leads-table");
              }
            } else {
              toast.error(LEAD_UPDATE_CONSTANTS.FAILED);
            }
          } catch (error) {
            toast.error(LEAD_UPDATE_CONSTANTS.ERROR_OCCUR);
          }
        } else {
          try {
            const response = await axiosService.post(
              `${LEAD_URL.CREATE_LEAD}`,
              requestData
            );
            if (response.status === 200) {
              toast.success(LEAD_CONSTANTS.SUCCESS);
              if (action === "saveAndNew") {
                resetForm();
                setFieldValue("secondaryPhoneNumber", "1");
                setFieldValue("phoneNumber", "1");
                setFieldValue("referralPhoneNumber", "1");
                setLeadData(null);
                setHasReferral(null);
                setIsMailChecked(false);
                setIsCallChecked(false);
                setIsSmsChecked(false);
              } else {
                navigate("/lead-management/view-leads-table");
              }
            } else {
              toast.error(LEAD_CONSTANTS.FAILED);
            }
          } catch (error) {
            toast.error(LEAD_CONSTANTS.ERROR_OCCUR);
          }
        }
      }
    }
  };

  const leadSourceOptions = [
    { value: "FACEBOOK", label: "FACEBOOK" },
    { value: "TWITTER", label: "TWITTER" },
    { value: "WEBSITE", label: "WEBSITE" },
    { value: "AGENT", label: "AGENT" },
    { value: "ENQUIRY", label: "ENQUIRY" },
  ];

  const hasReferralOptions = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];
  const modeOfContactOptions = [
    { value: "CALL", label: "CALL" },
    { value: "EMAIL", label: "EMAIL" },
    { value: "WHATSAPP", label: "WHATSAPP" },
    { value: "SMS", label: "SMS" },
  ];
  const programOptions = [
    { value: "VOCATION", label: "VOCATION" },
    { value: "NONVOCATIONAL", label: "NON VOCATIONAL" },
    { value: "CERTIFICATE", label: "CERTIFICATE" },
  ];
  const campusOptions = [
    { value: "BRAMPTON", label: "BRAMPTON" },
    { value: "MISSISSAUGA", label: "MISSISSAUGA" },
  ];
  const refferalRelationOptions = [
    { value: "Sibling", label: "Sibling" },
    { value: "Friend", label: "Friend" },
  ];

  const Priority = [
    { value: "LOW", label: "LOW" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "HIGH", label: "HIGH" },
  ];
  const LeadStatusOption = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "WARM", label: "WARM" },
    { value: "COLD", label: "COLD" },
    { value: "CLOSEDWON", label: "CLOSEDWON" },
    { value: "CLOSEDLOST", label: "CLOSEDLOST" },
  ];

  const isSaveButtonDisabled = (values) => {
    return (
      values.firstName === "" ||
      values.lastName === "" ||
      values.addressLine1 === "" ||
      values.addressLine2 === "" ||
      values.priority === "" ||
      values.phoneNumber.length < 2 ||
      values.email === "" ||
      values.leadSource === "" ||
      values.contactMode === "" ||
      values.program === "" ||
      values.campus === "" ||
      values.leadStatus === "" ||
      values.leadOwner === "" ||
      !values.firstName ||
      !values.lastName ||
      !values.addressLine1 ||
      !values.addressLine2 ||
      !values.priority ||
      !values.phoneNumber ||
      !values.email ||
      !values.leadSource ||
      !values.contactMode ||
      !values.program ||
      !values.campus ||
      !values.leadStatus ||
      !values.leadOwner
    );
  };

  return (
    <Formik
      initialValues={leadData ? leadData : initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, resetForm, setFieldValue }) => (
        <Form>
          <div className="user-head-container sticky-header flex-sm-row gap-2 px-2">
            <div className="d-flex align-items-center gap-1">
              <span className="user-title-text">
                {leadData ? "Update Lead" : "Create Lead"}
              </span>
            </div>
            <div className="d-flex align-items-center gap-3">
              <button
                type="button"
                className="user-head-button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>

              {!leadId && (
                <button
                  className={`user-head-button ${
                    isSaveButtonDisabled(values)
                      ? "user-head-button-disabled"
                      : ""
                  }`}
                  onClick={() => {
                    onSubmit(values, "saveAndNew", setFieldValue, resetForm);
                  }}
                  disabled={isSaveButtonDisabled(values)}
                >
                  Save & New
                </button>
              )}

              <button
                className={`user-save-head-button ${
                  isSaveButtonDisabled(values)
                    ? "user-save-head-button-disabled"
                    : ""
                }`}
                onClick={() => onSubmit(values, "save")}
                disabled={isSaveButtonDisabled(values)}
              >
                {leadId ? "Update" : "Save"}
              </button>
            </div>
          </div>
          <div className="user-main-container pb-4">
            <div className="lead-grid-container  ">
              <div style={{ marginTop: "15px" }}>
                <span className="lead-personal-contact-information">
                  Personal & Contact Information
                </span>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-first-name">First Name *</label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="First Name"
                    name="firstName"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-middle-name">Middle Name</label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="Middle Name"
                    name="middleName"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-last-name">Last Name *</label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="Last Name"
                    name="lastName"
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-address-line-1">
                    Address Line 1 *
                  </label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="Address Line 1"
                    name="addressLine1"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-address-line-2">
                    Address Line 2 *
                  </label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="Address Line 1"
                    name="addressLine2"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-country-name">Country</label>
                  <Field
                    name="country"
                    render={({ field, form }) => (
                      <Select
                        className="create-admission-select-big-1"
                        options={countries}
                        classNamePrefix="react-select-big"
                        value={
                          field.value
                            ? countries.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(selectedOption) => {
                          form.setFieldValue(field.name, selectedOption.value);
                          setSelectedCountry(selectedOption.value);
                          form.setFieldValue("city", "");
                          setSelectedCity("");
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-city">City</label>
                  <Field
                    name="city"
                    render={({ field, form }) => (
                      <Select
                        className="create-admission-select-big-1"
                        options={cities}
                        classNamePrefix="react-select-big"
                        value={
                          field.value
                            ? cities.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(selectedOption) => {
                          form.setFieldValue(field.name, selectedOption.value);
                          setSelectedCity(selectedOption.value);
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-phone-number">Phone number *</label>
                  <Field
                    name="phoneNumber"
                    render={({ field, form }) => (
                      <PhoneInput
                        country={"ca"}
                        enableSearch={true}
                        value={values.phoneNumber}
                        onChange={(phoneNumber) =>
                          form.setFieldValue(field.name, phoneNumber)
                        }
                        inputProps={{
                          className: "lead-input col-8 col-md-10 phone-input",
                          placeholder: "Valid Mobile number",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-secondary-phone-number">
                    Secondary Phone number
                  </label>
                  <Field
                    name="secondaryPhoneNumber"
                    render={({ field, form }) => (
                      <PhoneInput
                        country={"ca"}
                        enableSearch={true}
                        value={values.secondaryPhoneNumber}
                        onChange={(secondaryPhoneNumber) =>
                          form.setFieldValue(field.name, secondaryPhoneNumber)
                        }
                        inputProps={{
                          className: "lead-input col-8 col-md-10 phone-input",
                          placeholder: "Valid Mobile number",
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className="col-12 col-md-4 col-lg-3 d-flex flex-column"
                  style={{ position: "relative" }}
                >
                  <label className="lead-email">Email ID *</label>
                  <Field
                    className=" lead-input col-8 col-md-10"
                    placeholder="Valid Email ID"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="create-lead-error-message"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-source-name">Priority *</label>

                  <Field
                    className=" lead-select-input"
                    placeholder="Example"
                    name="priority"
                    render={({ field, form }) => (
                      <Select
                        id="priority"
                        value={
                          field.value
                            ? Priority.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                        options={Priority}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <span className="lead-staff-referral-information">
                  Staff & Referral Information
                </span>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-source-name">Lead Source *</label>
                  <Field
                    className=" lead-select-input col-8 col-md-10"
                    placeholder="Example"
                    name="leadSource"
                    render={({ field, form }) => (
                      <Select
                        id="leadSource"
                        value={
                          field.value
                            ? leadSourceOptions.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        classNamePrefix="react-select-big"
                        onChange={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                        options={leadSourceOptions}
                        isSearchable={false}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-owner-name">
                    Lead Owner / Assigned To *
                  </label>

                  <Field
                    className=" lead-select-input col-8 col-md-10"
                    name="leadOwner"
                    render={({ field, form }) => (
                      <Select
                        value={
                          field.value
                            ? leadOwnerOptions.find(
                                (option) =>
                                  option.taskOwnerObject?.firstName ===
                                  leadOwner?.firstName
                              )
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(
                            field.name,
                            option?.taskOwnerObject
                          );
                          setLeadOwner(option?.taskOwnerObject);
                        }}
                        options={leadOwnerOptions}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose a Contact"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-owner-name">Lead Status *</label>

                  <Field
                    className=" lead-select-input col-8 col-md-10"
                    name="leadStatus"
                    render={({ field, form }) => (
                      <Select
                        value={
                          field.value
                            ? LeadStatusOption.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                        }}
                        options={LeadStatusOption}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose a Contact"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="lead-has-referral">Has Referral *</label>
                    <Field
                      as="select"
                      className=" lead-select-input col-8 col-md-10"
                      name="hasReferral"
                      render={({ field, form }) => (
                        <Select
                          id="leadSource"
                          value={
                            field.value
                              ? hasReferralOptions.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            if (option.value === "NO") {
                              form.setFieldValue("referralName", "");
                              form.setFieldValue("referralPhoneNumber", "1");
                              form.setFieldValue("referralEmail", "");
                              form.setFieldValue("referralRelationship", "");
                              setError(false);
                            }
                            setHasReferral(option.value);
                          }}
                          options={hasReferralOptions}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div
                    className="col-12 col-md-4 col-lg-3 d-flex flex-column"
                    style={{ position: "relative" }}
                  >
                    <label className="lead-referral-name">Referral Name</label>
                    <Field
                      className=" lead-input col-8 col-md-10"
                      placeholder="Enter referral name"
                      name="referralName"
                      readOnly={hasReferral === "NO"}
                    />
                    {error && (
                      <ErrorMessage
                        name="referralName"
                        component="div"
                        className="create-lead-error-message"
                      />
                    )}
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 d-flex flex-column"
                    style={{ position: "relative" }}
                  >
                    <label className="lead-referral-phone-number">
                      Referral Phone number
                    </label>
                    <Field
                      name="referralPhoneNumber"
                      render={({ field, form }) => (
                        <PhoneInput
                          country={"ca"}
                          disableDropdown={hasReferral === "NO"}
                          enableSearch={true}
                          value={values.referralPhoneNumber}
                          onChange={(referralPhoneNumber) =>
                            form.setFieldValue(field.name, referralPhoneNumber)
                          }
                          inputProps={{
                            className: "lead-input col-8 col-md-10 phone-input",
                            placeholder: "Valid Mobile number",
                            disabled: hasReferral === "NO",
                          }}
                        />
                      )}
                    />
                    {error && (
                      <ErrorMessage
                        name="referralPhoneNumber"
                        component="div"
                        className="create-lead-error-message"
                      />
                    )}
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 d-flex flex-column"
                    style={{ position: "relative" }}
                  >
                    <label className="lead-referral-email">
                      Referral Email ID
                    </label>
                    <Field
                      className=" lead-input col-8 col-md-10"
                      placeholder="Valid Email ID"
                      name="referralEmail"
                      readOnly={hasReferral === "NO"}
                    />
                    {error && (
                      <ErrorMessage
                        name="referralEmail"
                        component="div"
                        className="create-lead-error-message"
                      />
                    )}
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 d-flex flex-column"
                    style={{ position: "relative" }}
                  >
                    <label className="lead-referral-relationship-email">
                      Referral Relationship
                    </label>
                    <Field
                      as="select"
                      className=" lead-select-input col-8 col-md-10"
                      name="referralRelationship"
                      render={({ field, form }) => (
                        <Select
                          id="leadSource"
                          value={
                            field.value
                              ? refferalRelationOptions.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={refferalRelationOptions}
                          isSearchable={false}
                          placeholder="Choose an Option"
                          isDisabled={hasReferral === "NO"}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#ffffff" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                    {error && (
                      <ErrorMessage
                        name="referralRelationship"
                        component="div"
                        className="create-lead-error-message"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <span className="lead-consent-details">
                  Lead Consent Details
                </span>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-mode-contact">
                    Preferred mode of contact *
                  </label>
                  <Field
                    as="select"
                    className="lead-select-input col-8 col-md-10"
                    name="contactMode"
                    render={({ field, form }) => (
                      <Select
                        id="leadSource"
                        value={
                          field.value
                            ? modeOfContactOptions.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue("contactMode", option.value);
                          handleContactModeChange(option);
                        }}
                        options={modeOfContactOptions}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div
                  className="col-12 col-md-4 col-lg-3 d-flex"
                  style={{ alignItems: "center" }}
                >
                  <label className="lead-checkbox-1-name">
                    <Field
                      type="checkbox"
                      className="lead-checkbox-1"
                      placeholder="Valid Mobile Number"
                      name="isMail"
                      checked={isMailChecked}
                      onChange={() =>
                        handleContactModeChange({ value: "EMAIL" })
                      }
                    />
                    Consent to Contact through email
                  </label>
                </div>
                <div
                  className="col-12 col-md-4 col-lg-3 d-flex"
                  style={{ alignItems: "center" }}
                >
                  <label className="lead-checkbox-2-name">
                    <Field
                      type="checkbox"
                      className="lead-checkbox-1"
                      placeholder="Valid Mobile Number"
                      name="isSms"
                      checked={isSmsChecked}
                      onChange={() => handleContactModeChange({ value: "SMS" })}
                    />
                    Consent to Contact through SMS
                  </label>
                </div>
                <div
                  className="col-12 col-md-4 col-lg-3 d-flex"
                  style={{ alignItems: "center" }}
                >
                  <label className="lead-checkbox-3-name">
                    <Field
                      type="checkbox"
                      className="lead-checkbox-1"
                      placeholder="Valid Mobile Number"
                      name="isCall"
                      checked={isCallChecked}
                      onChange={() =>
                        handleContactModeChange({ value: "CALL" })
                      }
                    />
                    Consent to Contact through Call
                  </label>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <span className="lead-additional-details">
                  Additional Details
                </span>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-program-interest-name">
                    Program Interest *
                  </label>

                  <Field
                    as="select"
                    className=" lead-select-input col-8 col-md-10"
                    name="program"
                    render={({ field, form }) => (
                      <Select
                        id="leadSource"
                        value={
                          field.value
                            ? programOptions.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                        options={programOptions}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                  <label className="lead-campus-interest-name">
                    Campus Interest *
                  </label>

                  <Field
                    as="select"
                    className=" lead-select-input col-8 col-md-10"
                    name="campus"
                    render={({ field, form }) => (
                      <Select
                        id="leadSource"
                        value={
                          field.value
                            ? campusOptions.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                        options={campusOptions}
                        isSearchable={false}
                        classNamePrefix="react-select-big"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown color="#ffffff" />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12  d-flex flex-column">
                  <label className="lead-comments">Comments</label>
                  <Field
                    as="textarea"
                    className=" lead-comment-input"
                    placeholder="Your comments and questions"
                    name="comments"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateLeads;
