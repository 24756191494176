import { TASK_URLS } from "modules/lead-mangement/components/task-management/constants/TaskUrls";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { CircularConstants } from "../../constants/circular-constants";
import { CIRCULAR_URLS } from "../../constants/circular-urls";
import "./AddCircularNotice.css";
import TextEditor from "utils/components/text-editor/TextEditor";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";

function AddCircularNotice({ open, onClose, circularId }) {
  const [isImportant, setIsImportant] = useState(false);
  const [title, setTitle] = useState("");
  const [htmlContent, setHtmlContent] = useState("");

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (!title || !htmlContent) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [title, htmlContent]);

  useEffect(() => {
    if (circularId) {
      axiosService
        .get(`${CIRCULAR_URLS.VIEW_CIRCULAR}/${circularId}`)
        .then((response) => {
          setHtmlContent(response?.data?.data?.description);
          setTitle(response?.data?.data.title);
          setIsImportant(response?.data?.data.isImportant);
          setProgram(response?.data?.data?.program);
        });
    } else {
      setHtmlContent("");
      setTitle("");
      setIsImportant(false);
    }
  }, [circularId]);

  const handleHtmlContentChange = (content) => {
    setHtmlContent(content);
  };

  const close = () => {
    setTitle("");
    setIsImportant(false);
    setHtmlContent("");
    onClose();
    setProgram("");
  };
  const handleUpdateSubmit = async () => {
    await onSubmit(false);
  };

  const handleUpdateNotify = async () => {
    await onSubmit(true);
  };
  const onSubmit = async (isNotify) => {
    try {
      if (!title || !htmlContent) {
        toast.error("Title and description are mandatory fields!");
        return;
      }

      const requestData = {
        title: title,
        isImportant: isImportant,
        description: htmlContent,
        program: program,
        studentIds: selectedStudents,
        isNotify: isNotify,
      };

      if (circularId) {
        requestData.circularId = circularId;
      }

      const response = await axiosService.post(
        `${TASK_URLS.CREATE_CIRCULAR}?isNotify=${isNotify}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(CircularConstants.CIRCULAR_SUCCESS);
        setTitle("");
        close();
      } else {
        toast.error(`Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        toast.error("An error occurred while creating the Circular");
      }
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333333 !important",
    }),
  };

  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [program, setProgram] = useState("");
  const [programId, setProgramId] = useState("");
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      try {
        const response = await axiosService.get(
          `${PROGRAM_URLS.GET_ALL_PROGRAMS}`
        );
        const options = response?.data?.data?.programModel.map((program) => ({
          value: program.master.program,
          label: program.master.program,
          programId: program?.master?.programFeeMasterId,
        }));
        setDiplomaOptions(options);
      } catch (error) {
        console.error("Error fetching diploma options:", error);
      }
    };

    fetchDiplomaOptions();
  }, []);

  useEffect(() => {
    if (programId) {
      axiosService
        .get(`${CIRCULAR_URLS.GET_ALL_STUDENTS}/${programId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response?.data?.data;
            setStudentData(responseData);
          }
        })
        .catch((error) => {
          console.error("Error fetching student data:", error);
        });
    } else {
      setStudentData([]);
    }
  }, [programId]);

  const handleProgramChange = (selectedOption) => {
    setProgramId(selectedOption?.programId);
    setProgram(selectedOption?.value);
  };
  const [selectedStudents, setSelectedStudents] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      setSelectedStudents(
        studentData.map((student) => student.studentMasterId)
      );
    } else {
      setSelectedStudents(selectedOptions.map((option) => option.value));
    }
  };
  useEffect(() => {
    setSelectedStudents([]);
  }, [programId]);

  const options =
    studentData.length > 0
      ? [
          { value: "all", label: "All" },
          ...studentData.map((student) => ({
            value: student.studentMasterId,
            label: student?.admissionManagement?.personalData?.firstName,
          })),
        ]
      : [{ value: "all", label: "All" }];

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => {
        close();
      }}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-Con">
        <div
          className="col col-md-11 p-3"
          style={{
            height: "auto",
            maxHeight: "90vh",
            backgroundColor: "#212226",
          }}
        >
          <div className="new-conversation-popup-header">
            <span style={{ fontSize: "20px", fontWeight: 400, color: "#fff" }}>
              {circularId ? "Update Circular" : "Add Circular"}
            </span>
            <IoClose color="#ffffff" size={"23px"} onClick={close} />
          </div>
          <div className="d-flex flex-column gap-1 mt-2">
            <label style={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}>
              Title *
            </label>
            <input
              className="task-subject-input"
              placeholder=""
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{
                height: "32px",
                borderRadius: "3px",
                backgroundColor: "#333333",
              }}
            />
          </div>
          <div className="d-flex flex-column gap-1 mt-2">
            <label style={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}>
              Description *
            </label>
            <TextEditor
              onHtmlContentChange={handleHtmlContentChange}
              initialHtmlContent={htmlContent}
            ></TextEditor>
          </div>
          <div className="d-flex flex-column gap-1 mt-2">
            <label style={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}>
              Important Notice
            </label>
            <div className="text-white d-flex items-center gap-2 ">
              <input
                value={isImportant}
                className="Description"
                type="checkbox"
                checked={isImportant}
                onChange={(e) => setIsImportant(e.target.checked)}
              />
              <label
                style={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}
              >
                Yes
              </label>
            </div>
          </div>
          <div className="flex-column flex-md-row d-flex gap-2 mt-2">
            <div className="col-12 col-md-6 ">
              <div className="label-text-exam">Select a Program</div>
              <Select
                value={program ? { value: program, label: program } : null}
                onChange={handleProgramChange}
                options={diplomaOptions}
                isSearchable={false}
                classNamePrefix="react-select"
                styles={selectStyles}
                style={{
                  height: "33px",
                  borderRadius: "3px",
                  fontSize: "12px",
                  backgroundColor: "#212226",
                }}
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className="col-12 col-md-6 ">
              <div className="label-text-exam">Select Students</div>
              <Select
                options={options}
                isMulti
                onChange={handleSelectChange}
                isSearchable={false}
                classNamePrefix="react-select"
                styles={selectStyles}
                style={{
                  height: "33px",
                  borderRadius: "3px",
                  fontSize: "12px",
                  backgroundColor: "#212226",
                }}
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Choose an option"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 mt-3">
            <button
              className="communication-management-cancel-button"
              type="button"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`communication-save-head-button ${
                disable && "opacity-50"
              }`}
              onClick={handleUpdateSubmit}
              disabled={disable}
            >
              {circularId ? "Update" : "Submit"}
            </button>

            <button
              type="button"
              className={`communication-save-head-button ${
                disable && "opacity-50"
              }`}
              onClick={handleUpdateNotify}
              disabled={disable}
            >
              {circularId ? "Update & Notify" : "Submit & Notify"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddCircularNotice;
