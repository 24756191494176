import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateStudentProfile.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import { FaEye } from "react-icons/fa";

function CreateStudentProfile() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const location = useLocation();
  const navigation = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);

  const data = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);

  const initialValues = {
    academicLevel: admissionData?.profile?.academicLevel || "",
    favoriteSubjects: admissionData?.profile?.favoriteSubjects || "",
    practicalExperience: admissionData?.profile?.practicalExperience || "",
    preferredHobbies: admissionData?.profile?.preferredHobbies || "",
    academicAchievements: admissionData?.profile?.academicAchievements || "",
    totalYears: admissionData?.profile?.totalYears || "",
    reasonLeaving: admissionData?.profile?.reasonLeaving || "",
    courseExempted: admissionData?.profile?.courseExempted || "",
    instituteName: admissionData?.profile?.instituteName || "",
    consentFile: admissionData?.profile?.consentFile || null,
    consentFileName: admissionData?.profile?.consentFileName || null,
  };

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.profile?.isApproved);
        });
    }
  }, [location.pathname]);

  const validationSchema = Yup.object().shape({});

  const navigateToProgram = () => {
    navigation("/admission-management/create-program-details", {
      state: { data: data },
    });
  };

  const [showRejectInput, setShowRejectInput] = useState(false);
  const handleApprove = async () => {
    let requestData = admissionData?.profile;
    requestData.isApproved = true;
    requestData.isRejected = false;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_STUDENT_PROFILE}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Student Profile Approved Successfully");
        navigateToProgram();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.profile;
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_STUDENT_PROFILE}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Student Profile Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.profile?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(setFieldValue) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails[0].applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>
              <div className="create-student-profile-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Student Profile</div>
                <div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Highest academic level(e.g Completed High school,Completed
                      Bacheors e.t.c.)
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="academicLevel"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Favorite Subjects/Fields
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="favoriteSubjects"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Practical experience (Internship/part-time experience)
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="practicalExperience"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Preferred hobbies/extra-curricular activities
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="preferredHobbies"
                      className="create-admission-input-student-profile"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Additional academic achievements
                    </label>
                    <Field
                      disabled={true}
                      as="textarea"
                      name="academicAchievements"
                      className="create-admission-input-student-text-area"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      How many years have you studied in total
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="totalYears"
                      className="create-admission-input-big"
                    />
                  </div>
                </div>
                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Reason for leaving
                    </label>
                    <Field
                      disabled={true}
                      as="textarea"
                      name="reasonLeaving"
                      className="create-admission-input-student-text-area"
                    />
                  </div>
                </div>

                <div className="create-mailing-address-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Course has been exempted
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="courseExempted"
                      className="create-admission-input-exemption"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active">
                      Name of the Institute for exemption
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="instituteName"
                      className="create-admission-input-exemption"
                    />
                  </div>
                </div>
                <div
                  className="create-list-upload-inner-input-container"
                  style={{ marginTop: "10px" }}
                >
                  <div className="column d-flex" style={{ gap: "20px" }}>
                    <label className="label-text-active " htmlFor="consentFile">
                      Consent File*
                    </label>
                    <input
                      disabled={true}
                      className="d-none"
                      type="file"
                      accept="image/*"
                      id="upload1"
                      name="consentFile"
                      onChange={(event) =>
                        setFieldValue(
                          "consentFile",
                          event.currentTarget.files[0]
                        )
                      }
                    />
                    <span
                      className="create-list-upload-input-big"
                      onClick={() => {
                        document.getElementById("upload1").click();
                      }}
                    >
                      <span className="text-white">
                        {admissionData?.profile?.consentFileName ||
                          "No file chosen"}
                      </span>
                      <div className="create-list-upload-input-choose">
                        Choose File
                      </div>
                    </span>
                  </div>
                  {admissionData?.profile?.consentFile && (
                    <a
                      href={admissionData?.profile?.consentFile}
                      target="_blank"
                      rel="noreferrer"
                      className="view-eye-icon"
                    >
                      <FaEye />
                    </a>
                  )}
                </div>

                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    className="create-admission-previous-button"
                    onClick={() =>
                      navigation(
                        "/admission-management/create-list-upload-files",
                        { state: { data: data } }
                      )
                    }
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={navigateToProgram}
                    className="create-admission-save-button"
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.profile?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.profile?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.profile?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateStudentProfile;
