class SessionStorageService {
  PERMISSIONS_KEY = "Permissions";
  TOKEN_KEY = "token";

  setToken(token) {
    sessionStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken() {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  removeToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
  }

  isUserLoggedIn() {
    const token = sessionStorage.getItem(this.TOKEN_KEY);
    return token !== null && token.startsWith("Bearer");
  }

  isLoading(loading) {
    sessionStorage.setItem("loading", loading);
  }

  getLoading() {
    return sessionStorage.getItem("loading");
  }

  setPermissions(permissions) {
    sessionStorage.setItem(this.PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  removePermissions() {
    sessionStorage.removeItem(this.PERMISSIONS_KEY);
  }

  getPermissions() {
    const permissionString = sessionStorage.getItem(this.PERMISSIONS_KEY);
    return permissionString ? JSON.parse(permissionString) : [];
  }

  hasPermissionByButton(requiredPermission) {
    const permissions = this.getPermissionsByButton();
    return permissions.includes(requiredPermission);
  }

  getPermissionsByButton() {
    const permissionString = sessionStorage.getItem(this.PERMISSIONS_KEY);
    try {
      return permissionString ? JSON.parse(permissionString) : [];
    } catch (error) {
      return [];
    }
  }
  setUserData(data) {
    sessionStorage.setItem("userData", JSON?.stringify(data));
  }

  getUserData() {
    const data = sessionStorage.getItem("userData");
    return data ? JSON.parse(data) : null;
  }

  setAdmissionId(data) {
    sessionStorage.setItem("admissionId", JSON?.stringify(data));
  }
  getAdmissionId() {
    const data = sessionStorage.getItem("admissionId");
    return data ? JSON.parse(data) : null;
  }
  setNewsId(data) {
    sessionStorage.setItem("newsId", JSON?.stringify(data));
  }
  getNewsId() {
    const data = sessionStorage.getItem("newsId");
    return data ? JSON.parse(data) : null;
  }
  setHashValue(data) {
    sessionStorage.setItem("hashValue", JSON?.stringify(data));
  }
  getHashValue() {
    const data = sessionStorage.getItem("hashValue");
    return data ? JSON.parse(data) : null;
  }

  setStudentProfile(data) {
    sessionStorage.setItem("studentProfile", JSON?.stringify(data));
  }
  getStudentProfile() {
    const data = sessionStorage.getItem("studentProfile");
    return data ? JSON.parse(data) : null;
  }

  setPreStudentData(data) {
    sessionStorage.setItem("preStudentData", JSON?.stringify(data));
  }
  getPreStudentData() {
    const data = sessionStorage.getItem("preStudentData");
    return data ? JSON.parse(data) : null;
  }
  setPreStudentDocumentId(data) {
    sessionStorage.setItem("preStudentDocumentId", JSON?.stringify(data));
  }
  getPreStudentDocumentId() {
    const data = sessionStorage.getItem("preStudentDocumentId");
    return data ? JSON.parse(data) : null;
  }
}
const sessionService = new SessionStorageService();
export default sessionService;
