import { AppConfig } from "env.development";
export const EXAMS_URL = {
  GET_ALL_EXAM_MODULES_BY_STUDENTID: `${AppConfig.api_url}exam-module/v1/get-module/`,
  GET_ALL_QUESTIONS: `${AppConfig.api_url}exam-module/v1`,
  GET_ALL_STUDENT_EXAM_MODULES: `${AppConfig.api_url}exam-module/v1/`,
  GET_ALL_STUDENT_EXAM_MODULES_FOR_FACULTY: `${AppConfig.api_url}exam-module/v1/filter-all`,
  GET_ALL_STUDENT_EXAM_MODULES_FOR_FACULTY_WITH_FILTER: `${AppConfig.api_url}exam-module/v1/filter-all`,
  UPDATE_ANSWER: `${AppConfig.api_url}exam-module/v1`,
  UPDATE_BY_FACULTY: `${AppConfig.api_url}exam-module/v1/faculty`,
  GET_ALL_STUDENT_EXAMS: `${AppConfig.api_url}student-answer/v1/get-question/`,
  GET_STUDENT_EXAM_MODULE: `${AppConfig.api_url}student-answer/v1/`,
  SAVE_STUDENT_EXAM_MODULE: `${AppConfig.api_url}student-answer/v1`,
  GET_ALL_STUDENT_ANSWERS: `${AppConfig.api_url}faculty-exam-module/v2`,
  UPDATE_STUDENT_MARKS: `${AppConfig.api_url}student-answer/v1/update-faculty`,
  GET_STUDENT_ANSWERS: `${AppConfig.api_url}faculty-exam-module/v3`,

  //   SAVE_QUESTIONS: `${AppConfig.api_url}/exam-module/v1`,
};
