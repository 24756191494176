import React, { useEffect, useState } from "react";
import "./CreateCampaign.css";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { BsXSquareFill } from "react-icons/bs";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import { axiosService } from "services/axios.service";
import { RxTriangleDown } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateCampaign() {
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  const navigate = useNavigate();

  const today = new Date();
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "100% !important",
      minWidth: "230px !important",
      backgroundColor: "#212226 !important",
    }),
  };
  // const selectStylesRange = {
  //   control: (provided) => ({
  //     ...provided,
  //     borderRadius: "0 !important",
  //     width: "100% !important",
  //     backgroundColor: "#212226 !important",
  //   }),
  // };
  const [activeAccordion, setActiveAccordion] = useState(1);

  const initialValues = {
    campaignName: "",
    delayInSecs: "",
    scheduleRepeat: "",
    scheduleFrequency: "",
    scheduleDateTime: "",
    targetList: [
      {
        contactList: "",
        message: "",
        noOfContacts: "",
        range: "",
        rangeLimits: "",
      },
    ],
  };

  const [listNameOptions, setListNameOptions] = useState([]);
  const [messageNameOptions, setMessageNameOptions] = useState([]);

  useEffect(() => {
    axiosService
      .get(`${CAMPAIGN_URLS.GET_ALL_CONTACT_LIST}`)
      .then((response) => {
        const contactListName = response.data?.data;

        const listNameOptions = contactListName?.map((contactListName) => ({
          value: contactListName.listName,
          label: contactListName.listName,
          noOfContact: contactListName.noOfContacts,
          contactListObject: { listId: contactListName.listId },
        }));
        if (listNameOptions) {
          setListNameOptions(listNameOptions);
        }
      });
  }, []);
  const isPastDate = (date) => {
    const today = new Date();
    return date < today;
  };
  const dayClassNames = (date) => {
    return isPastDate(date) ? "past-date" : "";
  };
  useEffect(() => {
    axiosService
      .get(`${CAMPAIGN_URLS.ADD_MEESAGE_CAMPAIGN}`)
      .then((response) => {
        const messageName = response.data?.data;

        const messageNameOptions = messageName?.map((messageName) => ({
          value: messageName.messageName,
          label: messageName.messageName,
          messageNameObject: {
            messageId: messageName.messageId,
            messageName: messageName.messageName,
            body: messageName.body,
            mailSubject: messageName.mailSubject,
          },
        }));
        if (messageNameOptions) {
          setMessageNameOptions(messageNameOptions);
        }
      });
  }, []);

  function updateNoOfContacts(form, index, option) {
    let fieldName = `targetList.${index}.noOfContacts`;
    form.setFieldValue(fieldName, option.noOfContact);
  }

  // useEffect(() => {
  //   if (campaignId) {
  //     axiosService
  //       .get(`${CAMPAIGN_URLS.ADD_CAMPAIGN}/${campaignId}`)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           const responseData = response.data.data;

  //           setData(responseData);
  //         }
  //       });
  //   }
  // }, [location.pathname, campaignId]);

  const onSubmit = async (values) => {
    let formattedDate = formatDate(values.scheduleDateTime);

    let Data = {
      ...values,
      scheduleDateTime: formattedDate,
    };

    try {
      axiosService
        .post(`${CAMPAIGN_URLS.ADD_CAMPAIGN}`, Data)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Saved successfully");
            navigate("/campaigns/all-campaigns");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Failed");
          } else if (error.request) {
            toast.error("No response received from server");
          } else {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          }
        });
    } catch (error) {
      toast.error("Failed");
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <div className="view-leads-table-container">
              <div className="lead-table-head-container align-items-center sticky-header flex-row flex-wrap gap-2">
                <span className="communication-management-title-text">
                  Create New Campaign
                </span>

                <div className="d-flex align-items-center gap-3">
                  <button
                    className="communication-management-cancel-button"
                    onClick={() => {
                      navigate("/campaigns/all-campaigns");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="communication-save-head-button"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="create-campaign-accordion">
                <div className="create-campaign-accordion-item">
                  <div
                    className="create-campaign-accordion-title"
                    onClick={() => setActiveAccordion(1)}
                  >
                    <span
                      style={{
                        height: "30px",
                        width: "0px",
                        border: "3px solid #F69F3A",
                        marginRight: "10px",
                      }}
                    ></span>
                    Campaign Details
                  </div>
                  <div
                    className={
                      activeAccordion === 1
                        ? "create-campaign-active-accordion create-campaign-accordion-content"
                        : "create-campaign-accordion-content"
                    }
                  >
                    <div className="create-program-main-input-container">
                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Campaign Name *
                        </label>
                        <Field
                          name="campaignName"
                          type="text"
                          className="create-campaign-input"
                        />
                      </div>
                      {/* <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Email Delay (seconds)
                        </label>
                        <Field
                          type="text"
                          name="delayInSecs"
                          className="create-campaign-input"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="create-campaign-accordion-item">
                  <div
                    className="create-campaign-accordion-title"
                    onClick={() => setActiveAccordion(2)}
                  >
                    <span
                      style={{
                        height: "30px",
                        width: "0px",
                        border: "3px solid #F69F3A",
                        marginRight: "10px",
                      }}
                    ></span>
                    Target Audience
                  </div>
                  <div
                    className={
                      activeAccordion === 2
                        ? "create-campaign-active-accordion create-campaign-accordion-content"
                        : "create-campaign-accordion-content"
                    }
                  >
                    <FieldArray name="targetList">
                      {({ insert, remove, push }) => (
                        <div>
                          {values.targetList.length > 0 &&
                            values.targetList.map((friend, index) => (
                              <div
                                key={index}
                                className={
                                  index > 0
                                    ? "mt-2 d-flex flex-wrap gap-2"
                                    : "d-flex flex-wrap gap-2"
                                }
                              >
                                <div className="col">
                                  {index <= 0 && (
                                    <label
                                      htmlFor={`targetList.${index}.contactListName`}
                                      className="label-text-active"
                                    >
                                      Contact Listname *
                                    </label>
                                  )}

                                  <Field
                                    name={`targetList.${index}.contactList`}
                                    alt={`targetList.${index}.noOfContacts`}
                                    as="select"
                                    className="create-campaign-input"
                                  >
                                    {({ field, form }) => (
                                      <Select
                                        value={
                                          field.value && field.value.listId
                                            ? listNameOptions.find(
                                                (option) =>
                                                  option.enrolledStaffObject
                                                    ?.listId ===
                                                  field.value.listId
                                              )
                                            : null
                                        }
                                        onChange={(option) => {
                                          form.setFieldValue(
                                            field.name,
                                            option
                                              ? option.contactListObject
                                              : null
                                          );
                                          updateNoOfContacts(
                                            form,
                                            index,
                                            option
                                          );
                                        }}
                                        options={listNameOptions}
                                        isSearchable={false}
                                        classNamePrefix="react-select"
                                        components={{
                                          DropdownIndicator: () => (
                                            <RxTriangleDown color="#ffffff" />
                                          ),
                                          IndicatorSeparator: null,
                                        }}
                                        placeholder="Choose an option"
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        styles={selectStyles}
                                      />
                                    )}
                                  </Field>

                                  <ErrorMessage
                                    name={`targetList.${index}.contactList`}
                                    component="div"
                                    className="field-error"
                                  />
                                </div>
                                <div className="col">
                                  {index <= 0 && (
                                    <label
                                      htmlFor={`targetList.${index}.message`}
                                      className="label-text-active"
                                    >
                                      Message Name *
                                    </label>
                                  )}
                                  <Field
                                    name={`targetList.${index}.message`}
                                    as="select"
                                    className="create-campaign-input"
                                  >
                                    {({ field, form }) => (
                                      <Select
                                        value={
                                          field.value && field.value.messageId
                                            ? messageNameOptions.find(
                                                (option) =>
                                                  option.messageNameObject
                                                    ?.messageId ===
                                                  field.value.messageId
                                              )
                                            : null
                                        }
                                        onChange={(option) => {
                                          form.setFieldValue(
                                            field.name,
                                            option
                                              ? option.messageNameObject
                                              : null
                                          );
                                        }}
                                        options={messageNameOptions}
                                        isSearchable={false}
                                        classNamePrefix="react-select"
                                        components={{
                                          DropdownIndicator: () => (
                                            <RxTriangleDown color="#ffffff" />
                                          ),
                                          IndicatorSeparator: null,
                                        }}
                                        placeholder="Choose an option"
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        styles={selectStyles}
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name={`targetList.${index}.message`}
                                    component="div"
                                    className="field-error"
                                  />
                                </div>
                                <div className="col ">
                                  {index <= 0 && (
                                    <label
                                      htmlFor={`targetList.${index}.noOfContacts`}
                                      className="label-text-active"
                                    >
                                      No. of Contacts *
                                    </label>
                                  )}
                                  <Field
                                    disabled="true"
                                    name={`targetList.${index}.noOfContacts`}
                                    className="create-campaign-input"
                                    type="text"
                                    style={{ width: "100%", minWidth: "230px" }}
                                  />
                                  <ErrorMessage
                                    name={`targetList.${index}.noOfContacts`}
                                    component="div"
                                    className="field-error"
                                  />
                                </div>

                                <div className="col">
                                  {index <= 0 && (
                                    <label className="label-text-active">
                                      Action
                                    </label>
                                  )}
                                  <div>
                                    {values.targetList.length >= 5 ? (
                                      <BsFillPlusSquareFill
                                        className="create-campaign-add-button-icon"
                                        type="button"
                                        disabled="true"
                                      />
                                    ) : (
                                      <BsFillPlusSquareFill
                                        className="create-campaign-add-button-icon"
                                        type="button"
                                        onClick={() =>
                                          push({
                                            contactList: "",
                                            message: "",
                                            noOfContacts: "",
                                            range: "",
                                            rangeLimits: "",
                                          })
                                        }
                                      />
                                    )}
                                    {values.targetList.length === 1 ? (
                                      <BsXSquareFill
                                        className="create-campaign-add-button-icon"
                                        type="button"
                                        disabled="true"
                                      />
                                    ) : (
                                      <BsXSquareFill
                                        className="create-campaign-add-button-icon"
                                        type="button"
                                        onClick={() => remove(index)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="create-campaign-accordion-item">
                  <div
                    className="create-campaign-accordion-title"
                    onClick={() => setActiveAccordion(3)}
                  >
                    <span
                      style={{
                        height: "30px",
                        width: "0px",
                        border: "3px solid #F69F3A",
                        marginRight: "10px",
                      }}
                    ></span>
                    Schedule
                  </div>
                  <div
                    className={
                      activeAccordion === 3
                        ? "create-campaign-active-accordion create-campaign-accordion-content"
                        : "create-campaign-accordion-content"
                    }
                  >
                    <div className="create-program-main-input-container">
                      <div className="col-3">
                        <label className="label-text-active width-label-program">
                          Date & Time *
                        </label>

                        <Field name="scheduleDateTime">
                          {({ field, form }) => {
                            return (
                              <DatePicker
                                selected={
                                  field.value ? new Date(field.value) : null
                                }
                                onChange={(date) => {
                                  form.setFieldValue(field.name, date);
                                }}
                                showTimeSelect
                                minDate={today}
                                timeIntervals={30}
                                dayClassName={dayClassNames}
                                dateFormat="dd-MM-yyyy HH:mm:ss"
                                className="create-campaign-input"
                                placeholderText="Select Date & Time"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateCampaign;
