import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { axiosService } from "services/axios.service";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import { toast } from "react-toastify";

const AddContactPopUp = ({
  open,
  onClose,
  leadId,
  leadStatus,
  setLeadId,
  setLeadStatus,
  getAllContactUsingListID,
  selectedContact,
  contactModalType,
}) => {
  const [contactId, setContactId] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [contactNo, setContactNo] = useState(null);
  const [address, setAddress] = useState(null);
  const [birthday, setBirthday] = useState(null);

  function addContact() {
    let bdayString = birthday ? birthday.split("-").reverse().join("-") : "";
    let Data = {
      contactName: contactName,
      email: emailId,
      contactNumber: contactNo,
      address: address,
      birthday: bdayString,
    };

    // try {
    //     axiosService.post(`${CAMPAIGN_URLS.ADD_CONTACT}${leadId}`, Data).then((response) => {
    //         if (response.status === 200) {
    //             onClose();
    //             getAllContactUsingListID();
    //             setView(false);
    //             setEdit(false);
    //         }
    //     });
    // } catch (error) {
    //     if (error) {
    //         toast.error(`Error: ${error.response}`);
    //     }
    // }

    try {
      axiosService
        .post(`${CAMPAIGN_URLS.ADD_CONTACT}${leadId}`, Data)
        .then((response) => {
          if (response.status === 200) {
            onClose();
            getAllContactUsingListID();

            toast.success("Contact added Successfully");
          }
        })
        .catch((error) => {
          onClose();

          if (error.response) {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          } else if (error.request) {
            toast.error("No response received from server");
          } else {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          }
        });
    } catch (error) {
      toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
    }
  }

  function updateContact() {
    let bdayString = birthday ? birthday.split("-").reverse().join("-") : "";
    let Data = {
      contactId: contactId,
      contactName: contactName,
      email: emailId,
      contactNumber: contactNo,
      address: address,
      birthday: bdayString,
    };

    axiosService
      .put(`${CAMPAIGN_URLS.UPDATE_CONTACT}${leadId}`, Data)
      .then((response) => {
        if (response.status === 200) {
          onClose();
          getAllContactUsingListID();
        }
      });
  }

  useEffect(() => {
    if (selectedContact) {
      setContactId(selectedContact.contactId);
      setContactName(selectedContact.contactName);
      setEmailId(selectedContact.email);
      setContactNo(selectedContact.contactNumber);
      setAddress(selectedContact.address);
      let bdayString = selectedContact.birthday
        ? selectedContact.birthday.split("-").reverse().join("-")
        : "";
      setBirthday(bdayString);
    }
  }, [selectedContact]);

  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                {contactModalType === "view"
                  ? "View Contact"
                  : contactModalType === "edit"
                  ? "Edit Contact"
                  : "Add Contact"}
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                <IoClose color="#ffffff" size={"22px"} />
              </span>
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div>
                <label className="new-conversation-popup-label">
                  Contact Name*
                </label>

                <input
                  value={contactName}
                  disabled={contactModalType === "view"}
                  onChange={(event) => {
                    setContactName(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div>
                <label className="new-conversation-popup-label">
                  Email Id*
                </label>

                <input
                  value={emailId}
                  disabled={contactModalType === "view"}
                  onChange={(event) => {
                    setEmailId(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div>
                <label className="new-conversation-popup-label">
                  Contact Number*
                </label>

                <input
                  type="number"
                  disabled={contactModalType === "view"}
                  value={contactNo}
                  maxlength="11"
                  onChange={(event) => {
                    if (event.target.value <= 11) {
                      setContactNo(event.target.value);
                    } else {
                      setContactNo(event.target.value.slice(0, 11));
                    }
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div>
                <label className="new-conversation-popup-label">Address*</label>

                <input
                  value={address}
                  disabled={contactModalType === "view"}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div>
                <label className="new-conversation-popup-label">Birthday</label>

                <input
                  id="birthdayInput"
                  value={birthday}
                  disabled={contactModalType === "view"}
                  type="date"
                  onChange={(event) => {
                    setBirthday(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="d-flex gap-3 mt-2">
                <button
                  className="communication-management-cancel-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>
                {contactModalType != "view" && (
                  <button
                    disabled={
                      !contactName || !emailId || !contactNo || !address
                    }
                    className={`communication-management-submit-button ${
                      (!contactName || !emailId || !contactNo || !address) &&
                      "opacity-50"
                    }`}
                    onClick={() =>
                      selectedContact ? updateContact() : addContact()
                    }
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddContactPopUp;
