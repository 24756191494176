import { useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import vectorLoader from "../../../assets/images/VectorLoader.png";
import crmlogo from "../../../assets/images/crm-logo.svg";
import docImg from "../../../assets/images/doc-img-2.png";
import Errorimage from "../../../assets/images/error icon.svg";
import StudentVerification from "../../../assets/images/student verification image 1.svg";
import success from "../../../assets/images/success icon.svg";
import "./enrollment-letter/QrComponent.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
function QrComponent() {
  const [apiResponse, setApiResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const hashFromURL = window.location.hash.substring(20);

    const fetchData = async () => {
      try {
        setLoad(true);
        if (hashFromURL) {
          const response = await axiosService.getForLoad(
            `https://api.lmsmybeta.com/crm/file-info/v1?hashValue=${hashFromURL}`
          );

          const byteCharacters = atob(response.data.data.body);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });

          const url = URL.createObjectURL(blob) + "#toolbar=0";
          setApiResponse(url);
          setLoad(true);
        }
      } catch (error) {
        setLoad(true);
        setErrorMessage(" Error: Document Verification Failed");
      }
    };

    fetchData();
  }, []);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="d-flex " style={{ width: "100vw", overflowX: "hidden" }}>
      <div
        className="student-slide auth-img-container img"
        style={{
          width: "40%",
          position: "sticky",
          left: 0,
          top: 0,
          height: "100vh",
        }}
      >
        <img
          src={StudentVerification}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div
        className="iframe-container auth-text-animation student p-3"
        style={{ width: "100%", minHeight: "100vh" }}
      >
        {viewportWidth <= 768 ? (
          <div className="d-flex w-100 justify-content-center">
            <img
              src={docImg}
              alt="logo"
              style={{ height: "200px", width: "100%", position: "relative" }}
            />
            <div
              className="justify-content-between"
              style={{
                position: "absolute",
                backgroundColor: "rgba(246, 159, 58, 0.7)",
                height: "200px",
                width: "93%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  overflow: "hidden",
                }}
              >
                <img
                  src={crmlogo}
                  alt="logo"
                  style={{ height: "60px", right: "0px", marginRight: "-30px" }}
                />
              </div>
              <span
                className="student-container-title2"
                style={{ color: "#ffffff" }}
              >
                Student Document Verification
              </span>
            </div>
          </div>
        ) : (
          <div className="d-flex w-100 justify-content-between pb-4">
            <span className=" student-container-title">
              Student Document Verification
            </span>
            <img src={crmlogo} alt="logo" style={{ height: "60px" }} />
          </div>
        )}

        <div
          className="d-flex row g-0 student-inner-content-container "
          style={{ width: "100%" }}
        >
          {!errorMessage && (
            <span style={{ display: "flex" }}>
              <img src={success} alt="logo" className="success-icon" />
              <p className="student-success-message">
                Success: Student verification complete
              </p>
            </span>
          )}
          {errorMessage && (
            <div
              style={{
                height: "80%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Errorimage}
                  style={{ height: "30px" }}
                  alt="logo"
                  className="success-icon"
                />
                <div style={{ fontSize: 20 }} className="student-error-text">
                  {errorMessage}
                </div>

                <p
                  style={{ textAlign: "center", marginTop: "20px" }}
                  className="student-error-p-tag"
                >
                  Please contact{" "}
                  <span>
                    <a
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                      href="mailto: info.beta@gmail.com"
                      className="student-info-beta"
                    >
                      info.beta@gmail.com
                    </a>
                  </span>
                  <p
                    style={{ textAlign: "center" }}
                    className="student-error-p-tag"
                  >
                    if you have any questions.
                  </p>
                </p>
              </div>
            </div>
          )}

          {!load && (
            <span className="application-loader-iframe">
              <div style={{ position: "absolute" }}>
                <img
                  alt="img"
                  className="blink"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "7rem",
                    width: "36px",
                    height: "44px",
                  }}
                  src={vectorLoader}
                />
                <p className="loader-name">
                  Verifying document. Please wait...
                </p>
              </div>
            </span>
          )}
          {!errorMessage &&
            // <iframe
            //   frameborder="0"
            //   src={apiResponse}
            //   width="100%"
            //   height={viewportWidth <= 768 ? "150%" : "100%"}
            //   style={{
            //     maxWidth: "100%",
            //     border: "none",
            //   }}
            //   type="application/pdf"
            // ></iframe>
            apiResponse && (
              // <div style={{ height: '380px', overflow: 'auto', display: 'flex', justifyContent: 'center', width: '100%' }}>
              //   <div className="custom-scrollbar" style={{ width: viewportWidth <= 768 ? '100vw' : '70vw', display: 'flex', justifyContent: 'center', height: '100%' }}>
              //     <div style={{ width: '20%' }}>
              //     </div>

              //   </div>
              // </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "20%" }}></div>
                <div
                  className=""
                  style={{
                    width: viewportWidth <= 768 ? "100vw" : "70vw",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "55vh",
                    overflowY: "auto",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer fileUrl={apiResponse} scrollMode="Vertical" />
                  </Worker>
                </div>
              </div>
            )}
          <div className="policy-text-container p-3">
            <span>
              <a
                style={{ textDecoration: "underline" }}
                href="https://mybeta.ca/privacy-policy/"
                className="auth-label-text"
                target="blank"
              >
                Privacy Policy
              </a>
            </span>
            <a
              style={{ textDecoration: "underline" }}
              href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/TERMS+AND+CONDITIONS.pdf"
              className="auth-label-text"
              target="blank"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrComponent;
