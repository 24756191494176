import React, { useEffect } from "react";
import "./ViewIndividualLeads.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { LEAD_URL } from "modules/lead-mangement/constants/leads-url";
import PipelineLeads from "../pipeline-leads/PipelineLeads";
import {
  LEAD_CONVERT_CONSTANTS,
  LEAD_DELETE_CONSTANTS,
  SEND_EMAIL_CONSTANTS,
} from "modules/lead-mangement/constants/leads-constants";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";

function ViewIndividualLeads() {
  const navigate = useNavigate();
  const location = useLocation();

  const [leadData, setLeadData] = useState([]);
  const leadId = location.state?.leadId;
  useEffect(() => {
    axiosService
      .get(`${LEAD_URL.GET_LEAD_BY_LEAD_ID}${leadId}`)
      .then((response) => {
        const data = response?.data?.data;
        setLeadData(data);
      });
  }, [location.pathname]);
  const [activeTab, setActiveTab] = useState("overview");

  const handleClick = (tab, leadId) => {
    if (tab !== activeTab) {
      setActiveTab(tab, leadId);
    }
  };
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = new Date(leadData.updatedOn).toLocaleDateString(
    "en-GB",
    options
  );
  const formattedCreatedDate = new Date(leadData.addedOn).toLocaleDateString(
    "en-GB",
    options
  );
  const deleteLead = () => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this lead?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${LEAD_URL.DELETE_LEAD}${leadId}`)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Deleted!!",
                text: LEAD_DELETE_CONSTANTS.SUCCESS,
                icon: "success",
                customClass: {
                  popup: "my-custom-class",
                },
              });
              navigate("/lead-management/view-leads-table");
            } else {
              Swal.fire(LEAD_DELETE_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            Swal.fire(LEAD_DELETE_CONSTANTS.FAILED);
          });
      }
    });
  };
  const convertStatus = () => {
    Swal.fire({
      title: "Convert Status",
      text: "Are you sure you want to convert  this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, convert it!",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .get(`${LEAD_URL.CONVERT_LEAD}${leadId}`)

          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Converted!!",
                text: LEAD_CONVERT_CONSTANTS.SUCCESS,
                icon: "success",
                customClass: {
                  popup: "my-custom-class",
                },
              });
            } else {
              Swal.fire(LEAD_CONVERT_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            Swal.fire(LEAD_CONVERT_CONSTANTS.FAILED);
          });
      }
    });
  };

  const sendEmail = () => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to Send Email?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, send mail",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .post(`${LEAD_URL.SEND_EMAIL}?leadIds=${leadId}`)

          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Success",
                text: SEND_EMAIL_CONSTANTS.SUCCESS,
                icon: "success",
                customClass: {
                  popup: "my-custom-class",
                },
              });
            } else {
              Swal.fire(SEND_EMAIL_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            Swal.fire(SEND_EMAIL_CONSTANTS.FAILED);
          });
      }
    });
  };

  const tasks = leadData.tasks || [];

  const getPriorityClassName = (priority) => {
    switch (priority) {
      case "HIGH":
        return "High-btn";
      case "MEDIUM":
        return "Medium-btn";
      case "LOW":
        return "Low-btn";
      default:
        return "Default-btn";
    }
  };
  return (
    <div className="lead-main-container">
      <div className="lead-head-container flex-wrap flex-row gap-2">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-1 gap-lg-5">
          <span className="lead-title-text">
            {activeTab === "pipeline"}
            {"[Lead Id: " + leadData.leadId + "]"}
          </span>

          <div className="lead-button-Con">
            <button
              onClick={() => handleClick("overview", leadId)}
              className={`lead-button ${
                activeTab === "overview" ? "active" : ""
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => handleClick("pipeline")}
              className={`lead-button ${
                activeTab === "pipeline" ? "active" : ""
              }`}
            >
              Pipeline
            </button>
          </div>
        </div>
        <div className="d-lg-flex align-items-center d-none flex-wrap gap-3 ">
          <button
            className="lead-save-head-button"
            onClick={() => deleteLead()}
          >
            Delete
          </button>
          <button className="lead-save-head-button" onClick={() => sendEmail()}>
            Send Email
          </button>
          <button className="lead-head-button" onClick={() => convertStatus()}>
            Convert
          </button>
          <button
            className="lead-head-button"
            onClick={() => {
              navigate("/lead-management/create-leads", {
                state: { leadId: leadId },
              });
            }}
          >
            Edit/Update
          </button>
        </div>

        <Dropdown className="d-lg-none">
          <Dropdown.Toggle
            className="lead-table-head-button"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0" align="end" flip>
            <Dropdown.Item onClick={() => deleteLead()}>Delete</Dropdown.Item>
            <Dropdown.Item onClick={() => sendEmail()}>
              Send Email
            </Dropdown.Item>
            <Dropdown.Item onClick={() => convertStatus()}>
              Convert Status
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                navigate("/lead-management/create-leads", {
                  state: { leadId: leadId },
                });
              }}
            >
              Edit/Update
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {activeTab === "overview" && (
        <>
          <div className="lead-sub-head-Container d-flex row">
            <div className="col-12 col-md-6 col-lg-3 sub-head-Con d-flex justify-content-start gap-5">
              <div className="sub-head">
                <span className="lead-sub-title">Status</span>
                <span className="lead-sub-text">{leadData?.leadStatus}</span>
              </div>
              <div className="sub-head">
                <span className="lead-sub-title">Lead Owner</span>
                <span className="lead-sub-text">
                  {leadData.leadOwner?.firstName}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 sub-head-Con justify-content-start justify-content-lg-center">
              <div className="sub-head">
                <span className="lead-sub-title">Email</span>
                <span className="lead-sub-text">
                  {" "}
                  {leadData.email !== "" ? leadData.email : "-"}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-5 mt-2 mt-md-0  sub-head-Con d-flex justify-content-start justify-content-lg-end gap-5">
              <div className="sub-head">
                <span className="lead-sub-title">Phone</span>
                <span className="lead-sub-text">
                  {" "}
                  {leadData.phoneNumber !== "" ? leadData.phoneNumber : "-"}
                </span>
              </div>
              <div className="sub-head">
                <span className="lead-sub-title">Secondary Phone</span>
                <span className="lead-sub-text">
                  {leadData.secondaryPhoneNumber !== ""
                    ? leadData.secondaryPhoneNumber
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="lead-sub-header1">Upcoming Tasks</div>
          <div className="lead-table-container row flex-md-row-reverse">
            <div className="col-12 col-md-12 col-lg-4 column d-flex task-container">
              <div className="lead-sub-headers col-2">Upcoming Tasks</div>
              <div className=" row flex due-view-container col-6 col-lg-12">
                <div className="col-6 table-sub-head1 mt-4 mt-md-0 ">
                  <span className="header">Due this week</span>
                  <span className="header-text">0</span>
                </div>
                <div className="col-6  table-sub-head2 mt-4 mt-md-0 ">
                  <span className="header">Due this month</span>
                  <span className="header-text">0</span>
                </div>
              </div>
            </div>
            <div
              className="table-con col-12 col-lg-8 custom-scrollbar"
              style={{ overflowX: "auto" }}
            >
              <table style={{ width: "100%", height: "100%" }}>
                <thead className="table-head">
                  <tr>
                    <th style={{ textWrap: "nowrap" }}>Subject</th>
                    <th style={{ textWrap: "nowrap" }}>Due Date</th>
                    <th style={{ textWrap: "nowrap" }}>Status</th>
                    <th style={{ textWrap: "nowrap" }}>Lead Name</th>
                    <th style={{ textWrap: "nowrap" }}>Priority</th>
                  </tr>
                </thead>
                <tbody className="table-data">
                  {tasks.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No data to display.
                      </td>
                    </tr>
                  ) : (
                    tasks.map((task, index) => (
                      <tr key={index}>
                        <td>{task.subject || "-"}</td>
                        <td>{task.dueDate || "-"}</td>
                        <td>{task.status || "-"}</td>
                        <td>
                          {leadData.firstName} {leadData.lastName}
                        </td>
                        <td>
                          <button
                            className={getPriorityClassName(task.priority)}
                          >
                            {task.priority || "-"}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="info-view row">
            <div className="info-sub-view col-12 col-md-12 col-lg-6">
              <div className="lead-sub-header">Lead Information</div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Lead Name</span>
                <span className="info-right col-7">
                  {leadData.firstName + " " + leadData.lastName}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Email</span>
                <span className="info-right col-7">{leadData.email}</span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Phone</span>
                <span className="info-right col-7">{leadData.phoneNumber}</span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Secondary Phone</span>
                <span className="info-right col-7">
                  {leadData.secondaryPhoneNumber}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Address</span>
                <span className="info-right col-7">
                  {leadData.addressLine1 ? leadData.addressLine1 : ""}
                  {leadData.addressLine1 && leadData.addressLine2 && ","}
                  {leadData.addressLine2 ? leadData.addressLine2 : ""}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">City</span>
                <span className="info-right col-7">
                  {" "}
                  {leadData.city !== "" ? leadData.city : "-"}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Country</span>
                <span className="info-right col-7">
                  {" "}
                  {leadData.country !== "" ? leadData.country : "-"}
                </span>
              </div>
            </div>
            <div className="info-sub-view col-12 col-md-12 col-lg-6 mt-3 mt-md-0">
              <div className="lead-sub-header">
                Staff & Referral Information
              </div>
              <div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Lead Source</span>
                  <span className="info-right col-7">
                    {leadData.leadSource}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">
                    Lead Owner/Assigned to
                  </span>
                  <span className="info-right col-7">
                    {leadData?.leadOwner?.firstName}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4 ">Referral Name</span>
                  <span className="info-right col-7">
                    {leadData.referralName !== "" ? leadData.referralName : "-"}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Referral Phone</span>
                  <span className="info-right col-7">
                    {leadData.referralPhoneNumber !== ""
                      ? leadData.referralPhoneNumber
                      : "-"}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Referral Email</span>
                  <span className="info-right col-7">
                    {leadData.referralEmail !== ""
                      ? leadData.referralEmail
                      : "-"}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Referral Relationship</span>
                  <span className="info-right col-7">
                    {leadData.referralRelationship !== ""
                      ? leadData.referralRelationship
                      : "-"}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">
                    Preferred Mode of Contact
                  </span>
                  <span className="info-right col-7">
                    {leadData.contactMode !== "" ? leadData.contactMode : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="info-view row">
            <div className="lead-sub-header">Additional Information</div>
            <div className="info-sub-view col-12 col-md-12 col-lg-6">
              <div className="inner-info-view row ">
                <span className="info-left col-4">Program of Interest</span>
                <span className="info-right col-7">
                  {" "}
                  {leadData.program !== "" ? leadData.program : "-"}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Campus of Interest</span>
                <span className="info-right col-7">
                  {" "}
                  {leadData.campus !== "" ? leadData.campus : "-"}
                </span>
              </div>
              <div className="inner-info-view row ">
                <span className="info-left col-4">Comments</span>
                <span className="info-right col-7">
                  {leadData.comments !== "" ? leadData.comments : "-"}
                </span>
              </div>
            </div>
            <div className="info-sub-view col-12 col-md-12 col-lg-6 ">
              <div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Created Date</span>
                  <span className="info-right col-7">
                    {formattedCreatedDate}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Updated Date</span>
                  <span className="info-right col-7">{formattedDate}</span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4 ">Created By</span>
                  <span className="info-right col-7">
                    {" "}
                    {leadData.addedBy !== "" ? leadData.addedBy : "-"}
                  </span>
                </div>
                <div className="inner-info-view row ">
                  <span className="info-left col-4">Updated By</span>
                  <span className="info-right col-7">
                    {" "}
                    {leadData.updatedBy !== "" ? leadData.updatedBy : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {activeTab === "pipeline" && <PipelineLeads />}
    </div>
  );
}

export default ViewIndividualLeads;
