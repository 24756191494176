export const AppConfig = {
  //api_url: "http://betaerp-production.eba-79vg6axv.us-east-2.elasticbeanstalk.com/crm/api/",

  // api_url: "http://localhost:8080/crm/api/",
  // api_url_: "http://localhost:8080/crm/",

  api_url: "https://api.lmsmybeta.com/crm/api/",
  api_url_: "https://api.lmsmybeta.com/crm/",

  google_url: "http://localhost:8080/crm/",

  merchantId: "376299454",
  hashKey: "4942BD57-EFD1-4F4B-8EE7-8D9A63AD",

  // test
  // merchantId: "383612428",
  // hashKey: "FA0A241B-30F7-4A20-8BE0-9AD221FA",
};

// const apiEndpoints = {
//   auth: {
//     authenticate: "/auth/v1/authenticate",
//   },
//   user: {
//     base: "/user/v1",
//     all: "/user/v1/all",
//     byId: (userId) => `/user/v1/${userId}`,
//     delete: (userId) => `/user/v1/${userId}`,
//   },
//   enquiryForm: {
//     base: "/lead/v1/enquiry",
//   },
//   leads: {
//     base: "/lead/v1/all",
//   },
//   role: {
//     base: "/role/v1",
//     all: "/role/v1/all",
//   },
// };

// export default apiEndpoints;
