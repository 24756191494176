const { AppConfig } = require("env.development");

export const QUERY_FEEDBACK_URLS = {
  CREATE_NEW_CONVERSATION: `${AppConfig.api_url}chat/v1/start`,
  SEND_MESSAGE: `${AppConfig.api_url}chat/v1/conversation`,
  GET_CHAT_BY_CHAT_ID: `${AppConfig.api_url}chat/v1/getById/`,
  CLOSE_CHAT: `${AppConfig.api_url}chat/v1/closeChat/`,
  ATTACHMENT_FILE_UPLOAD: `${AppConfig.api_url}admission/upload`,
  GET_CLOSED_CHAT_LIST_BY_ID: `${AppConfig.api_url}chat/v1/allClosed/`,
  GET_OPENED_CHAT_LIST_BY_ID: `${AppConfig.api_url}chat/v1/allOpened/`,
};
