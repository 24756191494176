import html2canvas from "html2canvas";
import { useEffect, useRef } from "react";
import "./EnrollmentContract.css";

import { PDFDocument } from "pdf-lib";
import { FaRegSquare } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";

const EnrollmentContract = ({
  data,
  onClose,
  setEnrollmentPdfData,
  studentType,
}) => {
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);

  const languageOfInstruction = "English";
  const brampton = false;

  const captureDivsToPDFBlob = async () => {
    const divs = [
      div1Ref.current,
      div2Ref.current,
      div3Ref.current,
      div4Ref.current,
      div5Ref.current,
      div6Ref.current,
      div7Ref.current,
    ];
    const canvases = await Promise.all(divs.map((div) => html2canvas(div)));
    const pdfDoc = await PDFDocument.create();

    for (const canvas of canvases) {
      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);
      const { width, height } = img.scale(1);

      const page = pdfDoc.addPage([width, height]);

      page.drawImage(img, {
        x: 0,
        y: 0,
        width: width,
        height: height,
      });
    }

    let pdfBytes = await pdfDoc.save();

    while (pdfBytes.length > 2 * 1024 * 1024) {
      const compressedPdfDoc = await PDFDocument.load(pdfBytes);
      const tempDoc = await PDFDocument.create();
      const scale = Math.sqrt((2 * 1024 * 1024) / pdfBytes.length);

      for (let i = 0; i < compressedPdfDoc.getPageCount(); i++) {
        const [oldPage] = await tempDoc.copyPages(compressedPdfDoc, [i]);
        const { width, height } = oldPage.getSize();
        const newWidth = width * scale;
        const newHeight = height * scale;
        const newPage = tempDoc.addPage([newWidth, newHeight]);
        newPage.drawPage(oldPage, {
          x: 0,
          y: 0,
          width: newWidth,
          height: newHeight,
        });
      }

      pdfBytes = await tempDoc.save();
    }

    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const docFile = new File([pdfBlob], "document.pdf", {
      type: "application/pdf",
    });
    setEnrollmentPdfData(docFile);
    onClose();
  };

  useEffect(() => {
    captureDivsToPDFBlob();
  }, [data]);

  return (
    <span
      className="enrollment-container"
      style={{ backgroundColor: "#212226" }}
    >
      <div style={{ backgroundColor: "#212226" }}>
        <div id="enrollment-contract-pdf-content1" style={{ color: "black" }}>
          <br />
          <br />
          <div
            id="enrollment-contract-context-1"
            ref={div1Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <h3 className="enrollment-contract-bold-center-12">
                Beta College of Business and Technology
              </h3>
              <p className="enrollment-contract-center-10">
                {brampton
                  ? "Unit 200A, 1 Nelson Street West, Brampton, L6Y1N1"
                  : "4275 Village Centre Court, Unit 201, Mississauga ON, L4Z 1V3"}
              </p>
              <h2 className="enrollment-contract-bold-center-14">
                ENROLMENT CONTRACT
              </h2>
              <p className="enrollment-contract-bold-12">
                This Enrolment Contract is subject to the{" "}
                <i>Ontario Career Colleges Act, 2005</i> and the regulations
                made under the Act.
              </p>
              <p className="enrollment-contract-12">
                The undersigned person hereby enrolls as a student of{" "}
                <b>
                  2198859 ONTARIO LTD o/a Beta College of Business and
                  Technology
                </b>{" "}
                operating as Beta College of Business and Technology as of 2024
                for the following:
              </p>
              <p className="enrollment-contract-bold-12">STUDENT INFORMATION</p>
              {/* <div style={{ width: '100%' }}>Name of Student<span></span></div> */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Name of Student
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.title}. {data?.personalData?.firstName}{" "}
                  {data?.personalData?.lastName && data?.personalData?.lastName}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Mailing Address
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "0.5px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.mailingAddress?.streetNo}{" "}
                  {data?.mailingAddress?.street}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", width: "33%" }}>
                  City
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "0.5px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.mailingAddress?.city}
                  </span>
                </div>

                <div style={{ display: "flex", width: "33%" }}>
                  Province
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "0.5px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.mailingAddress?.province}
                  </span>
                </div>

                <div style={{ display: "flex", width: "33%" }}>
                  Postal Code
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "0.5px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.mailingAddress?.postalCode}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", width: "50%" }}>
                  Phone
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "0.5px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.mailingAddress?.mobile1}
                  </span>
                </div>

                <div style={{ display: "flex", width: "50%" }}>
                  Alternative Phone
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "0.5px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.mailingAddress?.mobile2}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Permanent Address
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                ></span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", width: "33%" }}>
                  City
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>

                <div style={{ display: "flex", width: "33%" }}>
                  Province
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>

                <div style={{ display: "flex", width: "33%" }}>
                  Postal Code
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", width: "50%" }}>
                  Country
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>

                <div style={{ display: "flex", width: "50%" }}>
                  Phone
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Email Address
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "0.5px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.email}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                International Student
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                    marginLeft: "5px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <span style={{ marginRight: "5px" }}>
                      {studentType === "INTERNATIONAL" ? (
                        <TiTick />
                      ) : (
                        <FaRegSquare />
                      )}
                    </span>{" "}
                    Yes
                  </div>
                  <div>
                    <span style={{ marginRight: "5px" }}>
                      {studentType !== "INTERNATIONAL" ? (
                        <TiTick />
                      ) : (
                        <FaRegSquare />
                      )}
                    </span>
                    No
                  </div>
                </span>
              </div>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                PROGRAM INFORMATION
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Name of Program -
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.programDetails[0]?.program}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", width: "50%" }}>
                  Commencing on
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.programDetails[0].fromDate}
                  </span>
                </div>

                <div style={{ display: "flex", width: "50%" }}>
                  Expected Completion Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.programDetails[0].toDate}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Credential to be Awarded Upon Successful Completion of the
                Program
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                ></span>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Language of Instruction
                <div style={{ marginLeft: "5px", width: "15%" }}>
                  <span style={{ marginRight: "5px" }}>
                    {languageOfInstruction === "English" ? (
                      <TiTick />
                    ) : (
                      <FaRegSquare />
                    )}
                  </span>{" "}
                  English
                </div>
                <div style={{ marginLeft: "5px", width: "15%" }}>
                  <span style={{ marginRight: "5px" }}>
                    {languageOfInstruction === "English" ? (
                      <FaRegSquare />
                    ) : (
                      <TiTick />
                    )}
                  </span>
                  Other
                </div>
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                ></span>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Training Location
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.programDetails[0].campus}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Additional Training Location (if any)
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                ></span>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Location of Practicum
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                ></span>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Program length (in hours)
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.programDetails[0].hoursPerWeek}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Class Schedule
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  Monday - Friday
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Method of program delivery
                <span
                  style={{
                    flexGrow: "1",
                    borderBottom: "1px solid black",
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  Online (Zoom)
                </span>
              </div>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Admission Requirements
              </p>

              <ul
                style={{ fontSize: "12px", marginBottom: "5px" }}
                class="enrollment-contract-list-hollow-square"
              >
                <li>
                  Have an Ontario Secondary School Diploma or equivalent; or
                </li>
                <li>Wonderlic SLE Assessment or</li>
                <li>
                  Be at least 18 years of age and pass a Superintendent approved
                  qualifying test; and
                </li>
              </ul>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Fees
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Admission tests or assessments</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Application processing</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.fees[0]?.applicationFee}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Tuition fees</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Book fees</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Expendable supplies</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.fees[0]?.eslFee}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Uniform and equipment</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Major equipment</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div
            id="enrollment-contract-context-2"
            ref={div2Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Field trips</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.fees[0]?.fieldTripsFee}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Professional / Exam fees</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Other compulsory fees (itemized) </div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>International student fees</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data?.fees[0]?.internationalFee}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Optional fees (specify)</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>Total fees</div>
                <div
                  style={{ display: "flex", width: "40%", fontWeight: "bold" }}
                >
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  ></span>
                </div>
              </div>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Acknowledgement and Certification
              </p>
              <div
                className="enrollment-contract-12"
                style={{
                  display: "flex",
                  marginBottom: "5px",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                I,
                <span
                  style={{
                    width: "20%",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.firstName} {data?.personalData?.lastName}
                </span>
                , acknowledge that I have received a copy of:
              </div>

              <ul
                style={{ fontSize: "12px", marginBottom: "5px" }}
                class="enrollment-contract-list-hollow-square"
              >
                <li>The Consent to Use of Personal Information</li>
                <li>The Payment Schedule</li>
                <li>The College's Fee Refund Policy</li>
                <li>
                  The Statement of Students' Rights and Responsibilities Issued
                  by the Superintendent of Career Colleges
                </li>
                <li>The College's Student Complaint Procedure</li>
                <li>
                  The College's Policy Relating to the Expulsion of Students
                </li>
                <li>The College's Sexual Violence Policy</li>
              </ul>

              <div className="enrollment-contract-12" style={{ width: "100%" }}>
                I certify that I have read and understood this Enrolment
                Contract.
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", borderBottom: "1px solid black" }}
                ></div>
                <div
                  style={{ width: "40%", display: "flex", fontSize: "12px" }}
                >
                  Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formattedDate}
                  </span>
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Signature of Student)
              </div>

              <p
                className="enrollment-contract-bold-12"
                style={{ marginTop: "10px" }}
              >
                Beta College of Business and Technology does not guarantee
                employment for any studentwho successfully completes a
                vocational program offered by Beta College of Business and
                Technology.
              </p>
              <p className="enrollment-contract-12">
                It is understood that fees are payable in accordance with the
                fees specified in this Enrolment Contract and all payments of
                fees shall become due forthwith upon a statement of accounting
                being rendered. Beta College of Business and Technology reserves
                the right to cancel this Enrolment Contract if the undersigned
                student does not attend classes during the first 14 days of the
                program after it begins.{" "}
                <b>
                  For information regarding cancellation of this Enrolment
                  Contract and refunds of fees paid, see sections 24 (2) to 33
                  of O. Reg. 415/06 made under the{" "}
                  <i>Ontario Career Colleges Act, 2005.</i>
                </b>
              </p>

              <p className="enrollment-contract-bold-12">
                The undersigned student is entitled to a copy of the signed
                contract immediately after it is signed.
              </p>

              <p className="enrollment-contract-12">
                The undersigned student hereby undertakes and agrees to pay the
                fees specified in this Enrolment Contract in accordance with the
                terms of this Enrolment Contract
              </p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", borderBottom: "1px solid black" }}
                ></div>
                <div
                  style={{ width: "40%", display: "flex", fontSize: "12px" }}
                >
                  Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formattedDate}
                  </span>
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Signature of Student)
              </div>

              <p
                className="enrollment-contract-12"
                style={{ marginTop: "10px" }}
              >
                Beta College of Business and Technology agrees to supply program
                to the above-named studentupon the terms herein mentioned. Beta
                College of Business and Technology may cancel this Enrolment
                Contract if the above-named student does not meet the admission
                requirements of{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data?.programDetails[0]?.program}
                </span>{" "}
                before the program begins.
              </p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", borderBottom: "1px solid black" }}
                ></div>
                <div
                  style={{ width: "40%", display: "flex", fontSize: "12px" }}
                >
                  Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {formattedDate}
                  </span>
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Signature of Admission Officer, Registrar, Agent)
              </div>
            </div>
          </div>
          <div
            id="enrollment-contract-context-3"
            ref={div3Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <p className="enrollment-contract-bold-center-12">
                Consent to Use of Personal Information
              </p>
              <p className="enrollment-contract-12">
                Career colleges must be registered under the{" "}
                <i>Ontario Career Colleges Act, 2005</i>, which is administered
                by the Superintendent of Career Colleges. The Act protects
                students by requiring career colleges to follow specific rules
                on, for example, fee refunds, training completions if the
                college closes, qualifications of instructors, access to
                transcripts and advertising. It also requires colleges to
                publish and meet certain performance objectives that may be
                required by the Superintendent for their vocational programs.
                This information may be used by other students when they are
                deciding where to obtain their training. The consent set out
                below will help the Superintendent to ensure that current and
                future students receive the protection provided by the Act.
              </p>
              <p className="enrollment-contract-12">
                I,{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data?.personalData?.firstName} {data?.personalData?.lastName}
                </span>
                , allow{" "}
                <b>
                  2198859 ONTARIO LTD o/a Beta College of Business and
                  Technology
                </b>{" "}
                to give my name, address, telephone number, e-mail address and
                other contact information to theSuperintendent of Career
                Colleges for the purposes checked below:
              </p>
              <ul
                style={{ fontSize: "12px", marginBottom: "5px" }}
                class="enrollment-contract-list-hollow-square"
              >
                <li>
                  To advise me of my rights under the{" "}
                  <i>Ontario Career Colleges Act, 2005</i> including my rights
                  to a refund of fees, access to transcripts and a formal
                  student complaint procedure; and
                </li>
                <li>
                  To determine whether 2198859 ONTARIO LTD o/a Beta College of
                  Business and Technology has met the performance objectives
                  required by the Superintendent for itsvocational programs.
                </li>
              </ul>
              <p className="enrollment-contract-12">
                I understand that I can refuse to sign this consent form and
                that I can withdraw my consent at anytime for future uses of my
                personal information by writing to admin@mybeta.ca I understand
                that if I refuse or withdraw my consent the Superintendent may
                not be able to contact me to inform me of my rights under the
                Act.
              </p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "58px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.title}. {data?.personalData?.firstName}{" "}
                  {data?.personalData?.lastName}
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Name of Student)
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", borderBottom: "1px solid black" }}
                ></div>
                <div
                  style={{ width: "40%", display: "flex", fontSize: "12px" }}
                >
                  Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formattedDate}
                  </span>
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Signature of Student)
              </div>
            </div>
          </div>
          <div
            id="enrollment-contract-context-4"
            ref={div4Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <p className="enrollment-contract-bold-center-12">
                Payment Schedule
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                <div>1. Payments prior to signing contract (if any)</div>
                <div style={{ display: "flex", width: "40%" }}>
                  CAN$
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                    }}
                  ></span>
                </div>
              </div>
              <div style={{ fontSize: "12px", marginTop: "15px" }}>
                2. Payments after signing contract.
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>
                  <span style={{ borderBottom: "1px solid black" }}>Date</span>
                </div>
                <div style={{ width: "40%" }}>
                  <span style={{ borderBottom: "1px solid black" }}>
                    Amount due.
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "500px",
                  justifyContent: "space-between",
                  fontSize: "12px",
                }}
              >
                <div style={{ width: "50%", fontWeight: "bold" }}>
                  Total payments (1 + 2)
                </div>
                <div style={{ width: "40%", fontWeight: "bold" }}>CAN$</div>
              </div>
              <p className="enrollment-contract-12">
                For programs approved for student loan purposes, the Payment
                Schedule should note that funds received from the Canada-Ontario
                Integrated Student Loan and Grant Funding (Ontario Student
                Assistance Program) or any other financial aid will be applied
                as payments. Verification of receipt of payment must be attached
                to the original contract.
              </p>
              <p className="enrollment-contract-12">
                The undersigned student hereby undertakes and agrees to pay, or
                see to payment of, the fees indicated above in accordance with
                the terms of this Enrolment Contract.
              </p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "58px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.title}. {data?.personalData?.firstName}{" "}
                  {data?.personalData?.lastName}
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Name of Student)
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", borderBottom: "1px solid black" }}
                ></div>
                <div
                  style={{ width: "40%", display: "flex", fontSize: "12px" }}
                >
                  Date
                  <span
                    style={{
                      flexGrow: "1",
                      borderBottom: "1px solid black",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formattedDate}
                  </span>
                </div>
              </div>
              <div
                style={{ width: "50%", textAlign: "center", fontSize: "12px" }}
              >
                (Signature of Student)
              </div>
            </div>
          </div>
          <div
            id="enrollment-contract-context-5"
            ref={div5Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <p className="enrollment-contract-bold-center-12">
                Fee Refund Policy as Prescribed under s. 24 (2) to 33 of O.Reg.
                415/06
              </p>
              <p className="enrollment-contract-12">
                <b>24.</b> (2) In sections 25 to 27,
              </p>
              <ul style={{ listStyle: "none", fontSize: "12px" }}>
                <li style={{ marginBottom: "8px" }}>
                  “earned fees” means the amount of all fees paid for a
                  vocational program that is proportional to the number of
                  instruction hours that have taken place when a withdrawal or
                  expulsion occurs; (“droits acquis”)
                </li>
                <li style={{ marginBottom: "8px" }}>
                  “program mid-point” means the point in the progress of a
                  vocational program where half of the scheduled hours of
                  instruction for the program have taken place; (“mi-parcours du
                  programme”)
                </li>
                <li style={{ marginBottom: "8px" }}>
                  “service fee” means the lesser of 20 per cent of all
                  vocational program fees and $500. (“frais de service”)
                </li>
              </ul>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Full refunds
              </p>
              <p className="enrollment-contract-12">
                <b>25.</b> If a student has entered into a contract with a
                career college for a vocational program, the college shall give
                a refund of all fees paid for the program in the following
                circumstances:
              </p>
              <ol style={{ fontSize: "12px", marginBottom: "8px" }}>
                <li>
                  The student rescinds (cancels) the contract in writing within
                  two days of receiving a copy of it, in accordance with section
                  36 of the Act.
                </li>
                <li>
                  Before the student completes the program, the college
                  discontinues the program or the college's approval to provide
                  the program is revoked by the Superintendent, but the college
                  remains registered under the Act.
                </li>
                <li>
                  The college collects any fees before receiving a certificate
                  of registration from the Superintendent.
                </li>
                <li>
                  The college collects any fees before the program was approved
                  by the Superintendent.
                </li>
                <li>
                  The college collects any fees other than a service fee before
                  the student has entered into a contract with the college.
                </li>
                <li>
                  The college expels the student in a manner or for reasons that
                  are contrary to the college's expulsion policy.
                </li>
                <li>
                  The college does not provide an evaluation, in writing, of the
                  student’s progress as required under section 12.
                </li>
                <li>
                  The student voids the contract under subsection 18 (2) due to
                  a statement, image or video made by the college that is
                  prohibited under subsection 18 (1).
                </li>
                <li>
                  The student voids the contract under section 22 because it is
                  missing a term required under section 20.
                </li>
                <li>
                  The student receives instruction from an instructor who is not
                  qualified under section 41 for more than 10 per cent of the
                  program’s duration.
                </li>
              </ol>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Full refunds minus service fee
              </p>
              <p className="enrollment-contract-12">
                <b>26.</b> A career college shall give a refund of all fees paid
                for a vocational program, except the service fee, in the
                following circumstances:
              </p>
              <ol style={{ fontSize: "12px" }}>
                <li>
                  The student gives written notice to the college, before the
                  program start date specified in the student's contract with
                  the college, that the student is withdrawing from the program.
                </li>
                <li>
                  The student is admitted to the program on the condition that
                  the student meet specified admission requirements before the
                  program start date specified in the student's contract with
                  the college, and the student does not meet the requirements
                  before that day.
                </li>
                <li>
                  The student does not attend the program within the first 14
                  days of the program after the program start date specified in
                  the student's contract with the college and is given written
                  notice that the contract is cancelled from the college within
                  the first 45 days of the program.
                </li>
                <li>
                  The college is notified by or on behalf of an international
                  student before the program mid-point that the international
                  student has not been issued a temporary resident visa as a
                  member of the student class under the{" "}
                  <i>Immigration and Refugee Protection Act</i>
                  (Canada).
                </li>
              </ol>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Partial refunds
              </p>
              <p className="enrollment-contract-12">
                <b>27.</b> (1) A career college shall give a student a refund of
                the fees paid for a vocational program in accordance with this
                section if,
              </p>
              <ol style={{ fontSize: "12px", listStyleType: "lower-alpha" }}>
                <li>
                  the student withdraws from the program after the program start
                  date specified in the student's contract with the college; or
                </li>
                <li>
                  the student is expelled from the program for a reason
                  permitted under the college’s expulsion policy.
                </li>
              </ol>
            </div>
          </div>
          <div
            id="enrollment-contract-context-6"
            ref={div6Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <p className="enrollment-contract-12">
                (2) If a student's program is scheduled to be up to 12 months in
                duration, the career college shall give a refund for the program
                as follows:
              </p>
              <ol style={{ fontSize: "12px" }}>
                <li>
                  If the withdrawal or expulsion occurs before the program
                  mid-point, the college shall give a refund equal to the amount
                  of all fees paid, less the service fee and any earned fees.
                </li>
                <li>
                  If the withdrawal or expulsion occurs after the program
                  mid-point, no refund is required for the program.
                </li>
              </ol>
              <p style={{ fontSize: "12px" }}>
                (3) If a student's program is scheduled to be more than 12
                months in duration, the career college shall give a refund for
                the initial 12-month period of the program and any subsequent
                period as follows:
              </p>
              <ol style={{ fontSize: "12px" }}>
                <li>
                  If the withdrawal or expulsion occurs before half of the
                  scheduled hours of instruction have taken place for the
                  period, the college shall give a refund equal to the amount of
                  all fees paid for the period, less the service fee and any
                  earned fees.
                </li>
                <li>
                  If the withdrawal or expulsion occurs after half of the
                  scheduled hours of instruction have taken place for the
                  period, no refund is required for that period.
                </li>
                <li>
                  If a period has not yet started at the time of the withdrawal
                  or expulsion, the college shall give a refund of all fees paid
                  for that period.
                </li>
              </ol>

              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Refund of compulsory fees
              </p>
              <p className="enrollment-contract-12">
                <b>28.</b> (1) A career college may only charge or collect
                compulsory fees in relation to a vocational program after the
                fees have been published by the Superintendent under subsection
                43 (2).
              </p>
              <p className="enrollment-contract-12">
                (2) If a career college collects a compulsory fee that has not
                been published by the Superintendent under subsection 43 (2),
                the college shall give a refund of the fee to the student on
                written request from the student.
              </p>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                No retention of refund
              </p>
              <p className="enrollment-contract-12">
                <b>29.</b> A career college shall not retain any refund of fees
                payable to a student under sections 25 to 28 in order to recover
                or set-off an amount a student owes the college for any service
                or program other than a vocational program offered by the
                college.
              </p>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Timing of refunds
              </p>
              <p className="enrollment-contract-12">
                <b>30.</b> A refund payable by the career college must be issued
                to students within 30 days after the day a student,
              </p>
              <ol style={{ fontSize: "12px", listStyle: "lower-alpha" }}>
                <li>
                  delivers a written notice to withdraw from the program to the
                  college;
                </li>
                <li>
                  is given a written notice of expulsion by the college; or
                </li>
                <li>
                  delivers a written request for a refund to the college under
                  subsection 28 (2).
                </li>
              </ol>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Treatment of books and equipment
              </p>
              <p className="enrollment-contract-12">
                <b>31.</b> In calculating a refund under sections 25 to 28, a
                career college may retain the retail cost of books or equipment
                that the career college supplied to the student if the student,
              </p>
              <ol style={{ fontSize: "12px", listStyle: "lower-alpha" }}>
                <li>
                  fails to return the books or equipment to the career college
                  within 10 days of the student's withdrawal or expulsion from
                  the program, or
                </li>
                <li>
                  returns the books or equipment to the career college within
                  the 10-day period referred to clause (a), but fails to return
                  it unopened or in the same state it was in when supplied.
                </li>
              </ol>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Notice of withdrawal for international students
              </p>
              <p
                className="enrollment-contract-12"
                style={{ marginBottom: "14px" }}
              >
                <b>32.</b> A notice to a career college that is provided by or
                on behalf of an international student or of a prospective
                international student and that states that the student has not
                been issued a temporary resident visa as a member of the student
                class under the <i>Immigration and Refugee Protection Act</i>{" "}
                (Canada) is deemed to be written notice to the college that a
                student is rescinding (cancelling) the contract under section 36
                of the Act or withdrawing from the program.
              </p>
              <p
                className="enrollment-contract-bold-12"
                style={{ margin: "14px 0px" }}
              >
                Currency
              </p>
              <p className="enrollment-contract-12">
                <b>33.</b> Any refund of fees that a career college is required
                to pay under the Act shall be paid in Canadian dollars.
              </p>
            </div>
          </div>

          <div
            id="enrollment-contract-context-7"
            ref={div7Ref}
            style={{
              width: "794px",
              height: "1123px",
              position: "relative",
              backgroundColor: "white",
              padding: "50px",
            }}
          >
            <div>
              <p className="enrollment-contract-bold-center-12">
                MINIMAL TECHNICAL REQUIREMENTS DISCLAIMER
              </p>
              <p className="enrollment-contract-12">
                Before starting your online studies, it is important to
                understand{" "}
                <b>
                  the minimal technical requirements necessary to participate
                  effectively
                </b>
                . These requirements are as follows:
              </p>
              <ul style={{ fontSize: "12px" }}>
                <li>
                  A desktop or laptop computer that meets the minimum system
                  requirements:
                  <ul>
                    <li>
                      Processor: Intel Core i3 / AMD Ryzen 3 or equivalent
                    </li>
                    <li>Memory (RAM): 4 GB or higher</li>
                    <li>Hard drive: 128 GB or higher (SSD recommended)</li>
                    <li>Screen resolution: 1024 x 768 or higher.</li>
                    <li>
                      Graphics card: Integrated graphics or a dedicated graphics
                      card with 1 GB VRAM or higher
                    </li>
                    <li>
                      Operating system: Windows 10, MacOS, or Linux (depending
                      on the course).
                    </li>
                    <li>
                      Up-to-date web browser (Google Chrome, Mozilla Firefox,
                      Safari, etc.)
                    </li>
                  </ul>
                </li>
                <li>
                  Internet connection: A reliable internet connection with a
                  minimum speed of 10 Mbps for downloads and 5 Mbps for uploads
                </li>
                <li>Webcam (built-in or external) for video conferencing.</li>
                <li>
                  Microphone and speakers (built-in or external) for audio
                  communication.
                </li>
              </ul>
              <p className="enrollment-contract-12">
                It is your responsibility as a student to ensure that you{" "}
                <b>
                  have access to the necessary equipment and resources to
                  complete your studies online
                </b>
                . This includes acquiring the appropriate hardware and software
                needed to participate in the online learning environment.
              </p>
              <p className="enrollment-contract-12">
                In the case that you do not have access to the required
                technical resources, it is recommended that you consult with the
                college's technical support team for assistance. They will be
                able to provide guidance on how to obtain the necessary
                resources to be able to complete your online studies.
              </p>
              <p
                className="enrollment-contract-12"
                style={{ fontWeight: "bold", fontStyle: "italic" }}
              >
                Beta College will provide you with access to all the required
                software (MS Office Suite, Zoom, QuickBooks, etc.) to complete
                your online studies. The software required to complete your
                program will be added to your desktop or computer, and the
                college's technical support team will assist you with the
                installation process.
              </p>
              <p className="enrollment-contract-12">
                By signing below, you acknowledge that you have read and
                understood the minimal technical requirements needed to study
                online and that you will take responsibility for ensuring you
                have access to the necessary resources for your studies.
              </p>
              <p className="enrollment-contract-12">
                I,
                <span
                  style={{
                    width: "20%",
                    borderBottom: "1px solid black",
                    display: "inline-block",
                    margin: "0px 5px",
                    textAlign: "center",
                  }}
                >
                  {data?.personalData?.firstName} {data?.personalData?.lastName}
                </span>
                , acknowledge that I read and understood the minimal technical
                requirements needed to study online and will take responsibility
                for ensuring they have access to the necessary resources for
                their studies.
              </p>
              <div className="enrollment-contract-12">
                Student's Signature:{" "}
                <span
                  style={{
                    width: "20%",
                    borderBottom: "1px solid black",
                    display: "inline-block",
                    margin: "0px 5px",
                  }}
                ></span>
              </div>
              <div className="enrollment-contract-12">
                Date:{" "}
                <span
                  style={{
                    width: "20%",
                    borderBottom: "1px solid black",
                    display: "inline-block",
                    margin: "0px 5px",
                    textAlign: "center",
                  }}
                >
                  {formattedDate}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default EnrollmentContract;
