import betalogo from "assets/images/icons/beta.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";
import { QRCode } from "react-qr-code";
import sha256 from "crypto-js/sha256";

const PdfContent = ({ pdfData, includeWatermark, hashValueData }) => {

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );
  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;
  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div>
        <p>
          {" "}
          <strong>Issue Date:</strong> {formatDate(new Date())}
        </p>
        <h3>Invoice</h3>
        <div className="d-flex justify-space-between gap-5">
          <p>
            Student Name:
            <p>
              Student ID:
              <p>
                Course:
                <p>The Campus of Study:</p>
              </p>
            </p>
          </p>
          <p>
            {pdfData?.master?.admissionManagement?.personalData?.firstName}{" "}
            {pdfData?.master?.admissionManagement?.personalData?.lastName}
            <p>
              {pdfData?.master?.admissionManagement?.personalData?.studentId}
              <p>
                {
                  pdfData?.master?.admissionManagement?.programDetails[0]
                    ?.program
                }
                <p>
                  {" "}
                  {
                    pdfData?.master?.admissionManagement?.programDetails[0]
                      ?.campus
                  }
                </p>
              </p>
            </p>
          </p>
        </div>
        <p>
          <div className="d-flex justify-space-between gap-5">
            <p>
              •{pdfData?.invoiceType} Deposit:
              <p>
                • {pdfData?.invoiceType} Deposit:
                <p>
                  • Deadline to pay the balance fee:
                  <p>• Mode of Payment::</p>
                </p>
              </p>
            </p>
            <p>
              CAD {pdfData?.invoiceAmount} (Payable)
              <p>
                CAD {pdfData?.invoiceAmount} (Balance)
                <p>
                  {pdfData?.dueDate}
                  <p>Interace transfer/credit card/Bank Transfer</p>
                </p>
              </p>
            </p>
          </div>
        </p>
        <p>
          <div className="d-flex justify-space-between gap-5">
            <p>
              Length Of Program:
              <p>
                • Start Date:
                <p>• Expected End Date:</p>
              </p>
            </p>
            <p>
              {
                pdfData?.master?.admissionManagement?.programDetails[0]
                  ?.programDuration
              }{" "}
              Weeks
              <p>
                {
                  pdfData?.master?.admissionManagement?.programDetails[0]
                    ?.fromDate
                }
                <p>
                  {
                    pdfData?.master?.admissionManagement?.programDetails[0]
                      ?.toDate
                  }
                </p>
              </p>
            </p>
          </div>
        </p>

        <p>Bank Account Details : </p>
        <table class="custom-table-fee">
          <tbody>
            <tr>
              <td>Business Name, Address:</td>
              <td>
                C2198859 Ontario Ltd. o/a Beta College of Business and
                Technology
              </td>
            </tr>
            <tr>
              <td>Bank Name:</td>
              <td>TD Canada Trust</td>
            </tr>
            <tr>
              <td>Bank Address:</td>
              <td>1571 Sandhurst Circle, Scarborough, Ontario, M1V 1V2</td>
            </tr>
            <tr>
              <td>Bank Code:</td>
              <td>004</td>
            </tr>
            <tr>
              <td>Transit Number:</td>
              <td>03302</td>
            </tr>
            <tr>
              <td>Bank Swift Code:</td>
              <td>TDOMCATTTOR</td>
            </tr>
            <tr>
              <td>Account Number:</td>
              <td>5261133</td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <p>For Beta College of Business and Technology:</p>
        <br></br>
        <p>Signature of Institute Representative:</p>
        <p>
          Monica Singh
          <p>
            <strong>Sr.Accounts Officer</strong>
          </p>
          <br></br>
          <strong>Important Information:</strong>
          <p>
            • All fees and payments are subject to Terms and Conditions and
            actual realization. Claims will only be
            <p>
              entertained once the fees/funds are deposited in the college bank
              account/financial institution. Transfer
              <p>
                proof from a financial institution or Official Bank receipt is
                mandatory to submit an application for a refund.
                <p>Please visit our website for more details.</p>
              </p>
            </p>
          </p>
          <p>
            • This letter can be verified by emailing
            <strong> admin@betacollege.ca</strong>
          </p>
        </p>
      </div>
      <div
        className="footer"
        style={{
          marginTop: includeWatermark === "download" ? "5rem" : "5rem",
          marginLeft: "20px",
          position: "relative",
        }}
      >
        {includeWatermark === "download" && hashValueData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "22px",
              zIndex: 1,
            }}
          >
            <QRCode size={70} value={url} />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

const FeesInvoice = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);
  const studentId =
    pdfData?.master?.admissionManagement?.personalData?.studentId;
  const applicationNo = "3";
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Fee-Invoice"}-${studentId}`).toString().substring(0, 20)
  );

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 8 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark === "view") {
        const watermarkText = "Sample";
        const fontSize = 1500;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 1;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }

      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });

      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 20,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 70)) /
          overlayCanvas.width
      );

      if (includeWatermark) {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");

            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

            // setHashValue(hashValue);

            const fileName = `${
              includeWatermark ? "Fees-Invoice.sample" : "Fees-Invoice"
            }_${studentId}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Fees-Invoice.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
      close();
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
      close();
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
        />
      )}
    </span>
  );
};

export default FeesInvoice;
