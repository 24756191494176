import "./AddStudentInvoice.css";
import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { axiosService } from "services/axios.service";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function AddStudentInvoice({ open, onClose, invoiceData, viewOnly }) {
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "100% !important",
    }),
  };
  const location = useLocation();
  const invoiceId = invoiceData?.feeInvoiceId;
  const [editData, setEditData] = useState("");

  useEffect(() => {
    if (invoiceId) {
      axiosService
        .get(`${FEE_URLS.ADD_INVOICE_FEE}/${invoiceId}`)
        .then((response) => {
          const responseData = response?.data?.data;

          setEditData(responseData);
          setStudentId(responseData?.master?.studentMasterId);
        });
    }
  }, [location.pathname, invoiceId]);

  const initialValues = {
    studentId: invoiceId ? editData?.master?.studentId : "",
    studentName: invoiceId
      ? editData?.master?.admissionManagement?.personalData?.firstName
      : "",
    programId: invoiceId
      ? editData?.master?.admissionManagement?.programDetails[0]?.programId
      : "",
    invoiceType: invoiceId ? editData?.invoiceType : "",
    invoiceAmount: invoiceId ? editData?.invoiceAmount : "",
    studentDiscount: invoiceId ? editData?.studentDiscount : "",
    startDate: invoiceId ? editData?.startDate : "",
    dueDate: invoiceId ? editData?.dueDate : "",
    otherType: invoiceId ? editData?.otherType : "",
    paymentMode: invoiceId ? editData?.paymentMode : "",
    status: invoiceId ? (editData?.isPaid ? "Paid" : "Pending") : "",
  };

  const validationSchema = Yup.object().shape({});

  const InvoiceTypeOptions = [
    { value: "APPLICATIONFEE", label: "Application Fee" },
    { value: "TUITIONFEE", label: "Tuition Fee" },
    { value: "MATERIALFEE", label: "Material Fee" },
    { value: "INTERNATIONALFEE", label: "International Fee" },
    { value: "BANKFEE", label: "Bank Fee" },
    { value: "ESLFEE", label: "ESL Fee" },
    { value: "FIELDTRIPSFEE", label: "FieldTrips Fee" },
    { value: "OTHERFEE", label: "Other Fee" },
  ];
  const modeOfPaymentOptions = [
    { value: "CASH", label: "Cash" },
    { value: "CHEQUE", label: "Cheque" },
  ];
  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
  ];
  const [studentIds, setStudentIds] = useState([]);

  useEffect(() => {
    const getStudentIds = async () => {
      try {
        const response = await axiosService.getForUrl(
          FEE_URLS.GET_ALL_STUDENTS
        );
        const data = response?.data?.data;

        const ids = data.map((student) => ({
          value: student.studentId,
          label: student.studentId,
        }));
        setStudentIds(ids);
      } catch (error) {
        console.error("Error fetching student IDs:", error);
      }
    };

    getStudentIds();
  }, []);
  const [studentId, setStudentId] = useState(null);

  const getStudentDetails = (value, form) => {
    axiosService
      .getForUrl(`${FEE_URLS.GET_STUDENT_DETAILS_BY_STUDENT_ID}${value}`)
      .then((response) => {
        const data = response?.data?.data?.admission;

        form.setFieldValue("studentName", data?.personalData?.firstName);
        form.setFieldValue("programId", data?.programDetails[0]?.programId);
      })
      .catch((error) => {});

    axiosService
      .getForUrl(`${FEE_URLS.GET_STUDENT_MASTER_ID}${value}`)
      .then((response) => {
        const data = response?.data?.data;
        setStudentId(data?.studentMasterId);
      })

      .catch((error) => {});
  };
  const onSubmit = async (values) => {
    const requestData = {
      invoiceType: values.invoiceType,
      invoiceAmount: values.invoiceAmount,
      studentDiscount: values.studentDiscount,
      startDate: values.startDate,
      dueDate: values.dueDate,
      otherType: values.otherType,

      master: { studentMasterId: studentId },
    };

    try {
      if (invoiceId) {
        requestData.feeInvoiceId = editData.feeInvoiceId;
        requestData.invoiceNumber = editData.invoiceNumber;
        requestData.paymentMode = values.paymentMode;
        requestData.status = values.status;
        requestData.isPaid = values.isPaid;

        const response = await axiosService.put(
          `${FEE_URLS.ADD_INVOICE_FEE}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Fee Invoice updated successfully");
          onClose();
        }
      } else {
        const response = await axiosService.post(
          `${FEE_URLS.ADD_INVOICE_FEE}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Fee Invoice Saved Successfully");
          onClose();
        }
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };
  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-7 p-3 custom-scrollbar"
          style={{ height: "auto", backgroundColor: "#212226" }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="flex-sm-row justify-content-between">
                  <div className="new-conversation-popup-header">
                    <span className="add-news-current-affair-title-text">
                      {invoiceId
                        ? `#${editData?.invoiceNumber}`
                        : "New Student Invoice"}
                    </span>

                    <span
                      className="d-flex justify-content-center align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <IoClose
                        color="#ffffff"
                        size={"23px"}
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex flex-column gap-2 py-2"
                  style={{
                    borderBottom: "1px solid #7a7a7a",
                  }}
                >
                  <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Student ID *</div>
                      <div>
                        <Field
                          name="studentId"
                          render={({ field, form }) => (
                            <Select
                              defaultValue={field.value}
                              value={
                                field.value
                                  ? studentIds?.find(
                                      (option) => option.value === field.value
                                    )
                                  : null
                              }
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                                getStudentDetails(option.value, form);
                              }}
                              options={studentIds}
                              isSearchable={true}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              isDisabled={viewOnly}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Student Name *</div>
                      <div>
                        <Field
                          name="studentName"
                          className="create-financial-input-big w-100"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Program ID *</div>
                      <div>
                        <Field
                          name="programId"
                          className="create-financial-input-big w-100"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Invoice Type *</div>

                      <Field
                        name="invoiceType"
                        render={({ field, form }) => (
                          <Select
                            id="invoiceType"
                            value={
                              field.value
                                ? InvoiceTypeOptions.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              if (option.value !== "OTHERFEE") {
                                form.setFieldValue("otherType", "");
                              }
                            }}
                            options={InvoiceTypeOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown color="#ffffff" />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            isDisabled={viewOnly}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {values.invoiceType === "OTHERFEE" && (
                    <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                      <div className=" col-12 col-md-6">
                        <div className="label-text-active">Other Type</div>
                        <div>
                          <Field
                            name="otherType"
                            className="create-financial-input-big w-100"
                            readOnly={viewOnly}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">
                        Invoice Amount * ($)
                      </div>
                      <div>
                        <Field
                          name="invoiceAmount"
                          className="create-financial-input-big w-100"
                          placeholder="Enter amount in dollars "
                          readOnly={viewOnly}
                        />
                      </div>
                    </div>
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">
                        Student Discount ($)
                      </div>
                      <div>
                        <Field
                          name="studentDiscount"
                          className="create-financial-input-big w-100"
                          placeholder="Enter amount in dollars "
                          readOnly={viewOnly}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Start Date *</div>
                      <div>
                        <Field
                          type="date"
                          name="startDate"
                          className="create-financial-input-big w-100"
                          readOnly={viewOnly}
                        />
                      </div>
                    </div>
                    <div className=" col-12 col-md-6">
                      <div className="label-text-active">Due Date *</div>
                      <div>
                        <Field
                          type="date"
                          name="dueDate"
                          className="create-financial-input-big w-100"
                          readOnly={viewOnly}
                        />
                      </div>
                    </div>
                  </div>
                  {invoiceId && (
                    <div className="d-flex gap-2 justify-content-between flex-column flex-md-row">
                      <div className=" col-12 col-md-6">
                        <div className="label-text-active">Mode of Payment</div>
                        <div>
                          <Field
                            name="paymentMode"
                            render={({ field, form }) => (
                              <Select
                                id="paymentMode"
                                value={
                                  field.value
                                    ? modeOfPaymentOptions.find(
                                        (option) => option.value === field.value
                                      )
                                    : null
                                }
                                onChange={(option) => {
                                  form.setFieldValue(field.name, option.value);
                                }}
                                options={modeOfPaymentOptions}
                                isSearchable={false}
                                classNamePrefix="react-select"
                                styles={selectStyles}
                                components={{
                                  DropdownIndicator: () => (
                                    <RxTriangleDown color="#ffffff" />
                                  ),
                                  IndicatorSeparator: null,
                                }}
                                placeholder="Choose an Option"
                                menuPlacement="auto"
                                menuPosition="fixed"
                                isDisabled={viewOnly}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className=" col-12 col-md-6">
                        <div className="label-text-active">Status</div>

                        <Field
                          name="status"
                          render={({ field, form }) => (
                            <Select
                              id="status"
                              value={
                                field.value
                                  ? statusOptions.find(
                                      (option) => option.value === field.value
                                    )
                                  : null
                              }
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                              }}
                              options={statusOptions}
                              isSearchable={false}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#ffffff" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              isDisabled={viewOnly}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className=" d-flex align-items-center gap-3 mt-3">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Close
                  </button>
                  {viewOnly != true && (
                    <button
                      className="communication-management-submit-button "
                      type="submit"
                    >
                      {invoiceId ? "Update" : "Submit"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddStudentInvoice;
