import React, { useEffect, useRef, useState } from "react";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import sessionService from "services/sessionstorage.service";

function AdmissionSideBarFaculty({ data }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const [program, setProgram] = useState(null);
  const [fees, setFees] = useState(null);
  const [payment, setPayment] = useState(null);
  const [cloaPayment, setCloaPayment] = useState(null);
  const [studentType, setStudentType] = useState("");
  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${data?.admissionId}`
        )
        .then((response) => {
          const page = response?.data?.data;
          setPage(page);
        });
    }
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setStudentType(data?.user?.studentType);
        });
    }
    if (data?.programId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PROGRAM_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setProgram(data);
        });

      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setPayment(data);
        });

      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_CLOA_PAYMENT_BY_PROGRAMID}${data?.programId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setCloaPayment(data);
        });
    }
    if (data?.feeId) {
      axiosService
        .get(`${ADMISSION_MANAGEMENT_URL.GET_FEE_BY_FEEID}${data?.feeId}`)
        .then((response) => {
          const data = response?.data?.data;
          setFees(data);
        });
    }
  }, []);

  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;

      switch (location.pathname) {
        case "/admission-management/create-personal-data":
          swiper.slideTo(0);
          break;
        case "/admission-management/create-mailing-address":
          swiper.slideTo(1);
          break;
        case "/admission-management/create-guardian-details":
          swiper.slideTo(2);
          break;
        case "/admission-management/create-academic-professional":
          swiper.slideTo(3);
          break;
        case "/admission-management/create-employement-work-experience":
          swiper.slideTo(4);
          break;
        case "/admission-management/create-other-information":
          swiper.slideTo(5);
          break;
        case "/admission-management/create-list-upload-files":
          swiper.slideTo(6);
          break;
        case "/admission-management/create-student-profile":
          swiper.slideTo(7);
          break;
        case "/admission-management/create-program-details":
          swiper.slideTo(8);
          break;
        case "/admission-management/create-financial":
          swiper.slideTo(9);
          break;
        case "/admission-management/cloa-payment-info":
          swiper.slideTo(10);
          break;
        case "/admission-management/payment-info":
          swiper.slideTo(11);
          break;
        default:
          swiper.slideTo(0);
          break;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <ul className="p-0 d-none flex-column d-lg-flex">
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-personal-data", {
              state: { data },
            })
          }
          className={`mt-0 program-detail-list-text-faculty 
        ${page?.personalData?.isApproved && "program-detail-list-text-approved"}
        ${page?.personalData?.isRejected && "program-detail-list-text-rejected"}
        ${
          location.pathname === "/admission-management/create-personal-data" &&
          "program-detail-list-active"
        }`}
        >
          Personal Data
        </button>

        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-mailing-address", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${
          page?.mailingAddress?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.mailingAddress?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-mailing-address" &&
          "program-detail-list-active"
        } `}
        >
          Mailing Address
        </button>

        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-guardian-details", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${
          page?.guardianDetail?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.guardianDetail?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-guardian-details" &&
          "program-detail-list-active"
        } `}
        >
          Guardian Details
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-academic-professional", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${page?.academics[0]?.isApproved && "program-detail-list-text-approved"}
        ${page?.academics[0]?.isRejected && "program-detail-list-text-rejected"}
      ${
        location.pathname ===
          "/admission-management/create-academic-professional" &&
        "program-detail-list-active"
      } `}
        >
          Academic and Professional Qualifications
        </button>
        <button
          type="button"
          onClick={() =>
            navigate(
              "/admission-management/create-employement-work-experience",
              {
                state: { data },
              }
            )
          }
          className={`program-detail-list-text-faculty
        ${
          page?.employments[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.employments[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname ===
          "/admission-management/create-employement-work-experience" &&
        "program-detail-list-active"
      } `}
        >
          Employment and Work Experience
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-other-information", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${
          page?.otherInformation?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.otherInformation?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-other-information" &&
          "program-detail-list-active"
        } `}
        >
          Other Information
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-list-upload-files", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${page?.upload?.isApproved && "program-detail-list-text-approved"}
        ${page?.upload?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-list-upload-files" &&
          "program-detail-list-active"
        } `}
        >
          List of Uploaded files
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-student-profile", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${page?.profile?.isApproved && "program-detail-list-text-approved"}
        ${page?.profile?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-student-profile" &&
          "program-detail-list-active"
        } `}
        >
          Student Profile
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-program-details", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${program?.isApproved && "program-detail-list-text-approved"}
        ${program?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-program-details" &&
          "program-detail-list-active"
        } `}
        >
          Program Details
        </button>
        <button
          type="button"
          onClick={() =>
            navigate("/admission-management/create-financial", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${fees?.isApproved && "program-detail-list-text-approved"}
        ${fees?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname === "/admission-management/create-financial" &&
          "program-detail-list-active"
        } `}
        >
          Fee/Financial
        </button>
        {studentType !== "OSAP" && (
          <button
            type="button"
            disabled={!cloaPayment}
            onClick={() =>
              navigate("/admission-management/cloa-payment-info", {
                state: { data },
              })
            }
            className={`program-detail-list-text-faculty
        ${cloaPayment?.isApproved && "program-detail-list-text-approved"}
        ${cloaPayment?.isRejected && "program-detail-list-text-rejected"}
        ${cloaPayment === undefined && "program-detail-list-inactive-faculty"}

        ${
          location.pathname === "/admission-management/cloa-payment-info" &&
          "program-detail-list-active"
        } `}
          >
            CLOA Payment Info
          </button>
        )}

        <button
          type="button"
          disabled={!payment}
          onClick={() =>
            navigate("/admission-management/payment-info", {
              state: { data },
            })
          }
          className={`program-detail-list-text-faculty
        ${payment?.isApproved && "program-detail-list-text-approved"}
        ${payment?.isRejected && "program-detail-list-text-rejected"}
        ${payment === undefined && "program-detail-list-inactive-faculty"}

        ${
          location.pathname === "/admission-management/payment-info" &&
          "program-detail-list-active"
        } `}
        >
          Payment Info
        </button>
      </ul>
      <ul className="p-0 d-flex flex-column d-lg-none">
        <Swiper
          ref={swiperRef}
          style={{ width: "100%", paddingRight: "10px", paddingLeft: "20px" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={10}
          slidesPerView="auto"
        >
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-personal-data", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty 
          ${
            page?.personalData?.isApproved &&
            "program-detail-list-text-approved"
          }
          ${
            page?.personalData?.isRejected &&
            "program-detail-list-text-rejected"
          }
          ${
            location.pathname ===
              "/admission-management/create-personal-data" &&
            "program-detail-list-active"
          }`}
          >
            Personal Data
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-mailing-address", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${
          page?.mailingAddress?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.mailingAddress?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-mailing-address" &&
          "program-detail-list-active"
        } `}
          >
            Mailing Address
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-guardian-details", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${
          page?.guardianDetail?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.guardianDetail?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-guardian-details" &&
          "program-detail-list-active"
        } `}
          >
            Guardian Details
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-academic-professional", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${page?.academics[0]?.isApproved && "program-detail-list-text-approved"}
        ${page?.academics[0]?.isRejected && "program-detail-list-text-rejected"}
      ${
        location.pathname ===
          "/admission-management/create-academic-professional" &&
        "program-detail-list-active"
      } `}
          >
            Academic and Professional Qualifications
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate(
                "/admission-management/create-employement-work-experience",
                {
                  state: { data },
                }
              )
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${
          page?.employments[0]?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.employments[0]?.isRejected &&
          "program-detail-list-text-rejected"
        }
      ${
        location.pathname ===
          "/admission-management/create-employement-work-experience" &&
        "program-detail-list-active"
      } `}
          >
            Employment and Work Experience
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-other-information", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${
          page?.otherInformation?.isApproved &&
          "program-detail-list-text-approved"
        }
        ${
          page?.otherInformation?.isRejected &&
          "program-detail-list-text-rejected"
        }
        ${
          location.pathname ===
            "/admission-management/create-other-information" &&
          "program-detail-list-active"
        } `}
          >
            Other Information
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-list-upload-files", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${page?.upload?.isApproved && "program-detail-list-text-approved"}
        ${page?.upload?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-list-upload-files" &&
          "program-detail-list-active"
        } `}
          >
            List of Uploaded files
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-student-profile", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${page?.profile?.isApproved && "program-detail-list-text-approved"}
        ${page?.profile?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-student-profile" &&
          "program-detail-list-active"
        } `}
          >
            Student Profile
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-program-details", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${program?.isApproved && "program-detail-list-text-approved"}
        ${program?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname ===
            "/admission-management/create-program-details" &&
          "program-detail-list-active"
        } `}
          >
            Program Details
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              navigate("/admission-management/create-financial", {
                state: { data },
              })
            }
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${fees?.isApproved && "program-detail-list-text-approved"}
        ${fees?.isRejected && "program-detail-list-text-rejected"}

        ${
          location.pathname === "/admission-management/create-financial" &&
          "program-detail-list-active"
        } `}
          >
            Fee/Financial
          </SwiperSlide>{" "}
          {studentType !== "OSAP" && (
            <SwiperSlide
              onClick={() => {
                if (cloaPayment) {
                  navigate("/admission-management/cloa-payment-info", {
                    state: { data },
                  });
                }
              }}
              className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${cloaPayment?.isApproved && "program-detail-list-text-approved"}
        ${cloaPayment?.isRejected && "program-detail-list-text-rejected"}
        ${cloaPayment === undefined && "program-detail-list-inactive-faculty"}

        ${
          location.pathname === "/admission-management/cloa-payment-info" &&
          "program-detail-list-active"
        } `}
            >
              CLOA Payment Info
            </SwiperSlide>
          )}
          <SwiperSlide
            onClick={() => {
              if (payment) {
                navigate("/admission-management/payment-info", {
                  state: { data },
                });
              }
            }}
            className={`sidebar-mobile-swiper-text-container  program-detail-list-text-faculty
        ${payment?.isApproved && "program-detail-list-text-approved"}
        ${payment?.isRejected && "program-detail-list-text-rejected"}
        ${payment === undefined && "program-detail-list-inactive-faculty"}

        ${
          location.pathname === "/admission-management/payment-info" &&
          "program-detail-list-active"
        } `}
          >
            Payment Info
          </SwiperSlide>
        </Swiper>
      </ul>
    </>
  );
}

export default AdmissionSideBarFaculty;
