import React from "react";
import "./ViewModules.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

function ViewModules() {
  const location = useLocation();
  const navigate = useNavigate();
  const Data = location.state;
  const moduleData = Data?.data?.Data;
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="academics-main-container">
      <div className="sticky-header lead-table-head-container align-items-center flex-row flex-wrap gap-2">
        <div className="d-flex gap-1 align-items-center">
          <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
            <IoIosArrowBack size={20} />
          </span>
          <h6 className="lead-table-title-text">
            {moduleData?.moduleName || "-"}
          </h6>
        </div>

        <button
          className="user-head-button"
          onClick={() =>
            navigate("/academics-management/add-module", {
              state: { moduleId: moduleData?.moduleId },
            })
          }
        >
          Edit Module
        </button>
      </div>

      <div className="w-100 d-flex flex-column gap-2 mt-2">
        <div className="program-info-sub-view col-12">
          <div className="view-program-sub-header">Module Details</div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Module Name</span>
            <span className="program-info-right col ps-4">
              {moduleData?.moduleName || "-"}
            </span>
          </div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Module Code</span>
            <span className="program-info-right col ps-4">
              {moduleData?.moduleCode || "-"}
            </span>
          </div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Total Hours</span>
            <span className="program-info-right col ps-4">
              {moduleData?.totalHours || "-"}
            </span>
          </div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Beta Credits</span>
            <span className="program-info-right col ps-4">
              {moduleData?.betaCredits || "-"}
            </span>
          </div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Level</span>
            <span className="program-info-right col ps-4">
              {moduleData?.level || "-"}
            </span>
          </div>
          <div className="inner-info-view row g-0">
            <span className="view-module-program-info-left">Status</span>
            <span className="program-info-right col ps-4">
              {moduleData?.status || "-"}
            </span>
          </div>
        </div>
        <div className="program-info-sub-view col-12">
          <div className="view-program-sub-header">Module Descriptor</div>
          <span
            className="program-view-text col-12"
            dangerouslySetInnerHTML={{
              __html: moduleData?.moduleOverview || "-",
            }}
          ></span>
        </div>
        <div className="program-info-sub-view col-12">
          <div className="view-program-sub-header">Indicative Content</div>

          <span
            className="program-view-text col-12"
            dangerouslySetInnerHTML={{
              __html: moduleData?.indicativeContent || "-",
            }}
          ></span>
        </div>
        <div className="program-info-sub-view col-12">
          <div className="view-program-sub-header">
            Module Outcome & Assessment
          </div>

          <div className="w-100 custom-scrollbar">
            <table style={{ width: "100%" }}>
              <thead className="view-module-table-head">
                <tr>
                  <th className="table-data-view-profile">Outcome</th>
                  <th className="table-data-view-profile">Coursework</th>
                  <th className="table-data-view-profile">Exam</th>
                  <th className="table-data-view-profile">In-Person</th>
                </tr>
              </thead>
              <tbody>
                {moduleData?.moduleOutcomes &&
                  moduleData.moduleOutcomes.map((data, index) => (
                    <tr key={index}>
                      <td className="table-data-view-profile">
                        {data.outcome}
                      </td>
                      <td className="table-data-view-profile">
                        {data.coursework === true ? "YES" : "NO"}
                      </td>
                      <td className="table-data-view-profile">
                        {data.exam === true ? "YES" : "NO"}
                      </td>
                      <td className="table-data-view-profile">
                        {data.inPerson === true ? "YES" : "NO"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="program-info-sub-view col-12">
          <div className="view-program-sub-header">
            Breakdown Learning and Teaching Activities
          </div>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <table style={{ minWidth: "100%" }}>
              <thead className="view-module-table-head">
                <tr>
                  <th
                    className="table-data-view-profile"
                    style={{ width: "18%" }}
                  >
                    Learning Activities
                  </th>
                  <th
                    className="table-data-view-profile"
                    style={{ width: "7%" }}
                  >
                    Hours
                  </th>
                  <th
                    className="table-data-view-profile"
                    style={{ width: "75%" }}
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {moduleData?.breakdownLearnings &&
                  moduleData.moduleOutcomes.map((data, index) => (
                    <tr key={index}>
                      <td className="table-data-view-profile">
                        {data.learningActivities}
                      </td>
                      <td className="table-data-view-profile">{data.hours}</td>
                      <td className="table-data-view-profile">
                        {data.description}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewModules;
