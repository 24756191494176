import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AllFaculty from "../all-faculty/AllFaculty";
import NewEmployee from "../new-employee/NewEmployee";
import ViewFaculty from "../view-faculty/ViewFaculty";
import HolidayAndLeaves from "../holiday-and-leaves/HolidayAndLeaves";
import ViewFacultyAttendance from "../view-faculty-attendance/ViewFacultyAttendance";

function HrModuleRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/hr-module") {
      navigate("/hr-module/view-all-faculty");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="view-all-faculty" element={<AllFaculty />} />
      <Route path="new-employee" element={<NewEmployee />} />
      <Route path="view-faculty" element={<ViewFaculty />} />
      <Route
        path="view-faculty-attendance"
        element={<ViewFacultyAttendance />}
      />
      <Route path="holiday-and-leaves" element={<HolidayAndLeaves />} />
    </Routes>
  );
}

export default HrModuleRoutes;
