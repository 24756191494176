const { AppConfig } = require("env.development");

export const FORUM_DISCUSSION_URLS = {
  CREATE_NEW_FORUM: `${AppConfig.api_url}forum/v1`,
  CREATE_NEW_DISCUSSION: `${AppConfig.api_url}discussion/v1`,
  GET_ALL_FORUM: `${AppConfig.api_url}forum/v1/all`,
  GET_FORUM_BY_FORUMID: `${AppConfig.api_url}forum/v1/`,
  GET_DISCUSSION_BY_ID: `${AppConfig.api_url}discussion/v1/`,
  CREATE_POST: `${AppConfig.api_url}post/v1`,
  GET_FORUM_STATS: `${AppConfig.api_url}forum/v1/forum-data/all`,
  DELETE_FORUM: `${AppConfig.api_url}forum/v1/`,
  DELETE_DISCUSSION: `${AppConfig.api_url}discussion/v1/`,
  DELETE_POST: `${AppConfig.api_url}post/v1/moderate/`,
};
