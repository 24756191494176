import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "services/axios.service";
import "./ViewLeads.css";
import { LEAD_URL } from "modules/lead-mangement/constants/leads-url";
import useravatar from "../../../../assets/images/user-avatar.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";
import Modal from "react-modal";
import {
  LEAD_DELETE_CONSTANTS,
  LEAD_IMPORT_CONSTANTS,
} from "modules/lead-mangement/constants/leads-constants";
import { toast } from "react-toastify";
import CreateTaskModal from "./create-task-in-pop-up/CreateTaskModal";
import Swal from "sweetalert2";

function ViewLeads() {
  const navigate = useNavigate();

  const [leadData, setLeadData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedLead, setSelectedLead] = useState();
  const location = useLocation();

  useEffect(() => {
    axiosService.get(`${LEAD_URL.GET_ALL_LEADS}`).then((response) => {
      const data = response?.data?.data;
      setLeadData(data);
    });
  }, [location.pathname, refresh]);

  const deleteLead = () => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this lead?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${LEAD_URL.DELETE_LEAD}${selectedLead?.leadId}`)
          .then((response) => {
            if (response.status === 200) {
              setRefresh(!refresh);
              Swal.fire({
                title: "Deleted!!",
                text: LEAD_DELETE_CONSTANTS.SUCCESS,
                icon: "success",
                customClass: {
                  popup: "my-custom-class",
                },
              });
              navigate("/lead-management/view-leads-table");
            } else {
              Swal.fire(LEAD_DELETE_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            Swal.fire(LEAD_DELETE_CONSTANTS.FAILED);
          });
      }
    });
  };

  const LeadCellRenderer = (params) => {
    return (
      <div className="d-flex gap-2">
        <span className="d-flex justify-content-center align-items-center ">
          {params.data.lead?.imageurl !== null ? (
            <img
              src={params.data.lead?.imageurl}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          ) : (
            <img
              src={useravatar}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          )}
        </span>
        <span className="d-flex flex-column">
          <span className="tabel-cell-text-1">{params.data.lead?.name}</span>
          <span className="tabel-cell-text-2 text-lowercase">
            {params.data.lead?.email}
          </span>
        </span>
      </div>
    );
  };
  const LocationCellRenderer = (params) => {
    return (
      <div className="d-flex flex-column">
        <span className="tabel-cell-text-1">
          {params?.data?.location?.city}
        </span>
        <span className="tabel-cell-text-2">
          {params?.data?.location?.country}
        </span>
      </div>
    );
  };
  function customLocationFilter(params) {
    const locationData = params.data.location;
    const filterText = params.filter;

    if (locationData && filterText) {
      const city = locationData.city || "";
      const country = locationData.country || "";
      const locationFields = (city + " " + country).toLowerCase();
      return locationFields.includes(filterText.toLowerCase());
    }

    return true;
  }
  function customLeadFilter(params) {
    const leadData = params.data.lead;
    const filterText = params.filter;

    if (leadData && filterText) {
      const name = leadData.name || "";
      const email = leadData.email || "";
      const leadFields = (name + " " + email).toLowerCase();
      return leadFields.includes(filterText.toLowerCase());
    }

    return true;
  }

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      floatingFilter: false,
      filter: false,
    },

    {
      headerName: "Lead Details",
      filterParams: {
        applyButton: true,
      },
      valueGetter: function (params) {
        const leadData = params.data.lead;
        const name = leadData.name || "";
        const email = leadData.email || "";
        return name + " " + email;
      },
      customFilterFunction: customLeadFilter,
      cellRenderer: LeadCellRenderer,
    },
    {
      headerName: "Location",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
      },
      valueGetter: function (params) {
        const locationData = params.data.location;
        const city = locationData.city || "";
        const country = locationData.country || "";
        return city + " " + country;
      },
      customFilterFunction: customLocationFilter,
      cellRenderer: LocationCellRenderer,
    },
    {
      headerName: "Status",
      field: "leadStatus",
    },
    {
      headerName: "Phone No",
      field: "phoneNumber",
    },
    {
      headerName: "Lead Source",
      field: "leadsource",
    },

    {
      headerName: "Lead Owner",
      field: "leadowner.firstName",
    },
    {
      headerName: "Mode of Contact",
      field: "modeofcontact",
    },
    {
      headerName: "Course of Interest",
      field: "programInterest",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
    onSelectionChanged: onSelectionChanged,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  const onRowClicked = (event) => {
    navigate("/lead-management/view-leads", {
      state: { leadId: event.data.leadId },
    });
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = "#000";
    }
  }

  function closeModal() {
    setImportFile(null);
    setIsOpen(false);
    setLeadNextButtonActive(false);
  }

  const [importFile, setImportFile] = useState(null);

  function handleFileInputChange(event) {
    const selectedFile = event.target.files[0];
    setImportFile(selectedFile);
    setLeadNextButtonActive(true);
  }

  const handleDownload = () => {
    const fileUrl =
      "https://betaerp-site-docs.s3.us-east-2.amazonaws.com/Import_Lead_File_Sample.xlsx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "SampleLeadImport.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  function onSelectionChanged() {
    var selectedNodes = gridOptions.api.getSelectedNodes();
    var selectedData = selectedNodes.map((node) => node.data);

    if (selectedData.length > 0) {
      setSelectedLead(selectedData[0]);
    } else {
      setSelectedLead(null);
    }
  }
  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);

  const [leadNextButtonActive, setLeadNextButtonActive] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      setImportFile(droppedFile);
      setLeadNextButtonActive(true);
    }
  };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Leads</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <a
            className="lead-table-head-button-create"
            href="/#/lead-management/create-leads"
          >
            Create Lead
          </a>
          <Dropdown>
            <Dropdown.Toggle
              className="lead-table-dropdown-icon-container px-2"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu className="py-0 ">
              <Dropdown.Item onClick={openModal}>Import Leads</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle
              className="lead-table-head-button"
              id="dropdown-basic"
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0 ">
              <Dropdown.Item
                onClick={() => {
                  if (selectedLead) {
                    setOpenCreateTaskModal(true);
                  } else {
                    toast.warn("Please Select Lead");
                  }
                }}
              >
                Create Task
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  if (selectedLead) {
                    deleteLead();
                  } else {
                    toast.warn("Please Select Lead");
                  }
                }}
              >
                Delete Lead
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={leadData}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>

      <Modal
        className="crm-modal"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-Con">
          <div
            className=" col-11 col-md-4 p-3"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="import-lead-popup-modal">
              <div
                className="title mt-5"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <button
                  className="browse-btn"
                  onClick={() => document.getElementById("import-file").click()}
                >
                  Browse File
                </button>
                <input
                  type="file"
                  className="d-none"
                  id="import-file"
                  onChange={handleFileInputChange}
                  accept=".xls, .xlsx, .vcf, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/vcard, text/csv"
                />
              </div>
              {importFile && (
                <span className="text-center">
                  Uploaded File:{" "}
                  <span style={{ color: "#f69f3a" }}>{importFile?.name}</span>
                </span>
              )}
              <div className="title">
                <span>
                  Download sample file{" "}
                  <span
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleDownload}
                  >
                    XLSX
                  </span>
                </span>
              </div>
              <div className="title-text">
                <span>You can import records through an .xlsx</span>
              </div>
              <div className="footer">
                <button onClick={closeModal} id="cancelBtn">
                  Cancel
                </button>

                <button
                  disabled={!leadNextButtonActive}
                  onClick={async () => {
                    if (importFile) {
                      try {
                        const formData = new FormData();
                        formData.append("importFile", importFile);
                        const response = await axiosService.postFileImport(
                          `${LEAD_URL.IMPORT_LEAD}`,
                          importFile,
                          true
                        );

                        if (response.status === 200) {
                          toast.success(LEAD_IMPORT_CONSTANTS.SUCCESS);
                          const blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          });

                          const url = URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.download = "Import_Lead_File_Sample.xlsx";

                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          setIsOpen(false);
                          setImportFile(null);
                          setRefresh(!refresh);
                        } else {
                          toast.error(LEAD_IMPORT_CONSTANTS.FAILED);
                        }
                      } catch (error) {
                        toast.error(LEAD_IMPORT_CONSTANTS.ERROR_OCCUR);
                      }
                    }
                  }}
                  className={`import-next-button-activate ${
                    !leadNextButtonActive && "opacity-50"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {selectedLead && (
        <CreateTaskModal
          selectedLead={selectedLead}
          open={openCreateTaskModal}
          onClose={() => setOpenCreateTaskModal(false)}
        />
      )}
    </div>
  );
}

export default ViewLeads;
