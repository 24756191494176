import betalogo from "assets/images/icons/beta.svg";
import sha256 from "crypto-js/sha256";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import { QRCode } from "react-qr-code";
import { axiosService } from "services/axios.service";
import "./officialReceipt.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";

const OfficialReceipt = ({ downloadType, close, pdfData }) => {
  const [load, setLoad] = useState(false);
  const studentId =
    pdfData?.master?.admissionManagement?.personalData?.studentId;
  const applicationNo =
    pdfData?.master?.admissionManagement?.programDetails[0]?.applicationNo;
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Fees-Receipt"}-${studentId}`).toString().substring(0, 20)
  );

  useEffect(() => {
    const downloadPDF = async (includeWatermark) => {
      const textContentRef = document.getElementById("text-content");
      const mainCanvas = await html2canvas(textContentRef, { scale: 5 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      if (includeWatermark === "view") {
        const watermarkText = "Sample";
        const fontSize = 500;
        const rotationAngle = -45;

        overlayContext.font = `${fontSize}px Arial`;
        overlayContext.fillStyle = "rgba(200, 200, 200, 0.5)";

        const textWidth = overlayContext.measureText(watermarkText).width;
        const x = (mainCanvas.width - textWidth) / 2;
        const y = mainCanvas.height / 2 + 500;

        overlayContext.translate(x, y);
        overlayContext.rotate((rotationAngle * Math.PI) / 180);
        overlayContext.fillText(watermarkText, 0, 0);
      }
      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        0,
        0,
        finalPdf.internal.pageSize.width,
        (overlayCanvas.height * finalPdf.internal.pageSize.width) /
          overlayCanvas.width
      );

      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

      if (includeWatermark === "view") {
        const pdfBlob = finalPdf.output("blob");
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          `<iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`
        );
        setLoad(false);
      } else {
        if (hashValueData) {
          try {
            const pdfBlob = finalPdf.output("blob");

            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

            // setHashValue(hashValue);

            const fileName = `${
              includeWatermark ? "Fees-Receipt-sample" : "Fees-Receipt"
            }_${studentId}.pdf`;

            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "official_Receipt.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);

            setLoad(false);
            close();
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
            close();
          }
        }
      }
    };

    if (downloadType === "view") {
      setLoad(true);
      downloadPDF("view");
    }
    if (downloadType === "download") {
      setLoad(true);
      downloadPDF(false);
    }
  }, [downloadType]);

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent
          pdfData={pdfData}
          includeWatermark={downloadType}
          hashValueData={hashValueData}
        />
      )}
    </span>
  );
};

export default OfficialReceipt;

function PdfContent({ pdfData, includeWatermark, hashValueData }) {

  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );

  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  return (
    <div className="text-content" id="text-content">
      <div id="header-container" className="header-ts">
        <Header />
      </div>
      <div
        style={{
          marginLeft: "10px",
          width: "100%",
          position: "relative",
        }}
      >
        <div className="d-flex justify-space-between gap-5 ">
          <p>
            <strong>Date:</strong> {formatDate(new Date())}
          </p>
          <p style={{ marginLeft: "400px" }}>
            <strong>Receipt No:</strong> -
          </p>
        </div>
        <h3>Official Receipt</h3>

        <div className="d-flex justify-space-between gap-5">
          <p>
            Student Name:
            <p>
              Student ID:
              <p>
                Course:
                <p>The Campus of Study:</p>
              </p>
            </p>
          </p>
          <p>
            {pdfData?.master?.admissionManagement?.personalData?.firstName}{" "}
            {pdfData?.master?.admissionManagement?.personalData?.lastName}
            <p>
              {pdfData?.master?.admissionManagement?.personalData?.studentId}
              <p>
                {
                  pdfData?.master?.admissionManagement?.programDetails[0]
                    ?.program
                }
                <p>
                  {
                    pdfData?.master?.admissionManagement?.programDetails[0]
                      ?.campus
                  }
                </p>
              </p>
            </p>
          </p>
        </div>

        <div className="d-flex justify-space-between gap-5">
          <p>
            Total Fees Payable ({pdfData?.invoiceType}) :
            <p>
              Total Fees Paid ({pdfData?.invoiceType}):
              <p>Mode of Payment :</p>
            </p>
          </p>
          <p>
            CAD {pdfData?.invoiceAmount}
            <p>
              CAD {pdfData?.invoiceAmount}
              <p>{pdfData?.paymentMode}</p>
            </p>
          </p>
        </div>
        <p>
          For any questions, please feel free to contact Accounts Officer at
          +1(905) 497 9999 or
          <p>
            <span>
              email
              <a
                href="mailto:finance@mybeta.ca"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                finance@mybeta.ca
              </a>
            </span>
          </p>
        </p>

        <br></br>
        <p>For Beta College of Business and Technology:</p>
        <p>
          Monica Singh
          <p>
            <strong>Sr.Accounts Officer</strong>
          </p>
          <br></br>
          <strong>Important Information:</strong>
          <p>
            • All fees and payments are subject to Terms and Conditions and
            actual realization. Claims will only be
            <p>
              entertained once the fees/funds are deposited in the college bank
              account/financial institution. Transfer
              <p>
                proof from a financial institution or Official Bank receipt is
                mandatory to produce to admit any application
                <p>for a refund.</p>
              </p>
            </p>
          </p>
          <p>
            • This document can be verified by emailing
            <strong> admin@betacollege.ca / hello@mybeta.ca</strong>
          </p>
        </p>
      </div>

      <div
        className="footer"
        style={{
          marginTop: includeWatermark === "download" ? "16rem" : "17rem",
          marginLeft: "20px",
          position: "relative",
        }}
      >
        {includeWatermark === "download" && hashValueData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "19px",
              zIndex: 1,
            }}
          >
            <QRCode size={70} value={url} />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}
