import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import newfooter from "assets/images/Beta_New_Footer.png";
import newheader from "assets/images/Beta_New_Header.png";
import qrcode from "qrcode";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { PDF_URLS } from "../constants/pdf-urls";

const CLOA = ({ pdfData, applicationNo, setLoading }) => {
  const location = useLocation();

  const HeaderForFloa = () => (
    <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
      <Image
        alt="img"
        src={newheader}
        style={{ width: "100%", height: "100px" }}
      />
    </View>
  );

  const FooterForFloa = () => (
    <View
      style={{ position: "absolute", top: "-80px", left: "-50px", right: 0 }}
    >
      <Image
        alt="img"
        src={newfooter}
        style={{ width: "110%", height: "80px" }}
      />
    </View>
  );

  useEffect(() => {
    generatePDFLink();
  }, [location.pathname, pdfData]);

  const currentDate = new Date();

  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);

  const formattedIssueDate = formatDate(currentDate);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [hashValueData, setHashValueData] = useState("");
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      return "";
    }
  };
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `CLOA-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);
    setHashValueData(hashValue);
  }, [applicationNo, pdfData]);
  const qrCodeDataURL = generateQRCodeDataURL(
    `https://erp.lmsmybeta.com/#/qr-code?hashValue=${hashValueData}`
  );

  const handleSubmit = async (blobToSend) => {
    const pdfFileName = "CLOA.pdf";
    if (hashValueData) {
      try {
        const pdf = new File([blobToSend], pdfFileName, {
          type: "application/pdf",
        });
        const fileName = `${"CLOA"}_${applicationNo}.pdf`;
        const pdfFile = new File([pdf], fileName, {
          type: "application/pdf",
        });
        const url = `${PDF_URLS.CLOA_GENERATE_MAIL}?hashValue=${hashValueData}&admissionId=${pdfData?.admissionId}&applicationNo=${applicationNo}`;
      
        const formData = new FormData();
        formData.append("file", pdfFile);
        const response = await axiosService.postFile(url, pdfFile, {});
        if (response.status === 200) {
          setLoading();
        } else {
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const feeTableText = "";
  const Issue = formattedIssueDate;
  const Expiry = pdfData?.fees[0]?.programDetail?.expirationAcceptanceLetter;
  const Name = pdfData?.personalData?.lastName;
  const FirstName = pdfData?.personalData?.firstName;
  const Yes = "";
  const CAQ = "";
  const Expiry1 = "";
  const Student = pdfData?.admissionId;
  let Term1 = "-";
  let Term2 = "-";
  let Term3 = "-";

  if (pdfData?.fees && pdfData.fees.length > 0) {
    for (let i = 0; i < pdfData.fees.length; i++) {
      Term1 =
        pdfData.fees[i]?.programDetail?.programFee?.installment1 !== ""
          ? pdfData.fees[i]?.programDetail?.programFee?.installment1
          : "-";

      Term2 =
        pdfData.fees[i]?.programDetail?.programFee?.installment2 !== ""
          ? pdfData.fees[i]?.programDetail?.programFee?.installment2
          : "-";

      Term3 =
        pdfData.fees[i]?.programDetail?.programFee?.installment3 !== ""
          ? pdfData.fees[i]?.programDetail?.programFee?.installment3
          : "-";
    }
  }

  const Mailing1 = pdfData?.mailingAddress?.streetNo;
  const Mailing2 = pdfData?.mailingAddress?.street;
  const Mailing3 = pdfData?.mailingAddress?.city;
  const Mailing4 = pdfData?.mailingAddress?.postalCode;
  const Mailing5 = pdfData?.mailingAddress?.country;
  const DOB = pdfData?.personalData?.dateOfBirth;
  const College = "Beta College of Business & Technology, Canada";
  const PCC = "";
  const Contact = "";
  const Position = "";
  const Email = "";
  const DLI = "";
  const applicationFee = pdfData?.fees[0]?.applicationFee;
  const tuitionFee = pdfData?.fees[0]?.tuitionFee;
  const bankFee = pdfData?.fees[0]?.bankFee;
  const internationalFee = pdfData?.fees[0]?.internationalFee;
  const materialFee = pdfData?.fees[0]?.materialFee;
  const eslFee = pdfData?.fees[0]?.eslFee;
  const otherFee = pdfData?.fees[0]?.otherFee;
  const fieldTripsFee = pdfData?.fees[0]?.fieldTripsFee;
  const Notes = "";
  const feeTableText1 = "";

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      padding: 20,
    },
    top: {
      left: 40,
      right: 40,
      color: "grey",
    },
    heading: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 10,
      position: "fixed",
      flexWrap: "wrap", // Add this line to wrap the text
      fontFamily: "Helvetica-Bold",
    },
    header: {
      fontSize: 10,
      marginBottom: 20,
      position: "fixed",
      flexWrap: "wrap", // Add this line to wrap the text
      fontFamily: "Helvetica-Bold",
      top: -8,
    },
    link3: {
      fontSize: 10,
      marginBottom: 20,
      position: "fixed",
      flexWrap: "wrap",
      color: "#0563c1",
      fontFamily: "Helvetica-Bold",
      textDecoration: "underline",
    },
    text: {
      left: 15,
      fontSize: 9,
      width: 190,
      color: "grey",
    },
    footer: {
      position: "absolute",
      bottom: 30,
      left: 50,
      right: 40,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 1,
    },
    boldText: {
      fontFamily: "Helvetica-Bold",

      fontSize: 11,
    },
    actualValue: {
      fontSize: 10,
      fontFamily: "Helvetica",
    },
    footerheading: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      left: 13,
      right: 40,
    },
    end: {
      fontSize: 10,
      textAlign: "right",
      left: 61,
      paddingLeft: 4,
      fontFamily: "Helvetica-Bold",
    },
    column: {
      flexDirection: "column",
      flexGrow: 1,
      width: "50%",
    },
    text1: {
      fontSize: 9,
      width: 170,
      textAlign: "right",
      color: "grey",
    },

    h1: {
      fontSize: 14,
      left: 40,
      right: 40,
      fontFamily: "Helvetica-Bold",
    },
    h2: {
      left: 40,
      right: 40,
      fontSize: 9,
      color: "red",
      paddingRight: 60,
      fontFamily: "Helvetica-Bold",
    },
    h4: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 16,
    },
    h5: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 46,
    },
    Name: {},
    h6: {
      left: 42,
      right: 38,
      fontSize: 11,
      paddingRight: 60,
      top: 56,
    },
    h7: {
      fontSize: 11,
      top: 60,
    },
    h3: {
      fontSize: 11,
      paddingRight: 60,
      paddingBottom: 5,
      fontFamily: "Helvetica-Bold",
    },
    rightAlignedText: {
      fontSize: 11,
      alignSelf: "flex-end", // Align the text to the right end of the View
      marginBottom: 5, // Adjust the space between text
      paddingRight: 80,
      fontFamily: "Helvetica-Bold",
    },
    flex: {
      flexDirection: "row", // Create a row layout
      left: 40,
      right: 40,
      top: 10,
      justifyContent: "space-between", // Align items in a row with space in between
    },
    headertable: {
      top: 10,
      fontSize: 12,
      color: "white",
      marginBottom: 10,
      borderTop: "1",
      borderRight: "1",
      borderLeft: "1",
      marginLeft: 40,
      marginRight: 40,
      backgroundColor: "#2F5496",
      fontFamily: "Helvetica-Bold",
      paddingTop: 1,
    },
    infoContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 5,
    },
    // infoLabel: {
    //   width: '35%',
    //   fontSize: 10,

    // },
    Signature: {
      fontSize: 11,
      marginLeft: 39,
      top: 15,
      fontFamily: "Helvetica-Bold",
    },
    folder: {
      fontSize: 11,
      marginLeft: 40,
      marginRight: 88,
      top: 98,
      fontFamily: "Helvetica-Bold",
    },
    page2: {
      fontSize: 11,
      marginLeft: 39,
      top: -5,
      fontFamily: "Helvetica-Bold",
    },
    feeTableText1: {
      fontSize: 11,
      marginLeft: 39,
      top: 22,
      paddingBottom: 7,
    },
    feeTable: {
      top: 15,
      paddingBottom: 5,
      fontSize: 11,
      marginLeft: 39,
    },
    Signature1: {
      fontSize: 11,
      marginLeft: 39,
      top: 5,
      fontFamily: "Helvetica-Bold",
    },
    dot: {
      fontSize: 11,
      marginLeft: 59,
      top: 25,
    },
    dot1: {
      fontSize: 11,
      marginLeft: 41,
      top: 77,
    },
    remarks: {
      fontSize: 11,
      marginLeft: 43,
      top: 88,
      bottom: 8,
    },
    admissionnotice: {
      left: 42,
      right: 40,
      fontSize: 11,
      paddingRight: 60,
      top: 56,
    },
    link: {
      fontSize: 11,
      marginLeft: 43,
      top: 78,
      color: "#0563c1",
    },
    link1: {
      fontSize: 10,
      marginLeft: 43,
      top: 78,
      color: "#0563c1",
    },
    logo: {
      width: 130, // Adjust the width as needed
      height: 40, // Adjust the height as needed
      marginLeft: "74%", // Adjust the margin as needed
      resizeMode: "cover",
    },
    // infoLabel2
    // : {
    //   width: '35%',
    //   fontSize: 10,
    //   textAlign: "left",
    //   flexDirection: 'row',

    // },
    flexWrap: {
      top: 10,
    },
    border: {
      borderRightWidth: 1,
      borderLeft: 1,
      borderBottom: 1,

      borderColor: "black",
      marginLeft: 40,
      marginRight: 40,
    },
    Condition: {
      borderRightWidth: 1,
      borderLeft: 1,
      borderBottom: 1,

      borderColor: "black",
      marginLeft: 40,
      marginRight: 40,
      paddingBottom: 14,
    },
    //   notes: {
    //     marginLeft: 40,
    //     marginRight: 40,
    //  fontSize: 8,
    //  top:5,
    //   },

    infoValue: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      flexDirection: "row",
    },
    Notes: {
      fontSize: 10,

      flexDirection: "row",
    },
    Programs: {
      width: "65%",
      fontSize: 10,
      flexDirection: "row",
      top: 19,
      right: 160,
    },
    separator: {
      borderBottomWidth: 1,
      marginTop: 10,
      marginBottom: 10,
    },
    verticalLine: {
      borderRightWidth: 1,
      borderColor: "black",
      height: "100",
    },
    right: {
      borderRightWidth: 1,
      marginLeft: 130,
      justifyContent: "flex-end",
    },

    table: {
      marginLeft: 40,
      marginRight: 40,
      top: 10,
      borderWidth: 1,
      fontSize: 9,
    },
    table1: {
      marginLeft: 40,
      marginRight: 40,
      borderWidth: 1,
      fontSize: 10,
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    tableCol: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      paddingLeft: 3,
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
    },
    RegisteredtableCol: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",

      paddingLeft: 3,
      fontSize: 10,
      fontFamily: "Times-Italic",
    },
    RegisteredtableCol1: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",

      paddingLeft: 3,
      fontSize: 11,
    },
    Registeredaligment: {
      top: 20,
    },
    Registered: {
      fontFamily: "Helvetica-Bold",
    },
    body: {
      top: 100,
    },
    qrCodeImage: {
      width: 80,
      top: 195,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    qrCodeImageFor2: {
      width: 80,
      top: 400,
      height: 80,
      position: "relative",
      zIndex: 1,
    },
    alignment: {
      top: -17,
    },
    tablealigment: {
      top: 6,
    },
    newText: {
      borderTopWidth: 1,
      borderTopColor: "#000",
      paddingLeft: 3,
    },
    watermarkContainer: {
      position: "absolute",
      top: "40%",
      left: "30%",
      transform: "translate(-50%, -50%) rotate(-45deg)",
      opacity: 0.5,
    },
    watermarkText: {
      fontSize: 80,
      color: "gray",
    },
    tableColNew: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
    },
    subText: {
      fontWeight: "normal",
    },
  });

  const generatePDFLink = async () => {
    const pdfBlob = await new Promise((resolve, reject) => {
      pdf(
        <Document>
          <Page size="A4" style={styles.page}>
            <HeaderForFloa />
            <View style={styles.body}>
              <View style={styles.alignment}>
                <View>
                  <Text style={styles.h1}>
                    CONDITIONAL LETTER OF ACCEPTANCE
                  </Text>
                </View>
                <Text style={styles.h2}>
                  THIS LETTER CANNOT BE USED FOR VISA PURPOSES. PLEASE ENSURE
                  THAT ANY FEES ASKED FOR IN THIS LETTER ARE PAID BY THE
                  SPECIFIED DUE DATES TO SECURE A SEAT IN THE PROGRAM(S) BEING
                  OFFERED.
                </Text>
                <View style={styles.flex}>
                  <Text style={styles.h3}>
                    Date of Issue: <Text>{Issue}</Text>
                  </Text>
                  <Text style={styles.rightAlignedText}>
                    Date of Expiry: <Text>{Expiry}</Text>
                  </Text>
                </View>
                <View style={styles.tablealigment}>
                  <Text style={styles.headertable}> STUDENT INFORMATION</Text>
                  <View style={styles.table1}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColNew}>
                        <Text style={{ paddingLeft: 3 }}>
                          Family Name:{" "}
                          <Text style={styles.actualValue}>{Name}</Text>
                        </Text>

                        <Text style={styles.newText}>
                          Given Name:
                          <Text style={styles.actualValue}> {FirstName}</Text>
                        </Text>

                        <Text
                          style={{
                            paddingLeft: 3,
                            borderTop: 1,
                            borderTopColor: "#000",
                          }}
                        >
                          CAQ or MIDI:{" "}
                          <Text style={styles.actualValue}>{Yes}</Text>
                        </Text>
                        <Text style={{ paddingLeft: 3 }}>
                          CAQ No: <Text style={styles.actualValue}>{CAQ}</Text>
                        </Text>
                        <Text style={{ paddingLeft: 3 }}>
                          Expiry:{" "}
                          <Text style={styles.actualValue}>{Expiry1}</Text>
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.tableColNew,
                          { flexDirection: "column" },
                        ]}
                      >
                        <Text style={{ paddingLeft: 3 }}>Mailing Address:</Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Mailing1},{Mailing2}
                        </Text>

                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Mailing3}
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Mailing4}
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          {Mailing5}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text>
                          DOB: <Text style={styles.actualValue}>{DOB}</Text>
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text>
                          Student ID:{" "}
                          <Text style={styles.actualValue}>{Student}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <Text style={styles.headertable}>
                  {" "}
                  INSTITUIONAL INFORMATION
                </Text>
                <View style={styles.table1}>
                  <View></View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.Registered}>
                        College Name & Address:{" "}
                      </Text>
                      <Text style={styles.actualValue}>{College}</Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.boldText}>
                        Website:{" "}
                        <Text style={styles.link1}> www.mybeta.ca</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol}>
                      <Text style={styles.Registered}>
                        Type of Institution:
                        <Text style={styles.actualValue}> Private</Text>
                      </Text>
                      <Text style={styles.Registered}>
                        PCC Id: <Text style={styles.actualValue}>{PCC}</Text>
                      </Text>
                      <Text>
                        Registered as Private Career College under the PCC Act,
                        2005
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text>
                        Name of Contact:
                        <Text style={styles.actualValue}> {Contact}</Text>
                      </Text>
                      <Text>
                        Position:{" "}
                        <Text style={styles.actualValue}> {Position}</Text>
                      </Text>
                      <Text>
                        Name: <Text style={styles.actualValue}>{Email}</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text>
                        DLI Number:{" "}
                        <Text style={styles.actualValue}>{DLI}</Text>
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text>
                        Name of Contact:
                        <Text style={styles.actualValue}> {Contact}</Text>
                      </Text>
                      <Text>
                        Position:{" "}
                        <Text style={styles.actualValue}>{Position}</Text>
                      </Text>
                      <Text>
                        Name: <Text style={styles.actualValue}>{Email}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
                <Text style={styles.headertable}> PROGRAM INFORMATION</Text>
                <View style={styles.table1}>
                  <View></View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>Program details as per shared PDF </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text style={styles.Registered}> Fee Structure: </Text>

                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Application Fee:
                        <Text> {applicationFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Tuition Fee:
                        <Text> {tuitionFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Material/Book Fee:
                        <Text> {materialFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        International Fee:
                        <Text> {internationalFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Bank Fee:
                        <Text> {bankFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        ESL Fee:
                        <Text> {eslFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Field Trips Fee:
                        <Text> {fieldTripsFee}</Text>
                      </Text>
                      <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                        Other Fee:
                        <Text> {otherFee}</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.Condition}>
                  <View style={styles.infoContainer}>
                    <Text style={styles.infoValue}>
                      Condition of Acceptance:
                    </Text>
                    <Text style={styles.Programs}>
                      For PG Programs, prior to starting the class, student must
                      provide foreign credential assessed using WES or ICAS.
                    </Text>
                  </View>
                </View>
                <View style={styles.border}>
                  <View style={styles.infoContainer}>
                    <View style={styles.column1}>
                      <Text style={styles.infoValue}>Notes: </Text>
                      <Text style={styles.Notes}>{Notes}</Text>
                    </View>
                    <View style={styles.rightColumn1}></View>
                  </View>
                </View>
                <View>
                  <Text style={styles.Signature}>
                    Signature of Institution Representative:
                  </Text>
                </View>
                <View style={styles.flexWrap}>
                  <Text style={styles.Signature}>
                    Name of Institution Representative:
                    <Text style={styles.actualValue}> {Contact}</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
            </View>

            <View style={styles.footer}>
              <View style={styles.qrCodeContainer}></View>
              <FooterForFloa></FooterForFloa>
            </View>
          </Page>

          <Page size="A4" style={styles.page}>
            <HeaderForFloa />
            <View style={styles.body}>
              <View>
                <Text style={styles.page2}>
                  FEE RELATED INFORMATION FOR INTERNATIONAL STUDENTS
                </Text>
              </View>
              <View>
                <Text style={styles.Signature1}>
                  Tuition Fee Payment Schedule
                </Text>
                <View style={styles.feeTable}>
                  <Text> {feeTableText} </Text>
                </View>
              </View>
              <View Registeredaligment>
                <View style={styles.table}>
                  <View></View>

                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>1st Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term1}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>2nd Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term2}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>3rd Term Tuition Fees </Text>
                    </View>
                    <View style={styles.RegisteredtableCol1}>
                      <Text>{Term3}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text style={styles.feeTableText1}> {feeTableText1} </Text>
              <View style={styles.flexWrap}>
                <Text style={styles.Signature}>
                  {" "}
                  ISSUE OF LETTER OF ACCEPTANCE{" "}
                </Text>

                <Text style={styles.h4}>
                  Please note that this letter is NOT a Letter of Acceptance and
                  cannot be used to apply for student visa/study permit. A
                  separate Letter of Acceptance will be issued, once all of the
                  following apply:
                </Text>
              </View>
              <View style={styles.flexWrap}>
                <Text style={styles.dot}>
                  • You have paid the asked for
                  <Text style={styles.boldText}> Application Fee </Text> by the
                  specified due date.
                </Text>
                <Text style={styles.dot}>
                  • You have met with any
                  <Text style={styles.boldText}> other condition(s) </Text>{" "}
                  specified above, by the specified due date.
                </Text>
              </View>
              <Text style={styles.h5}>
                Once you receive your Letter of Acceptance and Payment Receipt,
                you can apply for student visa/study permit at the nearest
                Canadian embassy or consulate in your home country.
              </Text>
              <Text style={styles.admissionnotice}>
                To complete your admission process, you are also expected to pay
                the
                <Text style={styles.boldText}>
                  {" "}
                  deposit towards Program Tuition Fee{" "}
                </Text>
                by the specified due date, as given in the
                <Text style={styles.boldText}> Fee Structure</Text> column of
                the <Text style={styles.boldText}> Program Information </Text>
                section above.
              </Text>
              <View style={styles.h7}>
                <Text style={styles.Signature}>
                  {" "}
                  VERIFY YOUR STUDENT STATUS{" "}
                </Text>
              </View>
              <Text style={styles.dot1}>
                At any point during your application process at CIMT College,
                you can check the current status of your application by
                visiting:
              </Text>
              <View style={{ textDecoration: "underline" }}>
                <Text style={styles.link}>
                  https://erp.lmsmybeta.com/#/verification-home
                </Text>
              </View>
              <Text style={styles.remarks}>
                Please note that all international students upon arrival in
                Canada must report directly to
              </Text>
              <Text style={styles.folder}>
                International Department, Beta College of Business & Technology
              </Text>
              <Text style={styles.folder}>
                4275 Village Centre Court, Unit 201, Mississauga L4Z 1V3,
                Ontario, Canada
              </Text>
            </View>
            <View>
              <Image style={styles.qrCodeImageFor2} src={qrCodeDataURL} />
            </View>
            <View style={styles.footer}>
              <View style={styles.qrCodeContainer}></View>
              <FooterForFloa></FooterForFloa>
            </View>
          </Page>
        </Document>
      )
        .toBlob()
        .then(resolve)
        .catch(reject);
    });

    const pdfFile = new File([pdfBlob], "CLOA.pdf", {
      type: "application/pdf",
    });

    if (pdfData) {
      handleSubmit(pdfFile);
    }
  };
};
export default CLOA;
