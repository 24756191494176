export const FORUM_DISCUSSION_CONSTANTS = {
  CREATE_FORUM_SUCCESS: "Forum Created successfully",
  CREATE_DISCUSSION_SUCCESS: "Discussion Created successfully",
  CREATE_POST_SUCCESS: "Post Created successfully",
  DELETE_SUCCESS: "Forum deleted successfully",
  DELETE_FAILURE: "Failed to Delete Forum",
  FAILED:
    "An unexpected error occurred during User. Please contact admin/support",
  DELETE_SUCCESS_DISCUSSION: "Discussion deleted successfully",
  DELETE_FAILURE_DISCUSSION: "Failed to Delete Discussion",
  DELETE_SUCCESS_POST: "Post deleted successfully",
  DELETE_FAILURE_POST: "Failed to Delete Post",
};
